/*
 * Copyright 2022 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

/* Generated by restful-react */

import React from "react";
import { Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";

import { getConfig } from "../config";
export const SPEC_VERSION = "1.0";
export interface ResponseMessage {
  code?:
    | "DEFAULT_ERROR_CODE"
    | "INVALID_ARGUMENT"
    | "INVALID_EMAIL"
    | "DOMAIN_NOT_ALLOWED_TO_REGISTER"
    | "USER_ALREADY_REGISTERED"
    | "USER_INVITATION_DOES_NOT_EXIST"
    | "USER_DOES_NOT_EXIST"
    | "USER_INVITE_OPERATION_FAILED"
    | "USER_DISABLED"
    | "ACCOUNT_DOES_NOT_EXIST"
    | "INACTIVE_ACCOUNT"
    | "ACCOUNT_MIGRATED"
    | "USER_DOMAIN_NOT_ALLOWED"
    | "MAX_FAILED_ATTEMPT_COUNT_EXCEEDED"
    | "RESOURCE_NOT_FOUND"
    | "ROLE_DOES_NOT_EXIST"
    | "EMAIL_NOT_VERIFIED"
    | "EMAIL_VERIFICATION_TOKEN_NOT_FOUND"
    | "INVALID_TOKEN"
    | "REVOKED_TOKEN"
    | "INVALID_CAPTCHA_TOKEN"
    | "NOT_ACCOUNT_MGR_NOR_HAS_ALL_APP_ACCESS"
    | "EXPIRED_TOKEN"
    | "TOKEN_ALREADY_REFRESHED_ONCE"
    | "ACCESS_DENIED"
    | "NG_ACCESS_DENIED"
    | "INVALID_CREDENTIAL"
    | "INVALID_CREDENTIALS_THIRD_PARTY"
    | "INVALID_KEY"
    | "INVALID_CONNECTOR_TYPE"
    | "INVALID_KEYPATH"
    | "INVALID_VARIABLE"
    | "UNKNOWN_HOST"
    | "UNREACHABLE_HOST"
    | "INVALID_PORT"
    | "SSH_SESSION_TIMEOUT"
    | "SOCKET_CONNECTION_ERROR"
    | "CONNECTION_ERROR"
    | "SOCKET_CONNECTION_TIMEOUT"
    | "CONNECTION_TIMEOUT"
    | "SSH_CONNECTION_ERROR"
    | "USER_GROUP_ERROR"
    | "INVALID_EXECUTION_ID"
    | "ERROR_IN_GETTING_CHANNEL_STREAMS"
    | "UNEXPECTED"
    | "UNKNOWN_ERROR"
    | "UNKNOWN_EXECUTOR_TYPE_ERROR"
    | "DUPLICATE_STATE_NAMES"
    | "TRANSITION_NOT_LINKED"
    | "TRANSITION_TO_INCORRECT_STATE"
    | "TRANSITION_TYPE_NULL"
    | "STATES_WITH_DUP_TRANSITIONS"
    | "BARRIERS_NOT_RUNNING_CONCURRENTLY"
    | "NON_FORK_STATES"
    | "NON_REPEAT_STATES"
    | "INITIAL_STATE_NOT_DEFINED"
    | "FILE_INTEGRITY_CHECK_FAILED"
    | "INVALID_URL"
    | "FILE_DOWNLOAD_FAILED"
    | "PLATFORM_SOFTWARE_DELETE_ERROR"
    | "INVALID_CSV_FILE"
    | "INVALID_REQUEST"
    | "SCHEMA_VALIDATION_FAILED"
    | "FILTER_CREATION_ERROR"
    | "INVALID_YAML_ERROR"
    | "PLAN_CREATION_ERROR"
    | "INVALID_INFRA_STATE"
    | "PIPELINE_ALREADY_TRIGGERED"
    | "NON_EXISTING_PIPELINE"
    | "DUPLICATE_COMMAND_NAMES"
    | "INVALID_PIPELINE"
    | "COMMAND_DOES_NOT_EXIST"
    | "DUPLICATE_ARTIFACTSTREAM_NAMES"
    | "DUPLICATE_HOST_NAMES"
    | "STATE_NOT_FOR_TYPE"
    | "STATE_MACHINE_ISSUE"
    | "STATE_DISCONTINUE_FAILED"
    | "STATE_PAUSE_FAILED"
    | "PAUSE_ALL_ALREADY"
    | "RESUME_ALL_ALREADY"
    | "ROLLBACK_ALREADY"
    | "ABORT_ALL_ALREADY"
    | "RETRY_FAILED"
    | "UNKNOWN_ARTIFACT_TYPE"
    | "UNKNOWN_STAGE_ELEMENT_WRAPPER_TYPE"
    | "INIT_TIMEOUT"
    | "LICENSE_EXPIRED"
    | "NOT_LICENSED"
    | "REQUEST_TIMEOUT"
    | "WORKFLOW_ALREADY_TRIGGERED"
    | "JENKINS_ERROR"
    | "INVALID_ARTIFACT_SOURCE"
    | "INVALID_ARTIFACT_SERVER"
    | "INVALID_CLOUD_PROVIDER"
    | "UPDATE_NOT_ALLOWED"
    | "DELETE_NOT_ALLOWED"
    | "APPDYNAMICS_CONFIGURATION_ERROR"
    | "APM_CONFIGURATION_ERROR"
    | "SPLUNK_CONFIGURATION_ERROR"
    | "ELK_CONFIGURATION_ERROR"
    | "LOGZ_CONFIGURATION_ERROR"
    | "SUMO_CONFIGURATION_ERROR"
    | "INSTANA_CONFIGURATION_ERROR"
    | "APPDYNAMICS_ERROR"
    | "STACKDRIVER_ERROR"
    | "STACKDRIVER_CONFIGURATION_ERROR"
    | "NEWRELIC_CONFIGURATION_ERROR"
    | "NEWRELIC_ERROR"
    | "DYNA_TRACE_CONFIGURATION_ERROR"
    | "DYNA_TRACE_ERROR"
    | "CLOUDWATCH_ERROR"
    | "CLOUDWATCH_CONFIGURATION_ERROR"
    | "PROMETHEUS_CONFIGURATION_ERROR"
    | "DATA_DOG_CONFIGURATION_ERROR"
    | "SERVICE_GUARD_CONFIGURATION_ERROR"
    | "ENCRYPTION_NOT_CONFIGURED"
    | "UNAVAILABLE_DELEGATES"
    | "WORKFLOW_EXECUTION_IN_PROGRESS"
    | "PIPELINE_EXECUTION_IN_PROGRESS"
    | "AWS_ACCESS_DENIED"
    | "AWS_CLUSTER_NOT_FOUND"
    | "AWS_SERVICE_NOT_FOUND"
    | "IMAGE_NOT_FOUND"
    | "ILLEGAL_ARGUMENT"
    | "IMAGE_TAG_NOT_FOUND"
    | "DELEGATE_NOT_AVAILABLE"
    | "INVALID_YAML_PAYLOAD"
    | "AUTHENTICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "UNRECOGNIZED_YAML_FIELDS"
    | "COULD_NOT_MAP_BEFORE_YAML"
    | "MISSING_BEFORE_YAML"
    | "MISSING_YAML"
    | "NON_EMPTY_DELETIONS"
    | "GENERAL_YAML_ERROR"
    | "GENERAL_YAML_INFO"
    | "YAML_GIT_SYNC_ERROR"
    | "GIT_CONNECTION_ERROR"
    | "GIT_ERROR"
    | "ARTIFACT_SERVER_ERROR"
    | "ENCRYPT_DECRYPT_ERROR"
    | "SECRET_MANAGEMENT_ERROR"
    | "SECRET_NOT_FOUND"
    | "KMS_OPERATION_ERROR"
    | "GCP_KMS_OPERATION_ERROR"
    | "VAULT_OPERATION_ERROR"
    | "AWS_SECRETS_MANAGER_OPERATION_ERROR"
    | "AZURE_KEY_VAULT_OPERATION_ERROR"
    | "CYBERARK_OPERATION_ERROR"
    | "UNSUPPORTED_OPERATION_EXCEPTION"
    | "FEATURE_UNAVAILABLE"
    | "GENERAL_ERROR"
    | "BASELINE_CONFIGURATION_ERROR"
    | "SAML_IDP_CONFIGURATION_NOT_AVAILABLE"
    | "INVALID_AUTHENTICATION_MECHANISM"
    | "INVALID_SAML_CONFIGURATION"
    | "INVALID_OAUTH_CONFIGURATION"
    | "INVALID_LDAP_CONFIGURATION"
    | "USER_GROUP_SYNC_FAILURE"
    | "USER_GROUP_ALREADY_EXIST"
    | "INVALID_TWO_FACTOR_AUTHENTICATION_CONFIGURATION"
    | "EXPLANATION"
    | "HINT"
    | "NOT_WHITELISTED_IP"
    | "INVALID_TOTP_TOKEN"
    | "EMAIL_FAILED"
    | "SSL_HANDSHAKE_FAILED"
    | "NO_APPS_ASSIGNED"
    | "INVALID_INFRA_CONFIGURATION"
    | "TEMPLATES_LINKED"
    | "USER_HAS_NO_PERMISSIONS"
    | "USER_NOT_AUTHORIZED"
    | "USER_ALREADY_PRESENT"
    | "INVALID_USAGE_RESTRICTION"
    | "USAGE_RESTRICTION_ERROR"
    | "STATE_EXECUTION_INSTANCE_NOT_FOUND"
    | "DELEGATE_TASK_RETRY"
    | "KUBERNETES_YAML_ERROR"
    | "SAVE_FILE_INTO_GCP_STORAGE_FAILED"
    | "READ_FILE_FROM_GCP_STORAGE_FAILED"
    | "FILE_NOT_FOUND_ERROR"
    | "USAGE_LIMITS_EXCEEDED"
    | "EVENT_PUBLISH_FAILED"
    | "JIRA_ERROR"
    | "EXPRESSION_EVALUATION_FAILED"
    | "KUBERNETES_VALUES_ERROR"
    | "KUBERNETES_CLUSTER_ERROR"
    | "INCORRECT_SIGN_IN_MECHANISM"
    | "OAUTH_LOGIN_FAILED"
    | "INVALID_TERRAFORM_TARGETS_REQUEST"
    | "TERRAFORM_EXECUTION_ERROR"
    | "FILE_READ_FAILED"
    | "FILE_SIZE_EXCEEDS_LIMIT"
    | "CLUSTER_NOT_FOUND"
    | "MARKETPLACE_TOKEN_NOT_FOUND"
    | "INVALID_MARKETPLACE_TOKEN"
    | "INVALID_TICKETING_SERVER"
    | "SERVICENOW_ERROR"
    | "PASSWORD_EXPIRED"
    | "USER_LOCKED"
    | "PASSWORD_STRENGTH_CHECK_FAILED"
    | "ACCOUNT_DISABLED"
    | "INVALID_ACCOUNT_PERMISSION"
    | "PAGERDUTY_ERROR"
    | "HEALTH_ERROR"
    | "SAML_TEST_SUCCESS_MECHANISM_NOT_ENABLED"
    | "DOMAIN_WHITELIST_FILTER_CHECK_FAILED"
    | "INVALID_DASHBOARD_UPDATE_REQUEST"
    | "DUPLICATE_FIELD"
    | "INVALID_AZURE_VAULT_CONFIGURATION"
    | "USER_NOT_AUTHORIZED_DUE_TO_USAGE_RESTRICTIONS"
    | "INVALID_ROLLBACK"
    | "DATA_COLLECTION_ERROR"
    | "SUMO_DATA_COLLECTION_ERROR"
    | "DEPLOYMENT_GOVERNANCE_ERROR"
    | "BATCH_PROCESSING_ERROR"
    | "GRAPHQL_ERROR"
    | "FILE_CREATE_ERROR"
    | "ILLEGAL_STATE"
    | "GIT_DIFF_COMMIT_NOT_IN_ORDER"
    | "FAILED_TO_ACQUIRE_PERSISTENT_LOCK"
    | "FAILED_TO_ACQUIRE_NON_PERSISTENT_LOCK"
    | "POD_NOT_FOUND_ERROR"
    | "COMMAND_EXECUTION_ERROR"
    | "REGISTRY_EXCEPTION"
    | "ENGINE_INTERRUPT_PROCESSING_EXCEPTION"
    | "ENGINE_IO_EXCEPTION"
    | "ENGINE_OUTCOME_EXCEPTION"
    | "ENGINE_SWEEPING_OUTPUT_EXCEPTION"
    | "CACHE_NOT_FOUND_EXCEPTION"
    | "ENGINE_ENTITY_UPDATE_EXCEPTION"
    | "SHELL_EXECUTION_EXCEPTION"
    | "TEMPLATE_NOT_FOUND"
    | "AZURE_SERVICE_EXCEPTION"
    | "AZURE_CLIENT_EXCEPTION"
    | "GIT_UNSEEN_REMOTE_HEAD_COMMIT"
    | "TIMEOUT_ENGINE_EXCEPTION"
    | "NO_AVAILABLE_DELEGATES"
    | "NO_INSTALLED_DELEGATES"
    | "DUPLICATE_DELEGATE_EXCEPTION"
    | "GCP_MARKETPLACE_EXCEPTION"
    | "MISSING_DEFAULT_GOOGLE_CREDENTIALS"
    | "INCORRECT_DEFAULT_GOOGLE_CREDENTIALS"
    | "OPTIMISTIC_LOCKING_EXCEPTION"
    | "NG_PIPELINE_EXECUTION_EXCEPTION"
    | "NG_PIPELINE_CREATE_EXCEPTION"
    | "RESOURCE_NOT_FOUND_EXCEPTION"
    | "PMS_INITIALIZE_SDK_EXCEPTION"
    | "UNEXPECTED_SNIPPET_EXCEPTION"
    | "UNEXPECTED_SCHEMA_EXCEPTION"
    | "CONNECTOR_VALIDATION_EXCEPTION"
    | "TIMESCALE_NOT_AVAILABLE"
    | "MIGRATION_EXCEPTION"
    | "REQUEST_PROCESSING_INTERRUPTED"
    | "GCP_SECRET_MANAGER_OPERATION_ERROR"
    | "GCP_SECRET_OPERATION_ERROR"
    | "GIT_OPERATION_ERROR"
    | "TASK_FAILURE_ERROR"
    | "INSTANCE_STATS_PROCESS_ERROR"
    | "INSTANCE_STATS_MIGRATION_ERROR"
    | "DEPLOYMENT_MIGRATION_ERROR"
    | "INSTANCE_STATS_AGGREGATION_ERROR"
    | "UNRESOLVED_EXPRESSIONS_ERROR"
    | "KRYO_HANDLER_NOT_FOUND_ERROR"
    | "DELEGATE_ERROR_HANDLER_EXCEPTION"
    | "UNEXPECTED_TYPE_ERROR"
    | "EXCEPTION_HANDLER_NOT_FOUND"
    | "CONNECTOR_NOT_FOUND_EXCEPTION"
    | "GCP_SERVER_ERROR"
    | "HTTP_RESPONSE_EXCEPTION"
    | "SCM_NOT_FOUND_ERROR"
    | "SCM_CONFLICT_ERROR"
    | "SCM_UNPROCESSABLE_ENTITY"
    | "PROCESS_EXECUTION_EXCEPTION"
    | "SCM_UNAUTHORIZED"
    | "DATA"
    | "CONTEXT"
    | "PR_CREATION_ERROR"
    | "URL_NOT_REACHABLE"
    | "URL_NOT_PROVIDED"
    | "ENGINE_EXPRESSION_EVALUATION_ERROR"
    | "ENGINE_FUNCTOR_ERROR"
    | "JIRA_CLIENT_ERROR"
    | "SCM_NOT_MODIFIED"
    | "JIRA_STEP_ERROR"
    | "BUCKET_SERVER_ERROR";
  level?: "INFO" | "ERROR";
  message?: string;
  exception?: Throwable;
  failureTypes?: (
    | "EXPIRED"
    | "DELEGATE_PROVISIONING"
    | "CONNECTIVITY"
    | "AUTHENTICATION"
    | "VERIFICATION_FAILURE"
    | "APPLICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "TIMEOUT_ERROR"
  )[];
}

export interface RestResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: { [key: string]: any };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseString {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: string;
  responseMessages?: ResponseMessage[];
}

export interface StackTraceElement {
  methodName?: string;
  fileName?: string;
  lineNumber?: number;
  className?: string;
  nativeMethod?: boolean;
}

export interface Throwable {
  cause?: Throwable;
  stackTrace?: StackTraceElement[];
  message?: string;
  localizedMessage?: string;
  suppressed?: Throwable[];
}

export interface DelegateConnectionDetails {
  uuid?: string;
  version?: string;
  lastHeartbeat?: number;
}

export interface DelegateGroupDetails {
  groupId?: string;
  delegateGroupIdentifier?: string;
  delegateType?: string;
  groupName?: string;
  groupHostName?: string;
  delegateDescription?: string;
  delegateConfigurationId?: string;
  sizeDetails?: DelegateSizeDetails;
  groupImplicitSelectors?: {
    [key: string]:
      | "PROFILE_NAME"
      | "DELEGATE_NAME"
      | "HOST_NAME"
      | "GROUP_NAME"
      | "GROUP_SELECTORS"
      | "PROFILE_SELECTORS";
  };
  groupCustomSelectors?: string[];
  delegateInsightsDetails?: DelegateInsightsDetails;
  lastHeartBeat?: number;
  activelyConnected?: boolean;
  delegateInstanceDetails?: DelegateInner[];
}

export interface DelegateInner {
  uuid?: string;
  ip?: string;
  hostName?: string;
  delegateName?: string;
  delegateGroupName?: string;
  description?: string;
  status?: "ENABLED" | "WAITING_FOR_APPROVAL" | "DISABLED" | "DELETED";
  lastHeartBeat?: number;
  activelyConnected?: boolean;
  delegateProfileId?: string;
  delegateType?: string;
  polllingModeEnabled?: boolean;
  proxy?: boolean;
  ceEnabled?: boolean;
  includeScopes?: DelegateScope[];
  excludeScopes?: DelegateScope[];
  tags?: string[];
  implicitSelectors?: {
    [key: string]:
      | "PROFILE_NAME"
      | "DELEGATE_NAME"
      | "HOST_NAME"
      | "GROUP_NAME"
      | "GROUP_SELECTORS"
      | "PROFILE_SELECTORS";
  };
  profileExecutedAt?: number;
  profileError?: boolean;
  sampleDelegate?: boolean;
  connections?: DelegateConnectionDetails[];
}

export interface DelegateInsightsBarDetails {
  timeStamp?: number;
  counts?: PairDelegateInsightsTypeLong[];
}

export interface DelegateInsightsDetails {
  insights?: DelegateInsightsBarDetails[];
}

export interface DelegateSizeDetails {
  size?: "EXTRA_SMALL" | "LAPTOP" | "SMALL" | "MEDIUM" | "LARGE";
  label?: string;
  taskLimit?: number;
  replicas?: number;
  ram?: number;
  cpu?: number;
}

export interface Pair {
  value?: { [key: string]: any };
  key?: { [key: string]: any };
  right?: { [key: string]: any };
  left?: { [key: string]: any };
}

export interface PairDelegateInsightsTypeLong {
  value?: number;
  key?: "SUCCESSFUL" | "FAILED" | "IN_PROGRESS" | "PERPETUAL_TASK_ASSIGNED";
  right?: number;
  left?: "SUCCESSFUL" | "FAILED" | "IN_PROGRESS" | "PERPETUAL_TASK_ASSIGNED";
}

export interface RestResponseDelegateGroupDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateGroupDetails;
  responseMessages?: ResponseMessage[];
}

export interface DelegateGroupListing {
  delegateGroupDetails?: DelegateGroupDetails[];
}

export interface RestResponseDelegateGroupListing {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateGroupListing;
  responseMessages?: ResponseMessage[];
}

export interface DelegateResponseData {
  [key: string]: any;
}

export interface DelegateTaskResponse {
  accountId?: string;
  response?: DelegateResponseData;
  responseCode?: "OK" | "FAILED" | "RETRY_ON_OTHER_DELEGATE";
}

export interface DelegateTokenDetails {
  uuid?: string;
  accountId?: string;
  name?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  status?: "ACTIVE" | "REVOKED";
  value?: string;
}

export interface EmbeddedUser {
  uuid?: string;
  name?: string;
  email?: string;
}

export interface RestResponseDelegateTokenDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateTokenDetails;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseVoid {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Void;
  responseMessages?: ResponseMessage[];
}

export interface Void {
  [key: string]: any;
}

export interface RestResponseListDelegateTokenDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateTokenDetails[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseBoolean {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: boolean;
  responseMessages?: ResponseMessage[];
}

export interface AccessRequestDTO {
  accessRequestId?: string;
  accountId?: string;
  harnessUserGroupId?: string;
  harnessUserGroupName?: string;
  emailIds?: string[];
  accessStartAt?: number;
  accessEndAt?: number;
  hours?: number;
  accessType?: "GROUP_ACCESS" | "MEMBER_ACCESS";
  accessActive?: boolean;
}

export interface RestResponseAccessRequestDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccessRequestDTO;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListAccessRequestDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccessRequestDTO[];
  responseMessages?: ResponseMessage[];
}

export interface ImportStatus {
  collectionName?: string;
  imported?: number;
  idClashes?: number;
}

export interface ImportStatusReport {
  mode?: "DRY_RUN" | "UPSERT" | "INSERT";
  statuses?: ImportStatus[];
}

export interface RestResponseImportStatusReport {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ImportStatusReport;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseSetString {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: string[];
  responseMessages?: ResponseMessage[];
}

export interface Account {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  companyName: string;
  accountName: string;
  whitelistedDomains?: string[];
  licenseId?: string;
  dataRetentionDurationMs?: number;
  licenseInfo?: LicenseInfo;
  ceLicenseInfo?: CeLicenseInfo;
  accountEvents?: AccountEvent[];
  subdomainUrl?: string;
  twoFactorAdminEnforced?: boolean;
  forImport?: boolean;
  migratedToClusterUrl?: string;
  defaultExperience?: "NG" | "CG";
  createdFromNG?: boolean;
  localEncryptionEnabled?: boolean;
  delegateConfiguration?: DelegateConfiguration;
  techStacks?: TechStack[];
  oauthEnabled?: boolean;
  accountPreferences?: AccountPreferences;
  cloudCostEnabled?: boolean;
  ceAutoCollectK8sEvents?: boolean;
  trialSignupOptions?: TrialSignupOptions;
  serviceGuardLimit?: number;
  serviceAccountConfig?: ServiceAccountConfig;
  defaults?: {
    [key: string]: string;
  };
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
  povAccount?: boolean;
  harnessSupportAccessAllowed?: boolean;
}

export interface AccountEvent {
  accountEventType?:
    | "APP_CREATED"
    | "SERVICE_CREATED"
    | "ENV_CREATED"
    | "WORKFLOW_CREATED"
    | "WORKFLOW_DEPLOYED"
    | "PIPELINE_DEPLOYED"
    | "DELEGATE_INSTALLED"
    | "CLOUD_PROVIDER_CREATED"
    | "ARTIFACT_REPO_CREATED"
    | "PIPELINE_CREATED"
    | "ARTIFACT_STREAM_ADDED"
    | "INFRA_MAPPING_ADDED"
    | "INFRA_DEFINITION_ADDED"
    | "CUSTOM";
  customMsg?: string;
  category?: string;
  properties?: {
    [key: string]: string;
  };
}

export interface AccountPreferences {
  delegateSecretsCacheTTLInHours?: number;
}

export interface CeLicenseInfo {
  licenseType?: "FULL_TRIAL" | "LIMITED_TRIAL" | "PAID";
  expiryTime?: number;
}

export interface DelegateConfiguration {
  delegateVersions?: string[];
  action?: "SELF_DESTRUCT";
}

export interface LicenseInfo {
  accountType?: string;
  accountStatus?: string;
  expiryTime?: number;
  licenseUnits?: number;
}

export interface RestResponsePageResponseAccount {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Account[];
  responseMessages?: ResponseMessage[];
}

export interface ServiceAccountConfig {
  apiKeyLimit?: number;
  tokenLimit?: number;
}

export interface TechStack {
  category?: string;
  technology?: string;
}

export interface TrialSignupOptions {
  productsSelected?: ("CD" | "CE" | "CI")[];
  assistedOption?: boolean;
}

export interface RestResponseAccount {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Account;
  responseMessages?: ResponseMessage[];
}

export interface SubdomainUrl {
  url?: string;
}

export interface LicenseUpdateInfo {
  licenseInfo: LicenseInfo;
  requiredInfoToComply?: {
    [key: string]: {
      [key: string]: { [key: string]: any };
    };
  };
}

export interface AccountSalesContactsInfo {
  salesContacts?: string[];
}

export interface Activity {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  type?: "Command" | "Verification" | "Other";
  applicationName?: string;
  environmentId?: string;
  environmentName?: string;
  environmentType: "PROD" | "NON_PROD" | "ALL";
  commandName?: string;
  commandUnits: CommandUnit[];
  commandNameVersionMap?: {
    [key: string]: number;
  };
  commandType?: string;
  serviceId?: string;
  serviceName?: string;
  serviceTemplateId?: string;
  serviceTemplateName?: string;
  hostName?: string;
  publicDns?: string;
  serviceInstanceId?: string;
  infrastructureDefinitionId?: string;
  workflowExecutionId?: string;
  workflowId?: string;
  workflowExecutionName?: string;
  workflowType: "PIPELINE" | "ORCHESTRATION";
  stateExecutionInstanceId?: string;
  stateExecutionInstanceName?: string;
  version?: number;
  commandUnitType?:
    | "COMMAND"
    | "JENKINS"
    | "GCB"
    | "HELM"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "PCF_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_UPDATE_LISTENER"
    | "PCF_RESIZE"
    | "PCF_MAP_ROUTE"
    | "PCF_BG_SWAP_ROUTE"
    | "PCF_PLUGIN"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "KUBERNETES"
    | "AWS_AMI_SWITCH_ROUTES"
    | "AWS_ECS_UPDATE_LISTENER_BG"
    | "AWS_ECS_UPDATE_ROUTE_53_DNS_WEIGHT"
    | "AWS_ECS_SERVICE_SETUP"
    | "AWS_ECS_RUN_TASK_DEPLOY"
    | "AWS_ECS_SERVICE_SETUP_ROUTE53"
    | "AWS_ECS_SERVICE_SETUP_ELB"
    | "AWS_ECS_SERVICE_SETUP_DAEMON"
    | "AWS_ECS_SERVICE_ROLLBACK_DAEMON"
    | "AWS_ECS_SERVICE_DEPLOY"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_SWAP"
    | "AZURE_APP_SERVICE_SLOT_SETUP"
    | "AZURE_APP_SERVICE_SLOT_TRAFFIC_SHIFT"
    | "AZURE_APP_SERVICE_SLOT_SWAP"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES"
    | "AZURE_ARM_DEPLOYMENT"
    | "AZURE_BLUEPRINT_DEPLOYMENT"
    | "TERRAGRUNT_PROVISION";
  logPurged?: boolean;
  artifactStreamId?: string;
  artifactStreamName?: string;
  artifactId?: string;
  artifactName?: string;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  triggeredBy?: TriggeredBy;
  accountId?: string;
  validUntil?: string;
  pipeline?: boolean;
}

export interface AppContainer {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  fileUuid?: string;
  fileName?: string;
  mimeType?: string;
  size?: number;
  checksumType?: "MD5" | "SHA1" | "SHA256";
  checksum?: string;
  accountId?: string;
  standard?: boolean;
  description?: string;
  standardUpload?: boolean;
  family?: "TOMCAT" | "JBOSS";
  stackRootDirectory?: string;
  fileType?: "ZIP" | "TAR_GZ" | "TAR_BZ" | "TAR" | "UNKNOWN";
  systemCreated?: boolean;
  version?: string;
  hardened?: boolean;
}

export interface ArtifactStream {
  artifactStreamId?: string;
  artifactStreamType?: string;
  sourceName?: string;
  settingId?: string;
  artifactServerName?: string;
  name?: string;
  autoPopulate?: boolean;
  serviceId?: string;
  service?: Service;
  autoDownload?: boolean;
  autoApproveForProduction?: boolean;
  metadataOnly?: boolean;
  failedCronAttempts?: number;
  nextIteration?: number;
  nextCleanupIteration?: number;
  templateUuid?: string;
  templateVersion?: string;
  templateVariables?: Variable[];
  accountId?: string;
  keywords?: string[];
  artifactCount?: number;
  artifacts?: ArtifactSummary[];
  sample?: boolean;
  perpetualTaskId?: string;
  collectionStatus?: string;
  artifactStreamParameterized?: boolean;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface ArtifactStreamBinding {
  name?: string;
  artifactStreams?: ArtifactStreamSummary[];
}

export interface ArtifactStreamSummary {
  artifactStreamId?: string;
  settingId?: string;
  displayName?: string;
  name?: string;
  lastCollectedArtifact?: string;
  defaultArtifact?: ArtifactSummary;
}

export interface ArtifactSummary {
  uuid?: string;
  uiDisplayName?: string;
  buildNo?: string;
}

export interface AzureAppDeploymentData {
  instanceId?: string;
  instanceType?: string;
  instanceName?: string;
  resourceGroup?: string;
  subscriptionId?: string;
  appName?: string;
  deploySlot?: string;
  deploySlotId?: string;
  appServicePlanId?: string;
  hostName?: string;
  instanceIp?: string;
  instanceState?: string;
}

export interface AzureVMInstanceData {
  instanceId?: string;
  publicDnsName?: string;
  privateDnsName?: string;
  privateIpAddress?: string;
}

export interface CVConfiguration {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name: string;
  accountId: string;
  connectorId: string;
  envId: string;
  serviceId: string;
  stateType:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  analysisTolerance: "LOW" | "MEDIUM" | "HIGH";
  customThresholdRefId?: string;
  enabled24x7?: boolean;
  comparisonStrategy?:
    | "COMPARE_WITH_PREVIOUS"
    | "COMPARE_WITH_CURRENT"
    | "PREDICTIVE";
  contextId?: string;
  alertEnabled?: boolean;
  alertThreshold?: number;
  numOfOccurrencesForAlert?: number;
  snoozeStartTime?: number;
  snoozeEndTime?: number;
  connectorName?: string;
  serviceName?: string;
  envName?: string;
  appName?: string;
  workflowConfig?: boolean;
}

export interface CVEnabledService {
  service?: Service;
  cvConfig?: CVConfiguration[];
  appName?: string;
  appId?: string;
}

export interface CapacityReservationSpecificationResponse {
  capacityReservationPreference?: string;
  capacityReservationTarget?: CapacityReservationTargetResponse;
}

export interface CapacityReservationTargetResponse {
  capacityReservationId?: string;
  capacityReservationResourceGroupArn?: string;
}

export interface Command {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  commandUnitType?:
    | "EXEC"
    | "SCP"
    | "COPY_CONFIGS"
    | "COMMAND"
    | "SETUP_ENV"
    | "DOCKER_START"
    | "DOCKER_STOP"
    | "PROCESS_CHECK_RUNNING"
    | "PROCESS_CHECK_STOPPED"
    | "PORT_CHECK_CLEARED"
    | "PORT_CHECK_LISTENING"
    | "CODE_DEPLOY"
    | "AWS_LAMBDA"
    | "AWS_AMI"
    | "ECS_SETUP"
    | "ECS_SETUP_DAEMON_SCHEDULING_TYPE"
    | "KUBERNETES_SETUP"
    | "RESIZE"
    | "RESIZE_KUBERNETES"
    | "DOWNLOAD_ARTIFACT"
    | "K8S_DUMMY"
    | "SPOTINST_DUMMY"
    | "HELM_DUMMY"
    | "PCF_DUMMY"
    | "AZURE_VMSS_DUMMY"
    | "AZURE_WEBAPP"
    | "FETCH_INSTANCES_DUMMY"
    | "AZURE_ARM"
    | "TERRAGRUNT_PROVISION";
  commandExecutionStatus?:
    | "SUCCESS"
    | "FAILURE"
    | "RUNNING"
    | "QUEUED"
    | "SKIPPED";
  artifactNeeded?: boolean;
  deploymentType?: string;
  originEntityId?: string;
  containerFamily?: "TOMCAT" | "JBOSS";
  artifactType?:
    | "JAR"
    | "WAR"
    | "TAR"
    | "ZIP"
    | "NUGET"
    | "DOCKER"
    | "RPM"
    | "AWS_LAMBDA"
    | "AWS_CODEDEPLOY"
    | "PCF"
    | "AMI"
    | "AZURE_MACHINE_IMAGE"
    | "AZURE_WEBAPP"
    | "IIS"
    | "OTHER"
    | "IIS_APP"
    | "IIS_VirtualDirectory";
  referenceId?: string;
  referenceUuid?: string;
  templateReference?: TemplateReference;
  graph?: Graph;
  version?: number;
  commandUnits?: CommandUnit[];
  commandType?:
    | "START"
    | "STOP"
    | "INSTALL"
    | "ENABLE"
    | "DISABLE"
    | "VERIFY"
    | "OTHER"
    | "RESIZE"
    | "SETUP";
  templateVariables?: Variable[];
  templateMetadata?: TemplateMetadata;
  accountId?: string;
  variables?: Variable[];
}

export interface CommandUnit {
  name?: string;
  variables?: Variable[];
  commandExecutionStatus?:
    | "SUCCESS"
    | "FAILURE"
    | "RUNNING"
    | "QUEUED"
    | "SKIPPED";
  commandUnitType?:
    | "EXEC"
    | "SCP"
    | "COPY_CONFIGS"
    | "COMMAND"
    | "SETUP_ENV"
    | "DOCKER_START"
    | "DOCKER_STOP"
    | "PROCESS_CHECK_RUNNING"
    | "PROCESS_CHECK_STOPPED"
    | "PORT_CHECK_CLEARED"
    | "PORT_CHECK_LISTENING"
    | "CODE_DEPLOY"
    | "AWS_LAMBDA"
    | "AWS_AMI"
    | "ECS_SETUP"
    | "ECS_SETUP_DAEMON_SCHEDULING_TYPE"
    | "KUBERNETES_SETUP"
    | "RESIZE"
    | "RESIZE_KUBERNETES"
    | "DOWNLOAD_ARTIFACT"
    | "K8S_DUMMY"
    | "SPOTINST_DUMMY"
    | "HELM_DUMMY"
    | "PCF_DUMMY"
    | "AZURE_VMSS_DUMMY"
    | "AZURE_WEBAPP"
    | "FETCH_INSTANCES_DUMMY"
    | "AZURE_ARM"
    | "TERRAGRUNT_PROVISION";
  artifactNeeded?: boolean;
  deploymentType?: string;
}

export interface ConfigFile {
  templateId?: string;
  envId?: string;
  entityType:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  entityId?: string;
  description?: string;
  parentConfigFileId?: string;
  relativeFilePath?: string;
  targetToAllEnv?: boolean;
  defaultVersion?: number;
  envIdVersionMap?: {
    [key: string]: EntityVersion;
  };
  envIdVersionMapString?: string;
  setAsDefault?: boolean;
  notes?: string;
  overridePath?: string;
  configOverrideType?: "ALL" | "INSTANCES" | "CUSTOM";
  configOverrideExpression?: string;
  instances?: string[];
  overriddenConfigFile?: ConfigFile;
  encrypted?: boolean;
  encryptedFileId?: string;
  secretFileName?: string;
  serviceId?: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  encryptedBy?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  fileUuid?: string;
  fileName?: string;
  mimeType?: string;
  size?: number;
  checksumType?: "MD5" | "SHA1" | "SHA256";
  checksum?: string;
  accountId?: string;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
}

export interface ContextElement {
  uuid?: string;
  name?: string;
  elementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE";
}

export type CopiedTemplateMetadata = TemplateMetadata & {
  parentTemplateId?: string;
  parentTemplateVersion?: number;
  parentCommandVersion?: string;
  parentCommandName?: string;
  parentCommandStoreName?: string;
};

export interface CpuOptions {
  coreCount?: number;
  threadsPerCore?: number;
}

export interface DelegateTaskDetails {
  delegateTaskId?: string;
  taskDescription?: string;
  selectedDelegateId?: string;
  selectedDelegateName?: string;
  selectedDelegateHostName?: string;
  setupAbstractions?: {
    [key: string]: string;
  };
  taskType?: string;
}

export interface EbsInstanceBlockDevice {
  attachTime?: string;
  deleteOnTermination?: boolean;
  status?: string;
  volumeId?: string;
}

export interface EcsContainerDetails {
  taskId?: string;
  taskArn?: string;
  dockerId?: string;
  completeDockerId?: string;
  containerId?: string;
  containerInstanceId?: string;
  containerInstanceArn?: string;
  ecsServiceName?: string;
}

export interface ElasticGpuAssociation {
  elasticGpuId?: string;
  elasticGpuAssociationId?: string;
  elasticGpuAssociationState?: string;
  elasticGpuAssociationTime?: string;
}

export interface ElasticInferenceAcceleratorAssociation {
  elasticInferenceAcceleratorArn?: string;
  elasticInferenceAcceleratorAssociationId?: string;
  elasticInferenceAcceleratorAssociationState?: string;
  elasticInferenceAcceleratorAssociationTime?: string;
}

export interface ElementExecutionSummary {
  contextElement?: ContextElement;
  instanceStatusSummaries?: InstanceStatusSummary[];
  startTs?: number;
  endTs?: number;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  infraMappingSummaries?: InfraMappingSummary[];
  infraDefinitionSummaries?: InfraDefinitionSummary[];
  instancesCount?: number;
  totalTime?: number;
  avgTime?: number;
}

export interface EntityVersion {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  entityType?:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  entityName?: string;
  changeType?: "CREATED" | "UPDATED";
  entityUuid?: string;
  entityParentUuid?: string;
  entityData?: string;
  version?: number;
  accountId?: string;
}

export interface Graph {
  graphName?: string;
  nodes?: GraphNode[];
  links?: GraphLink[];
  subworkflows?: {
    [key: string]: Graph;
  };
}

export interface GraphGroup {
  id?: string;
  name?: string;
  type?: string;
  rollback?: boolean;
  status?: string;
  executionSummary?: { [key: string]: any };
  executionDetails?: { [key: string]: any };
  detailsReference?: string;
  origin?: boolean;
  executionHistoryCount?: number;
  interruptHistoryCount?: number;
  hasInspection?: boolean;
  valid?: boolean;
  validationMessage?: string;
  inValidFieldMessages?: {
    [key: string]: string;
  };
  elementStatusSummary?: ElementExecutionSummary[];
  instanceStatusSummary?: InstanceStatusSummary[];
  templateExpressions?: TemplateExpression[];
  variableOverrides?: NameValuePair[];
  templateVariables?: Variable[];
  templateUuid?: string;
  templateVersion?: string;
  importedTemplateDetails?: ImportedTemplateDetails;
  templateMetadata?: TemplateMetadata;
  properties?: {
    [key: string]: { [key: string]: any };
  };
  next?: GraphNode;
  group?: GraphGroup;
  delegateTaskId?: string;
  selectionLogsTrackingForTaskEnabled?: boolean;
  delegateTasksDetails?: DelegateTaskDetails[];
  selectionLogsTrackingForTasksEnabled?: boolean;
  elements?: GraphNode[];
  executionStrategy?: "SERIAL" | "PARALLEL";
}

export interface GraphLink {
  id?: string;
  from?: string;
  to?: string;
  type?: string;
}

export interface GraphNode {
  id?: string;
  name?: string;
  type?: string;
  rollback?: boolean;
  status?: string;
  executionSummary?: { [key: string]: any };
  executionDetails?: { [key: string]: any };
  detailsReference?: string;
  origin?: boolean;
  executionHistoryCount?: number;
  interruptHistoryCount?: number;
  hasInspection?: boolean;
  valid?: boolean;
  validationMessage?: string;
  inValidFieldMessages?: {
    [key: string]: string;
  };
  elementStatusSummary?: ElementExecutionSummary[];
  instanceStatusSummary?: InstanceStatusSummary[];
  templateExpressions?: TemplateExpression[];
  variableOverrides?: NameValuePair[];
  templateVariables?: Variable[];
  templateUuid?: string;
  templateVersion?: string;
  importedTemplateDetails?: ImportedTemplateDetails;
  templateMetadata?: TemplateMetadata;
  properties?: {
    [key: string]: { [key: string]: any };
  };
  next?: GraphNode;
  group?: GraphGroup;
  delegateTaskId?: string;
  selectionLogsTrackingForTaskEnabled?: boolean;
  delegateTasksDetails?: DelegateTaskDetails[];
  selectionLogsTrackingForTasksEnabled?: boolean;
}

export interface GroupIdentifier {
  groupName?: string;
  groupId?: string;
}

export type HarnessImportedTemplateDetails = ImportedTemplateDetails & {
  commandVersion?: string;
  commandName?: string;
  commandStoreName?: string;
  repoUrl?: string;
  tags?: string[];
};

export interface HarnessTagLink {
  uuid?: string;
  accountId?: string;
  appId?: string;
  key?: string;
  value?: string;
  entityType:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  entityId?: string;
  tagType?: "USER" | "HARNESS";
  appName?: string;
  entityName?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface HibernationOptions {
  configured?: boolean;
}

export interface HostElement {
  uuid?: string;
  hostName?: string;
  ip?: string;
  instanceId?: string;
  publicDns?: string;
  properties?: {
    [key: string]: { [key: string]: any };
  };
  ec2Instance?: Instance;
  pcfElement?: PcfInstanceElement;
  azureVMInstance?: AzureVMInstanceData;
  webAppInstance?: AzureAppDeploymentData;
  name?: string;
  elementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE";
}

export interface IamInstanceProfile {
  arn?: string;
  id?: string;
}

export interface ImportedTemplateDetails {
  [key: string]: any;
}

export type ImportedTemplateMetadata = TemplateMetadata & {
  defaultVersion?: number;
};

export interface InfraDefinitionSummary {
  infraDefinitionId?: string;
  cloudProviderType?:
    | "PHYSICAL_DATA_CENTER"
    | "AWS"
    | "AZURE"
    | "GCP"
    | "KUBERNETES_CLUSTER"
    | "PCF"
    | "CUSTOM";
  deploymentType?:
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM";
  cloudProviderName?: string;
  displayName?: string;
}

export interface InfraMappingSummary {
  infraMappingId?: string;
  computeProviderType?: string;
  infraMappingType?: string;
  deploymentType?: string;
  computeProviderName?: string;
  displayName?: string;
}

export interface Instance {
  amiLaunchIndex?: number;
  imageId?: string;
  instanceId?: string;
  instanceType?: string;
  kernelId?: string;
  keyName?: string;
  launchTime?: string;
  monitoring?: Monitoring;
  placement?: Placement;
  platform?: string;
  privateDnsName?: string;
  privateIpAddress?: string;
  productCodes?: ProductCode[];
  publicDnsName?: string;
  publicIpAddress?: string;
  ramdiskId?: string;
  state?: InstanceState;
  stateTransitionReason?: string;
  subnetId?: string;
  vpcId?: string;
  architecture?: string;
  blockDeviceMappings?: InstanceBlockDeviceMapping[];
  clientToken?: string;
  ebsOptimized?: boolean;
  enaSupport?: boolean;
  hypervisor?: string;
  iamInstanceProfile?: IamInstanceProfile;
  instanceLifecycle?: string;
  elasticGpuAssociations?: ElasticGpuAssociation[];
  elasticInferenceAcceleratorAssociations?: ElasticInferenceAcceleratorAssociation[];
  networkInterfaces?: InstanceNetworkInterface[];
  outpostArn?: string;
  rootDeviceName?: string;
  rootDeviceType?: string;
  securityGroups?: GroupIdentifier[];
  sourceDestCheck?: boolean;
  spotInstanceRequestId?: string;
  sriovNetSupport?: string;
  stateReason?: StateReason;
  tags?: Tag[];
  virtualizationType?: string;
  cpuOptions?: CpuOptions;
  capacityReservationId?: string;
  capacityReservationSpecification?: CapacityReservationSpecificationResponse;
  hibernationOptions?: HibernationOptions;
  licenses?: LicenseConfiguration[];
  metadataOptions?: InstanceMetadataOptionsResponse;
}

export interface InstanceBlockDeviceMapping {
  deviceName?: string;
  ebs?: EbsInstanceBlockDevice;
}

export interface InstanceElement {
  uuid?: string;
  displayName?: string;
  hostName?: string;
  dockerId?: string;
  host?: HostElement;
  serviceTemplateElement?: ServiceTemplateElement;
  podName?: string;
  namespace?: string;
  workloadName?: string;
  ecsContainerDetails?: EcsContainerDetails;
  newInstance?: boolean;
  name?: string;
  elementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE";
}

export interface InstanceIpv6Address {
  ipv6Address?: string;
}

export interface InstanceMetadataOptionsResponse {
  state?: string;
  httpTokens?: string;
  httpPutResponseHopLimit?: number;
  httpEndpoint?: string;
}

export interface InstanceNetworkInterface {
  association?: InstanceNetworkInterfaceAssociation;
  attachment?: InstanceNetworkInterfaceAttachment;
  description?: string;
  groups?: GroupIdentifier[];
  ipv6Addresses?: InstanceIpv6Address[];
  macAddress?: string;
  networkInterfaceId?: string;
  ownerId?: string;
  privateDnsName?: string;
  privateIpAddress?: string;
  privateIpAddresses?: InstancePrivateIpAddress[];
  sourceDestCheck?: boolean;
  status?: string;
  subnetId?: string;
  vpcId?: string;
  interfaceType?: string;
}

export interface InstanceNetworkInterfaceAssociation {
  ipOwnerId?: string;
  publicDnsName?: string;
  publicIp?: string;
}

export interface InstanceNetworkInterfaceAttachment {
  attachTime?: string;
  attachmentId?: string;
  deleteOnTermination?: boolean;
  deviceIndex?: number;
  status?: string;
}

export interface InstancePrivateIpAddress {
  association?: InstanceNetworkInterfaceAssociation;
  primary?: boolean;
  privateDnsName?: string;
  privateIpAddress?: string;
}

export interface InstanceState {
  code?: number;
  name?: string;
}

export interface InstanceStatusSummary {
  instanceElement?: InstanceElement;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
}

export interface LicenseConfiguration {
  licenseConfigurationArn?: string;
}

export interface Monitoring {
  state?: string;
}

export interface NameValuePair {
  name?: string;
  value: string;
  valueType?: string;
}

export interface PcfInstanceElement {
  uuid?: string;
  applicationId?: string;
  instanceIndex?: string;
  displayName?: string;
  name?: string;
  newInstance?: boolean;
  elementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE";
  upsize?: boolean;
}

export interface Placement {
  availabilityZone?: string;
  affinity?: string;
  groupName?: string;
  partitionNumber?: number;
  hostId?: string;
  tenancy?: string;
  spreadDomain?: string;
  hostResourceGroupArn?: string;
}

export interface ProductCode {
  productCodeId?: string;
  productCodeType?: string;
}

export interface RestResponsePageResponseCVEnabledService {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVEnabledService[];
  responseMessages?: ResponseMessage[];
}

export interface Service {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  description?: string;
  artifactType?:
    | "JAR"
    | "WAR"
    | "TAR"
    | "ZIP"
    | "NUGET"
    | "DOCKER"
    | "RPM"
    | "AWS_LAMBDA"
    | "AWS_CODEDEPLOY"
    | "PCF"
    | "AMI"
    | "AZURE_MACHINE_IMAGE"
    | "AZURE_WEBAPP"
    | "IIS"
    | "OTHER"
    | "IIS_APP"
    | "IIS_VirtualDirectory";
  deploymentType?:
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM";
  serviceId?: string;
  configMapYaml?: string;
  helmValueYaml?: string;
  version?: number;
  appContainer?: AppContainer;
  configFiles?: ConfigFile[];
  serviceVariables?: ServiceVariable[];
  artifactStreams?: ArtifactStream[];
  serviceCommands?: ServiceCommand[];
  lastDeploymentActivity?: Activity;
  lastProdDeploymentActivity?: Activity;
  setup?: Setup;
  keywords?: string[];
  helmVersion?: "V2" | "V3";
  cfCliVersion?: "V6" | "V7";
  accountId?: string;
  artifactStreamIds?: string[];
  artifactStreamBindings?: ArtifactStreamBinding[];
  sample?: boolean;
  tagLinks?: HarnessTagLink[];
  deploymentTypeTemplateId?: string;
  customDeploymentName?: string;
  artifactFromManifest?: boolean;
  k8sV2?: boolean;
  pcfV2?: boolean;
}

export interface ServiceCommand {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  serviceId?: string;
  envIdVersionMap?: {
    [key: string]: EntityVersion;
  };
  defaultVersion?: number;
  targetToAllEnv?: boolean;
  accountId?: string;
  command?: Command;
  templateUuid?: string;
  templateVersion?: string;
  importedTemplateDetails?: ImportedTemplateDetails;
  templateMetadata?: TemplateMetadata;
}

export interface ServiceElement {
  uuid?: string;
  name?: string;
  description?: string;
  elementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE";
}

export interface ServiceTemplateElement {
  uuid?: string;
  name?: string;
  serviceElement?: ServiceElement;
  elementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE";
}

export interface ServiceVariable {
  templateId?: string;
  envId?: string;
  entityType:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  entityId?: string;
  parentServiceVariableId?: string;
  overriddenServiceVariable?: ServiceVariable;
  overrideType?: "ALL" | "INSTANCES" | "CUSTOM";
  instances?: string[];
  expression?: string;
  accountId?: string;
  name?: string;
  value?: string[];
  type?: "TEXT" | "LB" | "ENCRYPTED_TEXT" | "ARTIFACT";
  allowedList?: string[];
  encryptedValue?: string;
  secretTextName?: string;
  serviceId?: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  encryptedBy?: string;
  artifactStreamSummaries?: ArtifactStreamSummary[];
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
}

export interface Setup {
  setupStatus?: "COMPLETE" | "INCOMPLETE";
  actions?: SetupAction[];
}

export interface SetupAction {
  displayText?: string;
  code?: string;
  url?: string;
  errorType?: "INFO" | "ERROR";
}

export interface StateReason {
  code?: string;
  message?: string;
}

export interface Tag {
  key?: string;
  value?: string;
}

export interface TemplateExpression {
  fieldName?: string;
  expression?: string;
  expressionAllowed?: boolean;
  description?: string;
  mandatory?: boolean;
  metadata?: {
    [key: string]: { [key: string]: any };
  };
}

export interface TemplateMetadata {
  [key: string]: any;
}

export interface TemplateReference {
  templateUuid?: string;
  templateVersion?: number;
}

export interface TriggeredBy {
  name?: string;
  email?: string;
}

export interface Variable {
  name?: string;
  description?: string;
  mandatory?: boolean;
  runtimeInput?: boolean;
  value?: string;
  fixed?: boolean;
  allowedValues?: string;
  allowedList?: string[];
  allowMultipleValues?: boolean;
  artifactStreamSummaries?: ArtifactStreamSummary[];
  metadata?: {
    [key: string]: { [key: string]: any };
  };
  type?: "TEXT" | "NUMBER" | "EMAIL" | "ENTITY" | "ARTIFACT" | "MANIFEST";
}

export interface RestResponseListService {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Service[];
  responseMessages?: ResponseMessage[];
}

export interface ProvisionStep {
  step?: string;
  done?: boolean;
}

export interface RestResponseListProvisionStep {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ProvisionStep[];
  responseMessages?: ResponseMessage[];
}

export interface AccountDetails {
  accountId?: string;
  accountName?: string;
  companyName?: string;
  cluster?: string;
  createdFromNG?: boolean;
  defaultExperience?: "NG" | "CG";
  licenseInfo?: LicenseInfo;
  ceLicenseInfo?: CeLicenseInfo;
}

export interface RestResponseAccountDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccountDetails;
  responseMessages?: ResponseMessage[];
}

export interface ServiceGuardLimitDTO {
  serviceGuardLimit?: number;
}

export type CDModuleLicenseDTO = ModuleLicenseDTO & {
  workloads?: number;
  deploymentsPerDay?: number;
};

export type CEModuleLicenseDTO = ModuleLicenseDTO & {
  numberOfCluster?: number;
  spendLimit?: number;
  dataRetentionInDays?: number;
};

export type CFModuleLicenseDTO = ModuleLicenseDTO & {
  numberOfUsers?: number;
  numberOfClientMAUs?: number;
};

export type CIModuleLicenseDTO = ModuleLicenseDTO & {
  numberOfCommitters?: number;
};

export type CVModuleLicenseDTO = ModuleLicenseDTO & {};

export interface ModuleLicenseDTO {
  id?: string;
  accountIdentifier?: string;
  moduleType?: "CD" | "CI" | "CV" | "CE" | "CF";
  edition?: "FREE" | "TEAM" | "ENTERPRISE";
  licenseType?: "TRIAL" | "PAID";
  status?: "ACTIVE" | "DELETED" | "EXPIRED";
  startTime?: number;
  expiryTime?: number;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface RestResponseModuleLicenseDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ModuleLicenseDTO;
  responseMessages?: ResponseMessage[];
}

export interface AccountLicenseDTO {
  accountId?: string;
  moduleLicenses?: {
    [key: string]: ModuleLicenseDTO;
  };
  allModuleLicenses?: {
    [key: string]: ModuleLicenseDTO[];
  };
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface RestResponseAccountLicenseDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccountLicenseDTO;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseActivity {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Activity;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseActivity {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Activity[];
  responseMessages?: ResponseMessage[];
}

export interface Log {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  activityId?: string;
  hostName?: string;
  commandUnitName?: string;
  logLine?: string;
  linesCount?: number;
  logLevel: "DEBUG" | "INFO" | "WARN" | "ERROR" | "FATAL";
  commandExecutionStatus:
    | "SUCCESS"
    | "FAILURE"
    | "RUNNING"
    | "QUEUED"
    | "SKIPPED";
  accountId?: string;
}

export interface RestResponsePageResponseLog {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Log[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseThirdPartyApiCallLog {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ThirdPartyApiCallLog[];
  responseMessages?: ResponseMessage[];
}

export interface ThirdPartyApiCallField {
  name?: string;
  value?: string;
  type?: "JSON" | "XML" | "NUMBER" | "URL" | "TEXT" | "TIMESTAMP";
}

export interface ThirdPartyApiCallLog {
  stateExecutionId?: string;
  accountId?: string;
  delegateId?: string;
  delegateTaskId?: string;
  title?: string;
  request?: ThirdPartyApiCallField[];
  response?: ThirdPartyApiCallField[];
  requestTimeStamp?: number;
  responseTimeStamp?: number;
  createdAt?: number;
  uuid?: string;
  validUntil?: string;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
}

export interface CommandUnitDetails {
  name?: string;
  commandExecutionStatus?:
    | "SUCCESS"
    | "FAILURE"
    | "RUNNING"
    | "QUEUED"
    | "SKIPPED";
  commandUnitType?:
    | "COMMAND"
    | "JENKINS"
    | "GCB"
    | "HELM"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "PCF_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_UPDATE_LISTENER"
    | "PCF_RESIZE"
    | "PCF_MAP_ROUTE"
    | "PCF_BG_SWAP_ROUTE"
    | "PCF_PLUGIN"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "KUBERNETES"
    | "AWS_AMI_SWITCH_ROUTES"
    | "AWS_ECS_UPDATE_LISTENER_BG"
    | "AWS_ECS_UPDATE_ROUTE_53_DNS_WEIGHT"
    | "AWS_ECS_SERVICE_SETUP"
    | "AWS_ECS_RUN_TASK_DEPLOY"
    | "AWS_ECS_SERVICE_SETUP_ROUTE53"
    | "AWS_ECS_SERVICE_SETUP_ELB"
    | "AWS_ECS_SERVICE_SETUP_DAEMON"
    | "AWS_ECS_SERVICE_ROLLBACK_DAEMON"
    | "AWS_ECS_SERVICE_DEPLOY"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_SWAP"
    | "AZURE_APP_SERVICE_SLOT_SETUP"
    | "AZURE_APP_SERVICE_SLOT_TRAFFIC_SHIFT"
    | "AZURE_APP_SERVICE_SLOT_SWAP"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES"
    | "AZURE_ARM_DEPLOYMENT"
    | "AZURE_BLUEPRINT_DEPLOYMENT"
    | "TERRAGRUNT_PROVISION";
  variables?: Variable[];
}

export interface RestResponseListCommandUnitDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CommandUnitDetails[];
  responseMessages?: ResponseMessage[];
}

export interface AlertFilter {
  alertType?:
    | "ApprovalNeeded"
    | "ManualInterventionNeeded"
    | "NoActiveDelegates"
    | "NoInstalledDelegates"
    | "DelegatesDown"
    | "DelegatesScalingGroupDownAlert"
    | "DelegateProfileError"
    | "NoEligibleDelegates"
    | "PerpetualTaskAlert"
    | "InvalidKMS"
    | "GitSyncError"
    | "GitConnectionError"
    | "INVALID_SMTP_CONFIGURATION"
    | "EMAIL_NOT_SENT_ALERT"
    | "USERGROUP_SYNC_FAILED"
    | "USAGE_LIMIT_EXCEEDED"
    | "INSTANCE_USAGE_APPROACHING_LIMIT"
    | "RESOURCE_USAGE_APPROACHING_LIMIT"
    | "DEPLOYMENT_RATE_APPROACHING_LIMIT"
    | "SETTING_ATTRIBUTE_VALIDATION_FAILED"
    | "ARTIFACT_COLLECTION_FAILED"
    | "CONTINUOUS_VERIFICATION_ALERT"
    | "CONTINUOUS_VERIFICATION_DATA_COLLECTION_ALERT"
    | "MANIFEST_COLLECTION_FAILED"
    | "DEPLOYMENT_FREEZE_EVENT";
  conditions?: Conditions;
}

export interface AlertNotificationRule {
  accountId?: string;
  alertCategory?:
    | "All"
    | "Setup"
    | "Approval"
    | "ManualIntervention"
    | "ContinuousVerification";
  alertFilter?: AlertFilter;
  userGroupsToNotify?: string[];
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  default?: boolean;
}

export interface CVAlertFilters {
  appIds?: string[];
  envIds?: string[];
  cvConfigIds?: string[];
  alertMinThreshold?: number;
}

export interface Conditions {
  operator?: "MATCHING" | "NOT_MATCHING";
  manualInterventionFilters?: ManualInterventionAlertFilters;
  cvAlertFilters?: CVAlertFilters;
}

export interface ManualInterventionAlertFilters {
  appIds?: string[];
  envIds?: string[];
}

export interface RestResponseListAlertNotificationRule {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AlertNotificationRule[];
  responseMessages?: ResponseMessage[];
}

export interface NotificationRulesStatus {
  accountId?: string;
  enabled?: boolean;
}

export interface RestResponseNotificationRulesStatus {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NotificationRulesStatus;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseAlertNotificationRule {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AlertNotificationRule;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapStringObject {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: { [key: string]: any };
  };
  responseMessages?: ResponseMessage[];
}

export interface AccountPermissions {
  permissions?: (
    | "ACCOUNT"
    | "LOGGED_IN"
    | "DELEGATE"
    | "NONE"
    | "APP"
    | "ALL_APP_ENTITIES"
    | "ENV"
    | "SERVICE"
    | "WORKFLOW"
    | "PIPELINE"
    | "DEPLOYMENT"
    | "APPLICATION_CREATE_DELETE"
    | "USER_PERMISSION_MANAGEMENT"
    | "ACCOUNT_MANAGEMENT"
    | "PROVISIONER"
    | "TEMPLATE_MANAGEMENT"
    | "USER_PERMISSION_READ"
    | "AUDIT_VIEWER"
    | "TAG_MANAGEMENT"
    | "CE_ADMIN"
    | "CE_VIEWER"
    | "MANAGE_CLOUD_PROVIDERS"
    | "MANAGE_CONNECTORS"
    | "MANAGE_APPLICATIONS"
    | "MANAGE_APPLICATION_STACKS"
    | "MANAGE_DELEGATES"
    | "MANAGE_ALERT_NOTIFICATION_RULES"
    | "MANAGE_DELEGATE_PROFILES"
    | "MANAGE_CONFIG_AS_CODE"
    | "MANAGE_SECRETS"
    | "MANAGE_SECRET_MANAGERS"
    | "MANAGE_SSH_AND_WINRM"
    | "MANAGE_AUTHENTICATION_SETTINGS"
    | "MANAGE_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "VIEW_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "MANAGE_IP_WHITELIST"
    | "MANAGE_IP_WHITELISTING"
    | "MANAGE_DEPLOYMENT_FREEZES"
    | "MANAGE_PIPELINE_GOVERNANCE_STANDARDS"
    | "MANAGE_API_KEYS"
    | "MANAGE_TAGS"
    | "MANAGE_CUSTOM_DASHBOARDS"
    | "CREATE_CUSTOM_DASHBOARDS"
    | "MANAGE_RESTRICTED_ACCESS"
  )[];
}

export interface ApiKeyEntry {
  uuid?: string;
  accountId?: string;
  name?: string;
  userGroupIds?: string[];
  userGroups?: UserGroup[];
  createdAt?: number;
  encryptedKey?: string[];
  decryptedKey?: string;
  hashOfKey?: string;
  sha256Hash?: string;
}

export interface AppPermission {
  permissionType?:
    | "ACCOUNT"
    | "LOGGED_IN"
    | "DELEGATE"
    | "NONE"
    | "APP"
    | "ALL_APP_ENTITIES"
    | "ENV"
    | "SERVICE"
    | "WORKFLOW"
    | "PIPELINE"
    | "DEPLOYMENT"
    | "APPLICATION_CREATE_DELETE"
    | "USER_PERMISSION_MANAGEMENT"
    | "ACCOUNT_MANAGEMENT"
    | "PROVISIONER"
    | "TEMPLATE_MANAGEMENT"
    | "USER_PERMISSION_READ"
    | "AUDIT_VIEWER"
    | "TAG_MANAGEMENT"
    | "CE_ADMIN"
    | "CE_VIEWER"
    | "MANAGE_CLOUD_PROVIDERS"
    | "MANAGE_CONNECTORS"
    | "MANAGE_APPLICATIONS"
    | "MANAGE_APPLICATION_STACKS"
    | "MANAGE_DELEGATES"
    | "MANAGE_ALERT_NOTIFICATION_RULES"
    | "MANAGE_DELEGATE_PROFILES"
    | "MANAGE_CONFIG_AS_CODE"
    | "MANAGE_SECRETS"
    | "MANAGE_SECRET_MANAGERS"
    | "MANAGE_SSH_AND_WINRM"
    | "MANAGE_AUTHENTICATION_SETTINGS"
    | "MANAGE_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "VIEW_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "MANAGE_IP_WHITELIST"
    | "MANAGE_IP_WHITELISTING"
    | "MANAGE_DEPLOYMENT_FREEZES"
    | "MANAGE_PIPELINE_GOVERNANCE_STANDARDS"
    | "MANAGE_API_KEYS"
    | "MANAGE_TAGS"
    | "MANAGE_CUSTOM_DASHBOARDS"
    | "CREATE_CUSTOM_DASHBOARDS"
    | "MANAGE_RESTRICTED_ACCESS";
  appFilter?: GenericEntityFilter;
  entityFilter?: Filter;
  actions?: (
    | "ALL"
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "EXECUTE"
    | "EXECUTE_WORKFLOW"
    | "EXECUTE_PIPELINE"
    | "EXECUTE_WORKFLOW_ROLLBACK"
    | "DEFAULT"
  )[];
}

export interface EnvFilter {
  ids?: string[];
  filterTypes?: string[];
}

export interface Filter {
  ids?: string[];
}

export interface GenericEntityFilter {
  ids?: string[];
  filterType?: string;
}

export interface NotificationSettings {
  useIndividualEmails?: boolean;
  sendMailToNewMembers?: boolean;
  emailAddresses: string[];
  slackConfig: SlackNotificationSetting;
  pagerDutyIntegrationKey?: string;
  microsoftTeamsWebhookUrl?: string;
}

export interface RestResponseApiKeyEntry {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ApiKeyEntry;
  responseMessages?: ResponseMessage[];
}

export interface SlackNotificationSetting {
  name?: string;
  outgoingWebhookUrl: string;
}

export interface User {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  companyName?: string;
  accountName?: string;
  userGroups?: UserGroup[];
  accounts?: Account[];
  pendingAccounts?: Account[];
  supportAccounts?: Account[];
  lastLogin?: number;
  firstLogin?: boolean;
  password?: string[];
  token?: string;
  twoFactorJwtToken?: string;
  emailVerified?: boolean;
  passwordExpired?: boolean;
  userLocked?: boolean;
  statsFetchedOn?: number;
  lastAccountId?: string;
  defaultAccountId?: string;
  lastAppId?: string;
  disabled?: boolean;
  imported?: boolean;
  userLockoutInfo?: UserLockoutInfo;
  twoFactorAuthenticationEnabled?: boolean;
  twoFactorAuthenticationMechanism?: "TOTP";
  oauthProvider?: string;
  reportedSegmentTracks?: string[];
  utmInfo?: UtmInfo;
  accountIds?: string[];
}

export interface UserGroup {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  description?: string;
  linkedSsoType?: "SAML" | "LDAP" | "OAUTH";
  linkedSsoId?: string;
  linkedSsoDisplayName?: string;
  ssoGroupId?: string;
  ssoGroupName?: string;
  importedByScim?: boolean;
  accountId?: string;
  memberIds?: string[];
  members?: User[];
  appPermissions?: AppPermission[];
  accountPermissions?: AccountPermissions;
  notificationSettings?: NotificationSettings;
  ssoLinked?: boolean;
  default?: boolean;
}

export interface UserLockoutInfo {
  numberOfFailedLoginAttempts?: number;
  userLockedAt?: number;
}

export interface UtmInfo {
  utmSource?: string;
  utmContent?: string;
  utmMedium?: string;
  utmTerm?: string;
  utmCampaign?: string;
}

export type WorkflowFilter = Filter & {
  filterTypes?: string[];
};

export interface RestResponsePageResponseApiKeyEntry {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ApiKeyEntry[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseAppContainer {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AppContainer;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseAppContainer {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AppContainer[];
  responseMessages?: ResponseMessage[];
}

export interface ApiKeyInfo {
  appKeyId?: string;
  apiKeyName?: string;
}

export interface Application {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  description?: string;
  accountId?: string;
  services?: Service[];
  environments?: Environment[];
  setup?: Setup;
  recentExecutions?: WorkflowExecution[];
  notifications?: Notification[];
  nextDeploymentOn?: number;
  keywords?: string[];
  yamlGitConfig?: YamlGitConfig;
  defaults?: {
    [key: string]: string;
  };
  sample?: boolean;
  isManualTriggerAuthorized?: boolean;
  tagLinks?: HarnessTagLink[];
}

export interface ApplicationManifest {
  accountId?: string;
  serviceId?: string;
  envId?: string;
  name?: string;
  kind?:
    | "VALUES"
    | "K8S_MANIFEST"
    | "PCF_OVERRIDE"
    | "AZURE_APP_SERVICE_MANIFEST"
    | "AZURE_APP_SETTINGS_OVERRIDE"
    | "AZURE_CONN_STRINGS_OVERRIDE"
    | "HELM_CHART_OVERRIDE"
    | "OC_PARAMS";
  storeType?:
    | "Local"
    | "Remote"
    | "HelmSourceRepo"
    | "HelmChartRepo"
    | "KustomizeSourceRepo"
    | "OC_TEMPLATES"
    | "CUSTOM"
    | "CUSTOM_OPENSHIFT_TEMPLATE";
  gitFileConfig?: GitFileConfig;
  helmChartConfig?: HelmChartConfig;
  kustomizeConfig?: KustomizeConfig;
  customSourceConfig?: CustomSourceConfig;
  helmCommandFlag?: HelmCommandFlagConfig;
  pollForChanges?: boolean;
  serviceName?: string;
  collectionStatus?: "UNSTABLE" | "COLLECTING" | "STABLE";
  perpetualTaskId?: string;
  failedAttempts?: number;
  skipVersioningForAllK8sObjects?: boolean;
  validationMessage?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface ApplicationManifestSummary {
  appManifestId?: string;
  settingId?: string;
  lastCollectedManifest?: ManifestSummary;
  defaultManifest?: ManifestSummary;
}

export interface Artifact {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  artifactStreamId?: string;
  artifactSourceName?: string;
  artifactStreamName?: string;
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  displayName?: string;
  revision?: string;
  serviceIds?: string[];
  services?: Service[];
  artifactFiles?: ArtifactFile[];
  artifactFileMetadata?: ArtifactFileMetadata[];
  status?:
    | "NEW"
    | "RUNNING"
    | "QUEUED"
    | "WAITING"
    | "READY"
    | "APPROVED"
    | "REJECTED"
    | "ABORTED"
    | "FAILED"
    | "ERROR";
  description?: string;
  errorMessage?: string;
  contentStatus?:
    | "METADATA_ONLY"
    | "NOT_DOWNLOADED"
    | "DOWNLOADING"
    | "DOWNLOADED"
    | "DELETED"
    | "FAILED";
  source?: {
    [key: string]: string;
  };
  settingId?: string;
  accountId?: string;
  artifactStreamType?: string;
  uiDisplayName?: string;
  buildIdentity?: string;
  url?: string;
  key?: string;
  fileName?: string;
  buildNo?: string;
  duplicate?: boolean;
  bucketName?: string;
  buildFullDisplayName?: string;
  artifactFileSize?: number;
  artifactPath?: string;
}

export interface ArtifactFile {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  fileUuid?: string;
  fileName?: string;
  mimeType?: string;
  size?: number;
  checksumType?: "MD5" | "SHA1" | "SHA256";
  checksum?: string;
  accountId?: string;
}

export interface ArtifactFileMetadata {
  fileName?: string;
  url?: string;
}

export interface ArtifactStreamMetadata {
  artifactStreamId?: string;
  runtimeValues?: {
    [key: string]: { [key: string]: any };
  };
}

export interface ArtifactVariable {
  entityType?:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  entityId?: string;
  overriddenArtifactVariables?: ArtifactVariable[];
  artifactStreamSummaries?: ArtifactStreamSummary[];
  displayInfo?: {
    [key: string]: string[];
  };
  workflowIds?: string[];
  uiDisplayName?: string;
  artifactStreamMetadata?: ArtifactStreamMetadata;
  lastDeployedArtifactInfo?: LastDeployedArtifactInformation;
  name?: string;
  description?: string;
  mandatory?: boolean;
  runtimeInput?: boolean;
  value?: string;
  fixed?: boolean;
  allowedValues?: string;
  allowedList?: string[];
  allowMultipleValues?: boolean;
  metadata?: {
    [key: string]: { [key: string]: any };
  };
  type?: "TEXT" | "NUMBER" | "EMAIL" | "ENTITY" | "ARTIFACT" | "MANIFEST";
}

export type AwsAmiInfrastructure = InfraMappingInfrastructureProvider & {
  region?: string;
  autoScalingGroupName?: string;
  classicLoadBalancers?: string[];
  targetGroupArns?: string[];
  hostNameConvention?: string;
  stageClassicLoadBalancers?: string[];
  stageTargetGroupArns?: string[];
  amiDeploymentType?: "AWS_ASG" | "SPOTINST";
  spotinstElastiGroupJson?: string;
  spotinstCloudProvider?: string;
  asgIdentifiesWorkload?: boolean;
  useTrafficShift?: boolean;
  expressions?: {
    [key: string]: string;
  };
};

export type AwsEcsInfrastructure = InfraMappingInfrastructureProvider & {
  region?: string;
  vpcId?: string;
  subnetIds?: string[];
  securityGroupIds?: string[];
  assignPublicIp?: boolean;
  executionRole?: string;
  launchType?: string;
  clusterName?: string;
  expressions?: {
    [key: string]: string;
  };
};

export interface AwsInstanceFilter {
  vpcIds?: string[];
  tags?: Tag[];
}

export type AwsInstanceInfrastructure = InfraMappingInfrastructureProvider & {
  region?: string;
  hostConnectionAttrs?: string;
  loadBalancerId?: string;
  loadBalancerName?: string;
  usePublicDns?: boolean;
  hostConnectionType?: string;
  awsInstanceFilter?: AwsInstanceFilter;
  autoScalingGroupName?: string;
  setDesiredCapacity?: boolean;
  desiredCapacity?: number;
  hostNameConvention?: string;
  provisionInstances?: boolean;
  expressions?: {
    [key: string]: string;
  };
};

export interface AwsLambdaExecutionSummary {
  functionMeta?: FunctionMeta;
  success?: boolean;
}

export type AwsLambdaInfrastructure = InfraMappingInfrastructureProvider & {
  region?: string;
  vpcId?: string;
  subnetIds?: string[];
  securityGroupIds?: string[];
  role?: string;
  expressions?: {
    [key: string]: string;
  };
};

export type AzureInstanceInfrastructure = InfraMappingInfrastructureProvider & {
  subscriptionId?: string;
  resourceGroup?: string;
  tags?: AzureTag[];
  hostConnectionAttrs?: string;
  winRmConnectionAttributes?: string;
  usePublicDns?: boolean;
};

export type AzureKubernetesService = InfraMappingInfrastructureProvider & {
  clusterName?: string;
  namespace?: string;
  releaseName?: string;
  subscriptionId?: string;
  resourceGroup?: string;
};

export interface AzureTag {
  key?: string;
  value?: string;
}

export type AzureVMSSInfra = InfraMappingInfrastructureProvider & {
  baseVMSSName?: string;
  userName?: string;
  resourceGroupName?: string;
  subscriptionId?: string;
  passwordSecretTextName?: string;
  hostConnectionAttrs?: string;
  vmssAuthType?: "PASSWORD" | "SSH_PUBLIC_KEY";
  vmssDeploymentType?: "NATIVE_VMSS" | "SPOTINST";
};

export type AzureWebAppInfra = InfraMappingInfrastructureProvider & {
  subscriptionId?: string;
  resourceGroup?: string;
  expressions?: {
    [key: string]: string;
  };
};

export type BasicOrchestrationWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
  preDeploymentSteps?: PhaseStep;
  rollbackProvisioners?: PhaseStep;
  rollbackWorkflowPhaseIdMap?: {
    [key: string]: WorkflowPhase;
  };
  workflowPhases?: WorkflowPhase[];
  postDeploymentSteps?: PhaseStep;
  failureStrategies?: FailureStrategy[];
  systemVariables?: Variable[];
  derivedVariables?: Variable[];
};

export type BlueGreenOrchestrationWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
  preDeploymentSteps?: PhaseStep;
  rollbackProvisioners?: PhaseStep;
  rollbackWorkflowPhaseIdMap?: {
    [key: string]: WorkflowPhase;
  };
  workflowPhases?: WorkflowPhase[];
  postDeploymentSteps?: PhaseStep;
  failureStrategies?: FailureStrategy[];
  systemVariables?: Variable[];
  derivedVariables?: Variable[];
};

export interface BuildExecutionSummary {
  artifactStreamId?: string;
  artifactSource?: string;
  revision?: string;
  buildUrl?: string;
  buildName?: string;
  metadata?: string;
}

export type BuildWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
  preDeploymentSteps?: PhaseStep;
  rollbackProvisioners?: PhaseStep;
  rollbackWorkflowPhaseIdMap?: {
    [key: string]: WorkflowPhase;
  };
  workflowPhases?: WorkflowPhase[];
  postDeploymentSteps?: PhaseStep;
  failureStrategies?: FailureStrategy[];
  systemVariables?: Variable[];
  derivedVariables?: Variable[];
};

export type CanaryOrchestrationWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
  preDeploymentSteps?: PhaseStep;
  rollbackProvisioners?: PhaseStep;
  rollbackWorkflowPhaseIdMap?: {
    [key: string]: WorkflowPhase;
  };
  workflowPhases?: WorkflowPhase[];
  postDeploymentSteps?: PhaseStep;
  failureStrategies?: FailureStrategy[];
  systemVariables?: Variable[];
  derivedVariables?: Variable[];
};

export type CodeDeployInfrastructure = InfraMappingInfrastructureProvider & {
  region?: string;
  applicationName?: string;
  deploymentGroup?: string;
  deploymentConfig?: string;
  hostNameConvention?: string;
};

export interface ConcurrencyStrategy {
  unitType: "INFRA" | "CUSTOM" | "NONE";
  holdingScope:
    | "PIPELINE"
    | "WORKFLOW"
    | "PHASE"
    | "PHASE_SECTION"
    | "NEXT_STEP";
  strategy: "ASAP" | "FIFO";
  resourceUnit: string;
  notificationGroups?: string[];
  notifyTriggeredByUser?: boolean;
}

export interface ContainerInfo {
  hostName?: string;
  ip?: string;
  containerId?: string;
  ec2Instance?: Instance;
  status?: "SUCCESS" | "FAILURE";
  podName?: string;
  workloadName?: string;
  newContainer?: boolean;
  ecsContainerDetails?: EcsContainerDetails;
  containerTasksReachable?: boolean;
  releaseName?: string;
  namespace?: string;
}

export interface CountsByStatuses {
  success?: number;
  failed?: number;
  inprogress?: number;
  queued?: number;
}

export type CustomInfrastructure = InfraMappingInfrastructureProvider & {
  infraVariables?: NameValuePair[];
  deploymentTypeTemplateVersion?: string;
  customDeploymentName?: string;
};

export type CustomOrchestrationWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
};

export interface CustomSourceConfig {
  script?: string;
  path?: string;
  delegateSelectors?: string[];
}

export interface DelegateMetaInfo {
  id?: string;
  hostName?: string;
}

export type DirectKubernetesInfrastructure = InfraMappingInfrastructureProvider & {
  clusterName?: string;
  namespace?: string;
  releaseName?: string;
  expressions?: {
    [key: string]: string;
  };
};

export type EcsBlueGreenOrchestrationWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
  preDeploymentSteps?: PhaseStep;
  rollbackProvisioners?: PhaseStep;
  rollbackWorkflowPhaseIdMap?: {
    [key: string]: WorkflowPhase;
  };
  workflowPhases?: WorkflowPhase[];
  postDeploymentSteps?: PhaseStep;
  failureStrategies?: FailureStrategy[];
  systemVariables?: Variable[];
  derivedVariables?: Variable[];
  ecsBGType?: string;
};

export interface EnvSummary {
  name?: string;
  uuid?: string;
  environmentType?: "PROD" | "NON_PROD" | "ALL";
}

export interface Environment {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  description?: string;
  configMapYaml?: string;
  helmValueYaml?: string;
  configMapYamlByServiceTemplateId?: {
    [key: string]: string;
  };
  helmValueYamlByServiceTemplateId?: {
    [key: string]: string;
  };
  environmentType: "PROD" | "NON_PROD" | "ALL";
  serviceTemplates?: ServiceTemplate[];
  configFiles?: ConfigFile[];
  setup?: Setup;
  infrastructureDefinitions?: InfrastructureDefinition[];
  infraDefinitionsCount?: number;
  keywords?: string[];
  accountId?: string;
  sample?: boolean;
  tagLinks?: HarnessTagLink[];
}

export interface ExecutionArgs {
  workflowType?: "PIPELINE" | "ORCHESTRATION";
  serviceId?: string;
  commandName?: string;
  executionStrategy?: "SERIAL" | "PARALLEL";
  artifacts?: Artifact[];
  artifactIdNames?: {
    [key: string]: string;
  };
  orchestrationId?: string;
  serviceInstances?: ServiceInstance[];
  serviceInstanceIdNames?: {
    [key: string]: string;
  };
  executionCredential?: ExecutionCredential;
  errorStrategy?: "CONTINUE" | "FAIL" | "PAUSE" | "RETRY";
  triggeredFromPipeline?: boolean;
  pipelineId?: string;
  pipelinePhaseElementId?: string;
  pipelinePhaseParallelIndex?: number;
  stageName?: string;
  workflowVariables?: {
    [key: string]: string;
  };
  notes?: string;
  triggeredBy?: EmbeddedUser;
  createdByType?: "USER" | "API_KEY" | "TRIGGER";
  triggeringApiKeyId?: string;
  excludeHostsWithSameArtifact?: boolean;
  notifyTriggeredUserOnly?: boolean;
  artifactVariables?: ArtifactVariable[];
  targetToSpecificHosts?: boolean;
  hosts?: string[];
  continueWithDefaultValues?: boolean;
  helmCharts?: HelmChart[];
  manifestVariables?: ManifestVariable[];
  helmChartIdNames?: {
    [key: string]: string;
  };
}

export interface ExecutionCredential {
  executionType?: "SSH";
}

export interface ExecutionDataValue {
  displayName?: string;
  value?: { [key: string]: any };
}

export interface FailureCriteria {
  failureThresholdPercentage?: number;
}

export interface FailureStrategy {
  failureTypes: (
    | "EXPIRED"
    | "DELEGATE_PROVISIONING"
    | "CONNECTIVITY"
    | "AUTHENTICATION"
    | "VERIFICATION_FAILURE"
    | "APPLICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "TIMEOUT_ERROR"
  )[];
  executionScope?: "WORKFLOW" | "WORKFLOW_PHASE";
  repairActionCode?:
    | "MANUAL_INTERVENTION"
    | "ROLLBACK_WORKFLOW"
    | "ROLLBACK_PHASE"
    | "IGNORE"
    | "RETRY"
    | "END_EXECUTION"
    | "CONTINUE_WITH_DEFAULTS"
    | "ABORT_WORKFLOW_EXECUTION";
  retryCount?: number;
  retryIntervals?: number[];
  repairActionCodeAfterRetry?:
    | "MANUAL_INTERVENTION"
    | "ROLLBACK_WORKFLOW"
    | "ROLLBACK_PHASE"
    | "IGNORE"
    | "RETRY"
    | "END_EXECUTION"
    | "CONTINUE_WITH_DEFAULTS"
    | "ABORT_WORKFLOW_EXECUTION";
  failureCriteria?: FailureCriteria;
  specificSteps?: string[];
  manualInterventionTimeout?: number;
  actionAfterTimeout?:
    | "ABORT"
    | "ABORT_ALL"
    | "PAUSE"
    | "PAUSE_FOR_INPUTS"
    | "PAUSE_ALL"
    | "RESUME"
    | "RESUME_ALL"
    | "RETRY"
    | "IGNORE"
    | "WAITING_FOR_MANUAL_INTERVENTION"
    | "MARK_FAILED"
    | "MARK_SUCCESS"
    | "ROLLBACK"
    | "NEXT_STEP"
    | "END_EXECUTION"
    | "ROLLBACK_DONE"
    | "MARK_EXPIRED"
    | "CONTINUE_WITH_DEFAULTS"
    | "CONTINUE_PIPELINE_STAGE";
}

export interface FunctionMeta {
  functionName?: string;
  functionArn?: string;
  version?: string;
}

export interface GitFileConfig {
  connectorId?: string;
  commitId?: string;
  branch?: string;
  filePath?: string;
  repoName?: string;
  filePathList?: string[];
  serviceSpecFilePath?: string;
  taskSpecFilePath?: string;
  useBranch?: boolean;
  useInlineServiceDefinition?: boolean;
  connectorName?: string;
}

export type GoogleKubernetesEngine = InfraMappingInfrastructureProvider & {
  clusterName?: string;
  namespace?: string;
  releaseName?: string;
  expressions?: {
    [key: string]: string;
  };
};

export interface HelmChart {
  uuid?: string;
  version?: string;
  applicationManifestId?: string;
  name?: string;
  displayName?: string;
  accountId?: string;
  appId?: string;
  serviceId?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  appVersion?: string;
  description?: string;
  metadata?: {
    [key: string]: string;
  };
}

export interface HelmChartConfig {
  connectorId?: string;
  chartName?: string;
  chartVersion?: string;
  chartUrl?: string;
  connectorName?: string;
  basePath?: string;
}

export interface HelmChartInfo {
  name?: string;
  version?: string;
  repoUrl?: string;
}

export interface HelmCommandFlagConfig {
  valueMap: {
    [key: string]: string;
  };
}

export interface HelmExecutionSummary {
  helmChartInfo?: HelmChartInfo;
  releaseName?: string;
  containerInfoList?: ContainerInfo[];
}

export interface Host {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  envId?: string;
  serviceTemplateId?: string;
  infraMappingId?: string;
  infraDefinitionId?: string;
  computeProviderId?: string;
  hostName?: string;
  publicDns?: string;
  hostConnAttr?: string;
  bastionConnAttr?: string;
  winrmConnAttr?: string;
  properties?: {
    [key: string]: { [key: string]: any };
  };
  ec2Instance?: Instance;
}

export interface InfraMappingInfrastructureProvider {
  cloudProviderId?: string;
}

export interface InfrastructureDefinition {
  uuid?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  name?: string;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  appId: string;
  provisionerId?: string;
  cloudProviderType:
    | "PHYSICAL_DATA_CENTER"
    | "AWS"
    | "AZURE"
    | "GCP"
    | "KUBERNETES_CLUSTER"
    | "PCF"
    | "CUSTOM";
  deploymentType:
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM";
  infrastructure: InfraMappingInfrastructureProvider;
  scopedToServices?: string[];
  envId: string;
  sample?: boolean;
  accountId?: string;
  deploymentTypeTemplateId?: string;
  customDeploymentName?: string;
}

export interface InfrastructureMapping {
  accountId?: string;
  infraMappingType?: string;
  computeProviderType?: string;
  computeProviderSettingId?: string;
  envId?: string;
  deploymentType?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  serviceTemplateId?: string;
  serviceId?: string;
  computeProviderName?: string;
  name?: string;
  displayName?: string;
  autoPopulate?: boolean;
  provisionerId?: string;
  blueprints?: {
    [key: string]: { [key: string]: any };
  };
  infrastructureDefinitionId?: string;
  sample?: boolean;
  customDeploymentTemplateId?: string;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
  hostConnectionAttrs?: string;
}

export interface InstanceExecutionHistory {
  intanceCount?: number;
  stateName?: string;
  errorCode?:
    | "DEFAULT_ERROR_CODE"
    | "INVALID_ARGUMENT"
    | "INVALID_EMAIL"
    | "DOMAIN_NOT_ALLOWED_TO_REGISTER"
    | "USER_ALREADY_REGISTERED"
    | "USER_INVITATION_DOES_NOT_EXIST"
    | "USER_DOES_NOT_EXIST"
    | "USER_INVITE_OPERATION_FAILED"
    | "USER_DISABLED"
    | "ACCOUNT_DOES_NOT_EXIST"
    | "INACTIVE_ACCOUNT"
    | "ACCOUNT_MIGRATED"
    | "USER_DOMAIN_NOT_ALLOWED"
    | "MAX_FAILED_ATTEMPT_COUNT_EXCEEDED"
    | "RESOURCE_NOT_FOUND"
    | "ROLE_DOES_NOT_EXIST"
    | "EMAIL_NOT_VERIFIED"
    | "EMAIL_VERIFICATION_TOKEN_NOT_FOUND"
    | "INVALID_TOKEN"
    | "REVOKED_TOKEN"
    | "INVALID_CAPTCHA_TOKEN"
    | "NOT_ACCOUNT_MGR_NOR_HAS_ALL_APP_ACCESS"
    | "EXPIRED_TOKEN"
    | "TOKEN_ALREADY_REFRESHED_ONCE"
    | "ACCESS_DENIED"
    | "NG_ACCESS_DENIED"
    | "INVALID_CREDENTIAL"
    | "INVALID_CREDENTIALS_THIRD_PARTY"
    | "INVALID_KEY"
    | "INVALID_CONNECTOR_TYPE"
    | "INVALID_KEYPATH"
    | "INVALID_VARIABLE"
    | "UNKNOWN_HOST"
    | "UNREACHABLE_HOST"
    | "INVALID_PORT"
    | "SSH_SESSION_TIMEOUT"
    | "SOCKET_CONNECTION_ERROR"
    | "CONNECTION_ERROR"
    | "SOCKET_CONNECTION_TIMEOUT"
    | "CONNECTION_TIMEOUT"
    | "SSH_CONNECTION_ERROR"
    | "USER_GROUP_ERROR"
    | "INVALID_EXECUTION_ID"
    | "ERROR_IN_GETTING_CHANNEL_STREAMS"
    | "UNEXPECTED"
    | "UNKNOWN_ERROR"
    | "UNKNOWN_EXECUTOR_TYPE_ERROR"
    | "DUPLICATE_STATE_NAMES"
    | "TRANSITION_NOT_LINKED"
    | "TRANSITION_TO_INCORRECT_STATE"
    | "TRANSITION_TYPE_NULL"
    | "STATES_WITH_DUP_TRANSITIONS"
    | "BARRIERS_NOT_RUNNING_CONCURRENTLY"
    | "NON_FORK_STATES"
    | "NON_REPEAT_STATES"
    | "INITIAL_STATE_NOT_DEFINED"
    | "FILE_INTEGRITY_CHECK_FAILED"
    | "INVALID_URL"
    | "FILE_DOWNLOAD_FAILED"
    | "PLATFORM_SOFTWARE_DELETE_ERROR"
    | "INVALID_CSV_FILE"
    | "INVALID_REQUEST"
    | "SCHEMA_VALIDATION_FAILED"
    | "FILTER_CREATION_ERROR"
    | "INVALID_YAML_ERROR"
    | "PLAN_CREATION_ERROR"
    | "INVALID_INFRA_STATE"
    | "PIPELINE_ALREADY_TRIGGERED"
    | "NON_EXISTING_PIPELINE"
    | "DUPLICATE_COMMAND_NAMES"
    | "INVALID_PIPELINE"
    | "COMMAND_DOES_NOT_EXIST"
    | "DUPLICATE_ARTIFACTSTREAM_NAMES"
    | "DUPLICATE_HOST_NAMES"
    | "STATE_NOT_FOR_TYPE"
    | "STATE_MACHINE_ISSUE"
    | "STATE_DISCONTINUE_FAILED"
    | "STATE_PAUSE_FAILED"
    | "PAUSE_ALL_ALREADY"
    | "RESUME_ALL_ALREADY"
    | "ROLLBACK_ALREADY"
    | "ABORT_ALL_ALREADY"
    | "RETRY_FAILED"
    | "UNKNOWN_ARTIFACT_TYPE"
    | "UNKNOWN_STAGE_ELEMENT_WRAPPER_TYPE"
    | "INIT_TIMEOUT"
    | "LICENSE_EXPIRED"
    | "NOT_LICENSED"
    | "REQUEST_TIMEOUT"
    | "WORKFLOW_ALREADY_TRIGGERED"
    | "JENKINS_ERROR"
    | "INVALID_ARTIFACT_SOURCE"
    | "INVALID_ARTIFACT_SERVER"
    | "INVALID_CLOUD_PROVIDER"
    | "UPDATE_NOT_ALLOWED"
    | "DELETE_NOT_ALLOWED"
    | "APPDYNAMICS_CONFIGURATION_ERROR"
    | "APM_CONFIGURATION_ERROR"
    | "SPLUNK_CONFIGURATION_ERROR"
    | "ELK_CONFIGURATION_ERROR"
    | "LOGZ_CONFIGURATION_ERROR"
    | "SUMO_CONFIGURATION_ERROR"
    | "INSTANA_CONFIGURATION_ERROR"
    | "APPDYNAMICS_ERROR"
    | "STACKDRIVER_ERROR"
    | "STACKDRIVER_CONFIGURATION_ERROR"
    | "NEWRELIC_CONFIGURATION_ERROR"
    | "NEWRELIC_ERROR"
    | "DYNA_TRACE_CONFIGURATION_ERROR"
    | "DYNA_TRACE_ERROR"
    | "CLOUDWATCH_ERROR"
    | "CLOUDWATCH_CONFIGURATION_ERROR"
    | "PROMETHEUS_CONFIGURATION_ERROR"
    | "DATA_DOG_CONFIGURATION_ERROR"
    | "SERVICE_GUARD_CONFIGURATION_ERROR"
    | "ENCRYPTION_NOT_CONFIGURED"
    | "UNAVAILABLE_DELEGATES"
    | "WORKFLOW_EXECUTION_IN_PROGRESS"
    | "PIPELINE_EXECUTION_IN_PROGRESS"
    | "AWS_ACCESS_DENIED"
    | "AWS_CLUSTER_NOT_FOUND"
    | "AWS_SERVICE_NOT_FOUND"
    | "IMAGE_NOT_FOUND"
    | "ILLEGAL_ARGUMENT"
    | "IMAGE_TAG_NOT_FOUND"
    | "DELEGATE_NOT_AVAILABLE"
    | "INVALID_YAML_PAYLOAD"
    | "AUTHENTICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "UNRECOGNIZED_YAML_FIELDS"
    | "COULD_NOT_MAP_BEFORE_YAML"
    | "MISSING_BEFORE_YAML"
    | "MISSING_YAML"
    | "NON_EMPTY_DELETIONS"
    | "GENERAL_YAML_ERROR"
    | "GENERAL_YAML_INFO"
    | "YAML_GIT_SYNC_ERROR"
    | "GIT_CONNECTION_ERROR"
    | "GIT_ERROR"
    | "ARTIFACT_SERVER_ERROR"
    | "ENCRYPT_DECRYPT_ERROR"
    | "SECRET_MANAGEMENT_ERROR"
    | "SECRET_NOT_FOUND"
    | "KMS_OPERATION_ERROR"
    | "GCP_KMS_OPERATION_ERROR"
    | "VAULT_OPERATION_ERROR"
    | "AWS_SECRETS_MANAGER_OPERATION_ERROR"
    | "AZURE_KEY_VAULT_OPERATION_ERROR"
    | "CYBERARK_OPERATION_ERROR"
    | "UNSUPPORTED_OPERATION_EXCEPTION"
    | "FEATURE_UNAVAILABLE"
    | "GENERAL_ERROR"
    | "BASELINE_CONFIGURATION_ERROR"
    | "SAML_IDP_CONFIGURATION_NOT_AVAILABLE"
    | "INVALID_AUTHENTICATION_MECHANISM"
    | "INVALID_SAML_CONFIGURATION"
    | "INVALID_OAUTH_CONFIGURATION"
    | "INVALID_LDAP_CONFIGURATION"
    | "USER_GROUP_SYNC_FAILURE"
    | "USER_GROUP_ALREADY_EXIST"
    | "INVALID_TWO_FACTOR_AUTHENTICATION_CONFIGURATION"
    | "EXPLANATION"
    | "HINT"
    | "NOT_WHITELISTED_IP"
    | "INVALID_TOTP_TOKEN"
    | "EMAIL_FAILED"
    | "SSL_HANDSHAKE_FAILED"
    | "NO_APPS_ASSIGNED"
    | "INVALID_INFRA_CONFIGURATION"
    | "TEMPLATES_LINKED"
    | "USER_HAS_NO_PERMISSIONS"
    | "USER_NOT_AUTHORIZED"
    | "USER_ALREADY_PRESENT"
    | "INVALID_USAGE_RESTRICTION"
    | "USAGE_RESTRICTION_ERROR"
    | "STATE_EXECUTION_INSTANCE_NOT_FOUND"
    | "DELEGATE_TASK_RETRY"
    | "KUBERNETES_YAML_ERROR"
    | "SAVE_FILE_INTO_GCP_STORAGE_FAILED"
    | "READ_FILE_FROM_GCP_STORAGE_FAILED"
    | "FILE_NOT_FOUND_ERROR"
    | "USAGE_LIMITS_EXCEEDED"
    | "EVENT_PUBLISH_FAILED"
    | "JIRA_ERROR"
    | "EXPRESSION_EVALUATION_FAILED"
    | "KUBERNETES_VALUES_ERROR"
    | "KUBERNETES_CLUSTER_ERROR"
    | "INCORRECT_SIGN_IN_MECHANISM"
    | "OAUTH_LOGIN_FAILED"
    | "INVALID_TERRAFORM_TARGETS_REQUEST"
    | "TERRAFORM_EXECUTION_ERROR"
    | "FILE_READ_FAILED"
    | "FILE_SIZE_EXCEEDS_LIMIT"
    | "CLUSTER_NOT_FOUND"
    | "MARKETPLACE_TOKEN_NOT_FOUND"
    | "INVALID_MARKETPLACE_TOKEN"
    | "INVALID_TICKETING_SERVER"
    | "SERVICENOW_ERROR"
    | "PASSWORD_EXPIRED"
    | "USER_LOCKED"
    | "PASSWORD_STRENGTH_CHECK_FAILED"
    | "ACCOUNT_DISABLED"
    | "INVALID_ACCOUNT_PERMISSION"
    | "PAGERDUTY_ERROR"
    | "HEALTH_ERROR"
    | "SAML_TEST_SUCCESS_MECHANISM_NOT_ENABLED"
    | "DOMAIN_WHITELIST_FILTER_CHECK_FAILED"
    | "INVALID_DASHBOARD_UPDATE_REQUEST"
    | "DUPLICATE_FIELD"
    | "INVALID_AZURE_VAULT_CONFIGURATION"
    | "USER_NOT_AUTHORIZED_DUE_TO_USAGE_RESTRICTIONS"
    | "INVALID_ROLLBACK"
    | "DATA_COLLECTION_ERROR"
    | "SUMO_DATA_COLLECTION_ERROR"
    | "DEPLOYMENT_GOVERNANCE_ERROR"
    | "BATCH_PROCESSING_ERROR"
    | "GRAPHQL_ERROR"
    | "FILE_CREATE_ERROR"
    | "ILLEGAL_STATE"
    | "GIT_DIFF_COMMIT_NOT_IN_ORDER"
    | "FAILED_TO_ACQUIRE_PERSISTENT_LOCK"
    | "FAILED_TO_ACQUIRE_NON_PERSISTENT_LOCK"
    | "POD_NOT_FOUND_ERROR"
    | "COMMAND_EXECUTION_ERROR"
    | "REGISTRY_EXCEPTION"
    | "ENGINE_INTERRUPT_PROCESSING_EXCEPTION"
    | "ENGINE_IO_EXCEPTION"
    | "ENGINE_OUTCOME_EXCEPTION"
    | "ENGINE_SWEEPING_OUTPUT_EXCEPTION"
    | "CACHE_NOT_FOUND_EXCEPTION"
    | "ENGINE_ENTITY_UPDATE_EXCEPTION"
    | "SHELL_EXECUTION_EXCEPTION"
    | "TEMPLATE_NOT_FOUND"
    | "AZURE_SERVICE_EXCEPTION"
    | "AZURE_CLIENT_EXCEPTION"
    | "GIT_UNSEEN_REMOTE_HEAD_COMMIT"
    | "TIMEOUT_ENGINE_EXCEPTION"
    | "NO_AVAILABLE_DELEGATES"
    | "NO_INSTALLED_DELEGATES"
    | "DUPLICATE_DELEGATE_EXCEPTION"
    | "GCP_MARKETPLACE_EXCEPTION"
    | "MISSING_DEFAULT_GOOGLE_CREDENTIALS"
    | "INCORRECT_DEFAULT_GOOGLE_CREDENTIALS"
    | "OPTIMISTIC_LOCKING_EXCEPTION"
    | "NG_PIPELINE_EXECUTION_EXCEPTION"
    | "NG_PIPELINE_CREATE_EXCEPTION"
    | "RESOURCE_NOT_FOUND_EXCEPTION"
    | "PMS_INITIALIZE_SDK_EXCEPTION"
    | "UNEXPECTED_SNIPPET_EXCEPTION"
    | "UNEXPECTED_SCHEMA_EXCEPTION"
    | "CONNECTOR_VALIDATION_EXCEPTION"
    | "TIMESCALE_NOT_AVAILABLE"
    | "MIGRATION_EXCEPTION"
    | "REQUEST_PROCESSING_INTERRUPTED"
    | "GCP_SECRET_MANAGER_OPERATION_ERROR"
    | "GCP_SECRET_OPERATION_ERROR"
    | "GIT_OPERATION_ERROR"
    | "TASK_FAILURE_ERROR"
    | "INSTANCE_STATS_PROCESS_ERROR"
    | "INSTANCE_STATS_MIGRATION_ERROR"
    | "DEPLOYMENT_MIGRATION_ERROR"
    | "INSTANCE_STATS_AGGREGATION_ERROR"
    | "UNRESOLVED_EXPRESSIONS_ERROR"
    | "KRYO_HANDLER_NOT_FOUND_ERROR"
    | "DELEGATE_ERROR_HANDLER_EXCEPTION"
    | "UNEXPECTED_TYPE_ERROR"
    | "EXCEPTION_HANDLER_NOT_FOUND"
    | "CONNECTOR_NOT_FOUND_EXCEPTION"
    | "GCP_SERVER_ERROR"
    | "HTTP_RESPONSE_EXCEPTION"
    | "SCM_NOT_FOUND_ERROR"
    | "SCM_CONFLICT_ERROR"
    | "SCM_UNPROCESSABLE_ENTITY"
    | "PROCESS_EXECUTION_EXCEPTION"
    | "SCM_UNAUTHORIZED"
    | "DATA"
    | "CONTEXT"
    | "PR_CREATION_ERROR"
    | "URL_NOT_REACHABLE"
    | "URL_NOT_PROVIDED"
    | "ENGINE_EXPRESSION_EVALUATION_ERROR"
    | "ENGINE_FUNCTOR_ERROR"
    | "JIRA_CLIENT_ERROR"
    | "SCM_NOT_MODIFIED"
    | "JIRA_STEP_ERROR"
    | "BUCKET_SERVER_ERROR";
  executionInterruptType?:
    | "ABORT"
    | "ABORT_ALL"
    | "PAUSE"
    | "PAUSE_FOR_INPUTS"
    | "PAUSE_ALL"
    | "RESUME"
    | "RESUME_ALL"
    | "RETRY"
    | "IGNORE"
    | "WAITING_FOR_MANUAL_INTERVENTION"
    | "MARK_FAILED"
    | "MARK_SUCCESS"
    | "ROLLBACK"
    | "NEXT_STEP"
    | "END_EXECUTION"
    | "ROLLBACK_DONE"
    | "MARK_EXPIRED"
    | "CONTINUE_WITH_DEFAULTS"
    | "CONTINUE_PIPELINE_STAGE";
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  message?: string;
}

export interface KustomizeConfig {
  pluginRootDir?: string;
  kustomizeDirPath?: string;
}

export interface LastDeployedArtifactInformation {
  artifact?: Artifact;
  executionStartTime?: number;
  envId?: string;
  executionId?: string;
  executionEntityId?: string;
  executionEntityType?: "PIPELINE" | "ORCHESTRATION";
  executionEntityName?: string;
}

export interface LastDeployedHelmChartInformation {
  helmchart?: HelmChart;
  executionStartTime?: number;
  envId?: string;
  executionId?: string;
  executionEntityId?: string;
  executionEntityType?: "PIPELINE" | "ORCHESTRATION";
  executionEntityName?: string;
}

export interface ManifestFile {
  fileName?: string;
  fileContent?: string;
  applicationManifestId?: string;
  accountId?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface ManifestSummary {
  uuid?: string;
  versionNo?: string;
  name?: string;
  source?: string;
  appManifestName?: string;
}

export interface ManifestVariable {
  workflowIds?: string[];
  serviceId?: string;
  serviceName?: string;
  applicationManifestSummary?: ApplicationManifestSummary[];
  lastDeployedHelmChartInfo?: LastDeployedHelmChartInformation;
  name?: string;
  description?: string;
  mandatory?: boolean;
  runtimeInput?: boolean;
  value?: string;
  fixed?: boolean;
  allowedValues?: string;
  allowedList?: string[];
  allowMultipleValues?: boolean;
  artifactStreamSummaries?: ArtifactStreamSummary[];
  metadata?: {
    [key: string]: { [key: string]: any };
  };
  type?: "TEXT" | "NUMBER" | "EMAIL" | "ENTITY" | "ARTIFACT" | "MANIFEST";
}

export type MultiServiceOrchestrationWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
  preDeploymentSteps?: PhaseStep;
  rollbackProvisioners?: PhaseStep;
  rollbackWorkflowPhaseIdMap?: {
    [key: string]: WorkflowPhase;
  };
  workflowPhases?: WorkflowPhase[];
  postDeploymentSteps?: PhaseStep;
  failureStrategies?: FailureStrategy[];
  systemVariables?: Variable[];
  derivedVariables?: Variable[];
};

export interface Notification {
  environmentId?: string;
  entityId?: string;
  entityType?:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  accountId?: string;
  eventType?:
    | "USER_INVITED_FROM_EXISTING_ACCOUNT"
    | "COMPLETE_USER_REGISTRATION"
    | "FIRST_DELEGATE_REGISTERED"
    | "FIRST_WORKFLOW_CREATED"
    | "FIRST_DEPLOYMENT_EXECUTED"
    | "FIRST_VERIFIED_DEPLOYMENT"
    | "FIRST_ROLLED_BACK_DEPLOYMENT"
    | "SETUP_CV_24X7"
    | "SETUP_2FA"
    | "SETUP_SSO"
    | "SETUP_IP_WHITELISTING"
    | "SETUP_RBAC"
    | "TRIAL_TO_PAID"
    | "TRIAL_TO_COMMUNITY"
    | "COMMUNITY_TO_PAID"
    | "COMMUNITY_TO_ESSENTIALS"
    | "ESSENTIALS_TO_PAID"
    | "PAID_TO_ESSENTIALS"
    | "TRIAL_TO_ESSENTIALS"
    | "CV_META_DATA"
    | "OPEN_ALERT"
    | "CLOSE_ALERT"
    | "NEW_TRIAL_SIGNUP"
    | "LICENSE_UPDATE"
    | "DEPLOYMENT_VERIFIED"
    | "JOIN_ACCOUNT_REQUEST"
    | "SERVICE_GUARD_SETUP"
    | "DEPLOYMENT_EVENT"
    | "INSTANCE_EVENT"
    | "CUSTOM"
    | "TECH_STACK"
    | "ACCOUNT_ENTITY_CHANGE"
    | "BLACKOUT_WINDOW_UPDATED"
    | "SECRET_MANAGER_TYPE"
    | "USER_INVITE_ACCEPTED_FOR_TRIAL_ACCOUNT";
  notificationType: "APPROVAL" | "CHANGE" | "FAILURE" | "INFORMATION";
  complete: boolean;
  actionable: boolean;
  notificationTemplateId?: string;
  notificationTemplateVariables?: {
    [key: string]: string;
  };
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface NotificationGroup {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  name: string;
  editable?: boolean;
  roles?: Role[];
  defaultNotificationGroupForAccount?: boolean;
  addressesByChannelType: {
    [key: string]: string[];
  };
}

export interface NotificationRule {
  uuid?: string;
  conditions?: (
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING"
  )[];
  executionScope?: "WORKFLOW" | "WORKFLOW_PHASE";
  notificationGroupAsExpression?: boolean;
  userGroupAsExpression?: boolean;
  notificationGroups: NotificationGroup[];
  userGroupIds: string[];
  userGroupExpression: string;
  batchNotifications?: boolean;
  active?: boolean;
}

export interface OrchestrationWorkflow {
  concurrencyStrategy?: ConcurrencyStrategy;
  orchestrationWorkflowType?:
    | "BUILD"
    | "BASIC"
    | "CANARY"
    | "MULTI_SERVICE"
    | "BLUE_GREEN"
    | "ROLLING"
    | "CUSTOM";
  valid?: boolean;
  validationMessage?: string;
  linkedTemplateUuids?: string[];
  serviceIds?: string[];
  requiredEntityTypes?: (
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG"
  )[];
  userVariables?: Variable[];
  infraMappingIds?: string[];
  infraDefinitionIds?: string[];
  notificationRules?: NotificationRule[];
}

export interface ParallelInfo {
  groupIndex?: number;
}

export type PcfInfraStructure = InfraMappingInfrastructureProvider & {
  organization?: string;
  space?: string;
  tempRouteMap?: string[];
  routeMaps?: string[];
};

export interface Permission {
  resourceType?:
    | "APPLICATION"
    | "SERVICE"
    | "CONFIGURATION"
    | "CONFIGURATION_OVERRIDE"
    | "WORKFLOW"
    | "ENVIRONMENT"
    | "ROLE"
    | "DEPLOYMENT"
    | "ARTIFACT"
    | "CLOUD"
    | "USER"
    | "CD"
    | "PIPELINE"
    | "SETTING"
    | "LIMIT"
    | "APP_STACK"
    | "NOTIFICATION_GROUP"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "WHITE_LIST"
    | "SSO"
    | "API_KEY"
    | "PROVISIONER"
    | "PREFERENCE"
    | "TEMPLATE"
    | "CUSTOM_DASHBOARD"
    | "BUDGET"
    | "GCP_RESOURCE"
    | "CLUSTERRECORD"
    | "K8S_LABEL"
    | "K8S_EVENT_YAML_DIFF"
    | "K8S_RECOMMENDATION"
    | "CE_ANOMALIES"
    | "CE_CLUSTER"
    | "CE_CONNECTOR"
    | "CE_BATCH"
    | "LINKED_ACCOUNT";
  action?:
    | "ALL"
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "EXECUTE"
    | "EXECUTE_WORKFLOW"
    | "EXECUTE_PIPELINE"
    | "EXECUTE_WORKFLOW_ROLLBACK"
    | "DEFAULT";
  envId?: string;
  appId?: string;
  accountId?: string;
  environmentType?: "PROD" | "NON_PROD" | "ALL";
  permissionScope?:
    | "ACCOUNT"
    | "LOGGED_IN"
    | "DELEGATE"
    | "NONE"
    | "APP"
    | "ALL_APP_ENTITIES"
    | "ENV"
    | "SERVICE"
    | "WORKFLOW"
    | "PIPELINE"
    | "DEPLOYMENT"
    | "APPLICATION_CREATE_DELETE"
    | "USER_PERMISSION_MANAGEMENT"
    | "ACCOUNT_MANAGEMENT"
    | "PROVISIONER"
    | "TEMPLATE_MANAGEMENT"
    | "USER_PERMISSION_READ"
    | "AUDIT_VIEWER"
    | "TAG_MANAGEMENT"
    | "CE_ADMIN"
    | "CE_VIEWER"
    | "MANAGE_CLOUD_PROVIDERS"
    | "MANAGE_CONNECTORS"
    | "MANAGE_APPLICATIONS"
    | "MANAGE_APPLICATION_STACKS"
    | "MANAGE_DELEGATES"
    | "MANAGE_ALERT_NOTIFICATION_RULES"
    | "MANAGE_DELEGATE_PROFILES"
    | "MANAGE_CONFIG_AS_CODE"
    | "MANAGE_SECRETS"
    | "MANAGE_SECRET_MANAGERS"
    | "MANAGE_SSH_AND_WINRM"
    | "MANAGE_AUTHENTICATION_SETTINGS"
    | "MANAGE_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "VIEW_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "MANAGE_IP_WHITELIST"
    | "MANAGE_IP_WHITELISTING"
    | "MANAGE_DEPLOYMENT_FREEZES"
    | "MANAGE_PIPELINE_GOVERNANCE_STANDARDS"
    | "MANAGE_API_KEYS"
    | "MANAGE_TAGS"
    | "MANAGE_CUSTOM_DASHBOARDS"
    | "CREATE_CUSTOM_DASHBOARDS"
    | "MANAGE_RESTRICTED_ACCESS";
}

export interface PhaseStep {
  uuid?: string;
  name?: string;
  phaseStepType?:
    | "SELECT_NODE"
    | "INFRASTRUCTURE_NODE"
    | "PROVISION_NODE"
    | "DISABLE_SERVICE"
    | "DEPLOY_SERVICE"
    | "ENABLE_SERVICE"
    | "VERIFY_SERVICE"
    | "WRAP_UP"
    | "PRE_DEPLOYMENT"
    | "ROLLBACK_PROVISIONERS"
    | "POST_DEPLOYMENT"
    | "STOP_SERVICE"
    | "DE_PROVISION_NODE"
    | "CLUSTER_SETUP"
    | "CONTAINER_SETUP"
    | "CONTAINER_DEPLOY"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROUTE_UPDATE"
    | "PCF_SWICH_ROUTES"
    | "START_SERVICE"
    | "DEPLOY_AWSCODEDEPLOY"
    | "PREPARE_STEPS"
    | "DEPLOY_AWS_LAMBDA"
    | "COLLECT_ARTIFACT"
    | "AMI_AUTOSCALING_GROUP_SETUP"
    | "AMI_DEPLOY_AUTOSCALING_GROUP"
    | "AMI_SWITCH_AUTOSCALING_GROUP_ROUTES"
    | "ECS_UPDATE_LISTENER_BG"
    | "ECS_UPDATE_ROUTE_53_DNS_WEIGHT"
    | "HELM_DEPLOY"
    | "ROUTE_UPDATE"
    | "K8S_PHASE_STEP"
    | "PROVISION_INFRASTRUCTURE"
    | "ROLLBACK_PROVISION_INFRASTRUCTURE"
    | "SPOTINST_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE"
    | "STAGE_EXECUTION"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROLLBACK"
    | "CUSTOM_DEPLOYMENT_PHASE_STEP"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_TRAFFIC_SHIFT"
    | "AZURE_WEBAPP_SLOT_ROLLBACK";
  steps?: GraphNode[];
  stepsInParallel?: boolean;
  failureStrategies?: FailureStrategy[];
  stepSkipStrategies?: StepSkipStrategy[];
  rollback?: boolean;
  phaseStepNameForRollback?: string;
  statusForRollback?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  artifactNeeded?: boolean;
  valid?: boolean;
  validationMessage?: string;
  waitInterval?: number;
}

export type PhysicalInfra = InfraMappingInfrastructureProvider & {
  hostNames?: string[];
  hosts?: Host[];
  loadBalancerId?: string;
  loadBalancerName?: string;
  hostConnectionAttrs?: string;
  expressions?: {
    [key: string]: string;
  };
};

export type PhysicalInfraWinrm = InfraMappingInfrastructureProvider & {
  hostNames?: string[];
  hosts?: Host[];
  loadBalancerId?: string;
  loadBalancerName?: string;
  winRmConnectionAttributes?: string;
};

export interface Pipeline {
  name: string;
  description?: string;
  pipelineStages?: PipelineStage[];
  stateEtaMap?: {
    [key: string]: number;
  };
  services?: Service[];
  workflowExecutions?: WorkflowExecution[];
  valid?: boolean;
  validationMessage?: string;
  templatized?: boolean;
  hasSshInfraMapping?: boolean;
  failureStrategies?: FailureStrategy[];
  pipelineVariables?: Variable[];
  envIds?: string[];
  workflowIds?: string[];
  envParameterized?: boolean;
  deploymentTypes?: (
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM"
  )[];
  envSummaries?: EnvSummary[];
  hasBuildWorkflow?: boolean;
  infraMappingIds?: string[];
  infraDefinitionIds?: string[];
  keywords?: string[];
  accountId?: string;
  sample?: boolean;
  tagLinks?: HarnessTagLink[];
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface PipelineExecution {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  pipelineId?: string;
  workflowExecutionId?: string;
  stateMachineId?: string;
  pipeline?: Pipeline;
  pipelineStageExecutions?: PipelineStageExecution[];
  appName?: string;
  workflowType?: "PIPELINE" | "ORCHESTRATION";
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  name?: string;
  startTs?: number;
  endTs?: number;
  estimatedTime?: number;
}

export interface PipelineStage {
  name?: string;
  parallel?: boolean;
  pipelineStageElements?: PipelineStageElement[];
  valid?: boolean;
  validationMessage?: string;
  looped?: boolean;
  loopedVarName?: string;
}

export interface PipelineStageElement {
  uuid?: string;
  name?: string;
  type?: string;
  parallelIndex?: number;
  properties?: {
    [key: string]: { [key: string]: any };
  };
  workflowVariables?: {
    [key: string]: string;
  };
  runtimeInputsConfig?: RuntimeInputsConfig;
  disable?: boolean;
  disableAssertion?: string;
  valid?: boolean;
  validationMessage?: string;
}

export interface PipelineStageExecution {
  pipelineStageElementId?: string;
  stateUuid?: string;
  stateName?: string;
  stateType?: string;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  startTs?: number;
  expiryTs?: number;
  endTs?: number;
  estimatedTime?: number;
  workflowExecutions?: WorkflowExecution[];
  stateExecutionData?: StateExecutionData;
  message?: string;
  looped?: boolean;
  waitingForInputs?: boolean;
  parallelInfo?: ParallelInfo;
  triggeredBy?: EmbeddedUser;
  disableAssertionInspection?: StateInspection;
  skipCondition?: string;
  needsInputButNotReceivedYet?: boolean;
}

export interface PipelineSummary {
  pipelineId?: string;
  pipelineName?: string;
}

export interface RestResponseApplication {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Application;
  responseMessages?: ResponseMessage[];
}

export interface Role {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  description?: string;
  accountId?: string;
  appName?: string;
  permissions?: Permission[];
  roleType?:
    | "ACCOUNT_ADMIN"
    | "APPLICATION_ADMIN"
    | "PROD_SUPPORT"
    | "NON_PROD_SUPPORT"
    | "CUSTOM";
  allApps?: boolean;
}

export interface RollbackWorkflowExecutionInfo {
  rollbackType?: "MANUAL" | "AUTO";
  rollbackStateExecutionId?: string;
  rollbackStartTs?: number;
  rollbackDuration?: number;
}

export type RollingOrchestrationWorkflow = OrchestrationWorkflow & {
  graph?: Graph;
  preDeploymentSteps?: PhaseStep;
  rollbackProvisioners?: PhaseStep;
  rollbackWorkflowPhaseIdMap?: {
    [key: string]: WorkflowPhase;
  };
  workflowPhases?: WorkflowPhase[];
  postDeploymentSteps?: PhaseStep;
  failureStrategies?: FailureStrategy[];
  systemVariables?: Variable[];
  derivedVariables?: Variable[];
};

export interface RuntimeInputsConfig {
  runtimeInputVariables?: string[];
  timeout?: number;
  userGroupIds?: string[];
  timeoutAction?:
    | "MANUAL_INTERVENTION"
    | "ROLLBACK_WORKFLOW"
    | "ROLLBACK_PHASE"
    | "IGNORE"
    | "RETRY"
    | "END_EXECUTION"
    | "CONTINUE_WITH_DEFAULTS"
    | "ABORT_WORKFLOW_EXECUTION";
}

export interface ServiceInstance {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  envId?: string;
  serviceTemplateId?: string;
  serviceTemplateName?: string;
  serviceId?: string;
  serviceName?: string;
  hostId?: string;
  hostName?: string;
  publicDns?: string;
  infraMappingId?: string;
  infraMappingType?: string;
  artifactStreamId?: string;
  artifactStreamName?: string;
  artifactId?: string;
  artifactName?: string;
  artifactDeployedOn?: number;
  artifactDeploymentStatus?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  artifactDeploymentActivityId?: string;
  lastActivityId?: string;
  lastActivityStatus?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  lastActivityCreatedAt?: number;
  commandName?: string;
  commandType?: string;
  lastDeployedOn?: number;
  accountId?: string;
  displayName?: string;
}

export interface ServiceTemplate {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  envId?: string;
  name?: string;
  description?: string;
  serviceId?: string;
  serviceConfigFiles?: ConfigFile[];
  serviceVariables?: ServiceVariable[];
  serviceArtifactType?:
    | "JAR"
    | "WAR"
    | "TAR"
    | "ZIP"
    | "NUGET"
    | "DOCKER"
    | "RPM"
    | "AWS_LAMBDA"
    | "AWS_CODEDEPLOY"
    | "PCF"
    | "AMI"
    | "AZURE_MACHINE_IMAGE"
    | "AZURE_WEBAPP"
    | "IIS"
    | "OTHER"
    | "IIS_APP"
    | "IIS_VirtualDirectory";
  configFilesOverrides?: ConfigFile[];
  serviceVariablesOverrides?: ServiceVariable[];
  configMapYamlOverride?: string;
  helmValueYamlOverride?: string;
  infrastructureMappings?: InfrastructureMapping[];
  valuesOverrideAppManifest?: ApplicationManifest;
  helmChartOverride?: ApplicationManifest;
  valuesOverrideManifestFile?: ManifestFile;
  ocParamsOverrideAppManifest?: ApplicationManifest;
  ocParamsOverrideFile?: ManifestFile;
  appSettingOverrideManifest?: ApplicationManifest;
  connStringsOverrideManifest?: ApplicationManifest;
  appSettingsOverrideManifestFile?: ManifestFile;
  connStringsOverrideManifestFile?: ManifestFile;
  defaultServiceTemplate?: boolean;
}

export interface State {
  id?: string;
  parentId?: string;
  name?: string;
  requiredContextElementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE";
  stateType?: string;
  rollback?: boolean;
  waitInterval?: number;
  timeoutMillis?: number;
  ignoreFailure?: boolean;
  templateExpressions?: TemplateExpression[];
  executeWithPreviousSteps?: boolean;
  templateUuid?: string;
  templateVersion?: string;
  templateVariables?: Variable[];
  patternsForRequiredContextElementType?: string[];
  requiredExecutionArgumentTypes?: (
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG"
  )[];
  selectionLogsTrackingForTasksEnabled?: boolean;
}

export interface StateExecutionData {
  stateName?: string;
  stateType?: string;
  startTs?: number;
  endTs?: number;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  errorMsg?: string;
  waitInterval?: number;
  element?: ContextElement;
  stateParams?: {
    [key: string]: { [key: string]: any };
  };
  delegateMetaInfo?: DelegateMetaInfo;
  templateVariable?: {
    [key: string]: { [key: string]: any };
  };
  executionSummary?: {
    [key: string]: ExecutionDataValue;
  };
  executionDetails?: {
    [key: string]: ExecutionDataValue;
  };
}

export interface StateInspection {
  stateExecutionInstanceId?: string;
  data?: {
    [key: string]: StateInspectionData;
  };
  validUntil?: string;
}

export interface StateInspectionData {
  [key: string]: any;
}

export interface StateMachine {
  uuid?: string;
  appId: string;
  createdAt?: number;
  accountId?: string;
  originId?: string;
  originVersion?: number;
  name?: string;
  orchestrationWorkflow?: OrchestrationWorkflow;
  valid?: boolean;
  states?: State[];
  transitions?: Transition[];
  childStateMachines?: {
    [key: string]: StateMachine;
  };
  initialStateName?: string;
  cachedStatesMap?: {
    [key: string]: State;
  };
  cachedTransitionFlowMap?: {
    [key: string]: {
      [key: string]: State[];
    };
  };
  initialState?: State;
  statesMap?: {
    [key: string]: State;
  };
  transitionFlowMap?: {
    [key: string]: {
      [key: string]: State[];
    };
  };
}

export interface StatusInstanceBreakdown {
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  instanceCount?: number;
  instanceExecutionHistories?: InstanceExecutionHistory[];
}

export interface StepSkipStrategy {
  scope: "ALL_STEPS" | "SPECIFIC_STEPS";
  stepIds?: string[];
  assertionExpression: string;
  phaseStep?: PhaseStep;
}

export interface Transition {
  fromState?: State;
  toState?: State;
  transitionType?:
    | "SUCCESS"
    | "FAILURE"
    | "ABORT"
    | "REPEAT"
    | "FORK"
    | "CONDITIONAL";
}

export interface WorkflowExecution {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdByType?: "USER" | "API_KEY" | "TRIGGER";
  createdAt?: number;
  accountId?: string;
  workflowId?: string;
  stateMachineId?: string;
  stateMachine?: StateMachine;
  envId?: string;
  envIds?: string[];
  workflowIds?: string[];
  cloudProviderIds?: string[];
  serviceIds?: string[];
  infraMappingIds?: string[];
  infraDefinitionIds?: string[];
  appName?: string;
  envName?: string;
  envType?: "PROD" | "NON_PROD" | "ALL";
  workflowType?: "PIPELINE" | "ORCHESTRATION";
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  graph?: Graph;
  executionNode?: GraphNode;
  pipelineExecution?: PipelineExecution;
  pipelineExecutionId?: string;
  stageName?: string;
  errorStrategy?: "CONTINUE" | "FAIL" | "PAUSE" | "RETRY";
  name?: string;
  releaseNo?: string;
  total?: number;
  breakdown?: CountsByStatuses;
  executionArgs?: ExecutionArgs;
  serviceExecutionSummaries?: ElementExecutionSummary[];
  statusInstanceBreakdownMap?: {
    [key: string]: StatusInstanceBreakdown;
  };
  startTs?: number;
  rollbackStartTs?: number;
  endTs?: number;
  duration?: number;
  rollbackDuration?: number;
  triggeredBy?: EmbeddedUser;
  pipelineSummary?: PipelineSummary;
  environments?: EnvSummary[];
  deployedEnvironments?: EnvSummary[];
  deployedServices?: string[];
  deployedCloudProviders?: string[];
  buildExecutionSummaries?: BuildExecutionSummary[];
  orchestrationType?:
    | "BUILD"
    | "BASIC"
    | "CANARY"
    | "MULTI_SERVICE"
    | "BLUE_GREEN"
    | "ROLLING"
    | "CUSTOM";
  deploymentTriggerId?: string;
  triggeringApiKeyInfo?: ApiKeyInfo;
  artifacts?: Artifact[];
  rollbackArtifacts?: Artifact[];
  helmCharts?: HelmChart[];
  keywords?: string[];
  onDemandRollback?: boolean;
  useSweepingOutputs?: boolean;
  originalExecution?: WorkflowExecutionInfo;
  helmExecutionSummary?: HelmExecutionSummary;
  awsLambdaExecutionSummaries?: AwsLambdaExecutionSummary[];
  concurrencyStrategy?: ConcurrencyStrategy;
  pipelineResumeId?: string;
  latestPipelineResume?: boolean;
  cdPageCandidate?: boolean;
  nextIteration?: number;
  tags?: NameValuePair[];
  message?: string;
  failureDetails?: string;
  validUntil?: string;
  baseline?: boolean;
}

export interface WorkflowExecutionInfo {
  name?: string;
  executionId?: string;
  startTs?: number;
  rollbackWorkflowExecutionInfo?: RollbackWorkflowExecutionInfo;
  accountId?: string;
  appId?: string;
  workflowId?: string;
}

export interface WorkflowPhase {
  uuid?: string;
  name?: string;
  serviceId?: string;
  daemonSet?: boolean;
  statefulSet?: boolean;
  infraMappingId?: string;
  infraMappingName?: string;
  infraDefinitionId?: string;
  infraDefinitionName?: string;
  deploymentType?:
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM";
  srvTemplatised?: boolean;
  infraTemplatised?: boolean;
  computeProviderId?: string;
  provisionNodes?: boolean;
  rollback?: boolean;
  phaseNameForRollback?: string;
  valid?: boolean;
  validationMessage?: string;
  templateExpressions?: TemplateExpression[];
  variableOverrides?: NameValuePair[];
  phaseSteps?: PhaseStep[];
}

export interface YamlGitConfig {
  url?: string;
  branchName?: string;
  repositoryName?: string;
  username?: string;
  password?: string[];
  sshSettingId?: string;
  keyAuth?: boolean;
  gitConnectorId?: string;
  encryptedPassword?: string;
  syncMode?: "GIT_TO_HARNESS" | "HARNESS_TO_GIT" | "BOTH" | "NONE";
  enabled?: boolean;
  webhookToken?: string;
  accountId?: string;
  entityId?: string;
  entityType:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  entityName?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
}

export interface RestResponsePageResponseApplication {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Application[];
  responseMessages?: ResponseMessage[];
}

export interface AppdynamicsTier {
  id?: number;
  name?: string;
  description?: string;
  type?: string;
  agentType?: string;
  externalTiers?: AppdynamicsTier[];
  dependencyPath?: string;
}

export interface RestResponseSetAppdynamicsTier {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AppdynamicsTier[];
  responseMessages?: ResponseMessage[];
}

export interface NewRelicApplication {
  name?: string;
  id?: number;
}

export interface RestResponseListNewRelicApplication {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NewRelicApplication[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseVerificationNodeDataSetupResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: VerificationNodeDataSetupResponse;
  responseMessages?: ResponseMessage[];
}

export interface VerificationLoadResponse {
  loadResponse?: { [key: string]: any };
  totalHits?: number;
  totalHitsThreshold?: number;
  loadPresent?: boolean;
}

export interface VerificationNodeDataSetupResponse {
  providerReachable?: boolean;
  loadResponse?: VerificationLoadResponse;
  dataForNode?: { [key: string]: any };
  configurationCorrect?: boolean;
}

export interface Aws {
  ec2Instance?: Instance;
  ip?: string;
  instanceId?: string;
  publicDns?: string;
  taskId?: string;
  taskArn?: string;
  dockerId?: string;
  completeDockerId?: string;
  containerId?: string;
  containerInstanceId?: string;
  containerInstanceArn?: string;
  ecsServiceName?: string;
}

export interface AzureVmss {
  ip?: string;
  instanceId?: string;
  publicDns?: string;
}

export interface AzureWebapp {
  ip?: string;
  instanceId?: string;
  instanceType?: string;
  instanceName?: string;
  instanceHostName?: string;
  appName?: string;
  deploySlot?: string;
  deploySlotId?: string;
  appServicePlanId?: string;
}

export interface AppdynamicsSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  applicationId?: number;
  tierId?: number;
  serviceLevel?: boolean;
}

export interface InstanceDetails {
  hostName?: string;
  workloadName?: string;
  newInstance?: boolean;
  properties?: {
    [key: string]: { [key: string]: any };
  };
  serviceTemplateName?: string;
  serviceTemplateId?: string;
  serviceName?: string;
  serviceId?: string;
  pcf?: Pcf;
  aws?: Aws;
  physicalHost?: PhysicalHost;
  k8s?: K8s;
  azureVmss?: AzureVmss;
  azureWebapp?: AzureWebapp;
  instanceType?:
    | "PCF"
    | "AWS"
    | "K8s"
    | "PHYSICAL_HOST"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP";
}

export interface K8s {
  ip?: string;
  podName?: string;
  dockerId?: string;
}

export interface Pcf {
  applicationId?: string;
  applicationName?: string;
  instanceIndex?: string;
}

export interface PhysicalHost {
  publicDns?: string;
  instanceId?: string;
}

export interface RestResponsePageResponseApplicationManifest {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ApplicationManifest[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseApplicationManifest {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ApplicationManifest;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseManifestFile {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ManifestFile;
  responseMessages?: ResponseMessage[];
}

export interface DirectoryNode {
  accountId?: string;
  type?: "FOLDER" | "YAML" | "FILE";
  name?: string;
  className?: string;
  shortClassName?: string;
  restName?: string;
  directoryPath?: DirectoryPath;
}

export interface DirectoryPath {
  path?: string;
}

export interface RestResponseDirectoryNode {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DirectoryNode;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListManifestFile {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ManifestFile[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseArtifact {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Artifact;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseArtifact {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Artifact[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseArtifactStream {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ArtifactStream;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseArtifactStream {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ArtifactStream[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapStringString {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: string;
  };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListArtifactStreamSummary {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ArtifactStreamSummary[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListString {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: string[];
  responseMessages?: ResponseMessage[];
}

export interface ApiKeyAuditDetails {
  apiKeyId?: string;
  apiKeyName?: string;
}

export interface AuditHeader {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  remoteUser?: User;
  application?: Application;
  component?: Service;
  environment?: Environment;
  url?: string;
  resourcePath?: string;
  queryParams?: string;
  requestMethod?:
    | "OPTIONS"
    | "HEAD"
    | "GET"
    | "PATCH"
    | "POST"
    | "PUT"
    | "DELETE";
  headerString?: string;
  responseType?: "SUCCESS" | "FAILED" | "COMPLETED_WITH_ERRORS";
  responseStatusCode?: number;
  errorCode?: string;
  remoteHostName?: string;
  remoteHostPort?: number;
  remoteIpAddress?: string;
  localHostName?: string;
  localIpAddress?: string;
  requestPayloadUuid?: string;
  responsePayloadUuid?: string;
  requestTime?: number;
  responseTime?: number;
  failureStatusMsg?: string;
  details?: {
    [key: string]: { [key: string]: any };
  };
  accountId?: string;
  gitAuditDetails?: GitAuditDetails;
  entityAuditRecords?: EntityAuditRecord[];
  apiKeyAuditDetails?: ApiKeyAuditDetails;
}

export interface EntityAuditRecord {
  entityId?: string;
  entityType?: string;
  entityName?: string;
  operationType?: string;
  entityOldYamlRecordId?: string;
  entityNewYamlRecordId?: string;
  yamlPath?: string;
  yamlError?: string;
  failure?: boolean;
  appId?: string;
  appName?: string;
  affectedResourceId?: string;
  affectedResourceName?: string;
  affectedResourceType?: string;
  affectedResourceOperation?: string;
  createdAt?: number;
}

export interface GitAuditDetails {
  author?: string;
  gitCommitId?: string;
  repoUrl?: string;
}

export interface RestResponsePageResponseAuditHeader {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AuditHeader[];
  responseMessages?: ResponseMessage[];
}

export interface AuditHeaderYamlResponse {
  auditHeaderId?: string;
  entityId?: string;
  oldYaml?: string;
  oldYamlPath?: string;
  newYaml?: string;
  newYamlPath?: string;
}

export interface RestResponseAuditHeaderYamlResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AuditHeaderYamlResponse;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListNameValuePair {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NameValuePair[];
  responseMessages?: ResponseMessage[];
}

export type AzureKubernetesCluster = Cluster & {
  resourceGroup?: string;
  subscriptionId?: string;
};

export interface RestResponseListAzureKubernetesCluster {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureKubernetesCluster[];
  responseMessages?: ResponseMessage[];
}

export interface AzureResourceGroup {
  name?: string;
  subscriptionId?: string;
}

export interface RestResponseListAzureResourceGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureResourceGroup[];
  responseMessages?: ResponseMessage[];
}

export interface AzureImageGallery {
  name?: string;
  subscriptionId?: string;
  resourceGroupName?: string;
  regionName?: string;
}

export interface RestResponseListAzureImageGallery {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureImageGallery[];
  responseMessages?: ResponseMessage[];
}

export interface AzureImageDefinition {
  name?: string;
  subscriptionId?: string;
  resourceGroupName?: string;
  location?: string;
  osType?: string;
  galleryName?: string;
}

export interface RestResponseListAzureImageDefinition {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureImageDefinition[];
  responseMessages?: ResponseMessage[];
}

export interface AzureContainerRegistry {
  name?: string;
  resourceGroup?: string;
  subscriptionId?: string;
  type?: string;
  resourceId?: string;
  loginServer?: string;
}

export interface RestResponseListAzureContainerRegistry {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureContainerRegistry[];
  responseMessages?: ResponseMessage[];
}

export interface AlertThreshold {
  percentage?: number;
  basedOn?: "ACTUAL_COST" | "FORECASTED_COST";
  emailAddresses?: string[];
  userGroupIds?: string[];
  slackWebhooks?: string[];
  alertsSent?: number;
  crossedAt?: number;
}

export type ApplicationBudgetScope = BudgetScope & {
  applicationIds?: string[];
  environmentType?: "PROD" | "NON_PROD" | "ALL";
};

export interface Budget {
  uuid?: string;
  accountId?: string;
  name?: string;
  scope?: BudgetScope;
  type?: "SPECIFIED_AMOUNT" | "PREVIOUS_MONTH_SPEND";
  budgetAmount?: number;
  actualCost?: number;
  forecastCost?: number;
  lastMonthCost?: number;
  alertThresholds?: AlertThreshold[];
  emailAddresses?: string[];
  userGroupIds?: string[];
  notifyOnSlack?: boolean;
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface BudgetScope {
  entityIds?: string[];
  entityNames?: string[];
  budgetScopeType?: string;
}

export type ClusterBudgetScope = BudgetScope & {
  clusterIds?: string[];
};

export type PerspectiveBudgetScope = BudgetScope & {
  viewId?: string;
  viewName?: string;
};

export interface RestResponseBudget {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Budget;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListBudget {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Budget[];
  responseMessages?: ResponseMessage[];
}

export interface BugsnagSetupTestData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  orgId?: string;
  projectId?: string;
  releaseStage?: string;
  query?: string;
  browserApplication?: boolean;
  serviceLevel?: boolean;
}

export interface BugsnagApplication {
  name?: string;
  id?: string;
}

export interface RestResponseSetBugsnagApplication {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: BugsnagApplication[];
  responseMessages?: ResponseMessage[];
}

export interface JobDetails {
  jobName?: string;
  url?: string;
  parameters?: JobParameter[];
  folder?: boolean;
}

export interface JobParameter {
  name?: string;
  options?: string[];
  defaultValue?: string;
  description?: string;
}

export interface RestResponseSetJobDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: JobDetails[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseJobDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: JobDetails;
  responseMessages?: ResponseMessage[];
}

export interface BuildDetails {
  number?: string;
  revision?: string;
  description?: string;
  artifactPath?: string;
  buildUrl?: string;
  buildDisplayName?: string;
  buildFullDisplayName?: string;
  artifactFileSize?: string;
  uiDisplayName?: string;
  status?: "FAILURE" | "UNSTABLE" | "SUCCESS";
  buildParameters?: {
    [key: string]: string;
  };
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  artifactFileMetadataList?: ArtifactFileMetadata[];
}

export interface RestResponseListBuildDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: BuildDetails[];
  responseMessages?: ResponseMessage[];
}

export interface AzureDevopsProject {
  id?: string;
  name?: string;
}

export interface RestResponseListAzureDevopsProject {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureDevopsProject[];
  responseMessages?: ResponseMessage[];
}

export interface AzureArtifactsFeed {
  id?: string;
  name?: string;
  fullyQualifiedName?: string;
  project?: AzureDevopsProject;
}

export interface RestResponseListAzureArtifactsFeed {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureArtifactsFeed[];
  responseMessages?: ResponseMessage[];
}

export interface AzureArtifactsPackage {
  id?: string;
  name?: string;
  protocolType?: string;
}

export interface RestResponseListAzureArtifactsPackage {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AzureArtifactsPackage[];
  responseMessages?: ResponseMessage[];
}

export interface CECommunications {
  uuid?: string;
  accountId?: string;
  emailId?: string;
  type?: "WEEKLY_REPORT";
  enabled?: boolean;
  selfEnabled?: boolean;
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface RestResponseListCECommunications {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CECommunications[];
  responseMessages?: ResponseMessage[];
}

export interface CEReportSchedule {
  uuid?: string;
  name?: string;
  enabled?: boolean;
  description?: string;
  viewsId: string[];
  userCron?: string;
  recipients?: string[];
  accountId?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  userCronTimeZone?: string;
  createdBy?: EmbeddedUser;
  lastUpdatedBy?: EmbeddedUser;
  nextExecution?: string;
}

export interface CESlackWebhook {
  uuid?: string;
  accountId?: string;
  webhookUrl?: string;
  sendCostReport?: boolean;
  sendAnomalyAlerts?: boolean;
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface RestResponseCESlackWebhook {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CESlackWebhook;
  responseMessages?: ResponseMessage[];
}

export interface CVActivityLog {
  uuid?: string;
  cvConfigId?: string;
  stateExecutionId?: string;
  timestamp?: number;
  dataCollectionMinute?: number;
  log?: string;
  logLevel?: "INFO" | "WARN" | "ERROR";
  timestampParams?: number[];
  accountId?: string;
  validUntil?: string;
  ansiLog?: string;
}

export interface RestResponseListCVActivityLog {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVActivityLog[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseCVConfiguration {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVConfiguration;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListCVConfiguration {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVConfiguration[];
  responseMessages?: ResponseMessage[];
}

export interface LogsCVConfiguration {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name: string;
  accountId: string;
  connectorId: string;
  envId: string;
  serviceId: string;
  stateType:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  analysisTolerance: "LOW" | "MEDIUM" | "HIGH";
  customThresholdRefId?: string;
  enabled24x7?: boolean;
  comparisonStrategy?:
    | "COMPARE_WITH_PREVIOUS"
    | "COMPARE_WITH_CURRENT"
    | "PREDICTIVE";
  contextId?: string;
  alertEnabled?: boolean;
  alertThreshold?: number;
  numOfOccurrencesForAlert?: number;
  snoozeStartTime?: number;
  snoozeEndTime?: number;
  connectorName?: string;
  serviceName?: string;
  envName?: string;
  appName?: string;
  query?: string;
  baselineStartMinute?: number;
  baselineEndMinute?: number;
  alertPriority?: "BASELINE" | "P5" | "P4" | "P3" | "P2" | "P1" | "P0";
  is247LogsV2?: boolean;
  workflowConfig?: boolean;
}

export interface CgEventConfig {
  name?: string;
  config: WebHookEventConfig;
  rule?: CgEventRule;
  accountId?: string;
  delegateSelectors?: string[];
  enabled?: boolean;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  summary?: string;
  uuid: string;
}

export interface CgEventRule {
  type?: "PIPELINE" | "WORKFLOW" | "ALL";
  pipelineRule?: PipelineRule;
  workflowRule?: WorkflowRule;
}

export interface KeyValuePair {
  key?: string;
  value?: string;
}

export interface PipelineRule {
  allEvents?: boolean;
  allPipelines?: boolean;
  pipelineIds?: string[];
  events?: string[];
}

export interface RestResponseListCgEventConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CgEventConfig[];
  responseMessages?: ResponseMessage[];
}

export interface WebHookEventConfig {
  url?: string;
  headers?: KeyValuePair[];
  useProxy?: boolean;
  tags?: string[];
  socketTimeoutMillis?: number;
}

export interface WorkflowRule {
  allEvents?: boolean;
  allWorkflows?: boolean;
  workflowIds?: string[];
  events?: string[];
}

export interface RestResponseCgEventConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CgEventConfig;
  responseMessages?: ResponseMessage[];
}

export interface CloudWatchMetric {
  metricName?: string;
  displayName?: string;
  dimension?: string;
  dimensionDisplay?: string;
  metricType?: string;
  enabledDefault?: boolean;
  statistics?: string;
  unit?:
    | "Seconds"
    | "Microseconds"
    | "Milliseconds"
    | "Bytes"
    | "Kilobytes"
    | "Megabytes"
    | "Gigabytes"
    | "Terabytes"
    | "Bits"
    | "Kilobits"
    | "Megabits"
    | "Gigabits"
    | "Terabits"
    | "Percent"
    | "Count"
    | "BytesSecond"
    | "KilobytesSecond"
    | "MegabytesSecond"
    | "GigabytesSecond"
    | "TerabytesSecond"
    | "BitsSecond"
    | "KilobitsSecond"
    | "MegabitsSecond"
    | "GigabitsSecond"
    | "TerabitsSecond"
    | "CountSecond"
    | "None";
}

export interface RestResponseListCloudWatchMetric {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CloudWatchMetric[];
  responseMessages?: ResponseMessage[];
}

export interface CloudWatchSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  region?: string;
  hostName?: string;
  loadBalancerMetricsByLBName?: {
    [key: string]: CloudWatchMetric[];
  };
  ecsMetrics?: {
    [key: string]: CloudWatchMetric[];
  };
  lambdaFunctionsMetrics?: {
    [key: string]: CloudWatchMetric[];
  };
  ec2Metrics?: CloudWatchMetric[];
  serviceLevel?: boolean;
}

export interface Cluster {
  clusterName?: string;
  clusterType?: string;
  cloudProviderId?: string;
}

export interface ClusterRecord {
  uuid?: string;
  accountId?: string;
  cluster?: Cluster;
  perpetualTaskIds?: string[];
  createdAt?: number;
  lastUpdatedAt?: number;
  deactivated?: boolean;
}

export type DirectKubernetesCluster = Cluster & {};

export type EcsCluster = Cluster & {
  region?: string;
};

export type GcpKubernetesCluster = Cluster & {};

export interface RestResponseClusterRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ClusterRecord;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListClusterRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ClusterRecord[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseConfigFile {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ConfigFile;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseConfigFile {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ConfigFile[];
  responseMessages?: ResponseMessage[];
}

export interface HeatMap {
  cvConfiguration?: CVConfiguration;
  riskLevelSummary?: HeatMapUnit[];
  observedTimeSeries?: {
    [key: string]: {
      [key: string]: TimeSeriesDataPoint[];
    };
  };
  predictedTimeSeries?: {
    [key: string]: {
      [key: string]: TimeSeriesDataPoint[];
    };
  };
}

export interface HeatMapUnit {
  startTime?: number;
  endTime?: number;
  highRisk?: number;
  mediumRisk?: number;
  lowRisk?: number;
  na?: number;
  overallScore?: number;
  scoreList?: number[];
  keyTransactionScore?: number;
  keyTransactionScoreList?: number[];
}

export interface RestResponseListHeatMap {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HeatMap[];
  responseMessages?: ResponseMessage[];
}

export interface TimeSeriesDataPoint {
  timestamp?: number;
  value?: number;
  risk?: number;
}

export interface ContinuousVerificationExecutionMetaData {
  workflowStartTs?: number;
  pipelineStartTs?: number;
  accountId?: string;
  envId?: string;
  applicationId?: string;
  serviceId?: string;
  workflowId?: string;
  workflowExecutionId?: string;
  stateExecutionId?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  pipelineId?: string;
  pipelineExecutionId?: string;
  phaseId?: string;
  artifactName?: string;
  envName?: string;
  workflowName?: string;
  appName?: string;
  serviceName?: string;
  phaseName?: string;
  stateStartTs?: number;
  pipelineName?: string;
  executionStatus?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  noData?: boolean;
  manualOverride?: boolean;
  validUntil?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface RestResponseLinkedHashMapLongLinkedHashMapStringLinkedHashMapStringLinkedHashMapStringLinkedHashMapStringListContinuousVerificationExecutionMetaData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          [key: string]: {
            [key: string]: ContinuousVerificationExecutionMetaData[];
          };
        };
      };
    };
  };
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseContinuousVerificationExecutionMetaData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ContinuousVerificationExecutionMetaData[];
  responseMessages?: ResponseMessage[];
}

export interface CVDeploymentData {
  appId?: string;
  envId?: string;
  serviceId?: string;
  accountId?: string;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  startTs?: number;
  workflowExecutionId?: string;
  pipelineExecutionId?: string;
  workflowName?: string;
  pipelineName?: string;
}

export interface RestResponseListCVDeploymentData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVDeploymentData[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListWorkflowExecution {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowExecution[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseSortedSetTransactionTimeSeries {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TransactionTimeSeries[];
  responseMessages?: ResponseMessage[];
}

export interface TimeSeriesOfMetric {
  risk?: number;
  metricName?: string;
  metricType?: string;
  metricDeeplinkUrl?: string;
  lastSeenTime?: number;
  timeSeries?: TimeSeriesDataPoint[];
  risksForTimeSeries?: TimeSeriesRisk[];
  longTermPattern?: boolean;
}

export interface TimeSeriesRisk {
  startTime?: number;
  endTime?: number;
  risk?: number;
}

export interface TransactionTimeSeries {
  tag?: string;
  transactionName?: string;
  metricTimeSeries?: TimeSeriesOfMetric[];
}

export interface RestResponseServiceGuardTimeSeries {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceGuardTimeSeries;
  responseMessages?: ResponseMessage[];
}

export interface ServiceGuardTimeSeries {
  timeSeriesSet?: TransactionTimeSeries[];
  transactionsInAnalysis?: string[];
  totalRecords?: number;
}

export interface TimeSeriesFilter {
  cvConfigId?: string;
  startTime?: number;
  endTime?: number;
  historyStartTime?: number;
  txnNames?: string[];
  metricNames?: string[];
  tags?: string[];
}

export interface LogMLAnalysisSummary {
  query?: string;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  analysisSummaryMessage?: string;
  baseLineExecutionId?: string;
  score?: number;
  highRiskClusters?: number;
  mediumRiskClusters?: number;
  lowRiskClusters?: number;
  controlClusters?: LogMLClusterSummary[];
  testClusters?: LogMLClusterSummary[];
  unknownClusters?: LogMLClusterSummary[];
  ignoreClusters?: LogMLClusterSummary[];
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  analysisComparisonStrategy?:
    | "COMPARE_WITH_PREVIOUS"
    | "COMPARE_WITH_CURRENT"
    | "PREDICTIVE";
  analysisMinute?: number;
  progress?: number;
  timeDuration?: number;
  newVersionNodes?: string[];
  previousVersionNodes?: string[];
  baselineStartTime?: number;
  baselineEndTime?: number;
  emptyResult?: boolean;
}

export interface LogMLClusterSummary {
  hostSummary?: {
    [key: string]: LogMLHostSummary;
  };
  logText?: string;
  tags?: string[];
  score?: number;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  priority?: "BASELINE" | "P5" | "P4" | "P3" | "P2" | "P1" | "P0";
  clusterLabel?: number;
  logMLFeedbackType?:
    | "IGNORE_SERVICE"
    | "IGNORE_WORKFLOW"
    | "IGNORE_WORKFLOW_EXECUTION"
    | "IGNORE_ALWAYS"
    | "DISMISS"
    | "PRIORITIZE"
    | "THUMBS_UP"
    | "THUMBS_DOWN"
    | "UNDO_IGNORE";
  logMLFeedbackId?: string;
  jiraLink?: string;
  feedbackSummary?: LogMLFeedbackSummary;
}

export interface LogMLFeedbackSummary {
  priority?: "BASELINE" | "P5" | "P4" | "P3" | "P2" | "P1" | "P0";
  logMLFeedbackId?: string;
  jiraLink?: string;
  feedbackNote?: string;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
}

export interface LogMLHostSummary {
  count?: number;
  unexpectedFreq?: boolean;
  frequencies?: number[];
  frequencyMap?: {
    [key: string]: number;
  };
  xcordinate?: number;
  ycordinate?: number;
}

export interface RestResponseLogMLAnalysisSummary {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LogMLAnalysisSummary;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapStringDouble {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: number;
  };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseLong {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: number;
  responseMessages?: ResponseMessage[];
}

export interface Store {
  name?: string;
}

export interface VerificationDataAnalysisResponse {
  executionStatus?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  stateExecutionData?: VerificationStateAnalysisExecutionData;
}

export interface VerificationStateAnalysisExecutionData {
  wingsPersistence?: WingsPersistence;
  correlationId?: string;
  stateExecutionInstanceId?: string;
  baselineExecutionId?: string;
  serverConfigId?: string;
  canaryNewHostNames?: string[];
  lastExecutionNodes?: string[];
  analysisMinute?: number;
  query?: string;
  progressPercentage?: number;
  comparisonStrategy?:
    | "COMPARE_WITH_PREVIOUS"
    | "COMPARE_WITH_CURRENT"
    | "PREDICTIVE";
  remainingMinutes?: number;
  customThresholdRefId?: string;
  stateName?: string;
  stateType?: string;
  startTs?: number;
  endTs?: number;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  errorMsg?: string;
  waitInterval?: number;
  element?: ContextElement;
  stateParams?: {
    [key: string]: { [key: string]: any };
  };
  delegateMetaInfo?: DelegateMetaInfo;
  templateVariable?: {
    [key: string]: { [key: string]: any };
  };
}

export interface WingsPersistence {
  classStores?: {
    [key: string]: Store;
  };
}

export interface AdditionalMetadata {
  values?: {
    [key: string]: { [key: string]: any };
  };
}

export interface DataCollectionInfoV2 {
  accountId?: string;
  applicationId?: string;
  envId?: string;
  startTime?: number;
  endTime?: number;
  hosts?: string[];
  cvConfigId?: string;
  stateExecutionId?: string;
  workflowId?: string;
  workflowExecutionId?: string;
  serviceId?: string;
  cvTaskId?: string;
  connectorId?: string;
  encryptedDataDetails?: EncryptedDataDetail[];
  dataCollectionStartTime?: number;
  shouldSendHeartbeat?: boolean;
}

export interface EncryptedDataDetail {
  encryptedData?: EncryptedRecordData;
  encryptionConfig?: EncryptionConfig;
  fieldName?: string;
  identifier?: SecretUniqueIdentifier;
}

export interface EncryptedDataParams {
  name?: string;
  value?: string;
}

export interface EncryptedRecordData {
  uuid?: string;
  name?: string;
  path?: string;
  parameters?: EncryptedDataParams[];
  encryptionKey?: string;
  encryptedValue?: string[];
  kmsId?: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  backupEncryptedValue?: string[];
  backupEncryptionKey?: string;
  backupKmsId?: string;
  backupEncryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  base64Encoded?: boolean;
  additionalMetadata?: AdditionalMetadata;
}

export interface EncryptionConfig {
  uuid?: string;
  name?: string;
  default?: boolean;
  type?: "KMS" | "VAULT" | "CUSTOM" | "SSH";
  numOfEncryptedValue?: number;
  encryptionServiceUrl?: string;
  validationCriteria?: string;
  globalKms?: boolean;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
}

export interface SecretUniqueIdentifier {
  kmsId?: string;
}

export interface RestResponseStateExecutionData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: StateExecutionData;
  responseMessages?: ResponseMessage[];
}

export interface APMFetchConfig {
  url?: string;
  body?: string;
  guid?: string;
}

export interface APMSetupTestNodeData {
  fetchConfig?: APMFetchConfig;
  apmMetricCollectionInfo?: MetricCollectionInfo;
  host?: string;
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  serviceLevel?: boolean;
}

export interface MetricCollectionInfo {
  metricName?: string;
  metricType?:
    | "INFRA"
    | "VALUE"
    | "RESP_TIME"
    | "THROUGHPUT"
    | "ERROR"
    | "APDEX"
    | "VALUE_LOWER";
  tag?: string;
  collectionUrl?: string;
  baselineCollectionUrl?: string;
  collectionBody?: string;
  responseType?: "JSON";
  responseMapping?: ResponseMapping;
  method?: "POST" | "GET";
}

export interface ResponseMapping {
  logMessageJsonPath?: string;
  hostJsonPath?: string;
  hostRegex?: string;
  timestampJsonPath?: string;
  timestampFormat?: string;
}

export interface CVCertifiedDetailsForWorkflowState {
  workflowName?: string;
  workflowId?: string;
  workflowExecutionId?: string;
  pipelineId?: string;
  pipelineName?: string;
  pipelineExecutionId?: string;
  phaseName?: string;
  stateExecutionId?: string;
  executionDetails?: StateExecutionInstance;
}

export interface ExecutionEventAdvisor {
  [key: string]: any;
}

export interface ExecutionInterruptEffect {
  interruptId?: string;
  tookEffectAt?: string;
}

export interface LoopParams {
  [key: string]: any;
}

export interface RestResponseListCVCertifiedDetailsForWorkflowState {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVCertifiedDetailsForWorkflowState[];
  responseMessages?: ResponseMessage[];
}

export interface StateExecutionInstance {
  uuid?: string;
  appId?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  accountId?: string;
  childStateMachineId?: string;
  displayName?: string;
  stateName?: string;
  stateType?: string;
  contextElement?: ContextElement;
  contextTransition?: boolean;
  rollback?: boolean;
  waitingForInputs?: boolean;
  actionOnTimeout?:
    | "MANUAL_INTERVENTION"
    | "ROLLBACK_WORKFLOW"
    | "ROLLBACK_PHASE"
    | "IGNORE"
    | "RETRY"
    | "END_EXECUTION"
    | "CONTINUE_WITH_DEFAULTS"
    | "ABORT_WORKFLOW_EXECUTION";
  continued?: boolean;
  waitingForManualIntervention?: boolean;
  actionAfterManualInterventionTimeout?:
    | "ABORT"
    | "ABORT_ALL"
    | "PAUSE"
    | "PAUSE_FOR_INPUTS"
    | "PAUSE_ALL"
    | "RESUME"
    | "RESUME_ALL"
    | "RETRY"
    | "IGNORE"
    | "WAITING_FOR_MANUAL_INTERVENTION"
    | "MARK_FAILED"
    | "MARK_SUCCESS"
    | "ROLLBACK"
    | "NEXT_STEP"
    | "END_EXECUTION"
    | "ROLLBACK_DONE"
    | "MARK_EXPIRED"
    | "CONTINUE_WITH_DEFAULTS"
    | "CONTINUE_PIPELINE_STAGE";
  delegateTaskId?: string;
  delegateTasksDetails?: DelegateTaskDetails[];
  selectionLogsTrackingForTasksEnabled?: boolean;
  rollbackPhaseName?: string;
  parentLoopedState?: boolean;
  loopedStateParams?: LoopParams;
  contextElements?: ContextElement[];
  stateExecutionMap?: {
    [key: string]: StateExecutionData;
  };
  stateExecutionDataHistory?: StateExecutionData[];
  dedicatedInterruptCount?: number;
  interruptHistory?: ExecutionInterruptEffect[];
  executionEventAdvisors?: ExecutionEventAdvisor[];
  notifyElements?: ContextElement[];
  callback?: StateMachineExecutionCallback;
  executionName?: string;
  executionType?: "PIPELINE" | "ORCHESTRATION";
  executionUuid?: string;
  parentInstanceId?: string;
  subGraphFilterId?: string;
  prevInstanceId?: string;
  nextInstanceId?: string;
  cloneInstanceId?: string;
  notifyId?: string;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  stateParams?: {
    [key: string]: { [key: string]: any };
  };
  startTs?: number;
  endTs?: number;
  expiryTs?: number;
  stateTimeout?: number;
  retry?: boolean;
  retryCount?: number;
  hasInspection?: boolean;
  workflowId?: string;
  pipelineStageElementId?: string;
  pipelineStageParallelIndex?: number;
  stageName?: string;
  phaseSubWorkflowId?: string;
  stepId?: string;
  orchestrationWorkflowType?:
    | "BUILD"
    | "BASIC"
    | "CANARY"
    | "MULTI_SERVICE"
    | "BLUE_GREEN"
    | "ROLLING"
    | "CUSTOM";
}

export interface StateMachineExecutionCallback {
  [key: string]: any;
}

export interface EntitySummary {
  id?: string;
  name?: string;
  type?: string;
}

export interface EntitySummaryStats {
  entitySummary?: EntitySummary;
  count?: number;
}

export interface InstanceSummaryStats {
  totalCount?: number;
  countMap?: {
    [key: string]: EntitySummaryStats[];
  };
}

export interface RestResponseInstanceSummaryStats {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InstanceSummaryStats;
  responseMessages?: ResponseMessage[];
}

export interface ContainerInstanceKey {
  containerId?: string;
  namespace?: string;
}

export interface HostInstanceKey {
  hostName?: string;
  infraMappingId?: string;
}

export interface InstanceInfo {
  [key: string]: any;
}

export interface PcfInstanceKey {
  id?: string;
}

export interface PodInstanceKey {
  podName?: string;
  namespace?: string;
}

export interface RestResponseInstance {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Instance;
  responseMessages?: ResponseMessage[];
}

export interface CurrentActiveInstances {
  environment?: EntitySummary;
  instanceCount?: number;
  artifact?: ArtifactSummary;
  manifest?: ManifestSummary;
  serviceInfra?: EntitySummary;
  workflow?: EntitySummary;
  deployedAt?: string;
  lastWorkflowExecution?: EntitySummary;
  onDemandRollbackAvailable?: boolean;
}

export interface DeploymentHistory {
  artifact?: ArtifactSummary;
  manifest?: ManifestSummary;
  deployedAt?: string;
  status?: string;
  triggeredBy?: EntitySummary;
  pipeline?: EntitySummary;
  workflow?: EntitySummary;
  instanceCount?: number;
  inframappings?: EntitySummary[];
  envs?: EntitySummary[];
  rolledBack?: boolean;
}

export interface RestResponseServiceInstanceDashboard {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceInstanceDashboard;
  responseMessages?: ResponseMessage[];
}

export interface ServiceInstanceDashboard {
  serviceSummary?: EntitySummary;
  currentActiveInstancesList?: CurrentActiveInstances[];
  deploymentHistoryList?: DeploymentHistory[];
}

export interface EnvironmentSummary {
  id?: string;
  name?: string;
  type?: string;
  prod?: boolean;
}

export interface InstanceStats {
  totalCount?: number;
  entitySummaryList?: EntitySummary[];
  invocationCount?: InvocationCount;
}

export interface InstanceStatsByArtifact {
  entitySummary?: ArtifactSummary;
  instanceStats?: InstanceStats;
}

export interface InstanceStatsByEnvironment {
  environmentSummary?: EnvironmentSummary;
  instanceStatsByArtifactList?: InstanceStatsByArtifact[];
  infraMappingSyncStatusList?: SyncStatus[];
  hasSyncIssues?: boolean;
}

export interface InstanceStatsByService {
  totalCount?: number;
  serviceSummary?: ServiceSummary;
  instanceStatsByEnvList?: InstanceStatsByEnvironment[];
}

export interface InvocationCount {
  key?: "LAST_30_DAYS" | "SINCE_LAST_DEPLOYED";
  count?: number;
  from?: number;
  to?: number;
}

export interface RestResponseListInstanceStatsByService {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InstanceStatsByService[];
  responseMessages?: ResponseMessage[];
}

export interface ServiceSummary {
  id?: string;
  name?: string;
  type?: string;
  appSummary?: EntitySummary;
}

export interface SyncStatus {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  envId?: string;
  serviceId?: string;
  infraMappingId?: string;
  infraMappingName?: string;
  lastSyncedAt?: number;
  lastSuccessfullySyncedAt?: number;
  syncFailureReason?: string;
}

export interface RestResponseListInstanceStatsByEnvironment {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InstanceStatsByEnvironment[];
  responseMessages?: ResponseMessage[];
}

export interface InstanceSummaryStatsByService {
  totalCount?: number;
  prodCount?: number;
  nonprodCount?: number;
  serviceSummary?: ServiceSummary;
  invocationCount?: InvocationCount;
}

export interface RestResponsePageResponseInstanceSummaryStatsByService {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InstanceSummaryStatsByService[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListBoolean {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: boolean[];
  responseMessages?: ResponseMessage[];
}

export interface Aggregate {
  entityType?: string;
  name?: string;
  id?: string;
  entityDeleted?: boolean;
  invocationCount?: number;
  invocationCountKey?: "LAST_30_DAYS" | "SINCE_LAST_DEPLOYED";
}

export interface DataPoint {
  timestamp?: number;
  accountId?: string;
  aggregateInvocationCountList?: Aggregate[];
  totalInvocationCount?: number;
  invocationCountKey?: "LAST_30_DAYS" | "SINCE_LAST_DEPLOYED";
}

export interface InstanceTimeline {
  points?: DataPoint[];
  localPercentile?: {
    [key: string]: { [key: string]: any };
  };
}

export interface RestResponseInstanceTimeline {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InstanceTimeline;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListTimeRange {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TimeRange[];
  responseMessages?: ResponseMessage[];
}

export interface TimeRange {
  from?: number;
  to?: number;
  timeZone?: string;
  durationBased?: boolean;
  duration?: number;
  endTime?: number;
  freezeOccurrence?: "DAILY" | "WEEKLY" | "MONTHLY" | "ANNUAL";
  expires?: boolean;
  label?: string;
}

export interface DataDogSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  datadogServiceName?: string;
  dockerMetrics?: {
    [key: string]: string;
  };
  ecsMetrics?: {
    [key: string]: string;
  };
  customMetricsMap?: {
    [key: string]: Metric[];
  };
  metrics?: string;
  hostNameField?: string;
  query?: string;
  customMetrics?: {
    [key: string]: Metric[];
  };
  deploymentType?: string;
  serviceLevel?: boolean;
}

export interface Metric {
  metricName?: string;
  mlMetricType?:
    | "INFRA"
    | "VALUE"
    | "RESP_TIME"
    | "THROUGHPUT"
    | "ERROR"
    | "APDEX"
    | "VALUE_LOWER";
  displayName?: string;
  tags?: string[];
}

export interface RestResponseListMetric {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Metric[];
  responseMessages?: ResponseMessage[];
}

export interface Delegate {
  uuid: string;
  createdAt?: number;
  accountId?: string;
  sessionIdentifier?: string;
  sizeDetails?: DelegateSizeDetails;
  owner?: DelegateEntityOwner;
  ng?: boolean;
  status?: "ENABLED" | "WAITING_FOR_APPROVAL" | "DISABLED" | "DELETED";
  description?: string;
  ip?: string;
  hostName?: string;
  delegateGroupName?: string;
  delegateGroupId?: string;
  delegateName?: string;
  delegateProfileId?: string;
  lastHeartBeat?: number;
  version?: string;
  sequenceNum?: string;
  delegateType?: string;
  delegateRandomToken?: string;
  keepAlivePacket?: boolean;
  polllingModeEnabled?: boolean;
  proxy?: boolean;
  ceEnabled?: boolean;
  supportedTaskTypes?: string[];
  currentlyExecutingDelegateTasks?: string[];
  useCdn?: boolean;
  useJreVersion?: string;
  location?: string;
  includeScopes?: DelegateScope[];
  excludeScopes?: DelegateScope[];
  tags?: string[];
  profileResult?: string;
  profileError?: boolean;
  profileExecutedAt?: number;
  sampleDelegate?: boolean;
  capabilitiesCheckNextIteration?: number;
  validUntil?: string;
  keywords?: string[];
}

export interface DelegateEntityOwner {
  identifier?: string;
}

export interface DelegateScope {
  uuid: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  name?: string;
  taskTypes?: (
    | "SCRIPT"
    | "HTTP"
    | "SPLUNK"
    | "APPDYNAMICS"
    | "INSTANA"
    | "NEWRELIC"
    | "STACKDRIVER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "CLOUD_WATCH"
    | "JENKINS"
    | "COMMAND"
    | "BAMBOO"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "GCS"
    | "GCB"
    | "GCP"
    | "ACR"
    | "NEXUS"
    | "S3"
    | "AZURE_ARTIFACTS"
    | "AZURE_VMSS"
    | "AZURE_APP_SERVICE"
    | "AZURE_ARM"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "ARTIFACTORY"
    | "HOST_VALIDATION"
    | "KMS"
    | "GIT"
    | "CONTAINER"
    | "AMI"
    | "HELM"
    | "COLLABORATION_PROVIDER"
    | "PCF"
    | "SPOTINST"
    | "APM"
    | "LOG"
    | "CLOUD_FORMATION"
    | "TERRAFORM"
    | "TERRAGRUNT"
    | "AWS"
    | "LDAP"
    | "K8S"
    | "SMB"
    | "SFTP"
    | "TRIGGER"
    | "JIRA"
    | "CONNECTIVITY_VALIDATION"
    | "BUILD_SOURCE"
    | "CUSTOM"
    | "SHELL_SCRIPT_PROVISION"
    | "SERVICENOW"
    | "HELM_REPO_CONFIG_VALIDATION"
    | "HELM_VALUES_FETCH_TASK"
    | "GUARD_24x7"
    | "CI"
    | "SLACK"
    | "ARTIFACT_COLLECT_NG"
    | "K8S_NG"
    | "CAPABILITY_VALIDATION"
    | "JIRA_NG"
    | "CVNG"
    | "NOTIFICATION"
    | "HTTP_NG"
    | "SHELL_SCRIPT_NG"
    | "GIT_NG"
    | "BATCH_CAPABILITY_CHECK"
    | "CUSTOM_MANIFEST_VALUES_FETCH_TASK"
    | "CUSTOM_MANIFEST_FETCH_TASK"
    | "TERRAFORM_NG"
    | "CE"
  )[];
  environmentTypes?: ("PROD" | "NON_PROD" | "ALL")[];
  applications?: string[];
  environments?: string[];
  serviceInfrastructures?: string[];
  services?: string[];
  infrastructureDefinitions?: string[];
  valid?: boolean;
}

export interface RestResponseDelegate {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Delegate;
  responseMessages?: ResponseMessage[];
}

export interface DelegateRegisterResponse {
  delegateId?: string;
  action?: "SELF_DESTRUCT" | "MIGRATE";
  migrateUrl?: string;
  sequenceNum?: string;
  delegateRandomToken?: string;
}

export interface RestResponseDelegateRegisterResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateRegisterResponse;
  responseMessages?: ResponseMessage[];
}

export interface DelegateParams {
  delegateId?: string;
  accountId?: string;
  sessionIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  delegateSize?: string;
  ip?: string;
  hostName?: string;
  delegateName?: string;
  delegateGroupName?: string;
  delegateGroupId?: string;
  delegateProfileId?: string;
  description?: string;
  version?: string;
  delegateType?: string;
  delegateRandomToken?: string;
  sequenceNum?: string;
  location?: string;
  lastHeartBeat?: number;
  sampleDelegate?: boolean;
  keepAlivePacket?: boolean;
  pollingModeEnabled?: boolean;
  proxy?: boolean;
  ceEnabled?: boolean;
  currentlyExecutingDelegateTasks?: string[];
}

export interface DelegateProfileParams {
  name?: string;
  profileId?: string;
  profileLastUpdatedAt?: number;
  scriptContent?: string;
}

export interface RestResponseDelegateProfileParams {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateProfileParams;
  responseMessages?: ResponseMessage[];
}

export interface DelegateConnectionHeartbeat {
  delegateConnectionId?: string;
  version?: string;
  location?: string;
}

export interface DelegateTaskPackage {
  accountId?: string;
  delegateTaskId?: string;
  delegateId?: string;
  logStreamingToken?: string;
  delegateCallbackToken?: string;
  data?: TaskData;
  encryptionConfigs?: {
    [key: string]: EncryptionConfig;
  };
  secretDetails?: {
    [key: string]: SecretDetail;
  };
  secrets?: string[];
  executionCapabilities?: ExecutionCapability[];
  logStreamingAbstractions?: {
    [key: string]: string;
  };
}

export interface Duration {
  seconds?: number;
  negative?: boolean;
  nano?: number;
  zero?: boolean;
  units?: TemporalUnit[];
}

export interface EncryptedRecord {
  uuid?: string;
  name?: string;
  parameters?: EncryptedDataParams[];
  path?: string;
  kmsId?: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  encryptionKey?: string;
  encryptedValue?: string[];
  backupEncryptedValue?: string[];
  backupEncryptionKey?: string;
  backupKmsId?: string;
  backupEncryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  base64Encoded?: boolean;
  additionalMetadata?: AdditionalMetadata;
}

export interface ExecutionCapability {
  capabilityType?:
    | "SOCKET"
    | "ALWAYS_TRUE"
    | "PROCESS_EXECUTOR"
    | "AWS_REGION"
    | "SYSTEM_ENV"
    | "HTTP"
    | "HELM_INSTALL"
    | "CHART_MUSEUM"
    | "ALWAYS_FALSE"
    | "SMTP"
    | "WINRM_HOST_CONNECTION"
    | "SSH_HOST_CONNECTION"
    | "SFTP"
    | "PCF_AUTO_SCALAR"
    | "PCF_CONNECTIVITY"
    | "PCF_INSTALL"
    | "POWERSHELL"
    | "HELM_COMMAND"
    | "CLUSTER_MASTER_URL"
    | "SHELL_CONNECTION"
    | "GIT_CONNECTION"
    | "KUSTOMIZE"
    | "SMB"
    | "SELECTORS"
    | "GIT_CONNECTION_NG"
    | "GIT_INSTALLATION"
    | "LITE_ENGINE";
  maxValidityPeriod?: Duration;
  periodUntilNextValidation?: Duration;
}

export interface SecretDetail {
  configUuid?: string;
  encryptedRecord?: EncryptedRecord;
}

export interface TaskData {
  parked?: boolean;
  async?: boolean;
  taskType: string;
  parameters?: { [key: string]: any }[];
  timeout?: number;
  expressionFunctorToken?: number;
  expressions?: {
    [key: string]: string;
  };
}

export interface TemporalUnit {
  duration?: Duration;
  dateBased?: boolean;
  durationEstimated?: boolean;
  timeBased?: boolean;
}

export interface DelegateConnectionResultDetail {
  uuid?: string;
  lastUpdatedAt?: number;
  accountId?: string;
  delegateId?: string;
  criteria?: string;
  validated?: boolean;
  duration?: number;
  validUntil?: string;
}

export interface DelegateScripts {
  version?: string;
  doUpgrade?: boolean;
  stopScript?: string;
  startScript?: string;
  delegateScript?: string;
  setupProxyScript?: string;
}

export interface RestResponseDelegateScripts {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateScripts;
  responseMessages?: ResponseMessage[];
}

export type DelegateTaskAbortEvent = DelegateTaskEvent & {};

export interface DelegateTaskEvent {
  accountId?: string;
  delegateTaskId?: string;
  sync?: boolean;
}

export interface DelegateTaskEventsResponse {
  delegateTaskEvents?: DelegateTaskEvent[];
  processTaskEventsAsync?: boolean;
}

export interface DelegateHeartbeatResponse {
  delegateId?: string;
  status?: string;
  useCdn?: boolean;
  jreVersion?: string;
  delegateRandomToken?: string;
  sequenceNumber?: string;
}

export interface RestResponseDelegateHeartbeatResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateHeartbeatResponse;
  responseMessages?: ResponseMessage[];
}

export interface ConnectorHeartbeatDelegateResponse {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier?: string;
  name?: string;
  connectorValidationResult?: ConnectorValidationResult;
}

export interface ConnectorValidationResult {
  status?: "SUCCESS" | "FAILURE" | "PARTIAL" | "UNKNOWN";
  errors?: ErrorDetail[];
  errorSummary?: string;
  testedAt?: number;
  delegateId?: string;
}

export interface ErrorDetail {
  reason?: string;
  message?: string;
  code?: number;
}

export interface RestResponseDelegateConfiguration {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateConfiguration;
  responseMessages?: ResponseMessage[];
}

export interface StreamingOutput {
  [key: string]: any;
}

export interface DelegateFile {
  fileUuid?: string;
  fileName?: string;
  fileLength?: number;
  accountId?: string;
  mimeType?: string;
  checksumType?: "MD5" | "SHA1" | "SHA256";
  checksum?: string;
  relativePath?: string;
  metadata?: {
    [key: string]: { [key: string]: any };
  };
  fileId?: string;
  bucket?:
    | "LOB"
    | "ARTIFACTS"
    | "AUDITS"
    | "CONFIGS"
    | "CUSTOM_MANIFEST"
    | "LOGS"
    | "PLATFORMS"
    | "TERRAFORM_STATE"
    | "PROFILE_RESULTS"
    | "TERRAFORM_PLAN"
    | "EXPORT_EXECUTIONS";
  entityId?: string;
  localFilePath?: string;
  delegateId?: string;
  taskId?: string;
  appId?: string;
  length?: number;
}

export interface RestResponseDelegateFile {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateFile;
  responseMessages?: ResponseMessage[];
}

export interface DelegateProfile {
  uuid: string;
  accountId?: string;
  name?: string;
  description?: string;
  primary?: boolean;
  approvalRequired?: boolean;
  startupScript?: string;
  scopingRules?: DelegateProfileScopingRule[];
  selectors?: string[];
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  identifier?: string;
  owner?: DelegateEntityOwner;
  ng?: boolean;
}

export interface DelegateProfileScopingRule {
  description?: string;
  scopingEntities?: {
    [key: string]: string[];
  };
}

export interface RestResponseDelegateProfile {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateProfile;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseDelegateProfile {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateProfile[];
  responseMessages?: ResponseMessage[];
}

export interface DelegateProfileDetails {
  uuid?: string;
  accountId?: string;
  name?: string;
  description?: string;
  primary?: boolean;
  approvalRequired?: boolean;
  startupScript?: string;
  scopingRules?: ScopingRuleDetails[];
  selectors?: string[];
  createdBy?: EmbeddedUserDetails;
  lastUpdatedBy?: EmbeddedUserDetails;
  identifier?: string;
}

export interface EmbeddedUserDetails {
  uuid?: string;
  name?: string;
  email?: string;
}

export interface RestResponseDelegateProfileDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateProfileDetails;
  responseMessages?: ResponseMessage[];
}

export interface ScopingRuleDetails {
  description?: string;
  applicationId?: string;
  serviceIds?: string[];
  environmentTypeId?: string;
  environmentIds?: string[];
}

export interface ScopingRules {
  scopingRuleDetails?: ScopingRuleDetails[];
}

export interface RestResponsePageResponseDelegateProfileDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateProfileDetails[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseDelegateScope {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateScope;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseDelegateScope {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateScope[];
  responseMessages?: ResponseMessage[];
}

export interface DelegateSelectionLogParams {
  delegateId?: string;
  delegateType?: string;
  delegateName?: string;
  delegateHostName?: string;
  delegateProfileName?: string;
  conclusion?: string;
  message?: string;
  eventTimestamp?: number;
  profileScopingRulesDetails?: ProfileScopingRulesDetails;
}

export interface ProfileScopingRulesDetails {
  profileId?: string;
  profileName?: string;
  scopingRulesDescriptions?: string[];
}

export interface RestResponseListDelegateSelectionLogParams {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateSelectionLogParams[];
  responseMessages?: ResponseMessage[];
}

export interface DelegateSelectionLogResponse {
  delegateSelectionLogs?: DelegateSelectionLogParams[];
  taskSetupAbstractions?: {
    [key: string]: string;
  };
}

export interface RestResponseDelegateSelectionLogResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateSelectionLogResponse;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseDelegate {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Delegate[];
  responseMessages?: ResponseMessage[];
}

export interface DelegateScalingGroup {
  groupName?: string;
  delegates?: DelegateInner[];
}

export interface DelegateStatus {
  publishedVersions?: string[];
  delegates?: DelegateInner[];
  scalingGroups?: DelegateScalingGroup[];
}

export interface RestResponseDelegateStatus {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateStatus;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseDouble {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: number;
  responseMessages?: ResponseMessage[];
}

export interface CEDelegateStatus {
  uuid?: string;
  found?: boolean;
  ceEnabled?: boolean;
  lastHeartBeat?: number;
  delegateName?: string;
  delegateType?: string;
  status?: "ENABLED" | "WAITING_FOR_APPROVAL" | "DISABLED" | "DELETED";
  connections?: DelegateConnectionDetails[];
  metricsServerCheck?: MetricsServerCheck;
  permissionRuleList?: Rule[];
}

export interface MetricsServerCheck {
  isInstalled?: boolean;
  message?: string;
}

export interface RestResponseCEDelegateStatus {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CEDelegateStatus;
  responseMessages?: ResponseMessage[];
}

export interface Rule {
  apiGroups?: string;
  resources?: string;
  verbs?: string;
  message?: string;
}

export interface RestResponseListDelegateSizeDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateSizeDetails[];
  responseMessages?: ResponseMessage[];
}

export interface DelegateScopes {
  includeScopeIds?: string[];
  excludeScopeIds?: string[];
}

export interface DelegateTags {
  tags?: string[];
}

export interface DelegateSetupDetails {
  sessionIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  name: string;
  description?: string;
  size: "EXTRA_SMALL" | "LAPTOP" | "SMALL" | "MEDIUM" | "LARGE";
  delegateConfigurationId: string;
  identifier?: string;
  k8sConfigDetails?: K8sConfigDetails;
  tags?: string[];
}

export interface K8sConfigDetails {
  k8sPermissionType?: "CLUSTER_ADMIN" | "CLUSTER_VIEWER" | "NAMESPACE_ADMIN";
  namespace?: string;
}

export interface RestResponseDelegateSetupDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateSetupDetails;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseTaskSelectorMap {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TaskSelectorMap;
  responseMessages?: ResponseMessage[];
}

export interface TaskSelectorMap {
  uuid: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  taskGroup?:
    | "SCRIPT"
    | "HTTP"
    | "SPLUNK"
    | "APPDYNAMICS"
    | "INSTANA"
    | "NEWRELIC"
    | "STACKDRIVER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "CLOUD_WATCH"
    | "JENKINS"
    | "COMMAND"
    | "BAMBOO"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "GCS"
    | "GCB"
    | "GCP"
    | "ACR"
    | "NEXUS"
    | "S3"
    | "AZURE_ARTIFACTS"
    | "AZURE_VMSS"
    | "AZURE_APP_SERVICE"
    | "AZURE_ARM"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "ARTIFACTORY"
    | "HOST_VALIDATION"
    | "KMS"
    | "GIT"
    | "CONTAINER"
    | "AMI"
    | "HELM"
    | "COLLABORATION_PROVIDER"
    | "PCF"
    | "SPOTINST"
    | "APM"
    | "LOG"
    | "CLOUD_FORMATION"
    | "TERRAFORM"
    | "TERRAGRUNT"
    | "AWS"
    | "LDAP"
    | "K8S"
    | "SMB"
    | "SFTP"
    | "TRIGGER"
    | "JIRA"
    | "CONNECTIVITY_VALIDATION"
    | "BUILD_SOURCE"
    | "CUSTOM"
    | "SHELL_SCRIPT_PROVISION"
    | "SERVICENOW"
    | "HELM_REPO_CONFIG_VALIDATION"
    | "HELM_VALUES_FETCH_TASK"
    | "GUARD_24x7"
    | "CI"
    | "SLACK"
    | "ARTIFACT_COLLECT_NG"
    | "K8S_NG"
    | "CAPABILITY_VALIDATION"
    | "JIRA_NG"
    | "CVNG"
    | "NOTIFICATION"
    | "HTTP_NG"
    | "SHELL_SCRIPT_NG"
    | "GIT_NG"
    | "BATCH_CAPABILITY_CHECK"
    | "CUSTOM_MANIFEST_VALUES_FETCH_TASK"
    | "CUSTOM_MANIFEST_FETCH_TASK"
    | "TERRAFORM_NG"
    | "CE";
  selectors?: string[];
}

export interface RestResponseListTaskSelectorMap {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TaskSelectorMap[];
  responseMessages?: ResponseMessage[];
}

export interface DelegateHeartbeatDetails {
  numberOfRegisteredDelegates?: number;
  numberOfConnectedDelegates?: number;
}

export interface RestResponseDelegateHeartbeatDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateHeartbeatDetails;
  responseMessages?: ResponseMessage[];
}

export interface DelegateInitializationDetails {
  delegateId?: string;
  hostname?: string;
  initialized?: boolean;
  profileError?: boolean;
  profileExecutedAt?: number;
}

export interface RestResponseListDelegateInitializationDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateInitializationDetails[];
  responseMessages?: ResponseMessage[];
}

export interface DynaTraceSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  serviceMethods?: string;
  serviceEntityId?: string;
  serviceLevel?: boolean;
}

export interface DynaTraceApplication {
  entityId?: string;
  displayName?: string;
}

export interface RestResponseListDynaTraceApplication {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DynaTraceApplication[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseObject {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: { [key: string]: any };
  responseMessages?: ResponseMessage[];
}

export interface ElkIndexTemplate {
  name?: string;
  properties?: {
    [key: string]: { [key: string]: any };
  };
}

export interface RestResponseMapStringElkIndexTemplate {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: ElkIndexTemplate;
  };
  responseMessages?: ResponseMessage[];
}

export interface ElkSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  query?: string;
  indices?: string;
  messageField?: string;
  timeStampField?: string;
  timeStampFieldFormat?: string;
  queryType?: "TERM" | "MATCH" | "MATCH_PHRASE";
  hostNameField?: string;
  serviceLevel?: boolean;
}

export interface RestResponseEnvironment {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Environment;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseEnvironment {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Environment[];
  responseMessages?: ResponseMessage[];
}

export interface KubernetesPayload {
  advancedConfig?: string;
}

export interface CloneMetadata {
  targetAppId?: string;
  serviceMapping?: {
    [key: string]: string;
  };
  workflow?: Workflow;
  environment?: Environment;
}

export interface Workflow {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name: string;
  description?: string;
  workflowType?: "PIPELINE" | "ORCHESTRATION";
  envId?: string;
  defaultVersion?: number;
  templatized?: boolean;
  templateExpressions?: TemplateExpression[];
  keywords?: string[];
  notes?: string;
  orchestration?: OrchestrationWorkflow;
  orchestrationWorkflow?: OrchestrationWorkflow;
  services?: Service[];
  workflowExecutions?: WorkflowExecution[];
  serviceId?: string;
  infraMappingId?: string;
  infraDefinitionId?: string;
  creationFlags?: WorkflowCreationFlags;
  envTemplatized?: boolean;
  tagLinks?: HarnessTagLink[];
  templatizedServiceIds?: string[];
  linkedTemplateUuids?: string[];
  linkedArtifactStreamIds?: string[];
  deploymentTypes?: (
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM"
  )[];
  accountId?: string;
  sample?: boolean;
}

export interface WorkflowCreationFlags {
  ecsBGType?: string;
  awsTrafficShiftType?: string;
  ecsBgDnsType?: boolean;
  awsTrafficShiftAlbType?: boolean;
}

export interface RestResponseWorkflowVariablesMetadata {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowVariablesMetadata;
  responseMessages?: ResponseMessage[];
}

export interface WorkflowVariablesMetadata {
  workflowVariables?: Variable[];
  changed?: boolean;
  changedMessage?: string;
}

export interface RestResponseWorkflowExecution {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowExecution;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseWorkflowExecution {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowExecution[];
  responseMessages?: ResponseMessage[];
}

export interface PipelineStageGroupedInfo {
  name?: string;
  pipelineStageElementNames?: string[];
  parallelIndex?: number;
}

export interface RestResponseListPipelineStageGroupedInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PipelineStageGroupedInfo[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseRollbackConfirmation {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: RollbackConfirmation;
  responseMessages?: ResponseMessage[];
}

export interface RollbackConfirmation {
  validationMessage?: string;
  activeWorkflowExecution?: WorkflowExecution;
  workflowId?: string;
  valid?: boolean;
  artifacts?: Artifact[];
}

export interface ExecutionInterrupt {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  executionInterruptType:
    | "ABORT"
    | "ABORT_ALL"
    | "PAUSE"
    | "PAUSE_FOR_INPUTS"
    | "PAUSE_ALL"
    | "RESUME"
    | "RESUME_ALL"
    | "RETRY"
    | "IGNORE"
    | "WAITING_FOR_MANUAL_INTERVENTION"
    | "MARK_FAILED"
    | "MARK_SUCCESS"
    | "ROLLBACK"
    | "NEXT_STEP"
    | "END_EXECUTION"
    | "ROLLBACK_DONE"
    | "MARK_EXPIRED"
    | "CONTINUE_WITH_DEFAULTS"
    | "CONTINUE_PIPELINE_STAGE";
  seized?: boolean;
  envId?: string;
  executionUuid: string;
  stateExecutionInstanceId?: string;
  accountId?: string;
  properties?: {
    [key: string]: { [key: string]: any };
  };
}

export interface RestResponseExecutionInterrupt {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExecutionInterrupt;
  responseMessages?: ResponseMessage[];
}

export interface ApprovalDetails {
  approvalId?: string;
  approvedBy?: EmbeddedUser;
  comments?: string;
  action?: "APPROVE" | "REJECT";
  approvalFromSlack?: boolean;
  variables?: NameValuePair[];
}

export interface RequiredExecutionArgs {
  entityTypes?: (
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG"
  )[];
  defaultExecutionArgs?: ExecutionArgs;
}

export interface RestResponseRequiredExecutionArgs {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: RequiredExecutionArgs;
  responseMessages?: ResponseMessage[];
}

export interface DeploymentMetadata {
  artifactRequiredServices?: Service[];
  artifactRequiredServiceIds?: string[];
  envSummaries?: EnvSummary[];
  envIds?: string[];
  deploymentTypes?: (
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM"
  )[];
  artifactVariables?: ArtifactVariable[];
  manifestVariables?: ManifestVariable[];
  manifestRequiredServiceIds?: string[];
}

export interface RestResponseDeploymentMetadata {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DeploymentMetadata;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseGraphNode {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GraphNode;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListStateExecutionData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: StateExecutionData[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListStateExecutionInterrupt {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: StateExecutionInterrupt[];
  responseMessages?: ResponseMessage[];
}

export interface StateExecutionInterrupt {
  interrupt?: ExecutionInterrupt;
  tookAffectAt?: string;
}

export interface RestResponseStateInspection {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: StateInspection;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListStateExecutionElement {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: StateExecutionElement[];
  responseMessages?: ResponseMessage[];
}

export interface StateExecutionElement {
  executionContextElementId?: string;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  name?: string;
  progress?: number;
  runningSteps?: string[];
}

export interface RestResponseSetWorkflowExecutionBaseline {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowExecutionBaseline[];
  responseMessages?: ResponseMessage[];
}

export interface WorkflowExecutionBaseline {
  workflowId?: string;
  envId?: string;
  serviceId?: string;
  workflowExecutionId?: string;
  accountId?: string;
  pipelineExecutionId?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface RestResponseWorkflowExecutionBaseline {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowExecutionBaseline;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListArtifact {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Artifact[];
  responseMessages?: ResponseMessage[];
}

export interface ApprovalAuthorization {
  authorized?: boolean;
}

export interface RestResponseApprovalAuthorization {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ApprovalAuthorization;
  responseMessages?: ResponseMessage[];
}

export interface ConcurrentExecutionResponse {
  state?: "BLOCKED" | "ACTIVE" | "FINISHED" | "REJECTED";
  unitType?: "INFRA" | "CUSTOM" | "NONE";
  executions?: WorkflowExecution[];
  infrastructureDetails?: {
    [key: string]: { [key: string]: any };
  };
}

export interface RestResponseConcurrentExecutionResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ConcurrentExecutionResponse;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapStringGraphGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: GraphGroup;
  };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseWorkflowExecutionInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowExecutionInfo;
  responseMessages?: ResponseMessage[];
}

export interface ExpAnalysisInfo {
  stateExecutionId?: string;
  appId?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  expName?: string;
  envId?: string;
  workflowExecutionId?: string;
  createdAt?: number;
  mismatch?: boolean;
}

export interface RestResponseListExpAnalysisInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExpAnalysisInfo[];
  responseMessages?: ResponseMessage[];
}

export interface ExperimentalMessageComparisonResult {
  stateExecutionId?: string;
  cvConfigId?: string;
  logCollectionMinute?: number;
  numVotes?: number;
  message1?: string;
  message2?: string;
  prediction?: string;
  cluster_type?: string;
  similarity?: number;
  modelVersion?: string;
  userVotes?: {
    [key: string]: string;
  };
  uuid?: string;
  createdAt?: number;
}

export interface RestResponseListExperimentalMessageComparisonResult {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExperimentalMessageComparisonResult[];
  responseMessages?: ResponseMessage[];
}

export interface ExperimentalMetricAnalysis {
  metricName?: string;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  experimentalRiskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  metricValues?: ExperimentalMetricAnalysisValue[];
  displayName?: string;
  fullMetricName?: string;
  tag?: string;
  mismatch?: boolean;
}

export interface ExperimentalMetricAnalysisValue {
  name?: string;
  type?: string;
  alertType?: string;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  experimentalRiskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  testValue?: number;
  controlValue?: number;
  hostAnalysisValues?: ExperimentalMetricHostAnalysisValue[];
  mismatch?: boolean;
}

export interface ExperimentalMetricHostAnalysisValue {
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  experimentalRiskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  testHostName?: string;
  controlHostName?: string;
  testValues?: number[];
  controlValues?: number[];
  anomalies?: number[];
  testStartIndex?: number;
  mismatch?: boolean;
}

export interface ExperimentalMetricRecord {
  workflowExecutionId?: string;
  stateExecutionId?: string;
  cvConfigId?: string;
  analysisMinute?: number;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  experimentalRiskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  mlAnalysisType?: "COMPARATIVE" | "PREDICTIVE" | "TIMESERIES_24x7";
  metricAnalysis?: ExperimentalMetricAnalysis[];
  baseLineExecutionId?: string;
  mismatch?: boolean;
  experimentStatus?: "UNDETERMINED" | "SUCCESS" | "FAILURE";
}

export interface RestResponseExperimentalMetricRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExperimentalMetricRecord;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseExpAnalysisInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExpAnalysisInfo[];
  responseMessages?: ResponseMessage[];
}

export interface ExperimentPerformance {
  [key: string]: any;
}

export interface RestResponseExperimentPerformance {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExperimentPerformance;
  responseMessages?: ResponseMessage[];
}

export interface ExportExecutionsRequestSummary {
  requestId?: string;
  status?: "QUEUED" | "READY" | "FAILED" | "EXPIRED";
  totalExecutions?: number;
  triggeredAt?: string;
  statusLink?: string;
  downloadLink?: string;
  expiresAt?: string;
  errorMessage?: string;
}

export interface RestResponseExportExecutionsRequestSummary {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExportExecutionsRequestSummary;
  responseMessages?: ResponseMessage[];
}

export interface ExportExecutionsUserParams {
  outputFormat?: "JSON";
  notifyOnlyTriggeringUser?: boolean;
  userGroupIds?: string[];
  createdByType?: "USER" | "API_KEY" | "TRIGGER";
}

export interface ExportExecutionsRequestLimitChecks {
  queuedRequests?: LimitCheck;
  executionCount?: LimitCheck;
}

export interface LimitCheck {
  limit?: number;
  value?: number;
}

export interface RestResponseExportExecutionsRequestLimitChecks {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ExportExecutionsRequestLimitChecks;
  responseMessages?: ResponseMessage[];
}

export interface ExecutionStatusResponse {
  status?: string;
}

export interface FeatureFlag {
  uuid?: string;
  name?: string;
  enabled?: boolean;
  lastUpdatedAt?: number;
}

export interface RestResponseFeatureFlag {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: FeatureFlag;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListFeatureFlag {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: FeatureFlag[];
  responseMessages?: ResponseMessage[];
}

export interface FeatureUsageComplianceReport {
  featureName?: string;
  properties?: {
    [key: string]: { [key: string]: any };
  };
}

export interface FeaturesUsageComplianceReport {
  accountId?: string;
  targetAccountType?: string;
  featureUsageComplianceReports?: FeatureUsageComplianceReport[];
}

export interface RestResponseFeaturesUsageComplianceReport {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: FeaturesUsageComplianceReport;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseFeatureRestrictions {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: {
      [key: string]: {
        [key: string]: { [key: string]: any };
      };
    };
  };
  responseMessages?: ResponseMessage[];
}

export interface GcpBillingAccount {
  uuid?: string;
  accountId?: string;
  organizationSettingId?: string;
  gcpBillingAccountId?: string;
  gcpBillingAccountName?: string;
  exportEnabled?: boolean;
  bqProjectId?: string;
  bqDatasetId?: string;
  bqDataSetRegion?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface RestResponseGcpBillingAccount {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GcpBillingAccount;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListGcpBillingAccount {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GcpBillingAccount[];
  responseMessages?: ResponseMessage[];
}

export interface GcpOrganization {
  uuid?: string;
  accountId?: string;
  organizationId?: string;
  organizationName?: string;
  serviceAccountEmail?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface RestResponseGcpOrganization {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GcpOrganization;
  responseMessages?: ResponseMessage[];
}

export interface GitRepositoryInfo {
  url?: string;
  displayUrl?: string;
  provider?: "GITHUB" | "BITBUCKET" | "GITLAB" | "UNKNOWN";
}

export interface GitSyncError {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  yamlFilePath?: string;
  changeType?: string;
  failureReason?: string;
  yamlContent?: string;
  gitCommitId?: string;
  nextIteration?: number;
  fullSyncPath?: boolean;
  lastAttemptedYaml?: string;
  status?: "ACTIVE" | "DISCARDED" | "EXPIRED" | "RESOLVED" | "OVERRIDDEN";
  gitConnectorId?: string;
  repositoryName?: string;
  gitConnectorName?: string;
  repositoryInfo?: GitRepositoryInfo;
  branchName?: string;
  yamlGitConfigId?: string;
  commitTime?: number;
  additionalErrorDetails?: GitSyncErrorDetails;
  gitSyncDirection?: string;
  userDoesNotHavePermForFile?: boolean;
}

export interface GitSyncErrorDetails {
  [key: string]: any;
}

export interface RestResponsePageResponseGitSyncError {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitSyncError[];
  responseMessages?: ResponseMessage[];
}

export interface GitProcessingError {
  accountId?: string;
  message?: string;
  createdAt?: number;
  gitConnectorId?: string;
  branchName?: string;
  repositoryName?: string;
  connectorName?: string;
  repositoryInfo?: GitRepositoryInfo;
}

export interface RestResponsePageResponseGitProcessingError {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitProcessingError[];
  responseMessages?: ResponseMessage[];
}

export interface GitFileActivity {
  uuid?: string;
  accountId?: string;
  filePath?: string;
  fileContent?: string;
  commitId?: string;
  processingCommitId?: string;
  changeType?: "ADD" | "RENAME" | "MODIFY" | "DELETE" | "NONE";
  errorMessage?: string;
  status?:
    | "SUCCESS"
    | "FAILED"
    | "DISCARDED"
    | "EXPIRED"
    | "SKIPPED"
    | "QUEUED";
  triggeredBy?: "USER" | "GIT" | "FULL_SYNC";
  changeFromAnotherCommit?: boolean;
  commitMessage?: string;
  processingCommitMessage?: string;
  appId?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  gitConnectorId?: string;
  repositoryName?: string;
  branchName?: string;
  connectorName?: string;
  repositoryInfo?: GitRepositoryInfo;
  userDoesNotHavePermForFile?: boolean;
}

export interface RestResponsePageResponseGitFileActivity {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitFileActivity[];
  responseMessages?: ResponseMessage[];
}

export interface ChangeSetDTO {
  gitDetail?: GitDetail;
  status?: "QUEUED" | "RUNNING" | "FAILED" | "COMPLETED" | "SKIPPED";
  changeSetId?: string;
  gitToHarness?: boolean;
  changesetInformation?: ChangesetInformation;
}

export interface ChangesetInformation {
  [key: string]: any;
}

export interface GitDetail {
  entityName?: string;
  entityType?:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  repositoryUrl?: string;
  branchName?: string;
  yamlGitConfigId?: string;
  gitConnectorId?: string;
  appId?: string;
  gitCommitId?: string;
  connectorName?: string;
  repositoryInfo?: GitRepositoryInfo;
}

export interface RestResponseListChangeSetDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ChangeSetDTO[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseInteger {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: number;
  responseMessages?: ResponseMessage[];
}

export interface GitToHarnessErrorCommitStats {
  gitCommitId?: string;
  failedCount?: number;
  commitTime?: number;
  gitConnectorId?: string;
  branchName?: string;
  repositoryName?: string;
  gitConnectorName?: string;
  commitMessage?: string;
  errorsForSummaryView?: GitSyncError[];
  repositoryInfo?: GitRepositoryInfo;
}

export interface RestResponsePageResponseGitToHarnessErrorCommitStats {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitToHarnessErrorCommitStats[];
  responseMessages?: ResponseMessage[];
}

export interface GitFileActivitySummary {
  uuid: string;
  accountId?: string;
  commitId?: string;
  branchName?: string;
  repositoryName?: string;
  gitConnectorId?: string;
  appId?: string;
  createdAt?: number;
  commitMessage?: string;
  lastUpdatedAt?: number;
  gitToHarness?: boolean;
  status?:
    | "QUEUED"
    | "RUNNING"
    | "COMPLETED"
    | "FAILED"
    | "COMPLETED_WITH_ERRORS"
    | "SKIPPED";
  fileProcessingSummary?: GitFileProcessingSummary;
  connectorName?: string;
  repositoryInfo?: GitRepositoryInfo;
}

export interface GitFileProcessingSummary {
  failureCount?: number;
  successCount?: number;
  totalCount?: number;
  skippedCount?: number;
  queuedCount?: number;
}

export interface RestResponsePageResponseGitFileActivitySummary {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitFileActivitySummary[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListGitDetail {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitDetail[];
  responseMessages?: ResponseMessage[];
}

export interface AccessTokenBean {
  projectId?: string;
  tokenValue?: string;
  expirationTimeMillis?: number;
}

export interface RestResponseAccessTokenBean {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccessTokenBean;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseSampleAppStatus {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SampleAppStatus;
  responseMessages?: ResponseMessage[];
}

export interface SampleAppEntityStatus {
  entityName?: string;
  entityType?: string;
  health?: "GOOD" | "BAD";
}

export interface SampleAppStatus {
  deploymentType?: string;
  health?: "GOOD" | "BAD";
  statusList?: SampleAppEntityStatus[];
}

export interface HarnessTag {
  uuid?: string;
  accountId?: string;
  key?: string;
  tagType?: "USER" | "HARNESS";
  allowedValues?: string[];
  inUseValues?: string[];
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface RestResponseHarnessTag {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HarnessTag;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseHarnessTag {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HarnessTag[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseHarnessTagLink {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HarnessTagLink[];
  responseMessages?: ResponseMessage[];
}

export interface HarnessUserGroup {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  description?: string;
  memberIds?: string[];
  accountIds?: string[];
  groupType?: "DEFAULT" | "RESTRICTED";
}

export interface RestResponseHarnessUserGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HarnessUserGroup;
  responseMessages?: ResponseMessage[];
}

export interface HarnessUserGroupDTO {
  name?: string;
  description?: string;
  emailIds?: string[];
  accountIds?: string[];
}

export interface HarnessSupportUserDTO {
  name?: string;
  id?: string;
  emailId?: string;
}

export interface RestResponseListHarnessSupportUserDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HarnessSupportUserDTO[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListHarnessUserGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HarnessUserGroup[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseHost {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Host;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseHost {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Host[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseUser {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: User;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListAccount {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Account[];
  responseMessages?: ResponseMessage[];
}

export interface AccountSettingsResponse {
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
  allowedDomains?: string[];
  oauthProviderTypes?: (
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN"
  )[];
}

export interface RestResponseAccountSettingsResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccountSettingsResponse;
  responseMessages?: ResponseMessage[];
}

export interface OauthUserInfo {
  email?: string;
  name?: string;
  login?: string;
  utmInfo?: UtmInfo;
  freemiumProducts?: ("CD" | "CE" | "CI")[];
  freemiumAssistedOption?: boolean;
}

export interface RestResponseListUser {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: User[];
  responseMessages?: ResponseMessage[];
}

export interface LogoutResponse {
  logoutUrl?: string;
}

export interface RestResponseLogoutResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LogoutResponse;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseInfrastructureDefinition {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfrastructureDefinition;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseInfrastructureDefinition {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfrastructureDefinition[];
  responseMessages?: ResponseMessage[];
}

export interface AwsElbListener {
  listenerArn?: string;
  loadBalancerArn?: string;
  port?: number;
  protocol?: string;
  rules?: AwsElbListenerRuleData[];
}

export interface AwsElbListenerRuleData {
  ruleArn?: string;
  rulePriority?: string;
  ruleTargetGroupArn?: string;
  default?: boolean;
}

export interface RestResponseListAwsElbListener {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsElbListener[];
  responseMessages?: ResponseMessage[];
}

export interface ListInfraDefinitionParams {
  deploymentTypeFromMetaData?: string[];
  serviceIds?: string[];
}

export interface InfraDefinitionDetail {
  infrastructureDefinition?: InfrastructureDefinition;
  derivedInfraMappingDetailList?: InfraMappingDetail[];
  countDerivedInfraMappings?: number;
}

export interface InfraMappingDetail {
  infrastructureMapping?: InfrastructureMapping;
  workflowExecutionList?: WorkflowExecution[];
}

export interface RestResponseInfraDefinitionDetail {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfraDefinitionDetail;
  responseMessages?: ResponseMessage[];
}

export interface AwsLoadBalancerDetails {
  arn?: string;
  name?: string;
  type?: string;
  scheme?: string;
  vpcId?: string;
  ipAddressType?: string;
  dnsname?: string;
}

export interface RestResponseListAwsLoadBalancerDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsLoadBalancerDetails[];
  responseMessages?: ResponseMessage[];
}

export interface ElastiGroup {
  id?: string;
  name?: string;
  capacity?: ElastiGroupCapacity;
}

export interface ElastiGroupCapacity {
  minimum?: number;
  maximum?: number;
  target?: number;
}

export interface RestResponseListElastiGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ElastiGroup[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseSpotinstElastigroupRunningCountData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SpotinstElastigroupRunningCountData;
  responseMessages?: ResponseMessage[];
}

export interface SpotinstElastigroupRunningCountData {
  elastigroupMin?: number;
  elastigroupMax?: number;
  elastigroupTarget?: number;
  elastigroupName?: string;
}

export interface RestResponseVirtualMachineScaleSetData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: VirtualMachineScaleSetData;
  responseMessages?: ResponseMessage[];
}

export interface VirtualMachineScaleSetData {
  id?: string;
  name?: string;
  virtualMachineAdministratorUsername?: string;
}

export interface DeploymentSlotData {
  type?: string;
  name?: string;
}

export interface RestResponseListDeploymentSlotData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DeploymentSlotData[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseInfraDefinitionDetail {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfraDefinitionDetail[];
  responseMessages?: ResponseMessage[];
}

export interface AwsAsgGetRunningCountData {
  asgMin?: number;
  asgMax?: number;
  asgDesired?: number;
  asgName?: string;
}

export interface RestResponseAwsAsgGetRunningCountData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsAsgGetRunningCountData;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapDeploymentTypeListSettingVariableTypes {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: (
      | "HOST_CONNECTION_ATTRIBUTES"
      | "BASTION_HOST_CONNECTION_ATTRIBUTES"
      | "SMTP"
      | "SFTP"
      | "JENKINS"
      | "BAMBOO"
      | "STRING"
      | "SPLUNK"
      | "ELK"
      | "LOGZ"
      | "SUMO"
      | "DATA_DOG"
      | "APM_VERIFICATION"
      | "BUG_SNAG"
      | "LOG_VERIFICATION"
      | "APP_DYNAMICS"
      | "NEW_RELIC"
      | "DYNA_TRACE"
      | "INSTANA"
      | "DATA_DOG_LOG"
      | "CLOUD_WATCH"
      | "SCALYR"
      | "ELB"
      | "SLACK"
      | "AWS"
      | "GCS"
      | "GCP"
      | "AZURE"
      | "PCF"
      | "DIRECT"
      | "KUBERNETES_CLUSTER"
      | "DOCKER"
      | "ECR"
      | "GCR"
      | "ACR"
      | "PHYSICAL_DATA_CENTER"
      | "KUBERNETES"
      | "NEXUS"
      | "ARTIFACTORY"
      | "SMB"
      | "AMAZON_S3"
      | "GIT"
      | "SSH_SESSION_CONFIG"
      | "SERVICE_VARIABLE"
      | "CONFIG_FILE"
      | "KMS"
      | "GCP_KMS"
      | "JIRA"
      | "SERVICENOW"
      | "SECRET_TEXT"
      | "YAML_GIT_SYNC"
      | "VAULT"
      | "VAULT_SSH"
      | "AWS_SECRETS_MANAGER"
      | "CYBERARK"
      | "WINRM_CONNECTION_ATTRIBUTES"
      | "WINRM_SESSION_CONFIG"
      | "PROMETHEUS"
      | "INFRASTRUCTURE_MAPPING"
      | "HTTP_HELM_REPO"
      | "AMAZON_S3_HELM_REPO"
      | "GCS_HELM_REPO"
      | "SPOT_INST"
      | "AZURE_ARTIFACTS_PAT"
      | "CUSTOM"
      | "CE_AWS"
      | "CE_GCP"
      | "CE_AZURE"
      | "AZURE_VAULT"
      | "KUBERNETES_CLUSTER_NG"
      | "GIT_NG"
      | "GCP_SECRETS_MANAGER"
      | "TRIGGER"
    )[];
  };
  responseMessages?: ResponseMessage[];
}

export interface AwsVPC {
  id?: string;
  name?: string;
}

export interface RestResponseListAwsVPC {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsVPC[];
  responseMessages?: ResponseMessage[];
}

export interface AwsSecurityGroup {
  id?: string;
  name?: string;
}

export interface RestResponseListAwsSecurityGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsSecurityGroup[];
  responseMessages?: ResponseMessage[];
}

export interface AwsRoute53HostedZoneData {
  hostedZoneId?: string;
  hostedZoneName?: string;
}

export interface RestResponseListAwsRoute53HostedZoneData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsRoute53HostedZoneData[];
  responseMessages?: ResponseMessage[];
}

export interface AwsSubnet {
  id?: string;
  name?: string;
}

export interface RestResponseListAwsSubnet {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsSubnet[];
  responseMessages?: ResponseMessage[];
}

export interface HostValidationResponse {
  hostName?: string;
  status?: string;
  errorCode?: string;
  errorDescription?: string;
}

export interface RestResponseListHostValidationResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HostValidationResponse[];
  responseMessages?: ResponseMessage[];
}

export interface HostValidationRequest {
  appId?: string;
  envId?: string;
  computeProviderSettingId?: string;
  deploymentType?: string;
  hostConnectionAttrs?: string;
  executionCredential?: ExecutionCredential;
  hostNames?: string[];
}

export interface RestResponseInfrastructureMapping {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfrastructureMapping;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseInfrastructureMapping {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfrastructureMapping[];
  responseMessages?: ResponseMessage[];
}

export type ARMInfrastructureProvisioner = InfrastructureProvisioner & {
  resourceType?: "ARM" | "BLUEPRINT";
  sourceType?: "TEMPLATE_BODY" | "GIT";
  scopeType?: "RESOURCE_GROUP" | "SUBSCRIPTION" | "MANAGEMENT_GROUP" | "TENANT";
  templateBody?: string;
  gitFileConfig?: GitFileConfig;
};

export interface BlueprintProperty {
  name?: string;
  value: string;
  valueType?: string;
  fields?: NameValuePair[];
}

export type CloudFormationInfrastructureProvisioner = InfrastructureProvisioner & {
  sourceType?: string;
  templateBody?: string;
  templateFilePath?: string;
  gitFileConfig?: GitFileConfig;
};

export interface InfrastructureMappingBlueprint {
  serviceId?: string;
  deploymentType:
    | "SSH"
    | "AWS_CODEDEPLOY"
    | "ECS"
    | "SPOTINST"
    | "KUBERNETES"
    | "HELM"
    | "AWS_LAMBDA"
    | "AMI"
    | "WINRM"
    | "PCF"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "CUSTOM";
  cloudProviderType: "AWS" | "GCP" | "PHYSICAL_DATA_CENTER";
  nodeFilteringType?:
    | "AWS_INSTANCE_FILTER"
    | "AWS_AUTOSCALING_GROUP"
    | "AWS_ECS_EC2"
    | "AWS_ECS_FARGATE"
    | "AWS_ASG_AMI";
  properties: BlueprintProperty[];
}

export interface InfrastructureProvisioner {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  description?: string;
  infrastructureProvisionerType?: string;
  variables?: NameValuePair[];
  mappingBlueprints?: InfrastructureMappingBlueprint[];
  accountId?: string;
  tagLinks?: HarnessTagLink[];
}

export interface RestResponseInfrastructureProvisioner {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfrastructureProvisioner;
  responseMessages?: ResponseMessage[];
}

export type ShellScriptInfrastructureProvisioner = InfrastructureProvisioner & {
  scriptBody?: string;
};

export type TerraformInfrastructureProvisioner = InfrastructureProvisioner & {
  sourceRepoSettingId?: string;
  sourceRepoBranch?: string;
  commitId?: string;
  repoName?: string;
  path: string;
  normalizedPath?: string;
  backendConfigs?: NameValuePair[];
  environmentVariables?: NameValuePair[];
  templatized?: boolean;
  workspaces?: string[];
  kmsId?: string;
  skipRefreshBeforeApplyingPlan?: boolean;
};

export type TerragruntInfrastructureProvisioner = InfrastructureProvisioner & {
  sourceRepoSettingId?: string;
  sourceRepoBranch?: string;
  commitId?: string;
  repoName?: string;
  path: string;
  normalizedPath?: string;
  templatized?: boolean;
  secretManagerId?: string;
  skipRefreshBeforeApplyingPlan?: boolean;
};

export interface RestResponsePageResponseInfrastructureProvisioner {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfrastructureProvisioner[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapPairDeploymentTypeCloudProviderTypeMapStringString {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: {
      [key: string]: string;
    };
  };
  responseMessages?: ResponseMessage[];
}

export interface InfrastructureProvisionerDetails {
  uuid?: string;
  name?: string;
  description?: string;
  infrastructureProvisionerType?: string;
  repository?: string;
  services?: {
    [key: string]: string;
  };
  cloudFormationSourceType?: string;
  azureARMResourceType?: "ARM" | "BLUEPRINT";
  tagLinks?: HarnessTagLink[];
}

export interface RestResponsePageResponseInfrastructureProvisionerDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InfrastructureProvisionerDetails[];
  responseMessages?: ResponseMessage[];
}

export interface AwsCFTemplateParamsData {
  paramKey?: string;
  paramType?: string;
  defaultValue?: string;
}

export interface RestResponseListAwsCFTemplateParamsData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AwsCFTemplateParamsData[];
  responseMessages?: ResponseMessage[];
}

export interface InstanaApplicationParams {
  hostTagFilter?: string;
  tagFilters?: InstanaTagFilter[];
}

export interface InstanaInfraParams {
  metrics?: string[];
  query?: string;
}

export interface InstanaSetupTestNodeData {
  infraParams?: InstanaInfraParams;
  applicationParams?: InstanaApplicationParams;
  tagFilters?: InstanaTagFilter[];
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  serviceLevel?: boolean;
}

export interface InstanaTagFilter {
  name?: string;
  value?: string;
  operator?:
    | "EQUALS"
    | "CONTAINS"
    | "NOT_EQUAL"
    | "NOT_CONTAIN"
    | "NOT_EMPTY"
    | "IS_EMPTY";
}

export interface LogDataRecord {
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  workflowId?: string;
  workflowExecutionId?: string;
  serviceId?: string;
  stateExecutionId?: string;
  cvConfigId?: string;
  query?: string;
  clusterLabel?: string;
  host?: string;
  timeStamp?: number;
  supervisedLabel?: string;
  timesLabeled?: number;
  count?: number;
  logMessage?: string;
  logMD5Hash?: string;
  clusterLevel?: "L0" | "L1" | "L2" | "H0" | "H1" | "H2" | "HF";
  logCollectionMinute?: number;
  accountId?: string;
  validUntil?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface RestResponseListLogDataRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LogDataRecord[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListLogLabel {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: (
    | "NOISE"
    | "CLEAN"
    | "INFRA"
    | "THIRD_PARTY"
    | "IMPORTANT"
    | "BACKGROUND"
    | "JAVA_THROWABLE"
    | "ERROR"
    | "EXCEPTION"
    | "RUNTIME"
    | "HTTP"
    | "UPSTREAM"
    | "DOWNSTREAM"
    | "DATABASE"
    | "NETWORK"
    | "APM"
    | "LOGS"
    | "JVM"
    | "WARN"
  )[];
  responseMessages?: ResponseMessage[];
}

export interface CVFeedbackRecord {
  uuid?: string;
  accountId?: string;
  serviceId?: string;
  envId?: string;
  stateExecutionId?: string;
  cvConfigId?: string;
  clusterLabel?: number;
  clusterType?: "CONTROL" | "TEST" | "UNKNOWN" | "IGNORE";
  logMessage?: string;
  comment?: string;
  supervisedLabel?: string;
  priority?: "BASELINE" | "P5" | "P4" | "P3" | "P2" | "P1" | "P0";
  jiraLink?: string;
  analysisMinute?: number;
  actionTaken?: "ADD_TO_BASELINE" | "REMOVE_FROM_BASELINE" | "UPDATE_PRIORITY";
  feedbackNote?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  createdBy?: EmbeddedUser;
  lastUpdatedBy?: EmbeddedUser;
  duplicate?: boolean;
}

export interface RestResponseMapPairStringStringInteger {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: number;
  };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListCVFeedbackRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVFeedbackRecord[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapStringListCVFeedbackRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: CVFeedbackRecord[];
  };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapStringListString {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: string[];
  };
  responseMessages?: ResponseMessage[];
}

export interface LabeledLogRecord {
  label?: string;
  accountId?: string;
  feedbackIds?: string[];
  logDataRecordIds?: string[];
  createdAt?: number;
  lastUpdatedAt?: number;
  serviceId?: string;
  envId?: string;
  uuid?: string;
}

export interface LogMLFeedbackRecord {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  serviceId?: string;
  envId?: string;
  workflowId?: string;
  workflowExecutionId?: string;
  stateExecutionId?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  clusterLabel?: number;
  clusterType?: "CONTROL" | "TEST" | "UNKNOWN" | "IGNORE";
  logMLFeedbackType?:
    | "IGNORE_SERVICE"
    | "IGNORE_WORKFLOW"
    | "IGNORE_WORKFLOW_EXECUTION"
    | "IGNORE_ALWAYS"
    | "DISMISS"
    | "PRIORITIZE"
    | "THUMBS_UP"
    | "THUMBS_DOWN"
    | "UNDO_IGNORE";
  logMessage?: string;
  logMD5Hash?: string;
  cvConfigId?: string;
  comment?: string;
  supervisedLabel?: string;
  priority?: "BASELINE" | "P5" | "P4" | "P3" | "P2" | "P1" | "P0";
  jiraLink?: string;
  analysisMinute?: number;
  actionTaken?: "ADD_TO_BASELINE" | "REMOVE_FROM_BASELINE" | "UPDATE_PRIORITY";
  metadata?: { [key: string]: any };
}

export interface RestResponseListLogMLFeedbackRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LogMLFeedbackRecord[];
  responseMessages?: ResponseMessage[];
}

export interface LogMLFeedback {
  appId?: string;
  stateExecutionId?: string;
  clusterType?: "CONTROL" | "TEST" | "UNKNOWN" | "IGNORE";
  clusterLabel?: number;
  logMLFeedbackType?:
    | "IGNORE_SERVICE"
    | "IGNORE_WORKFLOW"
    | "IGNORE_WORKFLOW_EXECUTION"
    | "IGNORE_ALWAYS"
    | "DISMISS"
    | "PRIORITIZE"
    | "THUMBS_UP"
    | "THUMBS_DOWN"
    | "UNDO_IGNORE";
  comment?: string;
  logMLFeedbackId?: string;
  analysisMinute?: number;
  serviceId?: string;
  envId?: string;
}

export interface RestResponseMapFeedbackActionListFeedbackAction {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: (
      | "ADD_TO_BASELINE"
      | "REMOVE_FROM_BASELINE"
      | "UPDATE_PRIORITY"
    )[];
  };
  responseMessages?: ResponseMessage[];
}

export interface CVCollaborationProviderParameters {
  collaborationProviderConfigId?: string;
  jiraTaskParameters?: JiraTaskParameters;
  cvFeedbackRecord?: CVFeedbackRecord;
}

export interface JiraConfig {
  type?: string;
  baseUrl?: string;
  username?: string;
  password?: string[];
  encryptedPassword?: string;
  accountId?: string;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
  certValidationRequired?: boolean;
}

export interface JiraCustomFieldValue {
  fieldType?: string;
  fieldValue?: string;
}

export interface JiraTaskParameters {
  jiraConfig?: JiraConfig;
  jiraAction?:
    | "CREATE_TICKET"
    | "UPDATE_TICKET"
    | "AUTH"
    | "GET_PROJECTS"
    | "GET_FIELDS_OPTIONS"
    | "GET_STATUSES"
    | "GET_CREATE_METADATA"
    | "FETCH_ISSUE"
    | "FETCH_ISSUE_DATA"
    | "CHECK_APPROVAL";
  project?: string;
  summary?: string;
  description?: string;
  issueType?: string;
  priority?: string;
  labels?: string[];
  customFields?: {
    [key: string]: JiraCustomFieldValue;
  };
  issueId?: string;
  updateIssueIds?: string[];
  status?: string;
  comment?: string;
  createmetaExpandParam?: string;
  encryptionDetails?: EncryptedDataDetail[];
  accountId?: string;
  appId?: string;
  activityId?: string;
  approvalId?: string;
  approvalField?: string;
  approvalValue?: string;
  rejectionField?: string;
  rejectionValue?: string;
}

export interface RestResponseMapStringMapStringInstanceDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: {
      [key: string]: InstanceDetails;
    };
  };
  responseMessages?: ResponseMessage[];
}

export interface CustomLogSetupTestNodeData {
  logCollectionInfo?: LogCollectionInfo;
  host?: string;
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  serviceLevel?: boolean;
}

export interface LogCollectionInfo {
  collectionUrl?: string;
  collectionBody?: string;
  responseType?: "JSON";
  responseMapping?: ResponseMapping;
  method?: "POST" | "GET";
}

export interface LoginSettings {
  uuid: string;
  accountId: string;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  userLockoutPolicy: UserLockoutPolicy;
  passwordExpirationPolicy: PasswordExpirationPolicy;
  passwordStrengthPolicy: PasswordStrengthPolicy;
}

export interface PasswordExpirationPolicy {
  enabled?: boolean;
  daysBeforePasswordExpires?: number;
  daysBeforeUserNotifiedOfPasswordExpiration?: number;
}

export interface PasswordStrengthPolicy {
  enabled?: boolean;
  minNumberOfCharacters?: number;
  minNumberOfUppercaseCharacters?: number;
  minNumberOfLowercaseCharacters?: number;
  minNumberOfSpecialCharacters?: number;
  minNumberOfDigits?: number;
}

export interface RestResponseLoginSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LoginSettings;
  responseMessages?: ResponseMessage[];
}

export interface UserLockoutPolicy {
  enableLockoutPolicy?: boolean;
  numberOfFailedAttemptsBeforeLockout?: number;
  lockOutPeriod?: number;
  notifyUser?: boolean;
  userGroupsToNotify?: UserGroup[];
}

export interface NewRelicSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  newRelicAppId?: number;
  serviceLevel?: boolean;
}

export interface NewRelicApplicationInstance {
  id?: number;
  host?: string;
  port?: number;
}

export interface RestResponseListNewRelicApplicationInstance {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NewRelicApplicationInstance[];
  responseMessages?: ResponseMessage[];
}

export interface NewRelicMetric {
  name?: string;
}

export interface RestResponseListNewRelicMetric {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NewRelicMetric[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseNewRelicApplication {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NewRelicApplication;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseNotification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Notification;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseNotification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Notification[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseNotificationGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NotificationGroup[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseNotificationGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NotificationGroup;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePipeline {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Pipeline;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponsePipeline {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Pipeline[];
  responseMessages?: ResponseMessage[];
}

export interface JsonNode {
  array?: boolean;
  null?: boolean;
  valueNode?: boolean;
  containerNode?: boolean;
  object?: boolean;
  missingNode?: boolean;
  nodeType?:
    | "ARRAY"
    | "BINARY"
    | "BOOLEAN"
    | "MISSING"
    | "NULL"
    | "NUMBER"
    | "OBJECT"
    | "POJO"
    | "STRING";
  pojo?: boolean;
  number?: boolean;
  integralNumber?: boolean;
  floatingPointNumber?: boolean;
  short?: boolean;
  int?: boolean;
  long?: boolean;
  float?: boolean;
  double?: boolean;
  bigDecimal?: boolean;
  bigInteger?: boolean;
  textual?: boolean;
  boolean?: boolean;
  binary?: boolean;
}

export interface RestResponseListStencil {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Stencil[];
  responseMessages?: ResponseMessage[];
}

export interface Stencil {
  name?: string;
  type?: string;
  stencilCategory?:
    | "BUILD"
    | "CLOUD"
    | "COLLABORATION"
    | "COLLECTIONS"
    | "COMMANDS"
    | "COMMONS"
    | "CONFIGURATIONS"
    | "CONTAINERS"
    | "CONTROLS"
    | "COPY"
    | "ENVIRONMENTS"
    | "FLOW_CONTROLS"
    | "KUBERNETES"
    | "OTHERS"
    | "PROVISIONERS"
    | "SCRIPTS"
    | "SUB_WORKFLOW"
    | "VERIFICATIONS"
    | "ECS"
    | "SPOTINST"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AZURE_VMSS"
    | "AZURE_WEBAPP"
    | "AZURE_ARM"
    | "TERRAGRUNT_PROVISION";
  jsonSchema?: JsonNode;
  uiSchema?: { [key: string]: any };
  displayOrder?: number;
}

export interface RestResponseListFailureStrategy {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: FailureStrategy[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListEntityType {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: (
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG"
  )[];
  responseMessages?: ResponseMessage[];
}

export interface AccountPlugin {
  type?: string;
  displayName?: string;
  pluginCategories?: (
    | "Artifact"
    | "Verification"
    | "Collaboration"
    | "CloudProvider"
    | "ConnectionAttributes"
    | "LoadBalancer"
    | "SourceRepo"
    | "HelmRepo"
    | "AzureArtifacts"
  )[];
  accountId?: string;
  version?: Version;
  enabled?: boolean;
}

export interface RestResponseListAccountPlugin {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccountPlugin[];
  responseMessages?: ResponseMessage[];
}

export interface Version {
  majorVersion?: number;
  minorVersion?: number;
  preReleaseVersion?: string;
  buildMetadata?: string;
  patchVersion?: number;
  normalVersion?: string;
}

export interface RestResponseMapStringMapStringObject {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: {
      [key: string]: { [key: string]: any };
    };
  };
  responseMessages?: ResponseMessage[];
}

export interface AccountAuditFilter {
  resourceTypes?: string[];
  resourceIds?: string[];
}

export interface ApplicationAuditFilter {
  appIds?: string[];
  resourceTypes?: string[];
  resourceIds?: string[];
}

export type AuditPreference = Preference & {
  startTime?: string;
  endTime?: string;
  lastNDays?: number;
  createdByUserIds?: string[];
  operationTypes?: string[];
  includeAccountLevelResources?: boolean;
  includeAppLevelResources?: boolean;
  accountAuditFilter?: AccountAuditFilter;
  applicationAuditFilter?: ApplicationAuditFilter;
};

export interface AuditPreferenceResponse {
  auditPreferences?: AuditPreference[];
  resourceLookupMap?: {
    [key: string]: ResourceLookup;
  };
}

export interface ResourceLookup {
  uuid: string;
  accountId?: string;
  appId?: string;
  resourceId?: string;
  resourceType?: string;
  resourceName?: string;
  tags?: NameValuePair[];
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface RestResponseAuditPreferenceResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AuditPreferenceResponse;
  responseMessages?: ResponseMessage[];
}

export type DeploymentPreference = Preference & {
  appIds?: string[];
  pipelineIds?: string[];
  workflowIds?: string[];
  serviceIds?: string[];
  envIds?: string[];
  status?: string[];
  startTime?: string;
  endTime?: string;
  includeIndirectExecutions?: boolean;
  harnessTagFilter?: HarnessTagFilter;
  uiDisplayTagString?: string;
  keywords?: string[];
};

export interface HarnessTagFilter {
  matchAll?: boolean;
  conditions?: TagFilterCondition[];
}

export interface Preference {
  name?: string;
  accountId?: string;
  userId?: string;
  preferenceType?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface RestResponsePageResponsePreference {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Preference[];
  responseMessages?: ResponseMessage[];
}

export interface TagFilterCondition {
  name?: string;
  tagType?: "USER" | "HARNESS";
  values?: string[];
  operator?:
    | "EQ"
    | "NOT_EQ"
    | "LT"
    | "LT_EQ"
    | "GE"
    | "GT"
    | "CONTAINS"
    | "STARTS_WITH"
    | "HAS"
    | "IN"
    | "NOT_IN"
    | "EXISTS"
    | "NOT_EXISTS"
    | "HAS_ALL"
    | "OR"
    | "AND"
    | "ELEMENT_MATCH";
}

export interface RestResponsePreference {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Preference;
  responseMessages?: ResponseMessage[];
}

export interface TimeSeries {
  txnName: string;
  url: string;
  metricName: string;
  metricType: string;
}

export interface PrometheusSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  timeSeriesToAnalyze?: TimeSeries[];
  serviceLevel?: boolean;
}

export interface ResourceConstraint {
  uuid: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  name?: string;
  capacity?: number;
  strategy?: "ASAP" | "FIFO";
  harnessOwned?: boolean;
}

export interface RestResponseResourceConstraint {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ResourceConstraint;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseResourceConstraint {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ResourceConstraint[];
  responseMessages?: ResponseMessage[];
}

export interface ActiveScope {
  releaseEntityType?: string;
  releaseEntityId?: string;
  releaseEntityName?: string;
  unit?: string;
  permits?: number;
  acquiredAt?: number;
}

export interface ResourceConstraintUsage {
  resourceConstraintId?: string;
  activeScopes?: ActiveScope[];
}

export interface RestResponseListResourceConstraintUsage {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ResourceConstraintUsage[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseResourceLookup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ResourceLookup[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseRole {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Role;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseRole {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Role[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseSSOConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SSOConfig;
  responseMessages?: ResponseMessage[];
}

export interface SSOConfig {
  accountId?: string;
  ssoSettings?: SSOSettings[];
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
}

export interface SSOSettings {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  type: "SAML" | "LDAP" | "OAUTH";
  displayName?: string;
  url?: string;
  nextIteration?: number;
  accountId?: string;
}

export interface OauthSettings {
  displayName?: string;
  filter?: string;
  allowedProviders?: (
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN"
  )[];
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  type: "SAML" | "LDAP" | "OAUTH";
  url?: string;
  nextIteration?: number;
  accountId?: string;
}

export interface RestResponseOauthSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: OauthSettings;
  responseMessages?: ResponseMessage[];
}

export interface LDAPTestAuthenticationRequest {
  email?: string;
  password?: string;
}

export interface LdapGroupResponse {
  dn?: string;
  name?: string;
  description?: string;
  totalMembers?: number;
  selectable?: boolean;
  message?: string;
  users?: LdapUserResponse[];
}

export interface LdapUserResponse {
  dn?: string;
  email?: string;
  name?: string;
}

export interface RestResponseCollectionLdapGroupResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LdapGroupResponse[];
  responseMessages?: ResponseMessage[];
}

export interface LoginTypeResponse {
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
  showCaptcha?: boolean;
  defaultExperience?: "NG" | "CG";
  ssorequest?: SSORequest;
  oauthEnabled?: boolean;
}

export interface RestResponseLoginTypeResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LoginTypeResponse;
  responseMessages?: ResponseMessage[];
}

export interface SSORequest {
  oauthProviderType?:
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN";
  idpRedirectUrl?: string;
  oauthProviderTypes?: (
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN"
  )[];
}

export interface LdapConnectionSettings {
  host: string;
  port?: number;
  sslEnabled?: boolean;
  referralsEnabled?: boolean;
  maxReferralHops?: number;
  bindDN?: string;
  bindPassword?: string;
  connectTimeout?: number;
  responseTimeout?: number;
}

export interface LdapGroupSettings {
  baseDN?: string;
  searchFilter?: string;
  nameAttr?: string;
  descriptionAttr?: string;
  userMembershipAttr?: string;
  referencedUserAttr?: string;
}

export interface LdapSettings {
  displayName?: string;
  accountId?: string;
  connectionSettings: LdapConnectionSettings;
  userSettingsList?: LdapUserSettings[];
  groupSettingsList?: LdapGroupSettings[];
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  type: "SAML" | "LDAP" | "OAUTH";
  url?: string;
  nextIteration?: number;
  userSettings?: LdapUserSettings;
  groupSettings?: LdapGroupSettings;
}

export interface LdapUserSettings {
  baseDN?: string;
  searchFilter?: string;
  emailAttr?: string;
  displayNameAttr?: string;
  groupMembershipAttr?: string;
}

export interface RestResponseLdapSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LdapSettings;
  responseMessages?: ResponseMessage[];
}

export interface LdapTestResponse {
  status?: "SUCCESS" | "FAILURE";
  message?: string;
}

export interface RestResponseLdapTestResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LdapTestResponse;
  responseMessages?: ResponseMessage[];
}

export interface LdapResponse {
  status?: "SUCCESS" | "FAILURE";
  message?: string;
}

export interface RestResponseLdapResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LdapResponse;
  responseMessages?: ResponseMessage[];
}

export interface Member {
  value?: string;
  ref?: string;
  display?: string;
}

export interface ScimGroup {
  id?: string;
  externalId?: string;
  meta?: JsonNode;
  schemas?: string[];
  displayName?: string;
  members?: Member[];
}

export type AddOperation = PatchOperation & {
  value?: JsonNode;
};

export type OktaAddOperation = PatchOperation & {
  value?: JsonNode;
};

export type OktaRemoveOperation = PatchOperation & {
  value?: JsonNode;
};

export type OktaReplaceOperation = PatchOperation & {
  value?: JsonNode;
};

export interface PatchOperation {
  path?: string;
}

export interface PatchRequest {
  Operations: PatchOperation[];
  id?: string;
  externalId?: string;
  meta?: JsonNode;
  schemas: string[];
}

export type RemoveOperation = PatchOperation & {
  value?: JsonNode;
};

export type ReplaceOperation = PatchOperation & {
  value?: JsonNode;
};

export interface ScimUser {
  schemas?: string[];
  userName?: string;
  displayName?: string;
  active?: boolean;
  emails?: JsonNode;
  roles?: JsonNode;
  name?: JsonNode;
  groups?: JsonNode;
  password?: JsonNode;
  id?: string;
  externalId?: string;
  meta?: JsonNode;
}

export interface RestResponseSearchResults {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SearchResults;
  responseMessages?: ResponseMessage[];
}

export interface SearchResult {
  id?: string;
  name?: string;
  description?: string;
  accountId?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  type?:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  createdBy?: EmbeddedUser;
  lastUpdatedBy?: EmbeddedUser;
  searchScore?: number;
}

export interface SearchResults {
  searchResults?: {
    [key: string]: SearchResult[];
  };
}

export interface AdvancedSearchQuery {
  searchQuery?: string;
  numResults?: number;
  offset?: number;
  entities?: string[];
}

export interface LocalTime {
  hour?: number;
  minute?: number;
  second?: number;
  nano?: number;
}

export interface RestResponseServerInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServerInfo;
  responseMessages?: ResponseMessage[];
}

export interface ServerInfo {
  zoneId?: ZoneId;
}

export interface ZoneId {
  id?: string;
  rules?: ZoneRules;
}

export interface ZoneOffset {
  totalSeconds?: number;
  id?: string;
  rules?: ZoneRules;
}

export interface ZoneOffsetTransition {
  offsetBefore?: ZoneOffset;
  offsetAfter?: ZoneOffset;
  duration?: Duration;
  gap?: boolean;
  overlap?: boolean;
  dateTimeAfter?: string;
  dateTimeBefore?: string;
  instant?: number;
}

export interface ZoneOffsetTransitionRule {
  month?:
    | "JANUARY"
    | "FEBRUARY"
    | "MARCH"
    | "APRIL"
    | "MAY"
    | "JUNE"
    | "JULY"
    | "AUGUST"
    | "SEPTEMBER"
    | "OCTOBER"
    | "NOVEMBER"
    | "DECEMBER";
  timeDefinition?: "UTC" | "WALL" | "STANDARD";
  standardOffset?: ZoneOffset;
  offsetBefore?: ZoneOffset;
  offsetAfter?: ZoneOffset;
  dayOfWeek?:
    | "MONDAY"
    | "TUESDAY"
    | "WEDNESDAY"
    | "THURSDAY"
    | "FRIDAY"
    | "SATURDAY"
    | "SUNDAY";
  dayOfMonthIndicator?: number;
  localTime?: LocalTime;
  midnightEndOfDay?: boolean;
}

export interface ZoneRules {
  transitions?: ZoneOffsetTransition[];
  fixedOffset?: boolean;
  transitionRules?: ZoneOffsetTransitionRule[];
}

export interface AwsLambdaInstanceKey {
  functionName?: string;
  functionVersion?: string;
}

export interface RestResponseServerlessInstance {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServerlessInstance;
  responseMessages?: ResponseMessage[];
}

export interface ServerlessInstance {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  instanceType?: "AWS_LAMBDA";
  lambdaInstanceKey?: AwsLambdaInstanceKey;
  envId?: string;
  envName?: string;
  envType?: "PROD" | "NON_PROD" | "ALL";
  accountId?: string;
  appName?: string;
  serviceId?: string;
  serviceName?: string;
  computeProviderId?: string;
  computeProviderName?: string;
  infraMappingId?: string;
  infraMappingName?: string;
  infraMappingType?: string;
  lastPipelineExecutionId?: string;
  lastPipelineExecutionName?: string;
  lastDeployedAt?: number;
  lastDeployedById?: string;
  lastDeployedByName?: string;
  lastWorkflowExecutionId?: string;
  lastWorkflowExecutionName?: string;
  lastArtifactSourceName?: string;
  lastArtifactStreamId?: string;
  lastArtifactBuildNum?: string;
  lastArtifactId?: string;
  lastArtifactName?: string;
  instanceInfo?: ServerlessInstanceInfo;
  deletedAt?: number;
  deleted?: boolean;
}

export interface ServerlessInstanceInfo {
  invocationCountMap?: {
    [key: string]: InvocationCount;
  };
}

export interface RestResponseServerlessInstanceTimeline {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServerlessInstanceTimeline;
  responseMessages?: ResponseMessage[];
}

export interface ServerlessInstanceTimeline {
  points?: DataPoint[];
}

export interface RestResponsePageResponseServiceInstance {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceInstance[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseService {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Service;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseService {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Service[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseArtifactStreamBinding {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ArtifactStreamBinding;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseServiceCommand {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceCommand;
  responseMessages?: ResponseMessage[];
}

export interface PcfServiceSpecification {
  serviceId: string;
  manifestYaml: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
}

export interface RestResponsePcfServiceSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PcfServiceSpecification;
  responseMessages?: ResponseMessage[];
}

export interface EcsServiceSpecification {
  serviceId: string;
  serviceSpecJson?: string;
  schedulingStrategy?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
}

export interface RestResponseEcsServiceSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: EcsServiceSpecification;
  responseMessages?: ResponseMessage[];
}

export interface ContainerDefinition {
  portMappings?: PortMapping[];
  name?: string;
  commands?: string[];
  cpu?: number;
  memory?: number;
  logConfiguration?: LogConfiguration;
  storageConfigurations?: StorageConfiguration[];
}

export interface ContainerTask {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  deploymentType?: string;
  serviceId?: string;
  advancedConfig?: string;
  containerDefinitions?: ContainerDefinition[];
}

export interface LogConfiguration {
  logDriver?: string;
  options?: LogOption[];
}

export interface LogOption {
  key?: string;
  value?: string;
}

export interface PortMapping {
  containerPort?: number;
  hostPort?: number;
}

export interface RestResponseContainerTask {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ContainerTask;
  responseMessages?: ResponseMessage[];
}

export interface StorageConfiguration {
  hostSourcePath?: string;
  containerPath?: string;
  readonly?: boolean;
}

export interface RestResponsePageResponseContainerTask {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ContainerTask[];
  responseMessages?: ResponseMessage[];
}

export interface HelmChartSpecification {
  serviceId?: string;
  chartUrl: string;
  chartName: string;
  chartVersion: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
}

export interface RestResponseHelmChartSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HelmChartSpecification;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseHelmChartSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: HelmChartSpecification[];
  responseMessages?: ResponseMessage[];
}

export interface DefaultSpecification {
  runtime?: string;
  memorySize?: number;
  timeout?: number;
}

export interface FunctionSpecification {
  runtime?: string;
  memorySize?: number;
  timeout?: number;
  functionName?: string;
  handler?: string;
}

export interface LambdaSpecification {
  serviceId?: string;
  defaults?: DefaultSpecification;
  functions?: FunctionSpecification[];
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
}

export interface RestResponseLambdaSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LambdaSpecification;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseLambdaSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LambdaSpecification[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseUserDataSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserDataSpecification;
  responseMessages?: ResponseMessage[];
}

export interface UserDataSpecification {
  serviceId?: string;
  data: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
}

export interface RestResponsePageResponseUserDataSpecification {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserDataSpecification[];
  responseMessages?: ResponseMessage[];
}

export interface CommandCategory {
  type?: "COMMANDS" | "COPY" | "SCRIPTS" | "VERIFICATIONS";
  displayName?: string;
  commandUnits?: CommandUnit[];
}

export interface RestResponseListCommandCategory {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CommandCategory[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListApplicationManifest {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ApplicationManifest[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseMapStringListHelmChart {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: {
    [key: string]: HelmChart[];
  };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseSetHelmSubCommand {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: (
    | "INSTALL"
    | "UPGRADE"
    | "ROLLBACK"
    | "HISTORY"
    | "DELETE"
    | "UNINSTALL"
    | "LIST"
    | "VERSION"
    | "PULL"
    | "FETCH"
    | "TEMPLATE"
  )[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListArtifactStream {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ArtifactStream[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListArtifactStreamBinding {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ArtifactStreamBinding[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseServiceTemplate {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceTemplate;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseServiceTemplate {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceTemplate[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseServiceVariable {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceVariable;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseServiceVariable {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceVariable[];
  responseMessages?: ResponseMessage[];
}

export interface AppEnvRestriction {
  appFilter?: GenericEntityFilter;
  envFilter?: EnvFilter;
}

export interface ConnectivityValidationAttributes {
  [key: string]: any;
}

export interface RestResponseSettingAttribute {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SettingAttribute;
  responseMessages?: ResponseMessage[];
}

export interface SettingAttribute {
  envId?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedAt: number;
  accountId?: string;
  name?: string;
  value?: SettingValue;
  validationAttributes?: ConnectivityValidationAttributes;
  category?:
    | "CLOUD_PROVIDER"
    | "CONNECTOR"
    | "SETTING"
    | "HELM_REPO"
    | "AZURE_ARTIFACTS"
    | "CE_CONNECTOR";
  appIds?: string[];
  usageRestrictions?: UsageRestrictions;
  artifactStreamCount?: number;
  artifactStreams?: ArtifactStreamSummary[];
  sample?: boolean;
  nextIteration?: number;
  nextSecretMigrationIteration?: number;
  secretsMigrated?: boolean;
  connectivityError?: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  encryptedBy?: string;
}

export interface SettingValue {
  type?: string;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
  certValidationRequired?: boolean;
}

export interface UsageRestrictions {
  appEnvRestrictions?: AppEnvRestriction[];
}

export interface RestResponsePageResponseSettingAttribute {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SettingAttribute[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseValidationResult {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ValidationResult;
  responseMessages?: ResponseMessage[];
}

export interface ValidationResult {
  valid?: boolean;
  errorMessage?: string;
}

export interface UserInvite {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  email?: string;
  roles?: Role[];
  userGroups?: UserGroup[];
  completed?: boolean;
  source?: UserInviteSource;
  agreement?: boolean;
  name?: string;
  givenName?: string;
  familyName?: string;
  phone?: string;
  country?: string;
  state?: string;
  freemiumProducts?: string[];
  freemiumAssistedOption?: boolean;
  password?: string[];
  accountName?: string;
  companyName?: string;
  marketPlaceToken?: string;
  importedByScim?: boolean;
  utmInfo?: UtmInfo;
  createdFromNG?: boolean;
  intent?: string;
}

export interface UserInviteSource {
  type?:
    | "MANUAL"
    | "SSO"
    | "TRIAL"
    | "MARKETPLACE"
    | "MARKETO_LINKEDIN"
    | "AZURE_MARKETPLACE"
    | "ONPREM";
  uuid?: string;
}

export interface RestResponseSmtpConfigResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SmtpConfigResponse;
  responseMessages?: ResponseMessage[];
}

export interface SmtpConfig {
  type?: string;
  host?: string;
  port?: number;
  fromAddress?: string;
  useSSL?: boolean;
  username?: string;
  password?: string[];
  encryptedPassword?: string;
}

export interface SmtpConfigResponse {
  smtpConfig?: SmtpConfig;
  encryptionDetails?: EncryptedDataDetail[];
}

export interface SplunkSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  query: string;
  hostNameField?: string;
  advancedQuery?: boolean;
  serviceLevel?: boolean;
}

export interface RestResponseListStackDriverMetric {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: StackDriverMetric[];
  responseMessages?: ResponseMessage[];
}

export interface StackDriverMetric {
  metricName?: string;
  metric?: string;
  displayName?: string;
  unit?: string;
  kind?: string;
  valueType?: string;
}

export interface Aggregation {
  perSeriesAligner?: string;
  crossSeriesReducer?: string;
  groupByFields?: string[];
}

export interface StackDriverMetricDefinition {
  metricType?: string;
  metricName?: string;
  txnName?: string;
  filterJson?: string;
  filter?: string;
  aggregation?: Aggregation;
}

export interface StackDriverSetupTestNodeData {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  loadBalancerMetrics?: {
    [key: string]: StackDriverMetric[];
  };
  podMetrics?: StackDriverMetric[];
  query?: string;
  hostnameField?: string;
  messageField?: string;
  metricDefinitions?: StackDriverMetricDefinition[];
  logConfiguration?: boolean;
  serviceLevel?: boolean;
}

export interface AggregatedDayStats {
  totalCount?: number;
  failedCount?: number;
  instancesCount?: number;
  daysStats?: DayStat[];
}

export interface DayStat {
  totalCount?: number;
  failedCount?: number;
  instancesCount?: number;
  date?: number;
}

export interface DeploymentStatistics {
  statsMap?: {
    [key: string]: AggregatedDayStats;
  };
  type?: "DEPLOYMENT" | "SERVICE_INSTANCE_STATISTICS";
}

export interface RestResponseDeploymentStatistics {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DeploymentStatistics;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseServiceInstanceStatistics {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ServiceInstanceStatistics;
  responseMessages?: ResponseMessage[];
}

export interface ServiceInstanceStatistics {
  statsMap?: {
    [key: string]: TopConsumer[];
  };
  type?: "DEPLOYMENT" | "SERVICE_INSTANCE_STATISTICS";
}

export interface TopConsumer {
  appId?: string;
  appName?: string;
  serviceId?: string;
  serviceName?: string;
  successfulActivityCount?: number;
  failedActivityCount?: number;
  totalCount?: number;
}

export interface SumoLogicSetupTestNodedata {
  appId: string;
  settingId: string;
  instanceName?: string;
  isServiceLevel?: boolean;
  instanceElement?: Instance;
  hostExpression?: string;
  workflowId?: string;
  guid?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  toTime?: number;
  fromTime?: number;
  query?: string;
  hostNameField?: string;
  serviceLevel?: boolean;
}

export interface NewRelicMetricHostAnalysisValue {
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  testHostName?: string;
  controlHostName?: string;
  testValues?: number[];
  controlValues?: number[];
  upperThresholds?: number[];
  lowerThresholds?: number[];
  anomalies?: number[];
  testStartIndex?: number;
  failFastCriteriaDescription?: string;
}

export interface RestResponseListNewRelicMetricHostAnalysisValue {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NewRelicMetricHostAnalysisValue[];
  responseMessages?: ResponseMessage[];
}

export interface NewRelicMetricAnalysis {
  metricName?: string;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  metricValues?: NewRelicMetricAnalysisValue[];
  displayName?: string;
  fullMetricName?: string;
  tag?: string;
}

export interface NewRelicMetricAnalysisRecord {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  message?: string;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  workflowExecutionId?: string;
  stateExecutionId?: string;
  accountId?: string;
  cvConfigId?: string;
  metricAnalyses?: NewRelicMetricAnalysis[];
  analysisMinute?: number;
  showTimeSeries?: boolean;
  baseLineExecutionId?: string;
  groupName?: string;
  dependencyPath?: string;
  mlAnalysisType?: "COMPARATIVE" | "PREDICTIVE" | "TIMESERIES_24x7";
  progress?: number;
}

export interface NewRelicMetricAnalysisValue {
  name?: string;
  type?: string;
  alertType?: string;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  testValue?: number;
  controlValue?: number;
  hostAnalysisValues?: NewRelicMetricHostAnalysisValue[];
}

export interface RestResponseSetNewRelicMetricAnalysisRecord {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NewRelicMetricAnalysisRecord[];
  responseMessages?: ResponseMessage[];
}

export interface Threshold {
  thresholdType?:
    | "ALERT_WHEN_LOWER"
    | "ALERT_WHEN_HIGHER"
    | "ALERT_HIGHER_OR_LOWER"
    | "NO_ALERT";
  comparisonType?: "RATIO" | "DELTA" | "ABSOLUTE";
  ml?: number;
  customThresholdType?: "ACCEPTABLE" | "ANOMALOUS";
  thresholdCriteria?: TimeSeriesCustomThresholdCriteria;
}

export interface TimeSeriesCustomThresholdCriteria {
  actionToTake?:
    | "FAIL_IMMEDIATELY"
    | "FAIL_AFTER_OCCURRENCES"
    | "FAIL_AFTER_CONSECUTIVE_OCCURRENCES";
  occurrences?: number;
}

export interface TimeSeriesMLTransactionThresholds {
  serviceId?: string;
  workflowId?: string;
  stateType?:
    | "SUB_WORKFLOW"
    | "REPEAT"
    | "FORK"
    | "WAIT"
    | "PAUSE"
    | "BARRIER"
    | "RESOURCE_CONSTRAINT"
    | "SHELL_SCRIPT"
    | "HTTP"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "EMAIL"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "NEW_RELIC_DEPLOYMENT_MARKER"
    | "DYNA_TRACE"
    | "PROMETHEUS"
    | "SPLUNKV2"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "DATA_DOG_LOG"
    | "CVNG"
    | "CLOUD_WATCH"
    | "AWS_LAMBDA_VERIFICATION"
    | "APM_VERIFICATION"
    | "LOG_VERIFICATION"
    | "BUG_SNAG"
    | "STACK_DRIVER"
    | "STACK_DRIVER_LOG"
    | "INSTANA"
    | "SCALYR"
    | "ENV_STATE"
    | "ENV_LOOP_STATE"
    | "ENV_RESUME_STATE"
    | "ENV_LOOP_RESUME_STATE"
    | "COMMAND"
    | "APPROVAL"
    | "APPROVAL_RESUME"
    | "ELASTIC_LOAD_BALANCER"
    | "JENKINS"
    | "GCB"
    | "BAMBOO"
    | "ARTIFACT_COLLECTION"
    | "ARTIFACT_CHECK"
    | "AZURE_NODE_SELECT"
    | "AZURE_VMSS_SETUP"
    | "AZURE_VMSS_DEPLOY"
    | "AZURE_VMSS_ROLLBACK"
    | "AZURE_VMSS_SWITCH_ROUTES"
    | "AZURE_VMSS_SWITCH_ROUTES_ROLLBACK"
    | "AZURE_WEBAPP_SLOT_SETUP"
    | "AZURE_WEBAPP_SLOT_SWAP"
    | "AZURE_WEBAPP_SLOT_SHIFT_TRAFFIC"
    | "AZURE_WEBAPP_SLOT_ROLLBACK"
    | "AWS_NODE_SELECT"
    | "DC_NODE_SELECT"
    | "ROLLING_NODE_SELECT"
    | "PHASE"
    | "PHASE_STEP"
    | "STAGING_ORIGINAL_EXECUTION"
    | "AWS_CODEDEPLOY_STATE"
    | "AWS_CODEDEPLOY_ROLLBACK"
    | "AWS_LAMBDA_STATE"
    | "AWS_LAMBDA_ROLLBACK"
    | "AWS_AMI_SERVICE_SETUP"
    | "ASG_AMI_SERVICE_ALB_SHIFT_SETUP"
    | "AWS_AMI_SERVICE_DEPLOY"
    | "ASG_AMI_SERVICE_ALB_SHIFT_DEPLOY"
    | "AWS_AMI_SWITCH_ROUTES"
    | "ASG_AMI_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_ROLLBACK_SWITCH_ROUTES"
    | "ASG_AMI_ROLLBACK_ALB_SHIFT_SWITCH_ROUTES"
    | "AWS_AMI_SERVICE_ROLLBACK"
    | "ECS_SERVICE_SETUP"
    | "SPOTINST_SETUP"
    | "SPOTINST_ALB_SHIFT_SETUP"
    | "SPOTINST_DEPLOY"
    | "SPOTINST_ALB_SHIFT_DEPLOY"
    | "SPOTINST_LISTENER_UPDATE"
    | "SPOTINST_LISTENER_ALB_SHIFT"
    | "SPOTINST_ROLLBACK"
    | "SPOTINST_LISTENER_UPDATE_ROLLBACK"
    | "SPOTINST_LISTENER_ALB_SHIFT_ROLLBACK"
    | "ECS_SERVICE_SETUP_ROLLBACK"
    | "ECS_DAEMON_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP"
    | "ECS_BG_SERVICE_SETUP_ROUTE53"
    | "ECS_SERVICE_DEPLOY"
    | "ECS_SERVICE_ROLLBACK"
    | "ECS_LISTENER_UPDATE"
    | "ECS_LISTENER_UPDATE_ROLLBACK"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE"
    | "ECS_ROUTE53_DNS_WEIGHT_UPDATE_ROLLBACK"
    | "KUBERNETES_SETUP"
    | "KUBERNETES_SETUP_ROLLBACK"
    | "KUBERNETES_DEPLOY"
    | "KUBERNETES_DEPLOY_ROLLBACK"
    | "KUBERNETES_STEADY_STATE_CHECK"
    | "ECS_STEADY_STATE_CHECK"
    | "ECS_RUN_TASK"
    | "GCP_CLUSTER_SETUP"
    | "HELM_DEPLOY"
    | "HELM_ROLLBACK"
    | "PCF_SETUP"
    | "PCF_RESIZE"
    | "PCF_ROLLBACK"
    | "PCF_MAP_ROUTE"
    | "PCF_UNMAP_ROUTE"
    | "PCF_BG_MAP_ROUTE"
    | "PCF_PLUGIN"
    | "TERRAFORM_PROVISION"
    | "TERRAFORM_APPLY"
    | "TERRAGRUNT_PROVISION"
    | "TERRAGRUNT_DESTROY"
    | "TERRAGRUNT_ROLLBACK"
    | "ARM_CREATE_RESOURCE"
    | "ARM_ROLLBACK"
    | "SHELL_SCRIPT_PROVISION"
    | "TERRAFORM_DESTROY"
    | "CLOUD_FORMATION_CREATE_STACK"
    | "CLOUD_FORMATION_DELETE_STACK"
    | "KUBERNETES_SWAP_SERVICE_SELECTORS"
    | "CLOUD_FORMATION_ROLLBACK_STACK"
    | "TERRAFORM_ROLLBACK"
    | "K8S_DEPLOYMENT_ROLLING"
    | "K8S_SCALE"
    | "K8S_DEPLOYMENT_ROLLING_ROLLBACK"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_DELETE"
    | "JIRA_CREATE_UPDATE"
    | "SERVICENOW_CREATE_UPDATE"
    | "K8S_TRAFFIC_SPLIT"
    | "K8S_APPLY"
    | "CUSTOM_DEPLOYMENT_FETCH_INSTANCES";
  groupName?: string;
  transactionName?: string;
  metricName?: string;
  cvConfigId?: string;
  accountId?: string;
  thresholds?: TimeSeriesMetricDefinition;
  thresholdType?: "ACCEPTABLE" | "ANOMALOUS";
  customThresholdRefId?: string;
  version?: number;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface TimeSeriesMetricDefinition {
  metricName?: string;
  metricType?: string;
  tags?: string[];
  customThresholds?: Threshold[];
  categorizedThresholds?: {
    [key: string]: Threshold[];
  };
  thresholds?: Threshold[];
}

export interface RestResponseListTimeSeriesMLTransactionThresholds {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TimeSeriesMLTransactionThresholds[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseTimeSeriesKeyTransactions {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TimeSeriesKeyTransactions;
  responseMessages?: ResponseMessage[];
}

export interface TimeSeriesKeyTransactions {
  uuid?: string;
  createdAt?: number;
  lastUpdatedAt?: number;
  createdBy?: EmbeddedUser;
  lastUpdatedBy?: EmbeddedUser;
  cvConfigId?: string;
  serviceId?: string;
  accountId?: string;
  keyTransactions?: string[];
}

export interface DeploymentTimeSeriesAnalysis {
  stateExecutionId?: string;
  customThresholdRefId?: string;
  baseLineExecutionId?: string;
  message?: string;
  riskLevel?: "HIGH" | "MEDIUM" | "LOW" | "NA";
  total?: number;
  metricAnalyses?: NewRelicMetricAnalysis[];
}

export interface RestResponseDeploymentTimeSeriesAnalysis {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DeploymentTimeSeriesAnalysis;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseTimeSeriesMLTransactionThresholds {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TimeSeriesMLTransactionThresholds;
  responseMessages?: ResponseMessage[];
}

export interface ArtifactSelection {
  serviceId?: string;
  serviceName?: string;
  type?:
    | "ARTIFACT_SOURCE"
    | "LAST_COLLECTED"
    | "LAST_DEPLOYED"
    | "PIPELINE_SOURCE"
    | "WEBHOOK_VARIABLE";
  artifactStreamId?: string;
  artifactSourceName?: string;
  artifactFilter?: string;
  pipelineId?: string;
  pipelineName?: string;
  workflowId?: string;
  workflowName?: string;
  regex?: boolean;
  uiDisplayName?: string;
}

export type ArtifactTriggerCondition = TriggerCondition & {
  artifactStreamId?: string;
  artifactSourceName?: string;
  artifactFilter?: string;
  regex?: boolean;
};

export interface ManifestSelection {
  serviceId?: string;
  serviceName?: string;
  type?:
    | "FROM_APP_MANIFEST"
    | "LAST_COLLECTED"
    | "LAST_DEPLOYED"
    | "PIPELINE_SOURCE"
    | "WEBHOOK_VARIABLE";
  appManifestId?: string;
  versionRegex?: string;
  pipelineId?: string;
  pipelineName?: string;
  workflowId?: string;
  workflowName?: string;
  appManifestName?: string;
}

export type ManifestTriggerCondition = TriggerCondition & {
  appManifestId?: string;
  serviceId?: string;
  serviceName?: string;
  versionRegex?: string;
  appManifestName?: string;
};

export type NewInstanceTriggerCondition = TriggerCondition & {};

export type PipelineTriggerCondition = TriggerCondition & {
  pipelineId?: string;
  pipelineName?: string;
};

export interface RestResponseTrigger {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Trigger;
  responseMessages?: ResponseMessage[];
}

export type ScheduledTriggerCondition = TriggerCondition & {
  cronExpression?: string;
  cronDescription?: string;
  onNewArtifactOnly?: boolean;
};

export interface ServiceInfraWorkflow {
  infraMappingId?: string;
  infraMappingName?: string;
  workflowId?: string;
  workflowName?: string;
  workflowType?: "PIPELINE" | "ORCHESTRATION";
}

export interface Trigger {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  accountId?: string;
  description?: string;
  condition: TriggerCondition;
  pipelineId?: string;
  pipelineName?: string;
  workflowId?: string;
  workflowName?: string;
  artifactSelections?: ArtifactSelection[];
  manifestSelections?: ManifestSelection[];
  workflowType?: "PIPELINE" | "ORCHESTRATION";
  workflowVariables?: {
    [key: string]: string;
  };
  continueWithDefaultValues?: boolean;
  serviceInfraWorkflows?: ServiceInfraWorkflow[];
  excludeHostsWithSameArtifact?: boolean;
  tagLinks?: HarnessTagLink[];
  disabled?: boolean;
  nextIterations?: number[];
}

export interface TriggerCondition {
  conditionType:
    | "NEW_ARTIFACT"
    | "PIPELINE_COMPLETION"
    | "SCHEDULED"
    | "WEBHOOK"
    | "NEW_INSTANCE"
    | "NEW_MANIFEST";
  conditionDisplayName?: string;
}

export interface WebHookToken {
  webHookToken?: string;
  httpMethod?: string;
  payload?: string;
}

export type WebHookTriggerCondition = TriggerCondition & {
  webHookToken?: WebHookToken;
  artifactStreamId?: string;
  parameters?: {
    [key: string]: string;
  };
  webhookSource?: "GITHUB" | "GITLAB" | "BITBUCKET" | "AZURE_DEVOPS";
  eventTypes?: (
    | "PULL_REQUEST"
    | "PUSH"
    | "REPO"
    | "ISSUE"
    | "PING"
    | "DELETE"
    | "ANY"
    | "OTHER"
    | "RELEASE"
    | "PACKAGE"
  )[];
  actions?: (
    | "CLOSED"
    | "EDITED"
    | "OPENED"
    | "REOPENED"
    | "ASSIGNED"
    | "UNASSIGNED"
    | "LABELED"
    | "UNLABELED"
    | "SYNCHRONIZED"
    | "REVIEW_REQUESTED"
    | "REVIEW_REQUESTED_REMOVED"
    | "PACKAGE_PUBLISHED"
  )[];
  releaseActions?: (
    | "CREATED"
    | "PUBLISHED"
    | "RELEASED"
    | "UNPUBLISHED"
    | "EDITED"
    | "DELETED"
    | "PRE_RELEASED"
  )[];
  bitBucketEvents?: (
    | "PING"
    | "DIAGNOSTICS_PING"
    | "ALL"
    | "PUSH_ANY"
    | "ANY"
    | "FORK"
    | "UPDATED"
    | "COMMIT_COMMENT_CREATED"
    | "BUILD_STATUS_CREATED"
    | "BUILD_STATUS_UPDATED"
    | "PUSH"
    | "REFS_CHANGED"
    | "ISSUE_ANY"
    | "ISSUE_CREATED"
    | "ISSUE_UPDATED"
    | "ISSUE_COMMENT_CREATED"
    | "PULL_REQUEST_ANY"
    | "PULL_REQUEST_CREATED"
    | "PULL_REQUEST_UPDATED"
    | "PULL_REQUEST_APPROVED"
    | "PULL_REQUEST_APPROVAL_REMOVED"
    | "PULL_REQUEST_MERGED"
    | "PULL_REQUEST_DECLINED"
    | "PULL_REQUEST_COMMENT_CREATED"
    | "PULL_REQUEST_COMMENT_UPDATED"
    | "PULL_REQUEST_COMMENT_DELETED"
  )[];
  filePaths?: string[];
  gitConnectorId?: string;
  repoName?: string;
  branchName?: string;
  branchRegex?: string;
  checkFileContentChanged?: boolean;
  webHookSecret?: string;
};

export interface RestResponsePageResponseTrigger {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Trigger[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseWebHookToken {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WebHookToken;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseWebhookParameters {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WebhookParameters;
  responseMessages?: ResponseMessage[];
}

export interface WebhookParameters {
  params?: string[];
  expressions?: string[];
}

export interface RestResponseWebhookEventType {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?:
    | "PULL_REQUEST"
    | "PUSH"
    | "REPO"
    | "ISSUE"
    | "PING"
    | "DELETE"
    | "ANY"
    | "OTHER"
    | "RELEASE"
    | "PACKAGE";
  responseMessages?: ResponseMessage[];
}

export interface RestResponseUsageRestrictions {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UsageRestrictions;
  responseMessages?: ResponseMessage[];
}

export interface AppRestrictionsSummary {
  application?: EntityReference;
  hasAllProdEnvAccess?: boolean;
  hasAllNonProdEnvAccess?: boolean;
  environments?: EntityReference[];
}

export interface EntityReference {
  id?: string;
  name?: string;
  appId?: string;
  entityType?: string;
}

export interface RestResponseRestrictionsSummary {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: RestrictionsSummary;
  responseMessages?: ResponseMessage[];
}

export interface RestrictionsSummary {
  hasAllAppAccess?: boolean;
  hasAllProdEnvAccess?: boolean;
  hasAllNonProdEnvAccess?: boolean;
  applications?: AppRestrictionsSummary[];
}

export interface IdNameReference {
  id?: string;
  name?: string;
}

export interface RestResponseUsageRestrictionsReferenceSummary {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UsageRestrictionsReferenceSummary;
  responseMessages?: ResponseMessage[];
}

export interface UsageRestrictionsReferenceSummary {
  total?: number;
  numOfSettings?: number;
  numOfSecrets?: number;
  settings?: IdNameReference[];
  secrets?: IdNameReference[];
}

export interface RestResponseUserGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserGroup;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseUserGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserGroup[];
  responseMessages?: ResponseMessage[];
}

export interface LdapLinkGroupRequest {
  ldapGroupDN?: string;
  ldapGroupName?: string;
}

export interface SamlLinkGroupRequest {
  samlGroupName?: string;
}

export interface PublicUser {
  user?: User;
  inviteAccepted?: boolean;
}

export interface RestResponsePageResponsePublicUser {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PublicUser[];
  responseMessages?: ResponseMessage[];
}

export interface AccountPermissionSummary {
  permissions?: (
    | "ACCOUNT"
    | "LOGGED_IN"
    | "DELEGATE"
    | "NONE"
    | "APP"
    | "ALL_APP_ENTITIES"
    | "ENV"
    | "SERVICE"
    | "WORKFLOW"
    | "PIPELINE"
    | "DEPLOYMENT"
    | "APPLICATION_CREATE_DELETE"
    | "USER_PERMISSION_MANAGEMENT"
    | "ACCOUNT_MANAGEMENT"
    | "PROVISIONER"
    | "TEMPLATE_MANAGEMENT"
    | "USER_PERMISSION_READ"
    | "AUDIT_VIEWER"
    | "TAG_MANAGEMENT"
    | "CE_ADMIN"
    | "CE_VIEWER"
    | "MANAGE_CLOUD_PROVIDERS"
    | "MANAGE_CONNECTORS"
    | "MANAGE_APPLICATIONS"
    | "MANAGE_APPLICATION_STACKS"
    | "MANAGE_DELEGATES"
    | "MANAGE_ALERT_NOTIFICATION_RULES"
    | "MANAGE_DELEGATE_PROFILES"
    | "MANAGE_CONFIG_AS_CODE"
    | "MANAGE_SECRETS"
    | "MANAGE_SECRET_MANAGERS"
    | "MANAGE_SSH_AND_WINRM"
    | "MANAGE_AUTHENTICATION_SETTINGS"
    | "MANAGE_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "VIEW_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "MANAGE_IP_WHITELIST"
    | "MANAGE_IP_WHITELISTING"
    | "MANAGE_DEPLOYMENT_FREEZES"
    | "MANAGE_PIPELINE_GOVERNANCE_STANDARDS"
    | "MANAGE_API_KEYS"
    | "MANAGE_TAGS"
    | "MANAGE_CUSTOM_DASHBOARDS"
    | "CREATE_CUSTOM_DASHBOARDS"
    | "MANAGE_RESTRICTED_ACCESS"
  )[];
}

export interface AppPermissionSummaryForUI {
  canCreateService?: boolean;
  canCreateProvisioner?: boolean;
  canCreateEnvironment?: boolean;
  canCreateWorkflow?: boolean;
  canCreatePipeline?: boolean;
  servicePermissions?: {
    [key: string]: (
      | "ALL"
      | "CREATE"
      | "READ"
      | "UPDATE"
      | "DELETE"
      | "EXECUTE"
      | "EXECUTE_WORKFLOW"
      | "EXECUTE_PIPELINE"
      | "EXECUTE_WORKFLOW_ROLLBACK"
      | "DEFAULT"
    )[];
  };
  provisionerPermissions?: {
    [key: string]: (
      | "ALL"
      | "CREATE"
      | "READ"
      | "UPDATE"
      | "DELETE"
      | "EXECUTE"
      | "EXECUTE_WORKFLOW"
      | "EXECUTE_PIPELINE"
      | "EXECUTE_WORKFLOW_ROLLBACK"
      | "DEFAULT"
    )[];
  };
  envPermissions?: {
    [key: string]: (
      | "ALL"
      | "CREATE"
      | "READ"
      | "UPDATE"
      | "DELETE"
      | "EXECUTE"
      | "EXECUTE_WORKFLOW"
      | "EXECUTE_PIPELINE"
      | "EXECUTE_WORKFLOW_ROLLBACK"
      | "DEFAULT"
    )[];
  };
  workflowPermissions?: {
    [key: string]: (
      | "ALL"
      | "CREATE"
      | "READ"
      | "UPDATE"
      | "DELETE"
      | "EXECUTE"
      | "EXECUTE_WORKFLOW"
      | "EXECUTE_PIPELINE"
      | "EXECUTE_WORKFLOW_ROLLBACK"
      | "DEFAULT"
    )[];
  };
  deploymentPermissions?: {
    [key: string]: (
      | "ALL"
      | "CREATE"
      | "READ"
      | "UPDATE"
      | "DELETE"
      | "EXECUTE"
      | "EXECUTE_WORKFLOW"
      | "EXECUTE_PIPELINE"
      | "EXECUTE_WORKFLOW_ROLLBACK"
      | "DEFAULT"
    )[];
  };
  pipelinePermissions?: {
    [key: string]: (
      | "ALL"
      | "CREATE"
      | "READ"
      | "UPDATE"
      | "DELETE"
      | "EXECUTE"
      | "EXECUTE_WORKFLOW"
      | "EXECUTE_PIPELINE"
      | "EXECUTE_WORKFLOW_ROLLBACK"
      | "DEFAULT"
    )[];
  };
}

export interface RestResponseUserPermissionInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserPermissionInfo;
  responseMessages?: ResponseMessage[];
}

export interface UserPermissionInfo {
  accountId?: string;
  accountPermissionSummary?: AccountPermissionSummary;
  appPermissionMap?: {
    [key: string]: AppPermissionSummaryForUI;
  };
  hasAllAppAccess?: boolean;
  dashboardPermissions?: {
    [key: string]: ("READ" | "UPDATE" | "DELETE" | "MANAGE")[];
  };
}

export interface RestResponseCollectionFeatureFlag {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: FeatureFlag[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseTwoFactorAuthenticationSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TwoFactorAuthenticationSettings;
  responseMessages?: ResponseMessage[];
}

export interface TwoFactorAuthenticationSettings {
  userId?: string;
  email?: string;
  twoFactorAuthenticationEnabled?: boolean;
  mechanism?: "TOTP";
  totpSecretKey?: string;
  totpqrurl?: string;
}

export interface RestResponseTwoFactorAdminOverrideSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TwoFactorAdminOverrideSettings;
  responseMessages?: ResponseMessage[];
}

export interface TwoFactorAdminOverrideSettings {
  adminOverrideTwoFactorEnabled?: boolean;
}

export interface AccountJoinRequest {
  name?: string;
  email?: string;
  companyName?: string;
  note?: string;
  accountAdminEmail?: string;
}

export interface ResetPasswordRequest {
  email?: string;
  isNG?: boolean;
}

export interface ResendInvitationEmailRequest {
  email?: string;
}

export interface UpdatePasswordRequest {
  password?: string;
}

export interface AccountRole {
  accountId?: string;
  accountName?: string;
  allApps?: boolean;
  applicationRoles?: ApplicationRole[];
  resourceAccess?: ImmutablePairResourceTypeAction[];
}

export interface ApplicationRole {
  appId?: string;
  appName?: string;
  allEnvironments?: boolean;
  environmentRoles?: EnvironmentRole[];
  resourceAccess?: ImmutablePairResourceTypeAction[];
}

export interface EnvironmentRole {
  envId?: string;
  envName?: string;
  environmentType?: "PROD" | "NON_PROD" | "ALL";
  resourceAccess?: {
    [key: string]:
      | "ALL"
      | "CREATE"
      | "READ"
      | "UPDATE"
      | "DELETE"
      | "EXECUTE"
      | "EXECUTE_WORKFLOW"
      | "EXECUTE_PIPELINE"
      | "EXECUTE_WORKFLOW_ROLLBACK"
      | "DEFAULT";
  };
}

export interface ImmutablePair {
  left?: { [key: string]: any };
  right?: { [key: string]: any };
  value?: { [key: string]: any };
  key?: { [key: string]: any };
}

export interface ImmutablePairResourceTypeAction {
  left?:
    | "APPLICATION"
    | "SERVICE"
    | "CONFIGURATION"
    | "CONFIGURATION_OVERRIDE"
    | "WORKFLOW"
    | "ENVIRONMENT"
    | "ROLE"
    | "DEPLOYMENT"
    | "ARTIFACT"
    | "CLOUD"
    | "USER"
    | "CD"
    | "PIPELINE"
    | "SETTING"
    | "LIMIT"
    | "APP_STACK"
    | "NOTIFICATION_GROUP"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "WHITE_LIST"
    | "SSO"
    | "API_KEY"
    | "PROVISIONER"
    | "PREFERENCE"
    | "TEMPLATE"
    | "CUSTOM_DASHBOARD"
    | "BUDGET"
    | "GCP_RESOURCE"
    | "CLUSTERRECORD"
    | "K8S_LABEL"
    | "K8S_EVENT_YAML_DIFF"
    | "K8S_RECOMMENDATION"
    | "CE_ANOMALIES"
    | "CE_CLUSTER"
    | "CE_CONNECTOR"
    | "CE_BATCH"
    | "LINKED_ACCOUNT";
  right?:
    | "ALL"
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "EXECUTE"
    | "EXECUTE_WORKFLOW"
    | "EXECUTE_PIPELINE"
    | "EXECUTE_WORKFLOW_ROLLBACK"
    | "DEFAULT";
  value?:
    | "ALL"
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "EXECUTE"
    | "EXECUTE_WORKFLOW"
    | "EXECUTE_PIPELINE"
    | "EXECUTE_WORKFLOW_ROLLBACK"
    | "DEFAULT";
  key?:
    | "APPLICATION"
    | "SERVICE"
    | "CONFIGURATION"
    | "CONFIGURATION_OVERRIDE"
    | "WORKFLOW"
    | "ENVIRONMENT"
    | "ROLE"
    | "DEPLOYMENT"
    | "ARTIFACT"
    | "CLOUD"
    | "USER"
    | "CD"
    | "PIPELINE"
    | "SETTING"
    | "LIMIT"
    | "APP_STACK"
    | "NOTIFICATION_GROUP"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "WHITE_LIST"
    | "SSO"
    | "API_KEY"
    | "PROVISIONER"
    | "PREFERENCE"
    | "TEMPLATE"
    | "CUSTOM_DASHBOARD"
    | "BUDGET"
    | "GCP_RESOURCE"
    | "CLUSTERRECORD"
    | "K8S_LABEL"
    | "K8S_EVENT_YAML_DIFF"
    | "K8S_RECOMMENDATION"
    | "CE_ANOMALIES"
    | "CE_CLUSTER"
    | "CE_CONNECTOR"
    | "CE_BATCH"
    | "LINKED_ACCOUNT";
}

export interface RestResponseAccountRole {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AccountRole;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseApplicationRole {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ApplicationRole;
  responseMessages?: ResponseMessage[];
}

export interface SwitchAccountRequest {
  accountId?: string;
}

export interface LoginRequest {
  authorization?: string;
}

export interface LoginTypeRequest {
  userName?: string;
}

export interface SsoRedirectRequest {
  jwtToken?: string;
}

export interface RestResponsePageResponseUserInvite {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserInvite[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseUserInvite {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserInvite;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListInviteOperationResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: (
    | "ACCOUNT_INVITE_ACCEPTED_NEED_PASSWORD"
    | "ACCOUNT_INVITE_ACCEPTED"
    | "USER_INVITED_SUCCESSFULLY"
    | "USER_ALREADY_ADDED"
    | "USER_ALREADY_INVITED"
    | "FAIL"
    | "INVITE_EXPIRED"
    | "INVITE_INVALID"
  )[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseInviteOperationResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?:
    | "ACCOUNT_INVITE_ACCEPTED_NEED_PASSWORD"
    | "ACCOUNT_INVITE_ACCEPTED"
    | "USER_INVITED_SUCCESSFULLY"
    | "USER_ALREADY_ADDED"
    | "USER_ALREADY_INVITED"
    | "FAIL"
    | "INVITE_EXPIRED"
    | "INVITE_INVALID";
  responseMessages?: ResponseMessage[];
}

export interface UserInviteDTO {
  email: string;
  token: string;
  accountId: string;
  name: string;
  password?: string;
}

export interface RestResponseZendeskSsoLoginResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ZendeskSsoLoginResponse;
  responseMessages?: ResponseMessage[];
}

export interface ZendeskSsoLoginResponse {
  redirectUrl?: string;
  userId?: string;
}

export interface RestResponseVersionPackage {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: VersionPackage;
  responseMessages?: ResponseMessage[];
}

export interface RuntimeInfo {
  primary?: boolean;
  primaryVersion?: string;
  deployMode?: string;
}

export interface VersionInfo {
  version?: string;
  buildNo?: string;
  gitCommit?: string;
  gitBranch?: string;
  timestamp?: string;
  patch?: string;
}

export interface VersionPackage {
  versionInfo?: VersionInfo;
  runtimeInfo?: RuntimeInfo;
}

export interface EntityVersionCollection {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  entityType?:
    | "SERVICE"
    | "PROVISIONER"
    | "ENVIRONMENT"
    | "HOST"
    | "RELEASE"
    | "ARTIFACT"
    | "SSH_USER"
    | "SSH_PASSWORD"
    | "SSH_APP_ACCOUNT"
    | "SSH_KEY_PASSPHRASE"
    | "SSH_APP_ACCOUNT_PASSOWRD"
    | "SIMPLE_DEPLOYMENT"
    | "ORCHESTRATED_DEPLOYMENT"
    | "PIPELINE"
    | "WORKFLOW"
    | "DEPLOYMENT"
    | "INSTANCE"
    | "APPLICATION"
    | "COMMAND"
    | "CONFIG"
    | "SERVICE_TEMPLATE"
    | "INFRASTRUCTURE_MAPPING"
    | "INFRASTRUCTURE_DEFINITION"
    | "USER"
    | "ARTIFACT_STREAM"
    | "APPDYNAMICS_CONFIGID"
    | "APPDYNAMICS_APPID"
    | "APPDYNAMICS_TIERID"
    | "ELK_CONFIGID"
    | "ELK_INDICES"
    | "NEWRELIC_CONFIGID"
    | "NEWRELIC_APPID"
    | "SS_SSH_CONNECTION_ATTRIBUTE"
    | "SS_WINRM_CONNECTION_ATTRIBUTE"
    | "SUMOLOGIC_CONFIGID"
    | "SPLUNK_CONFIGID"
    | "NEWRELIC_MARKER_CONFIGID"
    | "NEWRELIC_MARKER_APPID"
    | "API_KEY"
    | "ACCOUNT"
    | "APPLICATION_MANIFEST"
    | "USER_GROUP"
    | "WHITELISTED_IP"
    | "CF_AWS_CONFIG_ID"
    | "VERIFICATION_CONFIGURATION"
    | "HELM_GIT_CONFIG_ID"
    | "NOTIFICATION_GROUP"
    | "HELM_CHART_SPECIFICATION"
    | "PCF_SERVICE_SPECIFICATION"
    | "LAMBDA_SPECIFICATION"
    | "USER_DATA_SPECIFICATION"
    | "ECS_CONTAINER_SPECIFICATION"
    | "ECS_SERVICE_SPECIFICATION"
    | "K8S_CONTAINER_SPECIFICATION"
    | "CONFIG_FILE"
    | "SERVICE_COMMAND"
    | "MANIFEST_FILE"
    | "SERVICE_VARIABLE"
    | "TRIGGER"
    | "ROLE"
    | "TEMPLATE"
    | "TEMPLATE_FOLDER"
    | "SETTING_ATTRIBUTE"
    | "ENCRYPTED_RECORDS"
    | "CV_CONFIGURATION"
    | "TAG"
    | "CUSTOM_DASHBOARD"
    | "PIPELINE_GOVERNANCE_STANDARD"
    | "WORKFLOW_EXECUTION"
    | "SERVERLESS_INSTANCE"
    | "USER_INVITE"
    | "LOGIN_SETTINGS"
    | "SSO_SETTINGS"
    | "DELEGATE"
    | "DELEGATE_SCOPE"
    | "DELEGATE_PROFILE"
    | "EXPORT_EXECUTIONS_REQUEST"
    | "GCP_CONFIG"
    | "GIT_CONFIG"
    | "JENKINS_SERVER"
    | "SECRETS_MANAGER"
    | "HELM_CHART"
    | "SECRET"
    | "CONNECTOR"
    | "CLOUD_PROVIDER"
    | "GOVERNANCE_FREEZE_CONFIG"
    | "GOVERNANCE_CONFIG";
  entityName?: string;
  changeType?: "CREATED" | "UPDATED";
  entityUuid?: string;
  entityParentUuid?: string;
  entityData?: string;
  version?: number;
  accountId?: string;
}

export interface RestResponsePageResponseEntityVersionCollection {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: EntityVersionCollection[];
  responseMessages?: ResponseMessage[];
}

export interface WebHookRequest {
  application?: string;
  artifacts?: {
    [key: string]: { [key: string]: any };
  }[];
  manifests?: {
    [key: string]: { [key: string]: any };
  }[];
  parameters?: {
    [key: string]: string;
  };
}

export interface RestResponseWhitelist {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Whitelist;
  responseMessages?: ResponseMessage[];
}

export interface Whitelist {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  description?: string;
  status?: "ACTIVE" | "DISABLED";
  filter?: string;
}

export interface RestResponsePageResponseWhitelist {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Whitelist[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseWorkflowPhase {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowPhase;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseWorkflow {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Workflow;
  responseMessages?: ResponseMessage[];
}

export interface WorkflowVersion {
  defaultVersion?: number;
}

export interface RestResponsePageResponseWorkflow {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Workflow[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseTrafficShiftMetadata {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TrafficShiftMetadata;
  responseMessages?: ResponseMessage[];
}

export interface TrafficShiftMetadata {
  phaseIdsWithTrafficShift?: string[];
}

export interface RestResponsePhaseStep {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PhaseStep;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListNotificationRule {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: NotificationRule[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListInstanceElement {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: InstanceElement[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseConcurrencyStrategy {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ConcurrencyStrategy;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListVariable {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Variable[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseWorkflowCategorySteps {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: WorkflowCategorySteps;
  responseMessages?: ResponseMessage[];
}

export interface WorkflowCategorySteps {
  steps?: {
    [key: string]: WorkflowStepMeta;
  };
  categories?: WorkflowCategoryStepsMeta[];
}

export interface WorkflowCategoryStepsMeta {
  id?: string;
  name?: string;
  stepIds?: string[];
}

export interface WorkflowStepMeta {
  name?: string;
  favorite?: boolean;
  available?: boolean;
}

export interface Alert {
  uuid: string;
  appId: string;
  createdAt?: number;
  lastUpdatedAt: number;
  accountId?: string;
  type?:
    | "ApprovalNeeded"
    | "ManualInterventionNeeded"
    | "NoActiveDelegates"
    | "NoInstalledDelegates"
    | "DelegatesDown"
    | "DelegatesScalingGroupDownAlert"
    | "DelegateProfileError"
    | "NoEligibleDelegates"
    | "PerpetualTaskAlert"
    | "InvalidKMS"
    | "GitSyncError"
    | "GitConnectionError"
    | "INVALID_SMTP_CONFIGURATION"
    | "EMAIL_NOT_SENT_ALERT"
    | "USERGROUP_SYNC_FAILED"
    | "USAGE_LIMIT_EXCEEDED"
    | "INSTANCE_USAGE_APPROACHING_LIMIT"
    | "RESOURCE_USAGE_APPROACHING_LIMIT"
    | "DEPLOYMENT_RATE_APPROACHING_LIMIT"
    | "SETTING_ATTRIBUTE_VALIDATION_FAILED"
    | "ARTIFACT_COLLECTION_FAILED"
    | "CONTINUOUS_VERIFICATION_ALERT"
    | "CONTINUOUS_VERIFICATION_DATA_COLLECTION_ALERT"
    | "MANIFEST_COLLECTION_FAILED"
    | "DEPLOYMENT_FREEZE_EVENT";
  status?: "Open" | "Closed" | "Pending";
  title?: string;
  resolutionTitle?: string;
  category?:
    | "All"
    | "Setup"
    | "Approval"
    | "ManualIntervention"
    | "ContinuousVerification";
  severity?: "Warning" | "Error";
  alertData?: AlertData;
  alertReconciliation?: AlertReconciliation;
  closedAt?: number;
  triggerCount?: number;
  lastTriggeredAt?: number;
  validUntil?: string;
  cvCleanUpIteration?: number;
}

export interface AlertData {
  [key: string]: any;
}

export interface AlertReconciliation {
  needed?: boolean;
  nextIteration?: number;
}

export interface RestResponsePageResponseAlert {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Alert[];
  responseMessages?: ResponseMessage[];
}

export interface AlertType {
  alertType?:
    | "ApprovalNeeded"
    | "ManualInterventionNeeded"
    | "NoActiveDelegates"
    | "NoInstalledDelegates"
    | "DelegatesDown"
    | "DelegatesScalingGroupDownAlert"
    | "DelegateProfileError"
    | "NoEligibleDelegates"
    | "PerpetualTaskAlert"
    | "InvalidKMS"
    | "GitSyncError"
    | "GitConnectionError"
    | "INVALID_SMTP_CONFIGURATION"
    | "EMAIL_NOT_SENT_ALERT"
    | "USERGROUP_SYNC_FAILED"
    | "USAGE_LIMIT_EXCEEDED"
    | "INSTANCE_USAGE_APPROACHING_LIMIT"
    | "RESOURCE_USAGE_APPROACHING_LIMIT"
    | "DEPLOYMENT_RATE_APPROACHING_LIMIT"
    | "SETTING_ATTRIBUTE_VALIDATION_FAILED"
    | "ARTIFACT_COLLECTION_FAILED"
    | "CONTINUOUS_VERIFICATION_ALERT"
    | "CONTINUOUS_VERIFICATION_DATA_COLLECTION_ALERT"
    | "MANIFEST_COLLECTION_FAILED"
    | "DEPLOYMENT_FREEZE_EVENT";
  category?:
    | "All"
    | "Setup"
    | "Approval"
    | "ManualIntervention"
    | "ContinuousVerification";
  severity?: "Warning" | "Error";
}

export interface RestResponseListAlertType {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AlertType[];
  responseMessages?: ResponseMessage[];
}

export interface AlertRiskDetail {
  metricName?: string;
  txnName?: string;
}

export interface ContinuousVerificationAlertData {
  cvConfiguration?: CVConfiguration;
  mlAnalysisType?:
    | "TIME_SERIES"
    | "LOG_CLUSTER"
    | "LOG_ML"
    | "FEEDBACK_ANALYSIS";
  alertStatus?: "Open" | "Closed" | "Pending";
  logAnomaly?: string;
  tag?: string;
  hosts?: string[];
  portalUrl?: string;
  accountId?: string;
  highRiskTxns?: AlertRiskDetail[];
  riskScore?: number;
  analysisStartTime?: number;
  analysisEndTime?: number;
}

export interface Response {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: { [key: string]: any };
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseBoolean {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: boolean;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseString {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: string;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface K8sEventCollectionBundle {
  connectorIdentifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  clusterId?: string;
  cloudProviderId?: string;
  clusterName?: string;
}

export interface PerpetualTaskClientContext {
  clientId?: string;
  clientParams?: {
    [key: string]: string;
  };
  executionBundle?: string[];
  lastContextUpdated?: number;
}

export interface PerpetualTaskRecord {
  uuid?: string;
  accountId?: string;
  perpetualTaskType?: string;
  clientContext?: PerpetualTaskClientContext;
  intervalSeconds?: number;
  timeoutMillis?: number;
  delegateId?: string;
  taskDescription?: string;
  state?:
    | "TASK_UNASSIGNED"
    | "TASK_TO_REBALANCE"
    | "TASK_PAUSED"
    | "TASK_ASSIGNED"
    | "NO_DELEGATE_INSTALLED"
    | "NO_DELEGATE_AVAILABLE"
    | "NO_ELIGIBLE_DELEGATES"
    | "TASK_RUN_SUCCEEDED"
    | "TASK_RUN_FAILED";
  unassignedReason?:
    | "NO_DELEGATE_INSTALLED"
    | "NO_DELEGATE_AVAILABLE"
    | "NO_ELIGIBLE_DELEGATES";
  lastHeartbeat?: number;
  assignerIterations?: number[];
  rebalanceIteration?: number;
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface ResponsePerpetualTaskRecord {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PerpetualTaskRecord;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface CommandLibraryConfigurationDTO {
  supportedCommandStoreNameList?: string[];
  clImplementationVersion?: number;
}

export interface RestResponseCommandLibraryConfigurationDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CommandLibraryConfigurationDTO;
  responseMessages?: ResponseMessage[];
}

export interface ArtifactSource {
  [key: string]: any;
}

export type ArtifactSourceTemplate = BaseTemplate & {
  artifactSource?: ArtifactSource;
};

export interface AttributeMapping {
  relativePath?: string;
  mappedAttribute?: string;
}

export interface BaseTemplate {
  [key: string]: any;
}

export type CommandRefYaml = Yaml & {
  variables?: TemplateVariableYaml[];
  templateUri?: string;
};

export type CustomArtifactSourceTemplate = ArtifactSource & {
  script?: string;
  timeoutSeconds?: string;
  customRepositoryMapping?: CustomRepositoryMapping;
};

export type CustomDeploymentTypeTemplate = BaseTemplate & {
  fetchInstanceScript?: string;
  hostObjectArrayPath?: string;
  hostAttributes?: {
    [key: string]: string;
  };
};

export interface CustomRepositoryMapping {
  artifactRoot?: string;
  buildNoPath?: string;
  artifactAttributes?: AttributeMapping[];
}

export type HttpTemplate = BaseTemplate & {
  url?: string;
  method?: string;
  header?: string;
  headers?: KeyValuePair[];
  body?: string;
  assertion?: string;
  executeWithPreviousSteps?: boolean;
  timeoutMillis?: number;
};

export interface ImportedCommand {
  commandStoreName?: string;
  commandName?: string;
  templateId?: string;
  name?: string;
  appId?: string;
  description?: string;
  createdAt?: string;
  createdBy?: string;
  repoUrl?: string;
  tags?: string[];
  importedCommandVersionList?: ImportedCommandVersion[];
  highestVersion?: string;
}

export interface ImportedCommandVersion {
  commandStoreName?: string;
  commandName?: string;
  commandDisplayName?: string;
  templateId?: string;
  version?: string;
  description?: string;
  yamlContent?: string;
  templateObject?: BaseTemplate;
  variables?: Variable[];
  createdAt?: string;
  createdBy?: string;
}

export type PcfCommandTemplate = BaseTemplate & {
  scriptString?: string;
  timeoutIntervalInMinutes?: number;
};

export interface ReferencedTemplate {
  templateReference?: TemplateReference;
  variableMapping?: {
    [key: string]: Variable;
  };
}

export interface RestResponseListImportedCommand {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ImportedCommand[];
  responseMessages?: ResponseMessage[];
}

export type ShellScriptTemplate = BaseTemplate & {
  scriptType?: string;
  scriptString?: string;
  outputVars?: string;
  secretOutputVars?: string;
  timeoutMillis?: number;
};

export type SshCommandTemplate = BaseTemplate & {
  commandType?:
    | "START"
    | "STOP"
    | "INSTALL"
    | "ENABLE"
    | "DISABLE"
    | "VERIFY"
    | "OTHER"
    | "RESIZE"
    | "SETUP";
  commands?: Yaml[];
  commandUnits?: CommandUnit[];
  referencedTemplateList?: ReferencedTemplate[];
};

export interface TemplateVariableYaml {
  name?: string;
  description?: string;
  value?: string;
}

export interface Yaml {
  name?: string;
  commandUnitType?: string;
  deploymentType?: string;
}

export interface RestResponseImportedCommand {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ImportedCommand;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseTemplate {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Template;
  responseMessages?: ResponseMessage[];
}

export interface Template {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name: string;
  accountId?: string;
  type?: string;
  folderId?: string;
  version?: number;
  versionDetails?: string;
  description?: string;
  folderPathId?: string;
  folderPath?: string;
  gallery?: string;
  templateObject: BaseTemplate;
  variables?: Variable[];
  versionedTemplate?: VersionedTemplate;
  galleryId?: string;
  referencedTemplateId?: string;
  referencedTemplateVersion?: number;
  importedTemplateDetails?: ImportedTemplateDetails;
  templateMetadata?: TemplateMetadata;
  referencedTemplateUri?: string;
  keywords?: string[];
}

export interface VersionedTemplate {
  templateId?: string;
  version?: number;
  importedTemplateVersion?: string;
  accountId?: string;
  galleryId?: string;
  templateObject: BaseTemplate;
  variables?: Variable[];
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface CustomDeploymentTypeDTO {
  uuid?: string;
  name?: string;
  infraVariables?: Variable[];
}

export interface RestResponseCustomDeploymentTypeDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CustomDeploymentTypeDTO;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListCustomDeploymentTypeDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CustomDeploymentTypeDTO[];
  responseMessages?: ResponseMessage[];
}

export type AppDynamicsConnectorDTO = ConnectorConfigDTO & {
  username?: string;
  accountname: string;
  controllerUrl: string;
  delegateSelectors?: string[];
  passwordRef?: string;
  clientSecretRef?: string;
  clientId?: string;
  authType?: "UsernamePassword" | "ApiClientToken";
};

export interface ArtifactoryAuthCredentials {
  [key: string]: any;
}

export interface ArtifactoryAuthentication {
  type: "UsernamePassword" | "Anonymous";
  spec?: ArtifactoryAuthCredentials;
}

export type ArtifactoryConnector = ConnectorConfigDTO & {
  artifactoryServerUrl: string;
  auth?: ArtifactoryAuthentication;
  delegateSelectors?: string[];
};

export type ArtifactoryUsernamePasswordAuth = ArtifactoryAuthCredentials & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export interface AwsCodeCommitAuthenticationDTO {
  type: "HTTPS";
  spec: AwsCodeCommitCredentialsDTO;
}

export type AwsCodeCommitConnectorDTO = ConnectorConfigDTO & {
  url: string;
  authentication: AwsCodeCommitAuthenticationDTO;
  delegateSelectors?: string[];
  type: "Repo" | "Region";
};

export interface AwsCodeCommitCredentialsDTO {
  [key: string]: any;
}

export type AwsCodeCommitHttpsCredentialsDTO = AwsCodeCommitCredentialsDTO & {
  type: "AWSCredentials";
  spec: AwsCodeCommitHttpsCredentialsSpecDTO;
};

export interface AwsCodeCommitHttpsCredentialsSpecDTO {
  [key: string]: any;
}

export type AwsCodeCommitSecretKeyAccessKeyDTO = AwsCodeCommitHttpsCredentialsSpecDTO & {
  accessKey?: string;
  accessKeyRef?: string;
  secretKeyRef: string;
};

export type AwsConnector = ConnectorConfigDTO & {
  credential: AwsCredential;
  delegateSelectors?: string[];
};

export interface AwsCredential {
  crossAccountAccess?: CrossAccountAccess;
  type: "InheritFromDelegate" | "ManualConfig";
  spec?: AwsCredentialSpec;
}

export interface AwsCredentialSpec {
  [key: string]: any;
}

export interface AwsCurAttributes {
  reportName: string;
  s3BucketName: string;
  region?: string;
  s3Prefix?: string;
}

export interface AwsKmsConnectorCredential {
  type: "AssumeIAMRole" | "AssumeSTSRole" | "ManualConfig";
  spec?: AwsKmsCredentialSpec;
}

export type AwsKmsConnectorDTO = ConnectorConfigDTO & {
  credential?: AwsKmsConnectorCredential;
  kmsArn: string;
  region?: string;
  delegateSelectors?: string[];
  default?: boolean;
};

export interface AwsKmsCredentialSpec {
  [key: string]: any;
}

export type AwsKmsCredentialSpecAssumeIAM = AwsKmsCredentialSpec & {
  delegateSelectors: string[];
};

export type AwsKmsCredentialSpecAssumeSTS = AwsKmsCredentialSpec & {
  delegateSelectors: string[];
  roleArn: string;
  externalName?: string;
  assumeStsRoleDuration?: number;
};

export type AwsKmsCredentialSpecManualConfig = AwsKmsCredentialSpec & {
  accessKey: string;
  secretKey: string;
};

export type AwsManualConfigSpec = AwsCredentialSpec & {
  accessKey?: string;
  accessKeyRef?: string;
  secretKeyRef: string;
};

export type AzureKeyVaultConnectorDTO = ConnectorConfigDTO & {
  clientId: string;
  secretKey: string;
  tenantId: string;
  vaultName: string;
  subscription: string;
  azureEnvironmentType?: "AZURE" | "AZURE_US_GOVERNMENT";
  delegateSelectors?: string[];
  default?: boolean;
};

export interface BillingExportSpec {
  storageAccountName: string;
  containerName: string;
  directoryName: string;
  reportName: string;
  subscriptionId: string;
}

export interface BitbucketApiAccess {
  type: "UsernameToken";
  spec: BitbucketApiAccessSpecDTO;
}

export interface BitbucketApiAccessSpecDTO {
  [key: string]: any;
}

export interface BitbucketAuthentication {
  type: "Http" | "Ssh";
  spec: BitbucketCredentialsDTO;
}

export type BitbucketConnector = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  authentication: BitbucketAuthentication;
  apiAccess?: BitbucketApiAccess;
  delegateSelectors?: string[];
  type: "Account" | "Repo";
};

export interface BitbucketCredentialsDTO {
  [key: string]: any;
}

export type BitbucketHttpCredentials = BitbucketCredentialsDTO & {
  type: "UsernamePassword";
  spec: BitbucketHttpCredentialsSpecDTO;
};

export interface BitbucketHttpCredentialsSpecDTO {
  [key: string]: any;
}

export type BitbucketSshCredentials = BitbucketCredentialsDTO & {
  sshKeyRef: string;
};

export type BitbucketUsernamePassword = BitbucketHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type BitbucketUsernameTokenApiAccess = BitbucketApiAccessSpecDTO & {
  username?: string;
  usernameRef?: string;
  tokenRef: string;
};

export type CEAwsConnector = ConnectorConfigDTO & {
  crossAccountAccess: CrossAccountAccess;
  curAttributes?: AwsCurAttributes;
  awsAccountId?: string;
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
};

export type CEAzureConnector = ConnectorConfigDTO & {
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
  tenantId: string;
  subscriptionId: string;
  billingExportSpec?: BillingExportSpec;
};

export type CEKubernetesClusterConfig = ConnectorConfigDTO & {
  connectorRef: string;
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
};

export interface ConnectorConfigDTO {
  [key: string]: any;
}

export interface ConnectorInfoDTO {
  name: string;
  identifier: string;
  description?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  tags?: {
    [key: string]: string;
  };
  type:
    | "K8sCluster"
    | "Git"
    | "Splunk"
    | "AppDynamics"
    | "Prometheus"
    | "Dynatrace"
    | "Vault"
    | "AzureKeyVault"
    | "DockerRegistry"
    | "Local"
    | "AwsKms"
    | "GcpKms"
    | "Gcp"
    | "Aws"
    | "Artifactory"
    | "Jira"
    | "Nexus"
    | "Github"
    | "Gitlab"
    | "Bitbucket"
    | "Codecommit"
    | "CEAws"
    | "CEAzure"
    | "GcpCloudCost"
    | "CEK8sCluster"
    | "HttpHelmRepo"
    | "NewRelic"
    | "Datadog"
    | "SumoLogic";
  spec: ConnectorConfigDTO;
}

export interface CrossAccountAccess {
  crossAccountRoleArn: string;
  externalId?: string;
}

export interface DataCollectionRequest {
  connectorInfoDTO?: ConnectorInfoDTO;
  tracingId?: string;
  type?:
    | "SPLUNK_SAVED_SEARCHES"
    | "STACKDRIVER_DASHBOARD_LIST"
    | "STACKDRIVER_DASHBOARD_GET"
    | "STACKDRIVER_SAMPLE_DATA"
    | "STACKDRIVER_LOG_SAMPLE_DATA"
    | "APPDYNAMICS_FETCH_APPS"
    | "APPDYNAMICS_FETCH_TIERS"
    | "APPDYNAMICS_GET_METRIC_DATA"
    | "NEWRELIC_APPS_REQUEST"
    | "NEWRELIC_VALIDATION_REQUEST"
    | "PROMETHEUS_METRIC_LIST_GET"
    | "PROMETHEUS_LABEL_NAMES_GET"
    | "PROMETHEUS_LABEL_VALUES_GET"
    | "PROMETHEUS_SAMPLE_DATA";
  dsl?: string;
  baseUrl?: string;
}

export type DatadogConnectorDTO = ConnectorConfigDTO & {
  url: string;
  applicationKeyRef: string;
  apiKeyRef: string;
  delegateSelectors?: string[];
};

export interface DockerAuthCredentialsDTO {
  [key: string]: any;
}

export interface DockerAuthenticationDTO {
  type: "UsernamePassword" | "Anonymous";
  spec?: DockerAuthCredentialsDTO;
}

export type DockerConnectorDTO = ConnectorConfigDTO & {
  dockerRegistryUrl: string;
  providerType: "DockerHub" | "Harbor" | "Quay" | "Other";
  auth?: DockerAuthenticationDTO;
  delegateSelectors?: string[];
};

export type DockerUserNamePasswordDTO = DockerAuthCredentialsDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type DynatraceConnectorDTO = ConnectorConfigDTO & {
  url: string;
  apiTokenRef: string;
  delegateSelectors?: string[];
};

export interface GcpBillingExportSpec {
  datasetId: string;
}

export type GcpCloudCostConnector = ConnectorConfigDTO & {
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
  projectId: string;
  billingExportSpec?: GcpBillingExportSpec;
};

export type GcpConnector = ConnectorConfigDTO & {
  credential?: GcpConnectorCredential;
  delegateSelectors?: string[];
};

export interface GcpConnectorCredential {
  type: "InheritFromDelegate" | "ManualConfig";
  spec?: GcpCredentialSpec;
}

export interface GcpCredentialSpec {
  [key: string]: any;
}

export type GcpKmsConnectorDTO = ConnectorConfigDTO & {
  projectId?: string;
  region?: string;
  keyRing?: string;
  keyName?: string;
  credentials: string;
  delegateSelectors?: string[];
  default?: boolean;
};

export type GcpManualDetails = GcpCredentialSpec & {
  secretKeyRef: string;
};

export interface GitAuthenticationDTO {
  [key: string]: any;
}

export type GitConfigDTO = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  branchName?: string;
  delegateSelectors?: string[];
  type: "Http" | "Ssh";
  connectionType: "Account" | "Repo";
  spec: GitAuthenticationDTO;
};

export type GitHTTPAuthenticationDTO = GitAuthenticationDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type GitSSHAuthenticationDTO = GitAuthenticationDTO & {
  sshKeyRef: string;
};

export interface GithubApiAccess {
  type: "GithubApp" | "Token";
  spec?: GithubApiAccessSpecDTO;
}

export interface GithubApiAccessSpecDTO {
  [key: string]: any;
}

export type GithubAppSpec = GithubApiAccessSpecDTO & {
  installationId: string;
  applicationId: string;
  privateKeyRef: string;
};

export interface GithubAuthentication {
  type: "Http" | "Ssh";
  spec: GithubCredentialsDTO;
}

export type GithubConnector = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  authentication: GithubAuthentication;
  apiAccess?: GithubApiAccess;
  delegateSelectors?: string[];
  type: "Account" | "Repo";
};

export interface GithubCredentialsDTO {
  [key: string]: any;
}

export type GithubHttpCredentials = GithubCredentialsDTO & {
  type: "UsernamePassword" | "UsernameToken";
  spec: GithubHttpCredentialsSpecDTO;
};

export interface GithubHttpCredentialsSpecDTO {
  [key: string]: any;
}

export type GithubSshCredentials = GithubCredentialsDTO & {
  sshKeyRef: string;
};

export type GithubTokenSpec = GithubApiAccessSpecDTO & {
  tokenRef: string;
};

export type GithubUsernamePassword = GithubHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type GithubUsernameToken = GithubHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  tokenRef: string;
};

export interface GitlabApiAccess {
  type: "Token";
  spec?: GitlabApiAccessSpecDTO;
}

export interface GitlabApiAccessSpecDTO {
  [key: string]: any;
}

export interface GitlabAuthentication {
  type: "Http" | "Ssh";
  spec: GitlabCredentialsDTO;
}

export type GitlabConnector = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  authentication: GitlabAuthentication;
  apiAccess?: GitlabApiAccess;
  delegateSelectors?: string[];
  type: "Account" | "Repo";
};

export interface GitlabCredentialsDTO {
  [key: string]: any;
}

export type GitlabHttpCredentials = GitlabCredentialsDTO & {
  type: "UsernamePassword" | "UsernameToken" | "Kerberos";
  spec: GitlabHttpCredentialsSpecDTO;
};

export interface GitlabHttpCredentialsSpecDTO {
  [key: string]: any;
}

export type GitlabKerberos = GitlabHttpCredentialsSpecDTO & {
  kerberosKeyRef: string;
};

export type GitlabSshCredentials = GitlabCredentialsDTO & {
  sshKeyRef: string;
};

export type GitlabTokenSpec = GitlabApiAccessSpecDTO & {
  tokenRef: string;
};

export type GitlabUsernamePassword = GitlabHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type GitlabUsernameToken = GitlabHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  tokenRef: string;
};

export interface HttpHelmAuthCredentialsDTO {
  [key: string]: any;
}

export interface HttpHelmAuthenticationDTO {
  type: "UsernamePassword" | "Anonymous";
  spec?: HttpHelmAuthCredentialsDTO;
}

export type HttpHelmConnectorDTO = ConnectorConfigDTO & {
  helmRepoUrl: string;
  auth?: HttpHelmAuthenticationDTO;
  delegateSelectors?: string[];
};

export type HttpHelmUsernamePasswordDTO = HttpHelmAuthCredentialsDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type JiraConnector = ConnectorConfigDTO & {
  jiraUrl: string;
  username?: string;
  usernameRef?: string;
  passwordRef: string;
  delegateSelectors?: string[];
};

export interface KubernetesAuthCredentialDTO {
  [key: string]: any;
}

export interface KubernetesAuthDTO {
  type:
    | "UsernamePassword"
    | "ClientKeyCert"
    | "ServiceAccount"
    | "OpenIdConnect";
  spec: KubernetesAuthCredentialDTO;
}

export type KubernetesClientKeyCertDTO = KubernetesAuthCredentialDTO & {
  caCertRef?: string;
  clientCertRef: string;
  clientKeyRef: string;
  clientKeyPassphraseRef?: string;
  clientKeyAlgo?: string;
};

export type KubernetesClusterConfigDTO = ConnectorConfigDTO & {
  credential: KubernetesCredentialDTO;
  delegateSelectors?: string[];
};

export type KubernetesClusterDetailsDTO = KubernetesCredentialSpecDTO & {
  masterUrl: string;
  auth: KubernetesAuthDTO;
};

export interface KubernetesCredentialDTO {
  type: "InheritFromDelegate" | "ManualConfig";
  spec?: KubernetesCredentialSpecDTO;
}

export interface KubernetesCredentialSpecDTO {
  [key: string]: any;
}

export type KubernetesOpenIdConnectDTO = KubernetesAuthCredentialDTO & {
  oidcIssuerUrl?: string;
  oidcUsername?: string;
  oidcUsernameRef?: string;
  oidcClientIdRef: string;
  oidcPasswordRef: string;
  oidcSecretRef?: string;
  oidcScopes?: string;
};

export type KubernetesServiceAccountDTO = KubernetesAuthCredentialDTO & {
  serviceAccountTokenRef: string;
};

export type KubernetesUserNamePasswordDTO = KubernetesAuthCredentialDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type LocalConnectorDTO = ConnectorConfigDTO & {
  default?: boolean;
};

export type NewRelicConnectorDTO = ConnectorConfigDTO & {
  newRelicAccountId: string;
  url: string;
  apiKeyRef: string;
  delegateSelectors?: string[];
};

export interface NexusAuthCredentials {
  [key: string]: any;
}

export interface NexusAuthentication {
  type: "UsernamePassword" | "Anonymous";
  spec?: NexusAuthCredentials;
}

export type NexusConnector = ConnectorConfigDTO & {
  nexusServerUrl: string;
  version: string;
  auth?: NexusAuthentication;
  delegateSelectors?: string[];
};

export type NexusUsernamePasswordAuth = NexusAuthCredentials & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type PrometheusConnectorDTO = ConnectorConfigDTO & {
  url: string;
  delegateSelectors?: string[];
};

export interface SplunkConnectorDTO {
  splunkUrl: string;
  username?: string;
  accountId: string;
  delegateSelectors?: string[];
  passwordRef: string;
}

export type SumoLogicConnectorDTO = ConnectorConfigDTO & {
  url: string;
  accessIdRef: string;
  accessKeyRef: string;
  delegateSelectors?: string[];
};

export type VaultConnectorDTO = ConnectorConfigDTO & {
  authToken?: string;
  basePath?: string;
  vaultUrl?: string;
  renewalIntervalMinutes?: number;
  secretEngineManuallyConfigured?: boolean;
  secretEngineName?: string;
  appRoleId?: string;
  secretId?: string;
  secretEngineVersion?: number;
  delegateSelectors?: string[];
  default?: boolean;
  readOnly?: boolean;
  accessType?: "APP_ROLE" | "TOKEN";
};

export interface DataCollectionConnectorBundle {
  connectorIdentifier?: string;
  sourceIdentifier?: string;
  dataCollectionWorkerId?: string;
  connectorDTO?: ConnectorInfoDTO;
  dataCollectionType?: "CV" | "KUBERNETES";
}

export interface CVNGPerpetualTaskDTO {
  delegateId?: string;
  accountId?: string;
  cvngPerpetualTaskUnassignedReason?:
    | "NO_DELEGATE_INSTALLED"
    | "NO_DELEGATE_AVAILABLE"
    | "NO_ELIGIBLE_DELEGATES";
  cvngPerpetualTaskState?:
    | "TASK_UNASSIGNED"
    | "TASK_TO_REBALANCE"
    | "TASK_PAUSED"
    | "TASK_ASSIGNED";
}

export interface RestResponseCVNGPerpetualTaskDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CVNGPerpetualTaskDTO;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListSplunkSavedSearch {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SplunkSavedSearch[];
  responseMessages?: ResponseMessage[];
}

export interface SplunkSavedSearch {
  title?: string;
  searchQuery?: string;
}

export interface Bar {
  timestamp?: number;
  count?: number;
}

export interface Histogram {
  query?: string;
  intervalMs?: number;
  bars?: Bar[];
  errorMessage?: string;
  splunkQuery?: string;
  count?: number;
}

export interface RestResponseSplunkValidationResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SplunkValidationResponse;
  responseMessages?: ResponseMessage[];
}

export interface SampleLog {
  raw?: string;
  timestamp?: number;
}

export interface SplunkSampleResponse {
  rawSampleLogs?: SampleLog[];
  sample?: {
    [key: string]: string;
  };
  splunkQuery?: string;
  errorMessage?: string;
}

export interface SplunkValidationResponse {
  histogram?: Histogram;
  samples?: SplunkSampleResponse;
  errorMessage?: string;
  queryDurationMillis?: number;
}

export interface DashboardAccessPermissions {
  userGroups?: string[];
  allowedActions?: ("READ" | "UPDATE" | "DELETE" | "MANAGE")[];
}

export interface DashboardSettings {
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedAt?: number;
  accountId?: string;
  data?: string;
  description?: string;
  name?: string;
  canUpdate?: boolean;
  canDelete?: boolean;
  canManage?: boolean;
  permissions?: DashboardAccessPermissions[];
  uuid: string;
  owner?: boolean;
  shared?: boolean;
}

export interface RestResponseDashboardSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DashboardSettings;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseDashboardSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DashboardSettings[];
  responseMessages?: ResponseMessage[];
}

export type AllAppFilter = ApplicationFilter & {};

export type AllEnvFilter = EnvironmentFilter & {};

export type AllNonProdEnvFilter = EnvironmentFilter & {};

export type AllProdEnvFilter = EnvironmentFilter & {};

export interface ApplicationFilter {
  filterType?: "ALL" | "CUSTOM";
  envSelection?: EnvironmentFilter;
}

export type CustomAppFilter = ApplicationFilter & {
  apps?: string[];
};

export type CustomEnvFilter = EnvironmentFilter & {
  environments?: string[];
};

export interface EnvironmentFilter {
  filterType?: "ALL_PROD" | "ALL_NON_PROD" | "ALL" | "CUSTOM";
}

export interface GovernanceConfig {
  uuid?: string;
  accountId?: string;
  appId: string;
  deploymentFreeze?: boolean;
  lastUpdatedBy?: EmbeddedUser;
  timeRangeBasedFreezeConfigs?: TimeRangeBasedFreezeConfig[];
  weeklyFreezeConfigs?: WeeklyFreezeConfig[];
  enableNextIterations?: boolean;
  enableNextCloseIterations?: boolean;
  nextIterations?: number[];
  nextCloseIterations?: number[];
}

export interface RestResponseGovernanceConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GovernanceConfig;
  responseMessages?: ResponseMessage[];
}

export interface TimeRangeBasedFreezeConfig {
  freezeForAllApps?: boolean;
  appIds?: string[];
  environmentTypes?: ("PROD" | "NON_PROD" | "ALL")[];
  timeRange?: TimeRange;
  name?: string;
  description?: string;
  applicable?: boolean;
  appSelections?: ApplicationFilter[];
  userGroups?: string[];
  uuid?: string;
}

export interface WeeklyFreezeConfig {
  freezeForAllApps?: boolean;
  appIds?: string[];
  environmentTypes?: ("PROD" | "NON_PROD" | "ALL")[];
  weeklyRange?: WeeklyRange;
  name?: string;
  description?: string;
  applicable?: boolean;
  appSelections?: ApplicationFilter[];
  userGroups?: string[];
  uuid?: string;
}

export interface WeeklyRange {
  endDay?: string;
  endTime?: string;
  startDay?: string;
  startTime?: string;
  timeZone?: string;
}

export interface DeploymentFreezeInfo {
  freezeAll?: boolean;
  allEnvFrozenApps?: string[];
  appEnvs?: {
    [key: string]: string[];
  };
}

export interface RestResponseDeploymentFreezeInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DeploymentFreezeInfo;
  responseMessages?: ResponseMessage[];
}

export interface PipelineGovernanceConfig {
  uuid?: string;
  accountId?: string;
  name?: string;
  description?: string;
  rules?: PipelineGovernanceRule[];
  restrictions?: Restriction[];
  enabled?: boolean;
}

export interface PipelineGovernanceRule {
  tags?: Tag[];
  matchType?: "ANY" | "ALL";
  weight?: number;
  note?: string;
}

export interface RestResponsePipelineGovernanceConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PipelineGovernanceConfig;
  responseMessages?: ResponseMessage[];
}

export interface Restriction {
  type?: "APP_BASED" | "TAG_BASED";
  appIds?: string[];
  tags?: Tag[];
}

export interface RestResponseListPipelineGovernanceConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PipelineGovernanceConfig[];
  responseMessages?: ResponseMessage[];
}

export interface GovernanceRuleStatus {
  tags?: Tag[];
  weight?: number;
  tagsIncluded?: boolean;
  matchType?: "ANY" | "ALL";
  tagsLocations?: Usage[];
}

export interface GovernanceStandard {
  id?: string;
  name?: string;
  description?: string;
}

export interface PipelineReportCard {
  governanceStandard?: GovernanceStandard;
  pipelineId?: string;
  ruleStatuses?: GovernanceRuleStatus[];
}

export interface RestResponseListPipelineReportCard {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PipelineReportCard[];
  responseMessages?: ResponseMessage[];
}

export interface Usage {
  entityId?: string;
  entityType?: string;
  entityName?: string;
  properties?: {
    [key: string]: string;
  };
}

export interface GraphQLQuery {
  operationName?: string;
  query?: string;
  variables?: {
    [key: string]: { [key: string]: any };
  };
}

export interface StaticLimit {
  count?: number;
  limitType?: "STATIC" | "RATE_LIMIT";
}

export interface RateLimit {
  count?: number;
  duration?: number;
  durationUnit:
    | "NANOSECONDS"
    | "MICROSECONDS"
    | "MILLISECONDS"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS";
  limitType?: "STATIC" | "RATE_LIMIT";
}

export interface AwsSecretsManagerConfig {
  name?: string;
  accessKey?: string;
  secretKey?: string;
  region?: string;
  secretNamePrefix?: string;
  assumeIamRoleOnDelegate?: boolean;
  assumeStsRoleOnDelegate?: boolean;
  assumeStsRoleDuration?: number;
  roleArn?: string;
  externalName?: string;
  delegateSelectors?: string[];
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  default?: boolean;
  templatized?: boolean;
}

export interface AzureVaultConfig {
  name?: string;
  clientId?: string;
  secretKey?: string;
  tenantId?: string;
  vaultName?: string;
  subscription?: string;
  delegateSelectors?: string[];
  azureEnvironmentType?: "AZURE" | "AZURE_US_GOVERNMENT";
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  default?: boolean;
  templatized?: boolean;
}

export interface CustomSecretsManagerConfig {
  name?: string;
  templateId?: string;
  delegateSelectors?: string[];
  testVariables?: EncryptedDataParams[];
  executeOnDelegate?: boolean;
  host?: string;
  commandPath?: string;
  connectorId?: string;
  customSecretsManagerShellScript?: CustomSecretsManagerShellScript;
  remoteHostConnector?: EncryptableSetting;
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  connectorTemplatized?: boolean;
  encryptionServiceUrl?: string;
  validationCriteria?: string;
  default?: boolean;
  templatized?: boolean;
}

export interface CustomSecretsManagerShellScript {
  scriptType?: "BASH" | "POWERSHELL";
  scriptString?: string;
  variables?: string[];
  timeoutMillis?: number;
}

export interface EncryptableSetting {
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
  accountId?: string;
}

export interface RestResponseCustomSecretsManagerConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CustomSecretsManagerConfig;
  responseMessages?: ResponseMessage[];
}

export interface CyberArkConfig {
  name?: string;
  cyberArkUrl?: string;
  appId?: string;
  clientCertificate?: string;
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  encryptionServiceUrl?: string;
  validationCriteria?: string;
  certValidationRequired?: boolean;
  default?: boolean;
  templatized?: boolean;
}

export interface KmsConfig {
  name?: string;
  accessKey?: string;
  secretKey?: string;
  kmsArn?: string;
  region?: string;
  assumeIamRoleOnDelegate?: boolean;
  assumeStsRoleOnDelegate?: boolean;
  assumeStsRoleDuration?: number;
  roleArn?: string;
  externalName?: string;
  delegateSelectors?: string[];
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  default?: boolean;
  templatized?: boolean;
}

export interface RestResponseListSecretEngineSummary {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SecretEngineSummary[];
  responseMessages?: ResponseMessage[];
}

export interface SecretEngineSummary {
  name?: string;
  description?: string;
  type?: string;
  version?: number;
}

export interface SSHVaultConfig {
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  name?: string;
  vaultUrl?: string;
  authToken?: string;
  appRoleId?: string;
  secretId?: string;
  secretEngineName?: string;
  renewalInterval?: number;
  engineManuallyEntered?: boolean;
  namespace?: string;
  renewedAt?: number;
  useVaultAgent?: boolean;
  delegateSelectors?: string[];
  sinkPath?: string;
  certValidationRequired?: boolean;
  default?: boolean;
  templatized?: boolean;
}

export interface RestResponseListUuidAware {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UuidAware[];
  responseMessages?: ResponseMessage[];
}

export interface UuidAware {
  uuid?: string;
}

export interface SecretText {
  name?: string;
  kmsId?: string;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  hideFromListing?: boolean;
  inheritScopesFromSM?: boolean;
  additionalMetadata?: AdditionalMetadata;
  runtimeParameters?: {
    [key: string]: string;
  };
  value?: string;
  path?: string;
  parameters?: EncryptedDataParams[];
  inlineSecret?: boolean;
  referencedSecret?: boolean;
  parameterizedSecret?: boolean;
}

export interface AtomicInteger {
  andIncrement?: number;
  andDecrement?: number;
}

export interface EncryptedData {
  uuid: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  encryptionKey?: string;
  encryptedValue?: string[];
  path?: string;
  parameters?: EncryptedDataParams[];
  type:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
  parents?: EncryptedDataParent[];
  accountId?: string;
  enabled?: boolean;
  kmsId?: string;
  additionalMetadata?: AdditionalMetadata;
  encryptionType:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  fileSize?: number;
  appIds?: string[];
  serviceIds?: string[];
  envIds?: string[];
  backupEncryptedValue?: string[];
  backupEncryptionKey?: string;
  backupKmsId?: string;
  backupEncryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  serviceVariableIds?: string[];
  searchTags?: {
    [key: string]: AtomicInteger;
  };
  scopedToAccount?: boolean;
  usageRestrictions?: UsageRestrictions;
  inheritScopesFromSM?: boolean;
  nextMigrationIteration?: number;
  nextAwsToGcpKmsMigrationIteration?: number;
  nextLocalToGcpKmsMigrationIteration?: number;
  nextAwsKmsToGcpKmsMigrationIteration?: number;
  base64Encoded?: boolean;
  encryptedBy?: string;
  setupUsage?: number;
  runTimeUsage?: number;
  changeLog?: number;
  keywords?: string[];
  ngMetadata?: NGEncryptedDataMetadata;
  hideFromListing?: boolean;
  inlineSecret?: boolean;
  referencedSecret?: boolean;
  parameterizedSecret?: boolean;
}

export interface EncryptedDataParent {
  id?: string;
  type?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
  fieldName?: string;
}

export interface NGEncryptedDataMetadata {
  identifier?: string;
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  tags?: string[];
  secretManagerIdentifier?: string;
  secretManagerName?: string;
  description?: string;
  draft?: boolean;
}

export interface RestResponsePageResponseEncryptedData {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: EncryptedData[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseCollectionSettingAttribute {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SettingAttribute[];
  responseMessages?: ResponseMessage[];
}

export interface NGSecretManagerMetadata {
  identifier?: string;
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  tags?: NGTag[];
  description?: string;
  harnessManaged?: boolean;
  deleted?: boolean;
}

export interface NGTag {
  key: string;
  value: string;
}

export interface RestResponseListSecretManagerConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SecretManagerConfig[];
  responseMessages?: ResponseMessage[];
}

export interface SecretManagerConfig {
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  ngMetadata?: NGSecretManagerMetadata;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  default?: boolean;
  templatized?: boolean;
  name?: string;
  encryptionServiceUrl?: string;
  validationCriteria?: string;
}

export interface RestResponseSetSecretSetupUsage {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SecretSetupUsage[];
  responseMessages?: ResponseMessage[];
}

export interface SecretSetupUsage {
  entityId?: string;
  type?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "CYBERARK"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER";
  fieldName?: string;
  entity?: UuidAware;
}

export interface RestResponseSecretManagerConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SecretManagerConfig;
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseSecretUsageLog {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SecretUsageLog[];
  responseMessages?: ResponseMessage[];
}

export interface SecretUsageLog {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  encryptedDataId?: string;
  accountId?: string;
  workflowExecutionId?: string;
  envId?: string;
  entityName?: string;
  workflowExecutionName?: string;
  validUntil?: string;
  pipelineExecution?: boolean;
}

export interface RestResponseListSecretChangeLog {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SecretChangeLog[];
  responseMessages?: ResponseMessage[];
}

export interface SecretChangeLog {
  uuid: string;
  accountId?: string;
  encryptedDataId?: string;
  user: EmbeddedUser;
  description?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  external?: boolean;
}

export interface VaultConfig {
  basePath?: string;
  renewIntervalHours?: number;
  secretEngineVersion?: number;
  uuid: string;
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "CYBERARK"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH";
  accountId?: string;
  numOfEncryptedValue?: number;
  encryptedBy?: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  nextTokenRenewIteration?: number;
  manuallyEnteredSecretEngineMigrationIteration?: number;
  usageRestrictions?: UsageRestrictions;
  scopedToAccount?: boolean;
  templatizedFields?: string[];
  name?: string;
  vaultUrl?: string;
  authToken?: string;
  appRoleId?: string;
  secretId?: string;
  secretEngineName?: string;
  renewalInterval?: number;
  engineManuallyEntered?: boolean;
  namespace?: string;
  renewedAt?: number;
  useVaultAgent?: boolean;
  delegateSelectors?: string[];
  sinkPath?: string;
  readOnly?: boolean;
  certValidationRequired?: boolean;
  default?: boolean;
  templatized?: boolean;
}

export interface RestResponseTemplateGallery {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TemplateGallery;
  responseMessages?: ResponseMessage[];
}

export interface TemplateGallery {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  accountId?: string;
  description?: string;
  referencedGalleryId?: string;
  global?: boolean;
  keywords?: string[];
  galleryKey?: string;
}

export interface RestResponsePageResponseTemplateGallery {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TemplateGallery[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseTemplateFolder {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TemplateFolder;
  responseMessages?: ResponseMessage[];
}

export interface TemplateFolder {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  accountId?: string;
  name?: string;
  description?: string;
  parentId?: string;
  nodeType?: string;
  galleryId?: string;
  templatesCount?: number;
  pathId?: string;
  children?: TemplateFolder[];
  keywords?: string[];
}

export interface RestResponsePageResponseTemplate {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Template[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponsePageResponseTemplateVersion {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: TemplateVersion[];
  responseMessages?: ResponseMessage[];
}

export interface TemplateVersion {
  changeType?: string;
  templateUuid?: string;
  templateName?: string;
  templateType?: string;
  version?: number;
  versionDetails?: string;
  importedTemplateVersion?: string;
  accountId?: string;
  galleryId?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface CEView {
  uuid?: string;
  name?: string;
  accountId?: string;
  viewVersion?: string;
  viewTimeRange?: ViewTimeRange;
  viewRules?: ViewRule[];
  dataSources?: (
    | "CLUSTER"
    | "AWS"
    | "GCP"
    | "AZURE"
    | "COMMON"
    | "CUSTOM"
    | "LABEL"
  )[];
  viewVisualization?: ViewVisualization;
  viewType?: "SAMPLE" | "CUSTOMER" | "DEFAULT_AZURE" | "DEFAULT";
  viewState?: "DRAFT" | "COMPLETED";
  totalCost?: number;
  createdAt?: number;
  lastUpdatedAt?: number;
  createdBy?: EmbeddedUser;
  lastUpdatedBy?: EmbeddedUser;
}

export interface RestResponseCEView {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: CEView;
  responseMessages?: ResponseMessage[];
}

export interface ViewCondition {
  type?: string;
}

export interface ViewField {
  fieldId?: string;
  fieldName?: string;
  identifier?:
    | "CLUSTER"
    | "AWS"
    | "GCP"
    | "AZURE"
    | "COMMON"
    | "CUSTOM"
    | "LABEL";
  identifierName?: string;
}

export type ViewIdCondition = ViewCondition & {
  viewField?: ViewField;
  viewOperator?: "IN" | "NOT_IN" | "NOT_NULL" | "NULL";
  values?: string[];
};

export interface ViewRule {
  viewConditions?: ViewCondition[];
}

export interface ViewTimeRange {
  viewTimeRangeType?:
    | "LAST_7"
    | "LAST_30"
    | "LAST_MONTH"
    | "CURRENT_MONTH"
    | "CUSTOM";
  startTime?: number;
  endTime?: number;
}

export interface ViewVisualization {
  granularity?: "DAY" | "MONTH";
  groupBy?: ViewField;
  chartType?: "STACKED_TIME_SERIES" | "STACKED_LINE_CHART";
}

export interface RestResponseViewCustomField {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: ViewCustomField;
  responseMessages?: ResponseMessage[];
}

export interface ViewCustomField {
  uuid?: string;
  accountId?: string;
  viewId?: string;
  name?: string;
  description?: string;
  sqlFormula?: string;
  displayFormula?: string;
  userDefinedExpression?: string;
  viewFields?: ViewField[];
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface RestResponseYamlHistory {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: YamlHistory;
  responseMessages?: ResponseMessage[];
}

export interface YamlHistory {
  [key: string]: any;
}

export interface RestResponseYamlVersion {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: YamlVersion;
  responseMessages?: ResponseMessage[];
}

export interface YamlVersion {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  yamlVersionId?: string;
  version?: number;
  type?:
    | "SETUP"
    | "APP"
    | "SERVICE"
    | "SERVICE_COMMAND"
    | "CONFIG_FILE"
    | "ENVIRONMENT"
    | "CONFIG_FILE_OVERRIDE"
    | "SETTING"
    | "WORKFLOW"
    | "PIPELINE"
    | "PROVISIONER"
    | "TRIGGER"
    | "ARTIFACT_STREAM"
    | "INFRA_MAPPING"
    | "INFRA_DEFINITION"
    | "DEPLOYMENT_SPEC"
    | "APPLICATION_DEFAULTS"
    | "ACCOUNT_DEFAULTS"
    | "NOTIFICATION_GROUP"
    | "APPLICATION_MANIFEST"
    | "APPLICATION_MANIFEST_FILE"
    | "SERVICE_CV_CONFIG"
    | "TAGS"
    | "GLOBAL_TEMPLATE_LIBRARY"
    | "APPLICATION_TEMPLATE_LIBRARY"
    | "GOVERNANCE_CONFIG";
  entityId?: string;
  inEffectStart?: number;
  inEffectEnd?: number;
  yaml?: string;
  accountId?: string;
}

export interface RestResponseYamlGitConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: YamlGitConfig;
  responseMessages?: ResponseMessage[];
}

export interface Base {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface RestResponseBase {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Base;
  responseMessages?: ResponseMessage[];
}

export interface YamlPayload {
  name?: string;
  yaml?: string;
  path?: string;
}

export interface RestResponseYamlPayload {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: YamlPayload;
  responseMessages?: ResponseMessage[];
}

export interface GitSyncWebhook {
  accountId?: string;
  webhookToken?: string;
  entityId?: string;
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
}

export interface RestResponseGitSyncWebhook {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitSyncWebhook;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListGitSyncError {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: GitSyncError[];
  responseMessages?: ResponseMessage[];
}

export interface FileOperationStatus {
  yamlFilePath?: string;
  status?: "FAILED" | "SUCCESS" | "SKIPPED";
  errorMssg?: string;
  entityId?: string;
}

export interface RestResponseYamlOperationResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: YamlOperationResponse;
  responseMessages?: ResponseMessage[];
}

export interface YamlOperationResponse {
  responseStatus?: "FAILED" | "SUCCESS";
  errorMessage?: string;
  filesStatus?: FileOperationStatus[];
}

export interface RestResponseFileOperationStatus {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: FileOperationStatus;
  responseMessages?: ResponseMessage[];
}

export type BuildDetailsRequestBody = BuildDetails;

export type CustomSecretsManagerConfigRequestBody = CustomSecretsManagerConfig;

export type PreferenceRequestBody = Preference;

export type LogMLFeedbackRequestBody = LogMLFeedback;

export type FailureStrategyArrayRequestBody = FailureStrategy[];

export type PatchRequestRequestBody = PatchRequest;

export type HelmChartSpecificationRequestBody = HelmChartSpecification;

export type DelegateProfileDetailsRequestBody = DelegateProfileDetails;

export type TemplateFolderRequestBody = TemplateFolder;

export type NotificationGroupRequestBody = NotificationGroup;

export type UserGroupRequestBody = UserGroup;

export type UploadSamlMetaDataRequestBody = void;

export type ViewCustomFieldRequestBody = ViewCustomField;

export type ModuleLicenseDTORequestBody = ModuleLicenseDTO;

export type ApplicationManifestRequestBody = ApplicationManifest;

export type KubernetesPayloadRequestBody = KubernetesPayload;

export type ApiKeyEntryRequestBody = ApiKeyEntry;

export type DelegateScopesRequestBody = DelegateScopes;

export type ArtifactStreamBindingRequestBody = ArtifactStreamBinding;

export type SaveGcpSecretsManagerConfig1RequestBody = void;

export type ManifestFileRequestBody = ManifestFile;

export type VaultConfigRequestBody = VaultConfig;

export type InfrastructureProvisionerRequestBody = InfrastructureProvisioner;

export type UserInviteRequestBody = UserInvite;

export type LicenseInfoRequestBody = LicenseInfo;

export type WhitelistRequestBody = Whitelist;

export type InfrastructureMappingRequestBody = InfrastructureMapping;

export type ValidateBodyRequestBody = string;

export type TemplateGalleryRequestBody = TemplateGallery;

export type TemplateRequestBody = Template;

export type AlertNotificationRuleRequestBody = AlertNotificationRule;

export type CVConfigurationRequestBody = CVConfiguration;

export type InfrastructureDefinitionRequestBody = InfrastructureDefinition;

export interface UpdateAccountPreferenceBodyRequestBody {
  [key: string]: any;
}

export type TaskSelectorMapRequestBody = TaskSelectorMap;

export type PhaseStepRequestBody = PhaseStep;

export type YamlGitConfigRequestBody = YamlGitConfig;

export type SplunkConnectorDTORequestBody = SplunkConnectorDTO;

export type EcsServiceSpecificationRequestBody = EcsServiceSpecification;

export type DelegateRequestBody = Delegate;

export type LdapSettingsRequestBody = LdapSettings;

export type CVFeedbackRecordRequestBody = CVFeedbackRecord;

export type ServiceRequestBody = Service;

export type TriggerRequestBody = Trigger;

export type ServiceTemplateRequestBody = ServiceTemplate;

export type DelegateGroupDetailsRequestBody = DelegateGroupDetails;

export type GcpOrganizationRequestBody = GcpOrganization;

export type BudgetRequestBody = Budget;

export type KmsConfigRequestBody = KmsConfig;

export type ArtifactRequestBody = Artifact;

export type WorkflowPhaseRequestBody = WorkflowPhase;

export type AccessRequestDTORequestBody = AccessRequestDTO;

export type ImportAccountDataRequestBody = void;

export type UpdateWhitelistedDomainsBodyRequestBody = string[];

export type AccountRequestBody = Account;

export type GcpSignUpRequestBody = void;

export type UpdatePlatformRequestBody = void;

export type ArtifactStreamRequestBody = ArtifactStream;

export type CEReportScheduleRequestBody = CEReportSchedule;

export type CgEventConfigRequestBody = CgEventConfig;

export type DelegateParamsRequestBody = DelegateParams;

export type SaveApiCallLogsBodyRequestBody = string[];

export type DelegateProfileRequestBody = DelegateProfile;

export type DelegateScopeRequestBody = DelegateScope;

export type DelegateTagsRequestBody = DelegateTags;

export type DelegateSetupDetailsRequestBody = DelegateSetupDetails;

export type EnvironmentRequestBody = Environment;

export type CloneMetadataRequestBody = CloneMetadata;

export type ExecutionArgsRequestBody = ExecutionArgs;

export interface SaveMessageComparisonListBodyRequestBody {
  [key: string]: string;
}

export type GcpBillingAccountRequestBody = GcpBillingAccount;

export type HarnessTagRequestBody = HarnessTag;

export type HarnessTagLinkRequestBody = HarnessTagLink;

export type HarnessUserGroupDTORequestBody = HarnessUserGroupDTO;

export type PipelineRequestBody = Pipeline;

export type ResourceConstraintRequestBody = ResourceConstraint;

export type RoleRequestBody = Role;

export type OauthSettingsRequestBody = OauthSettings;

export type LDAPTestAuthenticationRequestRequestBody = LDAPTestAuthenticationRequest;

export type ScimGroupRequestBody = ScimGroup;

export type ScimUserRequestBody = ScimUser;

export type PcfServiceSpecificationRequestBody = PcfServiceSpecification;

export type ContainerTaskRequestBody = ContainerTask;

export type LambdaSpecificationRequestBody = LambdaSpecification;

export type UserDataSpecificationRequestBody = UserDataSpecification;

export type ServiceVariableRequestBody = ServiceVariable;

export type SettingAttributeRequestBody = SettingAttribute;

export type Update28RequestBody = void;

export type StackDriverSetupTestNodeDataRequestBody = StackDriverSetupTestNodeData;

export type UserRequestBody = User;

export type LoginRequestRequestBody = LoginRequest;

export type WebHookRequestRequestBody = WebHookRequest;

export type WorkflowRequestBody = Workflow;

export type ContinuousVerificationAlertDataRequestBody = ContinuousVerificationAlertData;

export type K8sEventCollectionBundleRequestBody = K8sEventCollectionBundle;

export type DataCollectionConnectorBundleRequestBody = DataCollectionConnectorBundle;

export type DashboardSettingsRequestBody = DashboardSettings;

export type AzureVaultConfigRequestBody = AzureVaultConfig;

export type SaveGlobalKmsConfigRequestBody = void;

export type SSHVaultConfigRequestBody = SSHVaultConfig;

export type SecretTextRequestBody = SecretText;

export type CEViewRequestBody = CEView;

export type YamlPayloadRequestBody = YamlPayload;

export interface LoginQueryParams {
  accountId?: string;
  captcha?: string;
}

export type LoginProps = Omit<
  MutateProps<RestResponseUser, unknown, LoginQueryParams, LoginRequest, void>,
  "path" | "verb"
>;

export const Login = (props: LoginProps) => (
  <Mutate<RestResponseUser, unknown, LoginQueryParams, LoginRequest, void>
    verb="POST"
    path={`/users/login`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseLoginProps = Omit<
  UseMutateProps<
    RestResponseUser,
    unknown,
    LoginQueryParams,
    LoginRequest,
    void
  >,
  "path" | "verb"
>;

export const useLogin = (props: UseLoginProps) =>
  useMutate<RestResponseUser, unknown, LoginQueryParams, LoginRequest, void>(
    "POST",
    `/users/login`,
    { base: getConfig("api"), ...props }
  );

export interface GetLoginTypeQueryParams {
  accountId?: string;
}

export type GetLoginTypeProps = Omit<
  MutateProps<
    RestResponseLoginTypeResponse,
    unknown,
    GetLoginTypeQueryParams,
    LoginTypeRequest,
    void
  >,
  "path" | "verb"
>;

export const GetLoginType = (props: GetLoginTypeProps) => (
  <Mutate<
    RestResponseLoginTypeResponse,
    unknown,
    GetLoginTypeQueryParams,
    LoginTypeRequest,
    void
  >
    verb="POST"
    path={`/users/logintype`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseGetLoginTypeProps = Omit<
  UseMutateProps<
    RestResponseLoginTypeResponse,
    unknown,
    GetLoginTypeQueryParams,
    LoginTypeRequest,
    void
  >,
  "path" | "verb"
>;

export const useGetLoginType = (props: UseGetLoginTypeProps) =>
  useMutate<
    RestResponseLoginTypeResponse,
    unknown,
    GetLoginTypeQueryParams,
    LoginTypeRequest,
    void
  >("POST", `/users/logintype`, { base: getConfig("api"), ...props });

export type ResetPasswordProps = Omit<
  MutateProps<RestResponse, unknown, void, ResetPasswordRequest, void>,
  "path" | "verb"
>;

export const ResetPassword = (props: ResetPasswordProps) => (
  <Mutate<RestResponse, unknown, void, ResetPasswordRequest, void>
    verb="POST"
    path={`/users/reset-password`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseResetPasswordProps = Omit<
  UseMutateProps<RestResponse, unknown, void, ResetPasswordRequest, void>,
  "path" | "verb"
>;

export const useResetPassword = (props: UseResetPasswordProps) =>
  useMutate<RestResponse, unknown, void, ResetPasswordRequest, void>(
    "POST",
    `/users/reset-password`,
    { base: getConfig("api"), ...props }
  );

export interface UpdatePasswordPathParams {
  token: string;
}

export type UpdatePasswordProps = Omit<
  MutateProps<
    RestResponse,
    unknown,
    void,
    UpdatePasswordRequest,
    UpdatePasswordPathParams
  >,
  "path" | "verb"
> &
  UpdatePasswordPathParams;

export const UpdatePassword = ({ token, ...props }: UpdatePasswordProps) => (
  <Mutate<
    RestResponse,
    unknown,
    void,
    UpdatePasswordRequest,
    UpdatePasswordPathParams
  >
    verb="POST"
    path={`/users/reset-password/${token}`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseUpdatePasswordProps = Omit<
  UseMutateProps<
    RestResponse,
    unknown,
    void,
    UpdatePasswordRequest,
    UpdatePasswordPathParams
  >,
  "path" | "verb"
> &
  UpdatePasswordPathParams;

export const useUpdatePassword = ({
  token,
  ...props
}: UseUpdatePasswordProps) =>
  useMutate<
    RestResponse,
    unknown,
    void,
    UpdatePasswordRequest,
    UpdatePasswordPathParams
  >(
    "POST",
    (paramsInPath: UpdatePasswordPathParams) =>
      `/users/reset-password/${paramsInPath.token}`,
    { base: getConfig("api"), pathParams: { token }, ...props }
  );

export type ForceLoginUsingHarnessPasswordProps = Omit<
  MutateProps<RestResponseUser, unknown, void, LoginRequestRequestBody, void>,
  "path" | "verb"
>;

export const ForceLoginUsingHarnessPassword = (
  props: ForceLoginUsingHarnessPasswordProps
) => (
  <Mutate<RestResponseUser, unknown, void, LoginRequestRequestBody, void>
    verb="POST"
    path={`/users/harness-local-login`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseForceLoginUsingHarnessPasswordProps = Omit<
  UseMutateProps<
    RestResponseUser,
    unknown,
    void,
    LoginRequestRequestBody,
    void
  >,
  "path" | "verb"
>;

export const useForceLoginUsingHarnessPassword = (
  props: UseForceLoginUsingHarnessPasswordProps
) =>
  useMutate<RestResponseUser, unknown, void, LoginRequestRequestBody, void>(
    "POST",
    `/users/harness-local-login`,
    { base: getConfig("api"), ...props }
  );

export type TwoFactorLoginProps = Omit<
  MutateProps<RestResponseUser, unknown, void, LoginRequestRequestBody, void>,
  "path" | "verb"
>;

export const TwoFactorLogin = (props: TwoFactorLoginProps) => (
  <Mutate<RestResponseUser, unknown, void, LoginRequestRequestBody, void>
    verb="POST"
    path={`/users/two-factor-login`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseTwoFactorLoginProps = Omit<
  UseMutateProps<
    RestResponseUser,
    unknown,
    void,
    LoginRequestRequestBody,
    void
  >,
  "path" | "verb"
>;

export const useTwoFactorLogin = (props: UseTwoFactorLoginProps) =>
  useMutate<RestResponseUser, unknown, void, LoginRequestRequestBody, void>(
    "POST",
    `/users/two-factor-login`,
    { base: getConfig("api"), ...props }
  );

export interface CompleteInviteAndSignIn1QueryParams {
  accountId?: string;
  generation?: "CG" | "NG";
}

export type CompleteInviteAndSignIn1Props = Omit<
  MutateProps<
    RestResponseUser,
    unknown,
    CompleteInviteAndSignIn1QueryParams,
    UserInviteDTO,
    void
  >,
  "path" | "verb"
>;

export const CompleteInviteAndSignIn1 = (
  props: CompleteInviteAndSignIn1Props
) => (
  <Mutate<
    RestResponseUser,
    unknown,
    CompleteInviteAndSignIn1QueryParams,
    UserInviteDTO,
    void
  >
    verb="PUT"
    path={`/users/invites/ngsignin`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseCompleteInviteAndSignIn1Props = Omit<
  UseMutateProps<
    RestResponseUser,
    unknown,
    CompleteInviteAndSignIn1QueryParams,
    UserInviteDTO,
    void
  >,
  "path" | "verb"
>;

export const useCompleteInviteAndSignIn1 = (
  props: UseCompleteInviteAndSignIn1Props
) =>
  useMutate<
    RestResponseUser,
    unknown,
    CompleteInviteAndSignIn1QueryParams,
    UserInviteDTO,
    void
  >("PUT", `/users/invites/ngsignin`, { base: getConfig("api"), ...props });

export interface SignupPathParams {
  source: string;
}

export type SignupProps = Omit<
  MutateProps<
    RestResponseBoolean,
    unknown,
    void,
    UserInviteRequestBody,
    SignupPathParams
  >,
  "path" | "verb"
> &
  SignupPathParams;

export const Signup = ({ source, ...props }: SignupProps) => (
  <Mutate<
    RestResponseBoolean,
    unknown,
    void,
    UserInviteRequestBody,
    SignupPathParams
  >
    verb="POST"
    path={`/signup/${source}`}
    base={getConfig("api")}
    {...props}
  />
);

export type UseSignupProps = Omit<
  UseMutateProps<
    RestResponseBoolean,
    unknown,
    void,
    UserInviteRequestBody,
    SignupPathParams
  >,
  "path" | "verb"
> &
  SignupPathParams;

export const useSignup = ({ source, ...props }: UseSignupProps) =>
  useMutate<
    RestResponseBoolean,
    unknown,
    void,
    UserInviteRequestBody,
    SignupPathParams
  >(
    "POST",
    (paramsInPath: SignupPathParams) => `/signup/${paramsInPath.source}`,
    { base: getConfig("api"), pathParams: { source }, ...props }
  );
