/*
 * Copyright 2022 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

/* Generated by restful-react */

import React from "react";
import { Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";

import { getConfig } from "../config";
export const SPEC_VERSION = "2.0";
export interface AccountDTO {
  identifier?: string;
  name?: string;
  companyName?: string;
  cluster?: string;
  defaultExperience?: "NG" | "CG";
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
  serviceAccountConfig?: ServiceAccountConfig;
  productLed?: boolean;
  nextGenEnabled?: boolean;
}

export interface Response {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: { [key: string]: any };
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseAccountDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AccountDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceAccountConfig {
  apiKeyLimit?: number;
  tokenLimit?: number;
}

export interface Failure {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  code?:
    | "DEFAULT_ERROR_CODE"
    | "INVALID_ARGUMENT"
    | "INVALID_EMAIL"
    | "DOMAIN_NOT_ALLOWED_TO_REGISTER"
    | "COMMNITY_EDITION_NOT_FOUND"
    | "DEPLOY_MODE_IS_NOT_ON_PREM"
    | "USER_ALREADY_REGISTERED"
    | "USER_INVITATION_DOES_NOT_EXIST"
    | "USER_DOES_NOT_EXIST"
    | "USER_INVITE_OPERATION_FAILED"
    | "USER_DISABLED"
    | "ACCOUNT_DOES_NOT_EXIST"
    | "INACTIVE_ACCOUNT"
    | "ACCOUNT_MIGRATED"
    | "USER_DOMAIN_NOT_ALLOWED"
    | "MAX_FAILED_ATTEMPT_COUNT_EXCEEDED"
    | "RESOURCE_NOT_FOUND"
    | "INVALID_FORMAT"
    | "ROLE_DOES_NOT_EXIST"
    | "EMAIL_NOT_VERIFIED"
    | "EMAIL_VERIFICATION_TOKEN_NOT_FOUND"
    | "INVALID_TOKEN"
    | "REVOKED_TOKEN"
    | "INVALID_CAPTCHA_TOKEN"
    | "NOT_ACCOUNT_MGR_NOR_HAS_ALL_APP_ACCESS"
    | "EXPIRED_TOKEN"
    | "INVALID_AGENT_MTLS_AUTHORITY"
    | "TOKEN_ALREADY_REFRESHED_ONCE"
    | "ACCESS_DENIED"
    | "NG_ACCESS_DENIED"
    | "INVALID_CREDENTIAL"
    | "INVALID_CREDENTIALS_THIRD_PARTY"
    | "INVALID_KEY"
    | "INVALID_CONNECTOR_TYPE"
    | "INVALID_KEYPATH"
    | "INVALID_VARIABLE"
    | "UNKNOWN_HOST"
    | "UNREACHABLE_HOST"
    | "INVALID_PORT"
    | "SSH_SESSION_TIMEOUT"
    | "SOCKET_CONNECTION_ERROR"
    | "CONNECTION_ERROR"
    | "SOCKET_CONNECTION_TIMEOUT"
    | "WINRM_COMMAND_EXECUTION_TIMEOUT"
    | "CONNECTION_TIMEOUT"
    | "SSH_CONNECTION_ERROR"
    | "USER_GROUP_ERROR"
    | "INVALID_EXECUTION_ID"
    | "ERROR_IN_GETTING_CHANNEL_STREAMS"
    | "UNEXPECTED"
    | "UNKNOWN_ERROR"
    | "UNKNOWN_EXECUTOR_TYPE_ERROR"
    | "DUPLICATE_STATE_NAMES"
    | "TRANSITION_NOT_LINKED"
    | "TRANSITION_TO_INCORRECT_STATE"
    | "TRANSITION_TYPE_NULL"
    | "STATES_WITH_DUP_TRANSITIONS"
    | "BARRIERS_NOT_RUNNING_CONCURRENTLY"
    | "NON_FORK_STATES"
    | "NON_REPEAT_STATES"
    | "INITIAL_STATE_NOT_DEFINED"
    | "FILE_INTEGRITY_CHECK_FAILED"
    | "INVALID_URL"
    | "FILE_DOWNLOAD_FAILED"
    | "PLATFORM_SOFTWARE_DELETE_ERROR"
    | "INVALID_CSV_FILE"
    | "INVALID_REQUEST"
    | "SCHEMA_VALIDATION_FAILED"
    | "FILTER_CREATION_ERROR"
    | "INVALID_YAML_ERROR"
    | "PLAN_CREATION_ERROR"
    | "INVALID_INFRA_STATE"
    | "PIPELINE_ALREADY_TRIGGERED"
    | "NON_EXISTING_PIPELINE"
    | "DUPLICATE_COMMAND_NAMES"
    | "INVALID_PIPELINE"
    | "COMMAND_DOES_NOT_EXIST"
    | "DUPLICATE_ARTIFACTSTREAM_NAMES"
    | "DUPLICATE_HOST_NAMES"
    | "STATE_NOT_FOR_TYPE"
    | "STATE_MACHINE_ISSUE"
    | "STATE_DISCONTINUE_FAILED"
    | "STATE_PAUSE_FAILED"
    | "PAUSE_ALL_ALREADY"
    | "RESUME_ALL_ALREADY"
    | "ROLLBACK_ALREADY"
    | "ABORT_ALL_ALREADY"
    | "EXPIRE_ALL_ALREADY"
    | "RETRY_FAILED"
    | "UNKNOWN_ARTIFACT_TYPE"
    | "UNKNOWN_STAGE_ELEMENT_WRAPPER_TYPE"
    | "INIT_TIMEOUT"
    | "LICENSE_EXPIRED"
    | "NOT_LICENSED"
    | "REQUEST_TIMEOUT"
    | "WORKFLOW_ALREADY_TRIGGERED"
    | "JENKINS_ERROR"
    | "INVALID_ARTIFACT_SOURCE"
    | "INVALID_ARTIFACT_SERVER"
    | "INVALID_CLOUD_PROVIDER"
    | "UPDATE_NOT_ALLOWED"
    | "DELETE_NOT_ALLOWED"
    | "APPDYNAMICS_CONFIGURATION_ERROR"
    | "APM_CONFIGURATION_ERROR"
    | "SPLUNK_CONFIGURATION_ERROR"
    | "ELK_CONFIGURATION_ERROR"
    | "LOGZ_CONFIGURATION_ERROR"
    | "SUMO_CONFIGURATION_ERROR"
    | "INSTANA_CONFIGURATION_ERROR"
    | "APPDYNAMICS_ERROR"
    | "STACKDRIVER_ERROR"
    | "STACKDRIVER_CONFIGURATION_ERROR"
    | "NEWRELIC_CONFIGURATION_ERROR"
    | "NEWRELIC_ERROR"
    | "DYNA_TRACE_CONFIGURATION_ERROR"
    | "DYNA_TRACE_ERROR"
    | "CLOUDWATCH_ERROR"
    | "CLOUDWATCH_CONFIGURATION_ERROR"
    | "PROMETHEUS_CONFIGURATION_ERROR"
    | "DATA_DOG_CONFIGURATION_ERROR"
    | "SERVICE_GUARD_CONFIGURATION_ERROR"
    | "ENCRYPTION_NOT_CONFIGURED"
    | "UNAVAILABLE_DELEGATES"
    | "WORKFLOW_EXECUTION_IN_PROGRESS"
    | "PIPELINE_EXECUTION_IN_PROGRESS"
    | "AWS_ACCESS_DENIED"
    | "AWS_CLUSTER_NOT_FOUND"
    | "AWS_SERVICE_NOT_FOUND"
    | "IMAGE_NOT_FOUND"
    | "ILLEGAL_ARGUMENT"
    | "IMAGE_TAG_NOT_FOUND"
    | "DELEGATE_NOT_AVAILABLE"
    | "INVALID_YAML_PAYLOAD"
    | "AUTHENTICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "UNRECOGNIZED_YAML_FIELDS"
    | "COULD_NOT_MAP_BEFORE_YAML"
    | "MISSING_BEFORE_YAML"
    | "MISSING_YAML"
    | "NON_EMPTY_DELETIONS"
    | "GENERAL_YAML_ERROR"
    | "GENERAL_YAML_INFO"
    | "YAML_GIT_SYNC_ERROR"
    | "GIT_CONNECTION_ERROR"
    | "GIT_ERROR"
    | "ARTIFACT_SERVER_ERROR"
    | "ENCRYPT_DECRYPT_ERROR"
    | "SECRET_MANAGEMENT_ERROR"
    | "SECRET_NOT_FOUND"
    | "KMS_OPERATION_ERROR"
    | "GCP_KMS_OPERATION_ERROR"
    | "VAULT_OPERATION_ERROR"
    | "AWS_SECRETS_MANAGER_OPERATION_ERROR"
    | "AZURE_KEY_VAULT_OPERATION_ERROR"
    | "UNSUPPORTED_OPERATION_EXCEPTION"
    | "FEATURE_UNAVAILABLE"
    | "GENERAL_ERROR"
    | "BASELINE_CONFIGURATION_ERROR"
    | "SAML_IDP_CONFIGURATION_NOT_AVAILABLE"
    | "INVALID_AUTHENTICATION_MECHANISM"
    | "INVALID_SAML_CONFIGURATION"
    | "INVALID_OAUTH_CONFIGURATION"
    | "INVALID_LDAP_CONFIGURATION"
    | "USER_GROUP_SYNC_FAILURE"
    | "USER_GROUP_ALREADY_EXIST"
    | "INVALID_TWO_FACTOR_AUTHENTICATION_CONFIGURATION"
    | "EXPLANATION"
    | "HINT"
    | "NOT_WHITELISTED_IP"
    | "INVALID_TOTP_TOKEN"
    | "EMAIL_FAILED"
    | "SSL_HANDSHAKE_FAILED"
    | "NO_APPS_ASSIGNED"
    | "INVALID_INFRA_CONFIGURATION"
    | "TEMPLATES_LINKED"
    | "USER_HAS_NO_PERMISSIONS"
    | "USER_NOT_AUTHORIZED"
    | "USER_ALREADY_PRESENT"
    | "EMAIL_ERROR"
    | "INVALID_USAGE_RESTRICTION"
    | "USAGE_RESTRICTION_ERROR"
    | "STATE_EXECUTION_INSTANCE_NOT_FOUND"
    | "DELEGATE_TASK_RETRY"
    | "KUBERNETES_API_TASK_EXCEPTION"
    | "KUBERNETES_TASK_EXCEPTION"
    | "KUBERNETES_YAML_ERROR"
    | "SAVE_FILE_INTO_GCP_STORAGE_FAILED"
    | "READ_FILE_FROM_GCP_STORAGE_FAILED"
    | "FILE_NOT_FOUND_ERROR"
    | "USAGE_LIMITS_EXCEEDED"
    | "EVENT_PUBLISH_FAILED"
    | "CUSTOM_APPROVAL_ERROR"
    | "JIRA_ERROR"
    | "EXPRESSION_EVALUATION_FAILED"
    | "KUBERNETES_VALUES_ERROR"
    | "KUBERNETES_CLUSTER_ERROR"
    | "INCORRECT_SIGN_IN_MECHANISM"
    | "OAUTH_LOGIN_FAILED"
    | "INVALID_TERRAFORM_TARGETS_REQUEST"
    | "TERRAFORM_EXECUTION_ERROR"
    | "FILE_READ_FAILED"
    | "FILE_SIZE_EXCEEDS_LIMIT"
    | "CLUSTER_NOT_FOUND"
    | "MARKETPLACE_TOKEN_NOT_FOUND"
    | "INVALID_MARKETPLACE_TOKEN"
    | "INVALID_TICKETING_SERVER"
    | "SERVICENOW_ERROR"
    | "PASSWORD_EXPIRED"
    | "USER_LOCKED"
    | "PASSWORD_STRENGTH_CHECK_FAILED"
    | "ACCOUNT_DISABLED"
    | "INVALID_ACCOUNT_PERMISSION"
    | "PAGERDUTY_ERROR"
    | "HEALTH_ERROR"
    | "SAML_TEST_SUCCESS_MECHANISM_NOT_ENABLED"
    | "DOMAIN_WHITELIST_FILTER_CHECK_FAILED"
    | "INVALID_DASHBOARD_UPDATE_REQUEST"
    | "DUPLICATE_FIELD"
    | "INVALID_AZURE_VAULT_CONFIGURATION"
    | "USER_NOT_AUTHORIZED_DUE_TO_USAGE_RESTRICTIONS"
    | "INVALID_ROLLBACK"
    | "DATA_COLLECTION_ERROR"
    | "SUMO_DATA_COLLECTION_ERROR"
    | "DEPLOYMENT_GOVERNANCE_ERROR"
    | "BATCH_PROCESSING_ERROR"
    | "GRAPHQL_ERROR"
    | "FILE_CREATE_ERROR"
    | "ILLEGAL_STATE"
    | "GIT_DIFF_COMMIT_NOT_IN_ORDER"
    | "FAILED_TO_ACQUIRE_PERSISTENT_LOCK"
    | "FAILED_TO_ACQUIRE_NON_PERSISTENT_LOCK"
    | "POD_NOT_FOUND_ERROR"
    | "COMMAND_EXECUTION_ERROR"
    | "REGISTRY_EXCEPTION"
    | "ENGINE_INTERRUPT_PROCESSING_EXCEPTION"
    | "ENGINE_IO_EXCEPTION"
    | "ENGINE_OUTCOME_EXCEPTION"
    | "ENGINE_SWEEPING_OUTPUT_EXCEPTION"
    | "CACHE_NOT_FOUND_EXCEPTION"
    | "ENGINE_ENTITY_UPDATE_EXCEPTION"
    | "SHELL_EXECUTION_EXCEPTION"
    | "TEMPLATE_NOT_FOUND"
    | "AZURE_SERVICE_EXCEPTION"
    | "AZURE_CLIENT_EXCEPTION"
    | "GIT_UNSEEN_REMOTE_HEAD_COMMIT"
    | "TIMEOUT_ENGINE_EXCEPTION"
    | "NO_AVAILABLE_DELEGATES"
    | "NO_GLOBAL_DELEGATE_ACCOUNT"
    | "NO_INSTALLED_DELEGATES"
    | "DUPLICATE_DELEGATE_EXCEPTION"
    | "GCP_MARKETPLACE_EXCEPTION"
    | "MISSING_DEFAULT_GOOGLE_CREDENTIALS"
    | "INCORRECT_DEFAULT_GOOGLE_CREDENTIALS"
    | "OPTIMISTIC_LOCKING_EXCEPTION"
    | "NG_PIPELINE_EXECUTION_EXCEPTION"
    | "NG_PIPELINE_CREATE_EXCEPTION"
    | "RESOURCE_NOT_FOUND_EXCEPTION"
    | "PMS_INITIALIZE_SDK_EXCEPTION"
    | "UNEXPECTED_SNIPPET_EXCEPTION"
    | "UNEXPECTED_SCHEMA_EXCEPTION"
    | "CONNECTOR_VALIDATION_EXCEPTION"
    | "TIMESCALE_NOT_AVAILABLE"
    | "MIGRATION_EXCEPTION"
    | "REQUEST_PROCESSING_INTERRUPTED"
    | "SECRET_MANAGER_ID_NOT_FOUND"
    | "GCP_SECRET_MANAGER_OPERATION_ERROR"
    | "GCP_SECRET_OPERATION_ERROR"
    | "GIT_OPERATION_ERROR"
    | "TASK_FAILURE_ERROR"
    | "INSTANCE_STATS_PROCESS_ERROR"
    | "INSTANCE_STATS_MIGRATION_ERROR"
    | "DEPLOYMENT_MIGRATION_ERROR"
    | "CG_LICENSE_USAGE_ERROR"
    | "INSTANCE_STATS_AGGREGATION_ERROR"
    | "UNRESOLVED_EXPRESSIONS_ERROR"
    | "KRYO_HANDLER_NOT_FOUND_ERROR"
    | "DELEGATE_ERROR_HANDLER_EXCEPTION"
    | "UNEXPECTED_TYPE_ERROR"
    | "EXCEPTION_HANDLER_NOT_FOUND"
    | "CONNECTOR_NOT_FOUND_EXCEPTION"
    | "GCP_SERVER_ERROR"
    | "HTTP_RESPONSE_EXCEPTION"
    | "SCM_NOT_FOUND_ERROR"
    | "SCM_CONFLICT_ERROR"
    | "SCM_CONFLICT_ERROR_V2"
    | "SCM_UNPROCESSABLE_ENTITY"
    | "PROCESS_EXECUTION_EXCEPTION"
    | "SCM_UNAUTHORIZED"
    | "SCM_BAD_REQUEST"
    | "SCM_INTERNAL_SERVER_ERROR"
    | "DATA"
    | "CONTEXT"
    | "PR_CREATION_ERROR"
    | "URL_NOT_REACHABLE"
    | "URL_NOT_PROVIDED"
    | "ENGINE_EXPRESSION_EVALUATION_ERROR"
    | "ENGINE_FUNCTOR_ERROR"
    | "JIRA_CLIENT_ERROR"
    | "SCM_NOT_MODIFIED"
    | "APPROVAL_STEP_NG_ERROR"
    | "BUCKET_SERVER_ERROR"
    | "GIT_SYNC_ERROR"
    | "TEMPLATE_EXCEPTION"
    | "ENTITY_REFERENCE_EXCEPTION"
    | "INVALID_INPUT_SET"
    | "INVALID_OVERLAY_INPUT_SET"
    | "RESOURCE_ALREADY_EXISTS"
    | "INVALID_JSON_PAYLOAD"
    | "POLICY_EVALUATION_FAILURE"
    | "POLICY_SET_ERROR"
    | "INVALID_ARTIFACTORY_REGISTRY_REQUEST"
    | "INVALID_NEXUS_REGISTRY_REQUEST"
    | "ENTITY_NOT_FOUND"
    | "INVALID_AZURE_CONTAINER_REGISTRY_REQUEST"
    | "AZURE_AUTHENTICATION_ERROR"
    | "AZURE_CONFIG_ERROR"
    | "DATA_PROCESSING_ERROR"
    | "INVALID_AZURE_AKS_REQUEST"
    | "AWS_IAM_ERROR"
    | "AWS_CF_ERROR"
    | "AWS_INSTANCE_ERROR"
    | "AWS_VPC_ERROR"
    | "AWS_TAG_ERROR"
    | "AWS_ASG_ERROR"
    | "AWS_LOAD_BALANCER_ERROR"
    | "SCM_INTERNAL_SERVER_ERROR_V2"
    | "SCM_UNAUTHORIZED_ERROR_V2"
    | "TOO_MANY_REQUESTS"
    | "INVALID_IDENTIFIER_REF"
    | "SPOTINST_NULL_ERROR"
    | "SCM_UNEXPECTED_ERROR"
    | "DUPLICATE_FILE_IMPORT"
    | "AZURE_APP_SERVICES_TASK_EXCEPTION"
    | "MEDIA_NOT_SUPPORTED"
    | "AWS_ECS_ERROR"
    | "AWS_APPLICATION_AUTO_SCALING"
    | "AWS_ECS_SERVICE_NOT_ACTIVE"
    | "AWS_ECS_CLIENT_ERROR"
    | "AWS_STS_ERROR";
  message?: string;
  correlationId?: string;
  errors?: ValidationError[];
}

export interface ValidationError {
  fieldId?: string;
  error?: string;
}

export interface EntityGitDetails {
  objectId?: string;
  branch?: string;
  repoIdentifier?: string;
  rootFolder?: string;
  filePath?: string;
  repoName?: string;
  commitId?: string;
  fileUrl?: string;
  repoUrl?: string;
}

export interface EntityValidityDetails {
  valid?: boolean;
  invalidYaml?: string;
}

export interface Error {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  code?:
    | "DEFAULT_ERROR_CODE"
    | "INVALID_ARGUMENT"
    | "INVALID_EMAIL"
    | "DOMAIN_NOT_ALLOWED_TO_REGISTER"
    | "COMMNITY_EDITION_NOT_FOUND"
    | "DEPLOY_MODE_IS_NOT_ON_PREM"
    | "USER_ALREADY_REGISTERED"
    | "USER_INVITATION_DOES_NOT_EXIST"
    | "USER_DOES_NOT_EXIST"
    | "USER_INVITE_OPERATION_FAILED"
    | "USER_DISABLED"
    | "ACCOUNT_DOES_NOT_EXIST"
    | "INACTIVE_ACCOUNT"
    | "ACCOUNT_MIGRATED"
    | "USER_DOMAIN_NOT_ALLOWED"
    | "MAX_FAILED_ATTEMPT_COUNT_EXCEEDED"
    | "RESOURCE_NOT_FOUND"
    | "INVALID_FORMAT"
    | "ROLE_DOES_NOT_EXIST"
    | "EMAIL_NOT_VERIFIED"
    | "EMAIL_VERIFICATION_TOKEN_NOT_FOUND"
    | "INVALID_TOKEN"
    | "REVOKED_TOKEN"
    | "INVALID_CAPTCHA_TOKEN"
    | "NOT_ACCOUNT_MGR_NOR_HAS_ALL_APP_ACCESS"
    | "EXPIRED_TOKEN"
    | "INVALID_AGENT_MTLS_AUTHORITY"
    | "TOKEN_ALREADY_REFRESHED_ONCE"
    | "ACCESS_DENIED"
    | "NG_ACCESS_DENIED"
    | "INVALID_CREDENTIAL"
    | "INVALID_CREDENTIALS_THIRD_PARTY"
    | "INVALID_KEY"
    | "INVALID_CONNECTOR_TYPE"
    | "INVALID_KEYPATH"
    | "INVALID_VARIABLE"
    | "UNKNOWN_HOST"
    | "UNREACHABLE_HOST"
    | "INVALID_PORT"
    | "SSH_SESSION_TIMEOUT"
    | "SOCKET_CONNECTION_ERROR"
    | "CONNECTION_ERROR"
    | "SOCKET_CONNECTION_TIMEOUT"
    | "WINRM_COMMAND_EXECUTION_TIMEOUT"
    | "CONNECTION_TIMEOUT"
    | "SSH_CONNECTION_ERROR"
    | "USER_GROUP_ERROR"
    | "INVALID_EXECUTION_ID"
    | "ERROR_IN_GETTING_CHANNEL_STREAMS"
    | "UNEXPECTED"
    | "UNKNOWN_ERROR"
    | "UNKNOWN_EXECUTOR_TYPE_ERROR"
    | "DUPLICATE_STATE_NAMES"
    | "TRANSITION_NOT_LINKED"
    | "TRANSITION_TO_INCORRECT_STATE"
    | "TRANSITION_TYPE_NULL"
    | "STATES_WITH_DUP_TRANSITIONS"
    | "BARRIERS_NOT_RUNNING_CONCURRENTLY"
    | "NON_FORK_STATES"
    | "NON_REPEAT_STATES"
    | "INITIAL_STATE_NOT_DEFINED"
    | "FILE_INTEGRITY_CHECK_FAILED"
    | "INVALID_URL"
    | "FILE_DOWNLOAD_FAILED"
    | "PLATFORM_SOFTWARE_DELETE_ERROR"
    | "INVALID_CSV_FILE"
    | "INVALID_REQUEST"
    | "SCHEMA_VALIDATION_FAILED"
    | "FILTER_CREATION_ERROR"
    | "INVALID_YAML_ERROR"
    | "PLAN_CREATION_ERROR"
    | "INVALID_INFRA_STATE"
    | "PIPELINE_ALREADY_TRIGGERED"
    | "NON_EXISTING_PIPELINE"
    | "DUPLICATE_COMMAND_NAMES"
    | "INVALID_PIPELINE"
    | "COMMAND_DOES_NOT_EXIST"
    | "DUPLICATE_ARTIFACTSTREAM_NAMES"
    | "DUPLICATE_HOST_NAMES"
    | "STATE_NOT_FOR_TYPE"
    | "STATE_MACHINE_ISSUE"
    | "STATE_DISCONTINUE_FAILED"
    | "STATE_PAUSE_FAILED"
    | "PAUSE_ALL_ALREADY"
    | "RESUME_ALL_ALREADY"
    | "ROLLBACK_ALREADY"
    | "ABORT_ALL_ALREADY"
    | "EXPIRE_ALL_ALREADY"
    | "RETRY_FAILED"
    | "UNKNOWN_ARTIFACT_TYPE"
    | "UNKNOWN_STAGE_ELEMENT_WRAPPER_TYPE"
    | "INIT_TIMEOUT"
    | "LICENSE_EXPIRED"
    | "NOT_LICENSED"
    | "REQUEST_TIMEOUT"
    | "WORKFLOW_ALREADY_TRIGGERED"
    | "JENKINS_ERROR"
    | "INVALID_ARTIFACT_SOURCE"
    | "INVALID_ARTIFACT_SERVER"
    | "INVALID_CLOUD_PROVIDER"
    | "UPDATE_NOT_ALLOWED"
    | "DELETE_NOT_ALLOWED"
    | "APPDYNAMICS_CONFIGURATION_ERROR"
    | "APM_CONFIGURATION_ERROR"
    | "SPLUNK_CONFIGURATION_ERROR"
    | "ELK_CONFIGURATION_ERROR"
    | "LOGZ_CONFIGURATION_ERROR"
    | "SUMO_CONFIGURATION_ERROR"
    | "INSTANA_CONFIGURATION_ERROR"
    | "APPDYNAMICS_ERROR"
    | "STACKDRIVER_ERROR"
    | "STACKDRIVER_CONFIGURATION_ERROR"
    | "NEWRELIC_CONFIGURATION_ERROR"
    | "NEWRELIC_ERROR"
    | "DYNA_TRACE_CONFIGURATION_ERROR"
    | "DYNA_TRACE_ERROR"
    | "CLOUDWATCH_ERROR"
    | "CLOUDWATCH_CONFIGURATION_ERROR"
    | "PROMETHEUS_CONFIGURATION_ERROR"
    | "DATA_DOG_CONFIGURATION_ERROR"
    | "SERVICE_GUARD_CONFIGURATION_ERROR"
    | "ENCRYPTION_NOT_CONFIGURED"
    | "UNAVAILABLE_DELEGATES"
    | "WORKFLOW_EXECUTION_IN_PROGRESS"
    | "PIPELINE_EXECUTION_IN_PROGRESS"
    | "AWS_ACCESS_DENIED"
    | "AWS_CLUSTER_NOT_FOUND"
    | "AWS_SERVICE_NOT_FOUND"
    | "IMAGE_NOT_FOUND"
    | "ILLEGAL_ARGUMENT"
    | "IMAGE_TAG_NOT_FOUND"
    | "DELEGATE_NOT_AVAILABLE"
    | "INVALID_YAML_PAYLOAD"
    | "AUTHENTICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "UNRECOGNIZED_YAML_FIELDS"
    | "COULD_NOT_MAP_BEFORE_YAML"
    | "MISSING_BEFORE_YAML"
    | "MISSING_YAML"
    | "NON_EMPTY_DELETIONS"
    | "GENERAL_YAML_ERROR"
    | "GENERAL_YAML_INFO"
    | "YAML_GIT_SYNC_ERROR"
    | "GIT_CONNECTION_ERROR"
    | "GIT_ERROR"
    | "ARTIFACT_SERVER_ERROR"
    | "ENCRYPT_DECRYPT_ERROR"
    | "SECRET_MANAGEMENT_ERROR"
    | "SECRET_NOT_FOUND"
    | "KMS_OPERATION_ERROR"
    | "GCP_KMS_OPERATION_ERROR"
    | "VAULT_OPERATION_ERROR"
    | "AWS_SECRETS_MANAGER_OPERATION_ERROR"
    | "AZURE_KEY_VAULT_OPERATION_ERROR"
    | "UNSUPPORTED_OPERATION_EXCEPTION"
    | "FEATURE_UNAVAILABLE"
    | "GENERAL_ERROR"
    | "BASELINE_CONFIGURATION_ERROR"
    | "SAML_IDP_CONFIGURATION_NOT_AVAILABLE"
    | "INVALID_AUTHENTICATION_MECHANISM"
    | "INVALID_SAML_CONFIGURATION"
    | "INVALID_OAUTH_CONFIGURATION"
    | "INVALID_LDAP_CONFIGURATION"
    | "USER_GROUP_SYNC_FAILURE"
    | "USER_GROUP_ALREADY_EXIST"
    | "INVALID_TWO_FACTOR_AUTHENTICATION_CONFIGURATION"
    | "EXPLANATION"
    | "HINT"
    | "NOT_WHITELISTED_IP"
    | "INVALID_TOTP_TOKEN"
    | "EMAIL_FAILED"
    | "SSL_HANDSHAKE_FAILED"
    | "NO_APPS_ASSIGNED"
    | "INVALID_INFRA_CONFIGURATION"
    | "TEMPLATES_LINKED"
    | "USER_HAS_NO_PERMISSIONS"
    | "USER_NOT_AUTHORIZED"
    | "USER_ALREADY_PRESENT"
    | "EMAIL_ERROR"
    | "INVALID_USAGE_RESTRICTION"
    | "USAGE_RESTRICTION_ERROR"
    | "STATE_EXECUTION_INSTANCE_NOT_FOUND"
    | "DELEGATE_TASK_RETRY"
    | "KUBERNETES_API_TASK_EXCEPTION"
    | "KUBERNETES_TASK_EXCEPTION"
    | "KUBERNETES_YAML_ERROR"
    | "SAVE_FILE_INTO_GCP_STORAGE_FAILED"
    | "READ_FILE_FROM_GCP_STORAGE_FAILED"
    | "FILE_NOT_FOUND_ERROR"
    | "USAGE_LIMITS_EXCEEDED"
    | "EVENT_PUBLISH_FAILED"
    | "CUSTOM_APPROVAL_ERROR"
    | "JIRA_ERROR"
    | "EXPRESSION_EVALUATION_FAILED"
    | "KUBERNETES_VALUES_ERROR"
    | "KUBERNETES_CLUSTER_ERROR"
    | "INCORRECT_SIGN_IN_MECHANISM"
    | "OAUTH_LOGIN_FAILED"
    | "INVALID_TERRAFORM_TARGETS_REQUEST"
    | "TERRAFORM_EXECUTION_ERROR"
    | "FILE_READ_FAILED"
    | "FILE_SIZE_EXCEEDS_LIMIT"
    | "CLUSTER_NOT_FOUND"
    | "MARKETPLACE_TOKEN_NOT_FOUND"
    | "INVALID_MARKETPLACE_TOKEN"
    | "INVALID_TICKETING_SERVER"
    | "SERVICENOW_ERROR"
    | "PASSWORD_EXPIRED"
    | "USER_LOCKED"
    | "PASSWORD_STRENGTH_CHECK_FAILED"
    | "ACCOUNT_DISABLED"
    | "INVALID_ACCOUNT_PERMISSION"
    | "PAGERDUTY_ERROR"
    | "HEALTH_ERROR"
    | "SAML_TEST_SUCCESS_MECHANISM_NOT_ENABLED"
    | "DOMAIN_WHITELIST_FILTER_CHECK_FAILED"
    | "INVALID_DASHBOARD_UPDATE_REQUEST"
    | "DUPLICATE_FIELD"
    | "INVALID_AZURE_VAULT_CONFIGURATION"
    | "USER_NOT_AUTHORIZED_DUE_TO_USAGE_RESTRICTIONS"
    | "INVALID_ROLLBACK"
    | "DATA_COLLECTION_ERROR"
    | "SUMO_DATA_COLLECTION_ERROR"
    | "DEPLOYMENT_GOVERNANCE_ERROR"
    | "BATCH_PROCESSING_ERROR"
    | "GRAPHQL_ERROR"
    | "FILE_CREATE_ERROR"
    | "ILLEGAL_STATE"
    | "GIT_DIFF_COMMIT_NOT_IN_ORDER"
    | "FAILED_TO_ACQUIRE_PERSISTENT_LOCK"
    | "FAILED_TO_ACQUIRE_NON_PERSISTENT_LOCK"
    | "POD_NOT_FOUND_ERROR"
    | "COMMAND_EXECUTION_ERROR"
    | "REGISTRY_EXCEPTION"
    | "ENGINE_INTERRUPT_PROCESSING_EXCEPTION"
    | "ENGINE_IO_EXCEPTION"
    | "ENGINE_OUTCOME_EXCEPTION"
    | "ENGINE_SWEEPING_OUTPUT_EXCEPTION"
    | "CACHE_NOT_FOUND_EXCEPTION"
    | "ENGINE_ENTITY_UPDATE_EXCEPTION"
    | "SHELL_EXECUTION_EXCEPTION"
    | "TEMPLATE_NOT_FOUND"
    | "AZURE_SERVICE_EXCEPTION"
    | "AZURE_CLIENT_EXCEPTION"
    | "GIT_UNSEEN_REMOTE_HEAD_COMMIT"
    | "TIMEOUT_ENGINE_EXCEPTION"
    | "NO_AVAILABLE_DELEGATES"
    | "NO_GLOBAL_DELEGATE_ACCOUNT"
    | "NO_INSTALLED_DELEGATES"
    | "DUPLICATE_DELEGATE_EXCEPTION"
    | "GCP_MARKETPLACE_EXCEPTION"
    | "MISSING_DEFAULT_GOOGLE_CREDENTIALS"
    | "INCORRECT_DEFAULT_GOOGLE_CREDENTIALS"
    | "OPTIMISTIC_LOCKING_EXCEPTION"
    | "NG_PIPELINE_EXECUTION_EXCEPTION"
    | "NG_PIPELINE_CREATE_EXCEPTION"
    | "RESOURCE_NOT_FOUND_EXCEPTION"
    | "PMS_INITIALIZE_SDK_EXCEPTION"
    | "UNEXPECTED_SNIPPET_EXCEPTION"
    | "UNEXPECTED_SCHEMA_EXCEPTION"
    | "CONNECTOR_VALIDATION_EXCEPTION"
    | "TIMESCALE_NOT_AVAILABLE"
    | "MIGRATION_EXCEPTION"
    | "REQUEST_PROCESSING_INTERRUPTED"
    | "SECRET_MANAGER_ID_NOT_FOUND"
    | "GCP_SECRET_MANAGER_OPERATION_ERROR"
    | "GCP_SECRET_OPERATION_ERROR"
    | "GIT_OPERATION_ERROR"
    | "TASK_FAILURE_ERROR"
    | "INSTANCE_STATS_PROCESS_ERROR"
    | "INSTANCE_STATS_MIGRATION_ERROR"
    | "DEPLOYMENT_MIGRATION_ERROR"
    | "CG_LICENSE_USAGE_ERROR"
    | "INSTANCE_STATS_AGGREGATION_ERROR"
    | "UNRESOLVED_EXPRESSIONS_ERROR"
    | "KRYO_HANDLER_NOT_FOUND_ERROR"
    | "DELEGATE_ERROR_HANDLER_EXCEPTION"
    | "UNEXPECTED_TYPE_ERROR"
    | "EXCEPTION_HANDLER_NOT_FOUND"
    | "CONNECTOR_NOT_FOUND_EXCEPTION"
    | "GCP_SERVER_ERROR"
    | "HTTP_RESPONSE_EXCEPTION"
    | "SCM_NOT_FOUND_ERROR"
    | "SCM_CONFLICT_ERROR"
    | "SCM_CONFLICT_ERROR_V2"
    | "SCM_UNPROCESSABLE_ENTITY"
    | "PROCESS_EXECUTION_EXCEPTION"
    | "SCM_UNAUTHORIZED"
    | "SCM_BAD_REQUEST"
    | "SCM_INTERNAL_SERVER_ERROR"
    | "DATA"
    | "CONTEXT"
    | "PR_CREATION_ERROR"
    | "URL_NOT_REACHABLE"
    | "URL_NOT_PROVIDED"
    | "ENGINE_EXPRESSION_EVALUATION_ERROR"
    | "ENGINE_FUNCTOR_ERROR"
    | "JIRA_CLIENT_ERROR"
    | "SCM_NOT_MODIFIED"
    | "APPROVAL_STEP_NG_ERROR"
    | "BUCKET_SERVER_ERROR"
    | "GIT_SYNC_ERROR"
    | "TEMPLATE_EXCEPTION"
    | "ENTITY_REFERENCE_EXCEPTION"
    | "INVALID_INPUT_SET"
    | "INVALID_OVERLAY_INPUT_SET"
    | "RESOURCE_ALREADY_EXISTS"
    | "INVALID_JSON_PAYLOAD"
    | "POLICY_EVALUATION_FAILURE"
    | "POLICY_SET_ERROR"
    | "INVALID_ARTIFACTORY_REGISTRY_REQUEST"
    | "INVALID_NEXUS_REGISTRY_REQUEST"
    | "ENTITY_NOT_FOUND"
    | "INVALID_AZURE_CONTAINER_REGISTRY_REQUEST"
    | "AZURE_AUTHENTICATION_ERROR"
    | "AZURE_CONFIG_ERROR"
    | "DATA_PROCESSING_ERROR"
    | "INVALID_AZURE_AKS_REQUEST"
    | "AWS_IAM_ERROR"
    | "AWS_CF_ERROR"
    | "AWS_INSTANCE_ERROR"
    | "AWS_VPC_ERROR"
    | "AWS_TAG_ERROR"
    | "AWS_ASG_ERROR"
    | "AWS_LOAD_BALANCER_ERROR"
    | "SCM_INTERNAL_SERVER_ERROR_V2"
    | "SCM_UNAUTHORIZED_ERROR_V2"
    | "TOO_MANY_REQUESTS"
    | "INVALID_IDENTIFIER_REF"
    | "SPOTINST_NULL_ERROR"
    | "SCM_UNEXPECTED_ERROR"
    | "DUPLICATE_FILE_IMPORT"
    | "AZURE_APP_SERVICES_TASK_EXCEPTION"
    | "MEDIA_NOT_SUPPORTED"
    | "AWS_ECS_ERROR"
    | "AWS_APPLICATION_AUTO_SCALING"
    | "AWS_ECS_SERVICE_NOT_ACTIVE"
    | "AWS_ECS_CLIENT_ERROR"
    | "AWS_STS_ERROR";
  message?: string;
  correlationId?: string;
  detailedMessage?: string;
  responseMessages?: ResponseMessage[];
  metadata?: ErrorMetadataDTO;
}

export interface ErrorMetadata {
  errorCode?:
    | "DEFAULT_ERROR_CODE"
    | "INVALID_ARGUMENT"
    | "INVALID_EMAIL"
    | "DOMAIN_NOT_ALLOWED_TO_REGISTER"
    | "COMMNITY_EDITION_NOT_FOUND"
    | "DEPLOY_MODE_IS_NOT_ON_PREM"
    | "USER_ALREADY_REGISTERED"
    | "USER_INVITATION_DOES_NOT_EXIST"
    | "USER_DOES_NOT_EXIST"
    | "USER_INVITE_OPERATION_FAILED"
    | "USER_DISABLED"
    | "ACCOUNT_DOES_NOT_EXIST"
    | "INACTIVE_ACCOUNT"
    | "ACCOUNT_MIGRATED"
    | "USER_DOMAIN_NOT_ALLOWED"
    | "MAX_FAILED_ATTEMPT_COUNT_EXCEEDED"
    | "RESOURCE_NOT_FOUND"
    | "INVALID_FORMAT"
    | "ROLE_DOES_NOT_EXIST"
    | "EMAIL_NOT_VERIFIED"
    | "EMAIL_VERIFICATION_TOKEN_NOT_FOUND"
    | "INVALID_TOKEN"
    | "REVOKED_TOKEN"
    | "INVALID_CAPTCHA_TOKEN"
    | "NOT_ACCOUNT_MGR_NOR_HAS_ALL_APP_ACCESS"
    | "EXPIRED_TOKEN"
    | "INVALID_AGENT_MTLS_AUTHORITY"
    | "TOKEN_ALREADY_REFRESHED_ONCE"
    | "ACCESS_DENIED"
    | "NG_ACCESS_DENIED"
    | "INVALID_CREDENTIAL"
    | "INVALID_CREDENTIALS_THIRD_PARTY"
    | "INVALID_KEY"
    | "INVALID_CONNECTOR_TYPE"
    | "INVALID_KEYPATH"
    | "INVALID_VARIABLE"
    | "UNKNOWN_HOST"
    | "UNREACHABLE_HOST"
    | "INVALID_PORT"
    | "SSH_SESSION_TIMEOUT"
    | "SOCKET_CONNECTION_ERROR"
    | "CONNECTION_ERROR"
    | "SOCKET_CONNECTION_TIMEOUT"
    | "WINRM_COMMAND_EXECUTION_TIMEOUT"
    | "CONNECTION_TIMEOUT"
    | "SSH_CONNECTION_ERROR"
    | "USER_GROUP_ERROR"
    | "INVALID_EXECUTION_ID"
    | "ERROR_IN_GETTING_CHANNEL_STREAMS"
    | "UNEXPECTED"
    | "UNKNOWN_ERROR"
    | "UNKNOWN_EXECUTOR_TYPE_ERROR"
    | "DUPLICATE_STATE_NAMES"
    | "TRANSITION_NOT_LINKED"
    | "TRANSITION_TO_INCORRECT_STATE"
    | "TRANSITION_TYPE_NULL"
    | "STATES_WITH_DUP_TRANSITIONS"
    | "BARRIERS_NOT_RUNNING_CONCURRENTLY"
    | "NON_FORK_STATES"
    | "NON_REPEAT_STATES"
    | "INITIAL_STATE_NOT_DEFINED"
    | "FILE_INTEGRITY_CHECK_FAILED"
    | "INVALID_URL"
    | "FILE_DOWNLOAD_FAILED"
    | "PLATFORM_SOFTWARE_DELETE_ERROR"
    | "INVALID_CSV_FILE"
    | "INVALID_REQUEST"
    | "SCHEMA_VALIDATION_FAILED"
    | "FILTER_CREATION_ERROR"
    | "INVALID_YAML_ERROR"
    | "PLAN_CREATION_ERROR"
    | "INVALID_INFRA_STATE"
    | "PIPELINE_ALREADY_TRIGGERED"
    | "NON_EXISTING_PIPELINE"
    | "DUPLICATE_COMMAND_NAMES"
    | "INVALID_PIPELINE"
    | "COMMAND_DOES_NOT_EXIST"
    | "DUPLICATE_ARTIFACTSTREAM_NAMES"
    | "DUPLICATE_HOST_NAMES"
    | "STATE_NOT_FOR_TYPE"
    | "STATE_MACHINE_ISSUE"
    | "STATE_DISCONTINUE_FAILED"
    | "STATE_PAUSE_FAILED"
    | "PAUSE_ALL_ALREADY"
    | "RESUME_ALL_ALREADY"
    | "ROLLBACK_ALREADY"
    | "ABORT_ALL_ALREADY"
    | "EXPIRE_ALL_ALREADY"
    | "RETRY_FAILED"
    | "UNKNOWN_ARTIFACT_TYPE"
    | "UNKNOWN_STAGE_ELEMENT_WRAPPER_TYPE"
    | "INIT_TIMEOUT"
    | "LICENSE_EXPIRED"
    | "NOT_LICENSED"
    | "REQUEST_TIMEOUT"
    | "WORKFLOW_ALREADY_TRIGGERED"
    | "JENKINS_ERROR"
    | "INVALID_ARTIFACT_SOURCE"
    | "INVALID_ARTIFACT_SERVER"
    | "INVALID_CLOUD_PROVIDER"
    | "UPDATE_NOT_ALLOWED"
    | "DELETE_NOT_ALLOWED"
    | "APPDYNAMICS_CONFIGURATION_ERROR"
    | "APM_CONFIGURATION_ERROR"
    | "SPLUNK_CONFIGURATION_ERROR"
    | "ELK_CONFIGURATION_ERROR"
    | "LOGZ_CONFIGURATION_ERROR"
    | "SUMO_CONFIGURATION_ERROR"
    | "INSTANA_CONFIGURATION_ERROR"
    | "APPDYNAMICS_ERROR"
    | "STACKDRIVER_ERROR"
    | "STACKDRIVER_CONFIGURATION_ERROR"
    | "NEWRELIC_CONFIGURATION_ERROR"
    | "NEWRELIC_ERROR"
    | "DYNA_TRACE_CONFIGURATION_ERROR"
    | "DYNA_TRACE_ERROR"
    | "CLOUDWATCH_ERROR"
    | "CLOUDWATCH_CONFIGURATION_ERROR"
    | "PROMETHEUS_CONFIGURATION_ERROR"
    | "DATA_DOG_CONFIGURATION_ERROR"
    | "SERVICE_GUARD_CONFIGURATION_ERROR"
    | "ENCRYPTION_NOT_CONFIGURED"
    | "UNAVAILABLE_DELEGATES"
    | "WORKFLOW_EXECUTION_IN_PROGRESS"
    | "PIPELINE_EXECUTION_IN_PROGRESS"
    | "AWS_ACCESS_DENIED"
    | "AWS_CLUSTER_NOT_FOUND"
    | "AWS_SERVICE_NOT_FOUND"
    | "IMAGE_NOT_FOUND"
    | "ILLEGAL_ARGUMENT"
    | "IMAGE_TAG_NOT_FOUND"
    | "DELEGATE_NOT_AVAILABLE"
    | "INVALID_YAML_PAYLOAD"
    | "AUTHENTICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "UNRECOGNIZED_YAML_FIELDS"
    | "COULD_NOT_MAP_BEFORE_YAML"
    | "MISSING_BEFORE_YAML"
    | "MISSING_YAML"
    | "NON_EMPTY_DELETIONS"
    | "GENERAL_YAML_ERROR"
    | "GENERAL_YAML_INFO"
    | "YAML_GIT_SYNC_ERROR"
    | "GIT_CONNECTION_ERROR"
    | "GIT_ERROR"
    | "ARTIFACT_SERVER_ERROR"
    | "ENCRYPT_DECRYPT_ERROR"
    | "SECRET_MANAGEMENT_ERROR"
    | "SECRET_NOT_FOUND"
    | "KMS_OPERATION_ERROR"
    | "GCP_KMS_OPERATION_ERROR"
    | "VAULT_OPERATION_ERROR"
    | "AWS_SECRETS_MANAGER_OPERATION_ERROR"
    | "AZURE_KEY_VAULT_OPERATION_ERROR"
    | "UNSUPPORTED_OPERATION_EXCEPTION"
    | "FEATURE_UNAVAILABLE"
    | "GENERAL_ERROR"
    | "BASELINE_CONFIGURATION_ERROR"
    | "SAML_IDP_CONFIGURATION_NOT_AVAILABLE"
    | "INVALID_AUTHENTICATION_MECHANISM"
    | "INVALID_SAML_CONFIGURATION"
    | "INVALID_OAUTH_CONFIGURATION"
    | "INVALID_LDAP_CONFIGURATION"
    | "USER_GROUP_SYNC_FAILURE"
    | "USER_GROUP_ALREADY_EXIST"
    | "INVALID_TWO_FACTOR_AUTHENTICATION_CONFIGURATION"
    | "EXPLANATION"
    | "HINT"
    | "NOT_WHITELISTED_IP"
    | "INVALID_TOTP_TOKEN"
    | "EMAIL_FAILED"
    | "SSL_HANDSHAKE_FAILED"
    | "NO_APPS_ASSIGNED"
    | "INVALID_INFRA_CONFIGURATION"
    | "TEMPLATES_LINKED"
    | "USER_HAS_NO_PERMISSIONS"
    | "USER_NOT_AUTHORIZED"
    | "USER_ALREADY_PRESENT"
    | "EMAIL_ERROR"
    | "INVALID_USAGE_RESTRICTION"
    | "USAGE_RESTRICTION_ERROR"
    | "STATE_EXECUTION_INSTANCE_NOT_FOUND"
    | "DELEGATE_TASK_RETRY"
    | "KUBERNETES_API_TASK_EXCEPTION"
    | "KUBERNETES_TASK_EXCEPTION"
    | "KUBERNETES_YAML_ERROR"
    | "SAVE_FILE_INTO_GCP_STORAGE_FAILED"
    | "READ_FILE_FROM_GCP_STORAGE_FAILED"
    | "FILE_NOT_FOUND_ERROR"
    | "USAGE_LIMITS_EXCEEDED"
    | "EVENT_PUBLISH_FAILED"
    | "CUSTOM_APPROVAL_ERROR"
    | "JIRA_ERROR"
    | "EXPRESSION_EVALUATION_FAILED"
    | "KUBERNETES_VALUES_ERROR"
    | "KUBERNETES_CLUSTER_ERROR"
    | "INCORRECT_SIGN_IN_MECHANISM"
    | "OAUTH_LOGIN_FAILED"
    | "INVALID_TERRAFORM_TARGETS_REQUEST"
    | "TERRAFORM_EXECUTION_ERROR"
    | "FILE_READ_FAILED"
    | "FILE_SIZE_EXCEEDS_LIMIT"
    | "CLUSTER_NOT_FOUND"
    | "MARKETPLACE_TOKEN_NOT_FOUND"
    | "INVALID_MARKETPLACE_TOKEN"
    | "INVALID_TICKETING_SERVER"
    | "SERVICENOW_ERROR"
    | "PASSWORD_EXPIRED"
    | "USER_LOCKED"
    | "PASSWORD_STRENGTH_CHECK_FAILED"
    | "ACCOUNT_DISABLED"
    | "INVALID_ACCOUNT_PERMISSION"
    | "PAGERDUTY_ERROR"
    | "HEALTH_ERROR"
    | "SAML_TEST_SUCCESS_MECHANISM_NOT_ENABLED"
    | "DOMAIN_WHITELIST_FILTER_CHECK_FAILED"
    | "INVALID_DASHBOARD_UPDATE_REQUEST"
    | "DUPLICATE_FIELD"
    | "INVALID_AZURE_VAULT_CONFIGURATION"
    | "USER_NOT_AUTHORIZED_DUE_TO_USAGE_RESTRICTIONS"
    | "INVALID_ROLLBACK"
    | "DATA_COLLECTION_ERROR"
    | "SUMO_DATA_COLLECTION_ERROR"
    | "DEPLOYMENT_GOVERNANCE_ERROR"
    | "BATCH_PROCESSING_ERROR"
    | "GRAPHQL_ERROR"
    | "FILE_CREATE_ERROR"
    | "ILLEGAL_STATE"
    | "GIT_DIFF_COMMIT_NOT_IN_ORDER"
    | "FAILED_TO_ACQUIRE_PERSISTENT_LOCK"
    | "FAILED_TO_ACQUIRE_NON_PERSISTENT_LOCK"
    | "POD_NOT_FOUND_ERROR"
    | "COMMAND_EXECUTION_ERROR"
    | "REGISTRY_EXCEPTION"
    | "ENGINE_INTERRUPT_PROCESSING_EXCEPTION"
    | "ENGINE_IO_EXCEPTION"
    | "ENGINE_OUTCOME_EXCEPTION"
    | "ENGINE_SWEEPING_OUTPUT_EXCEPTION"
    | "CACHE_NOT_FOUND_EXCEPTION"
    | "ENGINE_ENTITY_UPDATE_EXCEPTION"
    | "SHELL_EXECUTION_EXCEPTION"
    | "TEMPLATE_NOT_FOUND"
    | "AZURE_SERVICE_EXCEPTION"
    | "AZURE_CLIENT_EXCEPTION"
    | "GIT_UNSEEN_REMOTE_HEAD_COMMIT"
    | "TIMEOUT_ENGINE_EXCEPTION"
    | "NO_AVAILABLE_DELEGATES"
    | "NO_GLOBAL_DELEGATE_ACCOUNT"
    | "NO_INSTALLED_DELEGATES"
    | "DUPLICATE_DELEGATE_EXCEPTION"
    | "GCP_MARKETPLACE_EXCEPTION"
    | "MISSING_DEFAULT_GOOGLE_CREDENTIALS"
    | "INCORRECT_DEFAULT_GOOGLE_CREDENTIALS"
    | "OPTIMISTIC_LOCKING_EXCEPTION"
    | "NG_PIPELINE_EXECUTION_EXCEPTION"
    | "NG_PIPELINE_CREATE_EXCEPTION"
    | "RESOURCE_NOT_FOUND_EXCEPTION"
    | "PMS_INITIALIZE_SDK_EXCEPTION"
    | "UNEXPECTED_SNIPPET_EXCEPTION"
    | "UNEXPECTED_SCHEMA_EXCEPTION"
    | "CONNECTOR_VALIDATION_EXCEPTION"
    | "TIMESCALE_NOT_AVAILABLE"
    | "MIGRATION_EXCEPTION"
    | "REQUEST_PROCESSING_INTERRUPTED"
    | "SECRET_MANAGER_ID_NOT_FOUND"
    | "GCP_SECRET_MANAGER_OPERATION_ERROR"
    | "GCP_SECRET_OPERATION_ERROR"
    | "GIT_OPERATION_ERROR"
    | "TASK_FAILURE_ERROR"
    | "INSTANCE_STATS_PROCESS_ERROR"
    | "INSTANCE_STATS_MIGRATION_ERROR"
    | "DEPLOYMENT_MIGRATION_ERROR"
    | "CG_LICENSE_USAGE_ERROR"
    | "INSTANCE_STATS_AGGREGATION_ERROR"
    | "UNRESOLVED_EXPRESSIONS_ERROR"
    | "KRYO_HANDLER_NOT_FOUND_ERROR"
    | "DELEGATE_ERROR_HANDLER_EXCEPTION"
    | "UNEXPECTED_TYPE_ERROR"
    | "EXCEPTION_HANDLER_NOT_FOUND"
    | "CONNECTOR_NOT_FOUND_EXCEPTION"
    | "GCP_SERVER_ERROR"
    | "HTTP_RESPONSE_EXCEPTION"
    | "SCM_NOT_FOUND_ERROR"
    | "SCM_CONFLICT_ERROR"
    | "SCM_CONFLICT_ERROR_V2"
    | "SCM_UNPROCESSABLE_ENTITY"
    | "PROCESS_EXECUTION_EXCEPTION"
    | "SCM_UNAUTHORIZED"
    | "SCM_BAD_REQUEST"
    | "SCM_INTERNAL_SERVER_ERROR"
    | "DATA"
    | "CONTEXT"
    | "PR_CREATION_ERROR"
    | "URL_NOT_REACHABLE"
    | "URL_NOT_PROVIDED"
    | "ENGINE_EXPRESSION_EVALUATION_ERROR"
    | "ENGINE_FUNCTOR_ERROR"
    | "JIRA_CLIENT_ERROR"
    | "SCM_NOT_MODIFIED"
    | "APPROVAL_STEP_NG_ERROR"
    | "BUCKET_SERVER_ERROR"
    | "GIT_SYNC_ERROR"
    | "TEMPLATE_EXCEPTION"
    | "ENTITY_REFERENCE_EXCEPTION"
    | "INVALID_INPUT_SET"
    | "INVALID_OVERLAY_INPUT_SET"
    | "RESOURCE_ALREADY_EXISTS"
    | "INVALID_JSON_PAYLOAD"
    | "POLICY_EVALUATION_FAILURE"
    | "POLICY_SET_ERROR"
    | "INVALID_ARTIFACTORY_REGISTRY_REQUEST"
    | "INVALID_NEXUS_REGISTRY_REQUEST"
    | "ENTITY_NOT_FOUND"
    | "INVALID_AZURE_CONTAINER_REGISTRY_REQUEST"
    | "AZURE_AUTHENTICATION_ERROR"
    | "AZURE_CONFIG_ERROR"
    | "DATA_PROCESSING_ERROR"
    | "INVALID_AZURE_AKS_REQUEST"
    | "AWS_IAM_ERROR"
    | "AWS_CF_ERROR"
    | "AWS_INSTANCE_ERROR"
    | "AWS_VPC_ERROR"
    | "AWS_TAG_ERROR"
    | "AWS_ASG_ERROR"
    | "AWS_LOAD_BALANCER_ERROR"
    | "SCM_INTERNAL_SERVER_ERROR_V2"
    | "SCM_UNAUTHORIZED_ERROR_V2"
    | "TOO_MANY_REQUESTS"
    | "INVALID_IDENTIFIER_REF"
    | "SPOTINST_NULL_ERROR"
    | "SCM_UNEXPECTED_ERROR"
    | "DUPLICATE_FILE_IMPORT"
    | "AZURE_APP_SERVICES_TASK_EXCEPTION"
    | "MEDIA_NOT_SUPPORTED"
    | "AWS_ECS_ERROR"
    | "AWS_APPLICATION_AUTO_SCALING"
    | "AWS_ECS_SERVICE_NOT_ACTIVE"
    | "AWS_ECS_CLIENT_ERROR"
    | "AWS_STS_ERROR";
  errorMessage?: string;
}

export interface ErrorMetadataDTO {
  type?: string;
}

export interface ErrorNodeSummary {
  nodeInfo?: NodeInfo;
  templateInfo?: TemplateInfo;
  templateResponse?: TemplateResponse;
  childrenErrorNodes?: ErrorNodeSummary[];
}

export type FilterCreatorErrorResponse = ErrorMetadataDTO & {
  errorMetadataList?: ErrorMetadata[];
};

export interface InputSetError {
  fieldName?: string;
  message?: string;
  identifierOfErrorSource?: string;
}

export interface InputSetErrorResponse {
  errors?: InputSetError[];
}

export type InputSetErrorWrapper = ErrorMetadataDTO & {
  errorPipelineYaml?: string;
  uuidToErrorResponseMap?: {
    [key: string]: InputSetErrorResponse;
  };
  invalidInputSetReferences?: string[];
};

export type InvalidFieldsDTO = ErrorMetadataDTO & {};

export interface NodeErrorInfo {
  identifier?: string;
  type?: string;
  name?: string;
  fqn?: string;
}

export interface NodeInfo {
  identifier?: string;
  name?: string;
  localFqn?: string;
}

export type OverlayInputSetErrorWrapper = ErrorMetadataDTO & {
  invalidReferences?: {
    [key: string]: string;
  };
};

export interface ResponseMessage {
  code?:
    | "DEFAULT_ERROR_CODE"
    | "INVALID_ARGUMENT"
    | "INVALID_EMAIL"
    | "DOMAIN_NOT_ALLOWED_TO_REGISTER"
    | "COMMNITY_EDITION_NOT_FOUND"
    | "DEPLOY_MODE_IS_NOT_ON_PREM"
    | "USER_ALREADY_REGISTERED"
    | "USER_INVITATION_DOES_NOT_EXIST"
    | "USER_DOES_NOT_EXIST"
    | "USER_INVITE_OPERATION_FAILED"
    | "USER_DISABLED"
    | "ACCOUNT_DOES_NOT_EXIST"
    | "INACTIVE_ACCOUNT"
    | "ACCOUNT_MIGRATED"
    | "USER_DOMAIN_NOT_ALLOWED"
    | "MAX_FAILED_ATTEMPT_COUNT_EXCEEDED"
    | "RESOURCE_NOT_FOUND"
    | "INVALID_FORMAT"
    | "ROLE_DOES_NOT_EXIST"
    | "EMAIL_NOT_VERIFIED"
    | "EMAIL_VERIFICATION_TOKEN_NOT_FOUND"
    | "INVALID_TOKEN"
    | "REVOKED_TOKEN"
    | "INVALID_CAPTCHA_TOKEN"
    | "NOT_ACCOUNT_MGR_NOR_HAS_ALL_APP_ACCESS"
    | "EXPIRED_TOKEN"
    | "INVALID_AGENT_MTLS_AUTHORITY"
    | "TOKEN_ALREADY_REFRESHED_ONCE"
    | "ACCESS_DENIED"
    | "NG_ACCESS_DENIED"
    | "INVALID_CREDENTIAL"
    | "INVALID_CREDENTIALS_THIRD_PARTY"
    | "INVALID_KEY"
    | "INVALID_CONNECTOR_TYPE"
    | "INVALID_KEYPATH"
    | "INVALID_VARIABLE"
    | "UNKNOWN_HOST"
    | "UNREACHABLE_HOST"
    | "INVALID_PORT"
    | "SSH_SESSION_TIMEOUT"
    | "SOCKET_CONNECTION_ERROR"
    | "CONNECTION_ERROR"
    | "SOCKET_CONNECTION_TIMEOUT"
    | "WINRM_COMMAND_EXECUTION_TIMEOUT"
    | "CONNECTION_TIMEOUT"
    | "SSH_CONNECTION_ERROR"
    | "USER_GROUP_ERROR"
    | "INVALID_EXECUTION_ID"
    | "ERROR_IN_GETTING_CHANNEL_STREAMS"
    | "UNEXPECTED"
    | "UNKNOWN_ERROR"
    | "UNKNOWN_EXECUTOR_TYPE_ERROR"
    | "DUPLICATE_STATE_NAMES"
    | "TRANSITION_NOT_LINKED"
    | "TRANSITION_TO_INCORRECT_STATE"
    | "TRANSITION_TYPE_NULL"
    | "STATES_WITH_DUP_TRANSITIONS"
    | "BARRIERS_NOT_RUNNING_CONCURRENTLY"
    | "NON_FORK_STATES"
    | "NON_REPEAT_STATES"
    | "INITIAL_STATE_NOT_DEFINED"
    | "FILE_INTEGRITY_CHECK_FAILED"
    | "INVALID_URL"
    | "FILE_DOWNLOAD_FAILED"
    | "PLATFORM_SOFTWARE_DELETE_ERROR"
    | "INVALID_CSV_FILE"
    | "INVALID_REQUEST"
    | "SCHEMA_VALIDATION_FAILED"
    | "FILTER_CREATION_ERROR"
    | "INVALID_YAML_ERROR"
    | "PLAN_CREATION_ERROR"
    | "INVALID_INFRA_STATE"
    | "PIPELINE_ALREADY_TRIGGERED"
    | "NON_EXISTING_PIPELINE"
    | "DUPLICATE_COMMAND_NAMES"
    | "INVALID_PIPELINE"
    | "COMMAND_DOES_NOT_EXIST"
    | "DUPLICATE_ARTIFACTSTREAM_NAMES"
    | "DUPLICATE_HOST_NAMES"
    | "STATE_NOT_FOR_TYPE"
    | "STATE_MACHINE_ISSUE"
    | "STATE_DISCONTINUE_FAILED"
    | "STATE_PAUSE_FAILED"
    | "PAUSE_ALL_ALREADY"
    | "RESUME_ALL_ALREADY"
    | "ROLLBACK_ALREADY"
    | "ABORT_ALL_ALREADY"
    | "EXPIRE_ALL_ALREADY"
    | "RETRY_FAILED"
    | "UNKNOWN_ARTIFACT_TYPE"
    | "UNKNOWN_STAGE_ELEMENT_WRAPPER_TYPE"
    | "INIT_TIMEOUT"
    | "LICENSE_EXPIRED"
    | "NOT_LICENSED"
    | "REQUEST_TIMEOUT"
    | "WORKFLOW_ALREADY_TRIGGERED"
    | "JENKINS_ERROR"
    | "INVALID_ARTIFACT_SOURCE"
    | "INVALID_ARTIFACT_SERVER"
    | "INVALID_CLOUD_PROVIDER"
    | "UPDATE_NOT_ALLOWED"
    | "DELETE_NOT_ALLOWED"
    | "APPDYNAMICS_CONFIGURATION_ERROR"
    | "APM_CONFIGURATION_ERROR"
    | "SPLUNK_CONFIGURATION_ERROR"
    | "ELK_CONFIGURATION_ERROR"
    | "LOGZ_CONFIGURATION_ERROR"
    | "SUMO_CONFIGURATION_ERROR"
    | "INSTANA_CONFIGURATION_ERROR"
    | "APPDYNAMICS_ERROR"
    | "STACKDRIVER_ERROR"
    | "STACKDRIVER_CONFIGURATION_ERROR"
    | "NEWRELIC_CONFIGURATION_ERROR"
    | "NEWRELIC_ERROR"
    | "DYNA_TRACE_CONFIGURATION_ERROR"
    | "DYNA_TRACE_ERROR"
    | "CLOUDWATCH_ERROR"
    | "CLOUDWATCH_CONFIGURATION_ERROR"
    | "PROMETHEUS_CONFIGURATION_ERROR"
    | "DATA_DOG_CONFIGURATION_ERROR"
    | "SERVICE_GUARD_CONFIGURATION_ERROR"
    | "ENCRYPTION_NOT_CONFIGURED"
    | "UNAVAILABLE_DELEGATES"
    | "WORKFLOW_EXECUTION_IN_PROGRESS"
    | "PIPELINE_EXECUTION_IN_PROGRESS"
    | "AWS_ACCESS_DENIED"
    | "AWS_CLUSTER_NOT_FOUND"
    | "AWS_SERVICE_NOT_FOUND"
    | "IMAGE_NOT_FOUND"
    | "ILLEGAL_ARGUMENT"
    | "IMAGE_TAG_NOT_FOUND"
    | "DELEGATE_NOT_AVAILABLE"
    | "INVALID_YAML_PAYLOAD"
    | "AUTHENTICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "UNRECOGNIZED_YAML_FIELDS"
    | "COULD_NOT_MAP_BEFORE_YAML"
    | "MISSING_BEFORE_YAML"
    | "MISSING_YAML"
    | "NON_EMPTY_DELETIONS"
    | "GENERAL_YAML_ERROR"
    | "GENERAL_YAML_INFO"
    | "YAML_GIT_SYNC_ERROR"
    | "GIT_CONNECTION_ERROR"
    | "GIT_ERROR"
    | "ARTIFACT_SERVER_ERROR"
    | "ENCRYPT_DECRYPT_ERROR"
    | "SECRET_MANAGEMENT_ERROR"
    | "SECRET_NOT_FOUND"
    | "KMS_OPERATION_ERROR"
    | "GCP_KMS_OPERATION_ERROR"
    | "VAULT_OPERATION_ERROR"
    | "AWS_SECRETS_MANAGER_OPERATION_ERROR"
    | "AZURE_KEY_VAULT_OPERATION_ERROR"
    | "UNSUPPORTED_OPERATION_EXCEPTION"
    | "FEATURE_UNAVAILABLE"
    | "GENERAL_ERROR"
    | "BASELINE_CONFIGURATION_ERROR"
    | "SAML_IDP_CONFIGURATION_NOT_AVAILABLE"
    | "INVALID_AUTHENTICATION_MECHANISM"
    | "INVALID_SAML_CONFIGURATION"
    | "INVALID_OAUTH_CONFIGURATION"
    | "INVALID_LDAP_CONFIGURATION"
    | "USER_GROUP_SYNC_FAILURE"
    | "USER_GROUP_ALREADY_EXIST"
    | "INVALID_TWO_FACTOR_AUTHENTICATION_CONFIGURATION"
    | "EXPLANATION"
    | "HINT"
    | "NOT_WHITELISTED_IP"
    | "INVALID_TOTP_TOKEN"
    | "EMAIL_FAILED"
    | "SSL_HANDSHAKE_FAILED"
    | "NO_APPS_ASSIGNED"
    | "INVALID_INFRA_CONFIGURATION"
    | "TEMPLATES_LINKED"
    | "USER_HAS_NO_PERMISSIONS"
    | "USER_NOT_AUTHORIZED"
    | "USER_ALREADY_PRESENT"
    | "EMAIL_ERROR"
    | "INVALID_USAGE_RESTRICTION"
    | "USAGE_RESTRICTION_ERROR"
    | "STATE_EXECUTION_INSTANCE_NOT_FOUND"
    | "DELEGATE_TASK_RETRY"
    | "KUBERNETES_API_TASK_EXCEPTION"
    | "KUBERNETES_TASK_EXCEPTION"
    | "KUBERNETES_YAML_ERROR"
    | "SAVE_FILE_INTO_GCP_STORAGE_FAILED"
    | "READ_FILE_FROM_GCP_STORAGE_FAILED"
    | "FILE_NOT_FOUND_ERROR"
    | "USAGE_LIMITS_EXCEEDED"
    | "EVENT_PUBLISH_FAILED"
    | "CUSTOM_APPROVAL_ERROR"
    | "JIRA_ERROR"
    | "EXPRESSION_EVALUATION_FAILED"
    | "KUBERNETES_VALUES_ERROR"
    | "KUBERNETES_CLUSTER_ERROR"
    | "INCORRECT_SIGN_IN_MECHANISM"
    | "OAUTH_LOGIN_FAILED"
    | "INVALID_TERRAFORM_TARGETS_REQUEST"
    | "TERRAFORM_EXECUTION_ERROR"
    | "FILE_READ_FAILED"
    | "FILE_SIZE_EXCEEDS_LIMIT"
    | "CLUSTER_NOT_FOUND"
    | "MARKETPLACE_TOKEN_NOT_FOUND"
    | "INVALID_MARKETPLACE_TOKEN"
    | "INVALID_TICKETING_SERVER"
    | "SERVICENOW_ERROR"
    | "PASSWORD_EXPIRED"
    | "USER_LOCKED"
    | "PASSWORD_STRENGTH_CHECK_FAILED"
    | "ACCOUNT_DISABLED"
    | "INVALID_ACCOUNT_PERMISSION"
    | "PAGERDUTY_ERROR"
    | "HEALTH_ERROR"
    | "SAML_TEST_SUCCESS_MECHANISM_NOT_ENABLED"
    | "DOMAIN_WHITELIST_FILTER_CHECK_FAILED"
    | "INVALID_DASHBOARD_UPDATE_REQUEST"
    | "DUPLICATE_FIELD"
    | "INVALID_AZURE_VAULT_CONFIGURATION"
    | "USER_NOT_AUTHORIZED_DUE_TO_USAGE_RESTRICTIONS"
    | "INVALID_ROLLBACK"
    | "DATA_COLLECTION_ERROR"
    | "SUMO_DATA_COLLECTION_ERROR"
    | "DEPLOYMENT_GOVERNANCE_ERROR"
    | "BATCH_PROCESSING_ERROR"
    | "GRAPHQL_ERROR"
    | "FILE_CREATE_ERROR"
    | "ILLEGAL_STATE"
    | "GIT_DIFF_COMMIT_NOT_IN_ORDER"
    | "FAILED_TO_ACQUIRE_PERSISTENT_LOCK"
    | "FAILED_TO_ACQUIRE_NON_PERSISTENT_LOCK"
    | "POD_NOT_FOUND_ERROR"
    | "COMMAND_EXECUTION_ERROR"
    | "REGISTRY_EXCEPTION"
    | "ENGINE_INTERRUPT_PROCESSING_EXCEPTION"
    | "ENGINE_IO_EXCEPTION"
    | "ENGINE_OUTCOME_EXCEPTION"
    | "ENGINE_SWEEPING_OUTPUT_EXCEPTION"
    | "CACHE_NOT_FOUND_EXCEPTION"
    | "ENGINE_ENTITY_UPDATE_EXCEPTION"
    | "SHELL_EXECUTION_EXCEPTION"
    | "TEMPLATE_NOT_FOUND"
    | "AZURE_SERVICE_EXCEPTION"
    | "AZURE_CLIENT_EXCEPTION"
    | "GIT_UNSEEN_REMOTE_HEAD_COMMIT"
    | "TIMEOUT_ENGINE_EXCEPTION"
    | "NO_AVAILABLE_DELEGATES"
    | "NO_GLOBAL_DELEGATE_ACCOUNT"
    | "NO_INSTALLED_DELEGATES"
    | "DUPLICATE_DELEGATE_EXCEPTION"
    | "GCP_MARKETPLACE_EXCEPTION"
    | "MISSING_DEFAULT_GOOGLE_CREDENTIALS"
    | "INCORRECT_DEFAULT_GOOGLE_CREDENTIALS"
    | "OPTIMISTIC_LOCKING_EXCEPTION"
    | "NG_PIPELINE_EXECUTION_EXCEPTION"
    | "NG_PIPELINE_CREATE_EXCEPTION"
    | "RESOURCE_NOT_FOUND_EXCEPTION"
    | "PMS_INITIALIZE_SDK_EXCEPTION"
    | "UNEXPECTED_SNIPPET_EXCEPTION"
    | "UNEXPECTED_SCHEMA_EXCEPTION"
    | "CONNECTOR_VALIDATION_EXCEPTION"
    | "TIMESCALE_NOT_AVAILABLE"
    | "MIGRATION_EXCEPTION"
    | "REQUEST_PROCESSING_INTERRUPTED"
    | "SECRET_MANAGER_ID_NOT_FOUND"
    | "GCP_SECRET_MANAGER_OPERATION_ERROR"
    | "GCP_SECRET_OPERATION_ERROR"
    | "GIT_OPERATION_ERROR"
    | "TASK_FAILURE_ERROR"
    | "INSTANCE_STATS_PROCESS_ERROR"
    | "INSTANCE_STATS_MIGRATION_ERROR"
    | "DEPLOYMENT_MIGRATION_ERROR"
    | "CG_LICENSE_USAGE_ERROR"
    | "INSTANCE_STATS_AGGREGATION_ERROR"
    | "UNRESOLVED_EXPRESSIONS_ERROR"
    | "KRYO_HANDLER_NOT_FOUND_ERROR"
    | "DELEGATE_ERROR_HANDLER_EXCEPTION"
    | "UNEXPECTED_TYPE_ERROR"
    | "EXCEPTION_HANDLER_NOT_FOUND"
    | "CONNECTOR_NOT_FOUND_EXCEPTION"
    | "GCP_SERVER_ERROR"
    | "HTTP_RESPONSE_EXCEPTION"
    | "SCM_NOT_FOUND_ERROR"
    | "SCM_CONFLICT_ERROR"
    | "SCM_CONFLICT_ERROR_V2"
    | "SCM_UNPROCESSABLE_ENTITY"
    | "PROCESS_EXECUTION_EXCEPTION"
    | "SCM_UNAUTHORIZED"
    | "SCM_BAD_REQUEST"
    | "SCM_INTERNAL_SERVER_ERROR"
    | "DATA"
    | "CONTEXT"
    | "PR_CREATION_ERROR"
    | "URL_NOT_REACHABLE"
    | "URL_NOT_PROVIDED"
    | "ENGINE_EXPRESSION_EVALUATION_ERROR"
    | "ENGINE_FUNCTOR_ERROR"
    | "JIRA_CLIENT_ERROR"
    | "SCM_NOT_MODIFIED"
    | "APPROVAL_STEP_NG_ERROR"
    | "BUCKET_SERVER_ERROR"
    | "GIT_SYNC_ERROR"
    | "TEMPLATE_EXCEPTION"
    | "ENTITY_REFERENCE_EXCEPTION"
    | "INVALID_INPUT_SET"
    | "INVALID_OVERLAY_INPUT_SET"
    | "RESOURCE_ALREADY_EXISTS"
    | "INVALID_JSON_PAYLOAD"
    | "POLICY_EVALUATION_FAILURE"
    | "POLICY_SET_ERROR"
    | "INVALID_ARTIFACTORY_REGISTRY_REQUEST"
    | "INVALID_NEXUS_REGISTRY_REQUEST"
    | "ENTITY_NOT_FOUND"
    | "INVALID_AZURE_CONTAINER_REGISTRY_REQUEST"
    | "AZURE_AUTHENTICATION_ERROR"
    | "AZURE_CONFIG_ERROR"
    | "DATA_PROCESSING_ERROR"
    | "INVALID_AZURE_AKS_REQUEST"
    | "AWS_IAM_ERROR"
    | "AWS_CF_ERROR"
    | "AWS_INSTANCE_ERROR"
    | "AWS_VPC_ERROR"
    | "AWS_TAG_ERROR"
    | "AWS_ASG_ERROR"
    | "AWS_LOAD_BALANCER_ERROR"
    | "SCM_INTERNAL_SERVER_ERROR_V2"
    | "SCM_UNAUTHORIZED_ERROR_V2"
    | "TOO_MANY_REQUESTS"
    | "INVALID_IDENTIFIER_REF"
    | "SPOTINST_NULL_ERROR"
    | "SCM_UNEXPECTED_ERROR"
    | "DUPLICATE_FILE_IMPORT"
    | "AZURE_APP_SERVICES_TASK_EXCEPTION"
    | "MEDIA_NOT_SUPPORTED"
    | "AWS_ECS_ERROR"
    | "AWS_APPLICATION_AUTO_SCALING"
    | "AWS_ECS_SERVICE_NOT_ACTIVE"
    | "AWS_ECS_CLIENT_ERROR"
    | "AWS_STS_ERROR";
  level?: "INFO" | "ERROR";
  message?: string;
  exception?: Throwable;
  failureTypes?: (
    | "EXPIRED"
    | "DELEGATE_PROVISIONING"
    | "CONNECTIVITY"
    | "AUTHENTICATION"
    | "VERIFICATION_FAILURE"
    | "APPLICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "TIMEOUT_ERROR"
    | "POLICY_EVALUATION_FAILURE"
    | "EXECUTION_INPUT_TIMEOUT_FAILURE"
  )[];
}

export type SampleErrorMetadataDTO = ErrorMetadataDTO & {
  sampleMap?: {
    [key: string]: string;
  };
};

export type ScmErrorMetadataDTO = ErrorMetadataDTO & {
  conflictCommitId?: string;
};

export interface StackTraceElement {
  classLoaderName?: string;
  moduleName?: string;
  moduleVersion?: string;
  methodName?: string;
  fileName?: string;
  lineNumber?: number;
  className?: string;
  nativeMethod?: boolean;
}

export interface TemplateInfo {
  templateIdentifier?: string;
  versionLabel?: string;
  templateEntityType?:
    | "Step"
    | "Stage"
    | "Pipeline"
    | "CustomDeployment"
    | "MonitoredService"
    | "SecretManager";
}

export interface TemplateInputsErrorDTO {
  fieldName?: string;
  message?: string;
  identifierOfErrorSource?: string;
}

export type TemplateInputsErrorMetadataDTO = ErrorMetadataDTO & {
  errorYaml?: string;
  errorMap?: {
    [key: string]: TemplateInputsErrorDTO;
  };
};

export interface TemplateResponse {
  accountId: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier: string;
  name: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  yaml?: string;
  versionLabel?: string;
  templateEntityType?:
    | "Step"
    | "Stage"
    | "Pipeline"
    | "CustomDeployment"
    | "MonitoredService"
    | "SecretManager";
  childType?: string;
  templateScope?: "account" | "org" | "project" | "unknown";
  version?: number;
  gitDetails?: EntityGitDetails;
  entityValidityDetails?: EntityValidityDetails;
  lastUpdatedAt?: number;
  storeType?: "INLINE" | "REMOTE";
  connectorRef?: string;
  stableTemplate?: boolean;
}

export interface Throwable {
  cause?: Throwable;
  stackTrace?: StackTraceElement[];
  message?: string;
  suppressed?: Throwable[];
  localizedMessage?: string;
}

export type ValidateTemplateInputsResponseDTO = ErrorMetadataDTO & {
  validYaml?: boolean;
  errorNodeSummary?: ErrorNodeSummary;
};

export interface YamlSchemaErrorDTO {
  message?: string;
  messageWithFQN?: string;
  stageInfo?: NodeErrorInfo;
  stepInfo?: NodeErrorInfo;
  fqn?: string;
  hintMessage?: string;
}

export type YamlSchemaErrorWrapperDTO = ErrorMetadataDTO & {
  schemaErrors?: YamlSchemaErrorDTO[];
};

export interface EnvironmentGroupResponse {
  envGroup?: EnvironmentGroupResponseDTO;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface EnvironmentGroupResponseDTO {
  accountId?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier?: string;
  name?: string;
  description?: string;
  color?: string;
  deleted?: boolean;
  tags?: {
    [key: string]: string;
  };
  envIdentifiers?: string[];
  envResponse?: EnvironmentResponse[];
  gitDetails?: EntityGitDetails;
}

export interface EnvironmentResponse {
  environment?: EnvironmentResponseDTO;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface EnvironmentResponseDTO {
  accountId?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier?: string;
  name?: string;
  description?: string;
  color?: string;
  type?: "PreProduction" | "Production";
  deleted?: boolean;
  tags?: {
    [key: string]: string;
  };
  yaml?: string;
}

export interface Page {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: { [key: string]: any }[];
  pageIndex?: number;
  empty?: boolean;
}

export interface PageEnvironmentGroupResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: EnvironmentGroupResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageEnvironmentGroupResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageEnvironmentGroupResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AuditFilterProperties = FilterProperties & {
  scopes?: ResourceScopeDTO[];
  resources?: ResourceDTO[];
  modules?: (
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS"
  )[];
  actions?: (
    | "CREATE"
    | "UPDATE"
    | "RESTORE"
    | "DELETE"
    | "UPSERT"
    | "INVITE"
    | "RESEND_INVITE"
    | "REVOKE_INVITE"
    | "ADD_COLLABORATOR"
    | "REMOVE_COLLABORATOR"
    | "CREATE_TOKEN"
    | "REVOKE_TOKEN"
    | "LOGIN"
    | "LOGIN2FA"
    | "UNSUCCESSFUL_LOGIN"
    | "ADD_MEMBERSHIP"
    | "REMOVE_MEMBERSHIP"
    | "ERROR_BUDGET_RESET"
  )[];
  environments?: Environment[];
  principals?: Principal[];
  staticFilter?: "EXCLUDE_LOGIN_EVENTS" | "EXCLUDE_SYSTEM_EVENTS";
  startTime?: number;
  endTime?: number;
};

export interface CcmConnectorFilter {
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
  awsAccountId?: string;
  azureSubscriptionId?: string;
  azureTenantId?: string;
  gcpProjectId?: string;
  k8sConnectorRef?: string[];
}

export type ConnectorFilterProperties = FilterProperties & {
  connectorNames?: string[];
  connectorIdentifiers?: string[];
  description?: string;
  types?: (
    | "K8sCluster"
    | "Git"
    | "Splunk"
    | "AppDynamics"
    | "Prometheus"
    | "Dynatrace"
    | "Vault"
    | "AzureKeyVault"
    | "DockerRegistry"
    | "Local"
    | "AwsKms"
    | "GcpKms"
    | "AwsSecretManager"
    | "Gcp"
    | "Aws"
    | "Azure"
    | "Artifactory"
    | "Jira"
    | "Nexus"
    | "Github"
    | "Gitlab"
    | "Bitbucket"
    | "Codecommit"
    | "CEAws"
    | "CEAzure"
    | "GcpCloudCost"
    | "CEK8sCluster"
    | "HttpHelmRepo"
    | "NewRelic"
    | "Datadog"
    | "SumoLogic"
    | "PagerDuty"
    | "CustomHealth"
    | "ServiceNow"
    | "ErrorTracking"
    | "Pdc"
    | "AzureRepo"
    | "Jenkins"
    | "OciHelmRepo"
    | "CustomSecretManager"
    | "ELK"
  )[];
  categories?: (
    | "CLOUD_PROVIDER"
    | "SECRET_MANAGER"
    | "CLOUD_COST"
    | "ARTIFACTORY"
    | "CODE_REPO"
    | "MONITORING"
    | "TICKETING"
  )[];
  connectivityStatuses?: ("SUCCESS" | "FAILURE" | "PARTIAL" | "UNKNOWN")[];
  inheritingCredentialsFromDelegate?: boolean;
  connectorConnectivityModes?: ("DELEGATE" | "MANAGER")[];
  ccmConnectorFilter?: CcmConnectorFilter;
};

export type DelegateFilterProperties = FilterProperties & {
  status?:
    | "CONNECTED"
    | "DISCONNECTED"
    | "ENABLED"
    | "WAITING_FOR_APPROVAL"
    | "DISABLED"
    | "DELETED";
  description?: string;
  hostName?: string;
  delegateName?: string;
  delegateType?: string;
  delegateGroupIdentifier?: string;
  delegateTags?: string[];
};

export interface DelegateProfileFilterProperties {
  name?: string;
  identifier?: string;
  description?: string;
  approvalRequired?: boolean;
  selectors?: string[];
  tags?: {
    [key: string]: string;
  };
  filterType?:
    | "Connector"
    | "DelegateProfile"
    | "Delegate"
    | "PipelineSetup"
    | "PipelineExecution"
    | "Deployment"
    | "Audit"
    | "Template"
    | "EnvironmentGroup"
    | "FileStore"
    | "CCMRecommendation"
    | "Anomaly"
    | "Environment";
}

export interface EmbeddedUserDetailsDTO {
  name?: string;
  email?: string;
}

export interface Environment {
  type: "PreProduction" | "Production";
  identifier: string;
}

export interface EnvironmentFilterProperties {
  environmentNames?: string[];
  environmentIdentifiers?: string[];
  description?: string;
  environmentTypes?: ("PreProduction" | "Production")[];
  tags?: {
    [key: string]: string;
  };
  filterType?:
    | "Connector"
    | "DelegateProfile"
    | "Delegate"
    | "PipelineSetup"
    | "PipelineExecution"
    | "Deployment"
    | "Audit"
    | "Template"
    | "EnvironmentGroup"
    | "FileStore"
    | "CCMRecommendation"
    | "Anomaly"
    | "Environment";
}

export type EnvironmentGroupFilterProperties = FilterProperties & {
  description?: string;
  envGroupName?: string;
  envIdentifiers?: string[];
  envGroupTags?: NGTag[];
};

export type FilesFilterProperties = FilterProperties & {
  fileUsage?: "MANIFEST_FILE" | "CONFIG" | "SCRIPT";
  createdBy?: EmbeddedUserDetailsDTO;
  referencedBy?: ReferencedByDTO;
};

export interface FilterProperties {
  tags?: {
    [key: string]: string;
  };
  filterType?:
    | "Connector"
    | "DelegateProfile"
    | "Delegate"
    | "PipelineSetup"
    | "PipelineExecution"
    | "Deployment"
    | "Audit"
    | "Template"
    | "EnvironmentGroup"
    | "FileStore"
    | "CCMRecommendation"
    | "Anomaly"
    | "Environment";
}

export interface NGTag {
  key: string;
  value: string;
}

export type PipelineFilterProperties = FilterProperties & {
  pipelineTags?: NGTag[];
  pipelineIdentifiers?: string[];
  name?: string;
  description?: string;
  moduleProperties?: {
    [key: string]: { [key: string]: any };
  };
};

export interface Principal {
  type: "USER" | "SERVICE" | "API_KEY" | "SERVICE_ACCOUNT";
  name?: string;
  jwtclaims?: {
    [key: string]: string;
  };
}

export interface ReferencedByDTO {
  type?:
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait";
  name?: string;
}

export interface ResourceDTO {
  type:
    | "ORGANIZATION"
    | "PROJECT"
    | "USER_GROUP"
    | "SECRET"
    | "RESOURCE_GROUP"
    | "USER"
    | "ROLE"
    | "ROLE_ASSIGNMENT"
    | "PIPELINE"
    | "TRIGGER"
    | "TEMPLATE"
    | "INPUT_SET"
    | "DELEGATE_CONFIGURATION"
    | "DELEGATE_GROUPS"
    | "SERVICE"
    | "ENVIRONMENT"
    | "DELEGATE"
    | "SERVICE_ACCOUNT"
    | "CONNECTOR"
    | "API_KEY"
    | "TOKEN"
    | "DELEGATE_TOKEN"
    | "GOVERNANCE_POLICY"
    | "GOVERNANCE_POLICY_SET"
    | "VARIABLE"
    | "CHAOS_HUB"
    | "MONITORED_SERVICE"
    | "CHAOS_DELEGATE"
    | "CHAOS_SCENARIO"
    | "STO_TARGET"
    | "STO_EXEMPTION"
    | "CHAOS_GITOPS"
    | "SERVICE_LEVEL_OBJECTIVE"
    | "PERSPECTIVE"
    | "PERSPECTIVE_BUDGET"
    | "PERSPECTIVE_REPORT"
    | "COST_CATEGORY"
    | "SMTP"
    | "PERSPECTIVE_FOLDER"
    | "AUTOSTOPPING_RULE"
    | "AUTOSTOPPING_LB"
    | "AUTOSTOPPING_STARTSTOP"
    | "SETTING"
    | "NG_LOGIN_SETTINGS";
  identifier: string;
  labels?: {
    [key: string]: string;
  };
}

export interface ResourceScopeDTO {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  labels?: {
    [key: string]: string;
  };
}

export type TemplateFilterProperties = FilterProperties & {
  templateNames?: string[];
  templateIdentifiers?: string[];
  description?: string;
  templateEntityTypes?: (
    | "Step"
    | "Stage"
    | "Pipeline"
    | "CustomDeployment"
    | "MonitoredService"
    | "SecretManager"
  )[];
  childTypes?: string[];
};

export interface ResponseEnvironmentGroupResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvironmentGroupResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface EnvironmentGroupRequestDTO {
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier?: string;
  color?: string;
  yaml: string;
}

export interface EnvironmentGroupDeleteResponse {
  deleted?: boolean;
  accountId?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier?: string;
}

export interface ResponseEnvironmentGroupDeleteResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvironmentGroupDeleteResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseStepCategory {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: StepCategory;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface StepCategory {
  name?: string;
  stepsData?: StepData[];
  stepCategories?: StepCategory[];
}

export interface StepData {
  type?:
    | "CreatePR"
    | "MergePR"
    | "APPLY"
    | "SCALE"
    | "STAGE_DEPLOYMENT"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "K8sBGSwapServices"
    | "K8sDelete"
    | "K8sCanaryDelete"
    | "K8sCanaryDeploy"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "CreateStack"
    | "DeleteStack"
    | "RollbackStack"
    | "SHELL_SCRIPT_PROVISIONER"
    | "JIRA"
    | "SERVICENOW"
    | "EMAIL"
    | "BARRIERS"
    | "NEW_RELIC_DEPLOYMENT_MAKER"
    | "TEMPLATIZED_SECRET_MANAGER"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "Command"
    | "JenkinsBuild"
    | "AzureCreateARMResource"
    | "AzureCreateBPResource"
    | "AzureARMRollback";
  name?: string;
}

export interface ResponseMapServiceDefinitionTypeListExecutionStrategyType {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: {
    [key: string]: (
      | "Basic"
      | "Canary"
      | "BlueGreen"
      | "Rolling"
      | "Default"
      | "GitOps"
    )[];
  };
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseString {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: string;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface StrategyParameters {
  instances?: number;
  unitType?: "COUNT" | "PERCENTAGE";
  phases?: number[];
  artifactType?:
    | "JAR"
    | "WAR"
    | "TAR"
    | "ZIP"
    | "NUGET"
    | "DOCKER"
    | "RPM"
    | "AWS_LAMBDA"
    | "AWS_CODEDEPLOY"
    | "PCF"
    | "AMI"
    | "AZURE_MACHINE_IMAGE"
    | "AZURE_WEBAPP"
    | "IIS"
    | "OTHER"
    | "IIS_APP"
    | "IIS_VirtualDirectory";
}

export interface ResponseListServiceDefinitionType {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: (
    | "Kubernetes"
    | "NativeHelm"
    | "Ssh"
    | "WinRm"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "CustomDeployment"
    | "ECS"
  )[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ConnectorValidationResult {
  status?: "SUCCESS" | "FAILURE" | "PARTIAL" | "UNKNOWN";
  errors?: ErrorDetail[];
  errorSummary?: string;
  testedAt?: number;
  delegateId?: string;
}

export interface ErrorDetail {
  reason?: string;
  message?: string;
  code?: number;
}

export interface ResponseConnectorValidationResult {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ConnectorValidationResult;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseBoolean {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: boolean;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AppDynamicsConnectorDTO = ConnectorConfigDTO & {
  username?: string;
  accountname: string;
  controllerUrl: string;
  delegateSelectors?: string[];
  passwordRef?: string;
  clientSecretRef?: string;
  clientId?: string;
  authType?: "UsernamePassword" | "ApiClientToken";
};

export interface ArtifactoryAuthCredentials {
  [key: string]: any;
}

export interface ArtifactoryAuthentication {
  type: "UsernamePassword" | "Anonymous";
  spec?: ArtifactoryAuthCredentials;
}

export type ArtifactoryConnector = ConnectorConfigDTO & {
  artifactoryServerUrl: string;
  auth?: ArtifactoryAuthentication;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
};

export type ArtifactoryUsernamePasswordAuth = ArtifactoryAuthCredentials & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export interface AwsCodeCommitAuthenticationDTO {
  type: "HTTPS";
  spec: AwsCodeCommitCredentialsDTO;
}

export type AwsCodeCommitConnectorDTO = ConnectorConfigDTO & {
  url: string;
  authentication: AwsCodeCommitAuthenticationDTO;
  delegateSelectors?: string[];
  type: "Repo" | "Region";
};

export interface AwsCodeCommitCredentialsDTO {
  [key: string]: any;
}

export type AwsCodeCommitHttpsCredentialsDTO = AwsCodeCommitCredentialsDTO & {
  type: "AWSCredentials";
  spec: AwsCodeCommitHttpsCredentialsSpecDTO;
};

export interface AwsCodeCommitHttpsCredentialsSpecDTO {
  [key: string]: any;
}

export type AwsCodeCommitSecretKeyAccessKeyDTO = AwsCodeCommitHttpsCredentialsSpecDTO & {
  accessKey?: string;
  accessKeyRef?: string;
  secretKeyRef: string;
};

export type AwsConnector = ConnectorConfigDTO & {
  credential: AwsCredential;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
};

export interface AwsCredential {
  crossAccountAccess?: CrossAccountAccess;
  type: "InheritFromDelegate" | "ManualConfig" | "Irsa";
  spec?: AwsCredentialSpec;
  region?: string;
}

export interface AwsCredentialSpec {
  [key: string]: any;
}

export interface AwsCurAttributes {
  reportName: string;
  s3BucketName: string;
  region?: string;
  s3Prefix?: string;
}

export interface AwsKmsConnectorCredential {
  type: "AssumeIAMRole" | "AssumeSTSRole" | "ManualConfig";
  spec?: AwsKmsCredentialSpec;
}

export type AwsKmsConnectorDTO = ConnectorConfigDTO & {
  credential: AwsKmsConnectorCredential;
  kmsArn: string;
  region: string;
  delegateSelectors?: string[];
  default?: boolean;
};

export interface AwsKmsCredentialSpec {
  [key: string]: any;
}

export type AwsKmsCredentialSpecAssumeIAM = AwsKmsCredentialSpec & {
  delegateSelectors: string[];
};

export type AwsKmsCredentialSpecAssumeSTS = AwsKmsCredentialSpec & {
  delegateSelectors: string[];
  roleArn: string;
  externalName?: string;
  assumeStsRoleDuration?: number;
};

export type AwsKmsCredentialSpecManualConfig = AwsKmsCredentialSpec & {
  accessKey: string;
  secretKey: string;
};

export type AwsManualConfigSpec = AwsCredentialSpec & {
  accessKey?: string;
  accessKeyRef?: string;
  secretKeyRef: string;
};

export type AwsSMCredentialSpecAssumeIAM = AwsSecretManagerCredentialSpec & {
  [key: string]: any;
};

export type AwsSMCredentialSpecAssumeSTS = AwsSecretManagerCredentialSpec & {
  roleArn: string;
  externalId?: string;
  assumeStsRoleDuration?: number;
};

export type AwsSMCredentialSpecManualConfig = AwsSecretManagerCredentialSpec & {
  accessKey: string;
  secretKey: string;
};

export interface AwsSecretManagerCredential {
  type: "AssumeIAMRole" | "AssumeSTSRole" | "ManualConfig";
  spec?: AwsSecretManagerCredentialSpec;
}

export interface AwsSecretManagerCredentialSpec {
  [key: string]: any;
}

export type AwsSecretManagerDTO = ConnectorConfigDTO & {
  credential: AwsSecretManagerCredential;
  region: string;
  secretNamePrefix?: string;
  delegateSelectors?: string[];
  default?: boolean;
};

export interface AzureAuthCredentialDTO {
  [key: string]: any;
}

export interface AzureAuthDTO {
  type: "Secret" | "Certificate";
  spec: AzureAuthCredentialDTO;
}

export type AzureClientKeyCertDTO = AzureAuthCredentialDTO & {
  certificateRef: string;
};

export type AzureClientSecretKeyDTO = AzureAuthCredentialDTO & {
  secretRef: string;
};

export type AzureConnector = ConnectorConfigDTO & {
  credential: AzureCredential;
  delegateSelectors?: string[];
  azureEnvironmentType: "AZURE" | "AZURE_US_GOVERNMENT";
  executeOnDelegate?: boolean;
};

export interface AzureCredential {
  type: "InheritFromDelegate" | "ManualConfig";
  spec?: AzureCredentialSpec;
}

export interface AzureCredentialSpec {
  [key: string]: any;
}

export type AzureInheritFromDelegateDetails = AzureCredentialSpec & {
  auth: AzureMSIAuth;
};

export type AzureKeyVaultConnectorDTO = ConnectorConfigDTO & {
  clientId: string;
  secretKey: string;
  tenantId: string;
  vaultName: string;
  subscription: string;
  azureEnvironmentType?: "AZURE" | "AZURE_US_GOVERNMENT";
  delegateSelectors?: string[];
  default?: boolean;
};

export interface AzureMSIAuth {
  [key: string]: any;
}

export type AzureManualDetails = AzureCredentialSpec & {
  applicationId: string;
  tenantId: string;
  auth: AzureAuthDTO;
};

export interface AzureRepoApiAccess {
  type: "Token";
  spec?: AzureRepoApiAccessSpecDTO;
}

export interface AzureRepoApiAccessSpecDTO {
  [key: string]: any;
}

export interface AzureRepoAuthentication {
  type: "Http" | "Ssh";
  spec: AzureRepoCredentialsDTO;
}

export type AzureRepoConnector = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  authentication: AzureRepoAuthentication;
  apiAccess?: AzureRepoApiAccess;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
  type: "Project" | "Repo";
};

export interface AzureRepoCredentialsDTO {
  [key: string]: any;
}

export type AzureRepoHttpCredentials = AzureRepoCredentialsDTO & {
  type: "UsernameToken";
  spec: AzureRepoHttpCredentialsSpecDTO;
};

export interface AzureRepoHttpCredentialsSpecDTO {
  [key: string]: any;
}

export type AzureRepoSshCredentials = AzureRepoCredentialsDTO & {
  sshKeyRef: string;
};

export type AzureRepoTokenSpec = AzureRepoApiAccessSpecDTO & {
  tokenRef: string;
};

export type AzureRepoUsernameToken = AzureRepoHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  tokenRef: string;
};

export type AzureSystemAssignedMSIAuth = AzureAuthCredentialDTO & {
  [key: string]: any;
};

export type AzureUserAssignedMSIAuth = AzureAuthCredentialDTO & {
  clientId: string;
};

export interface BillingExportSpec {
  storageAccountName: string;
  containerName: string;
  directoryName: string;
  reportName: string;
  subscriptionId: string;
}

export interface BitbucketApiAccess {
  type: "UsernameToken";
  spec: BitbucketApiAccessSpecDTO;
}

export interface BitbucketApiAccessSpecDTO {
  [key: string]: any;
}

export interface BitbucketAuthentication {
  type: "Http" | "Ssh";
  spec: BitbucketCredentialsDTO;
}

export type BitbucketConnector = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  authentication: BitbucketAuthentication;
  apiAccess?: BitbucketApiAccess;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
  type: "Account" | "Repo" | "Project";
};

export interface BitbucketCredentialsDTO {
  [key: string]: any;
}

export type BitbucketHttpCredentials = BitbucketCredentialsDTO & {
  type: "UsernamePassword";
  spec: BitbucketHttpCredentialsSpecDTO;
};

export interface BitbucketHttpCredentialsSpecDTO {
  [key: string]: any;
}

export type BitbucketSshCredentials = BitbucketCredentialsDTO & {
  sshKeyRef: string;
};

export type BitbucketUsernamePassword = BitbucketHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type BitbucketUsernameTokenApiAccess = BitbucketApiAccessSpecDTO & {
  username?: string;
  usernameRef?: string;
  tokenRef: string;
};

export type CEAwsConnector = ConnectorConfigDTO & {
  crossAccountAccess: CrossAccountAccess;
  curAttributes?: AwsCurAttributes;
  awsAccountId?: string;
  isAWSGovCloudAccount?: boolean;
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
};

export type CEAzureConnector = ConnectorConfigDTO & {
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
  tenantId: string;
  subscriptionId: string;
  billingExportSpec?: BillingExportSpec;
};

export type CEKubernetesClusterConfig = ConnectorConfigDTO & {
  connectorRef: string;
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
};

export interface CcmK8sConnectorResponse {
  k8sConnector?: ConnectorResponse;
  ccmk8sConnector?: ConnectorResponse[];
}

export interface ConnectorActivityDetails {
  lastActivityTime?: number;
}

export interface ConnectorConfigDTO {
  [key: string]: any;
}

export interface ConnectorConnectivityDetails {
  status?: "SUCCESS" | "FAILURE" | "PARTIAL" | "UNKNOWN";
  errorSummary?: string;
  errors?: ErrorDetail[];
  testedAt?: number;
  lastTestedAt?: number;
  lastConnectedAt?: number;
}

export interface ConnectorInfoDTO {
  name: string;
  identifier: string;
  description?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  tags?: {
    [key: string]: string;
  };
  type:
    | "K8sCluster"
    | "Git"
    | "Splunk"
    | "AppDynamics"
    | "Prometheus"
    | "Dynatrace"
    | "Vault"
    | "AzureKeyVault"
    | "DockerRegistry"
    | "Local"
    | "AwsKms"
    | "GcpKms"
    | "AwsSecretManager"
    | "Gcp"
    | "Aws"
    | "Azure"
    | "Artifactory"
    | "Jira"
    | "Nexus"
    | "Github"
    | "Gitlab"
    | "Bitbucket"
    | "Codecommit"
    | "CEAws"
    | "CEAzure"
    | "GcpCloudCost"
    | "CEK8sCluster"
    | "HttpHelmRepo"
    | "NewRelic"
    | "Datadog"
    | "SumoLogic"
    | "PagerDuty"
    | "CustomHealth"
    | "ServiceNow"
    | "ErrorTracking"
    | "Pdc"
    | "AzureRepo"
    | "Jenkins"
    | "OciHelmRepo"
    | "CustomSecretManager"
    | "ELK";
  spec: ConnectorConfigDTO;
}

export interface ConnectorResponse {
  connector?: ConnectorInfoDTO;
  createdAt?: number;
  lastModifiedAt?: number;
  status?: ConnectorConnectivityDetails;
  activityDetails?: ConnectorActivityDetails;
  harnessManaged?: boolean;
  gitDetails?: EntityGitDetails;
  entityValidityDetails?: EntityValidityDetails;
  governanceMetadata?: GovernanceMetadata;
}

export interface CrossAccountAccess {
  crossAccountRoleArn: string;
  externalId?: string;
}

export type CustomHealthConnectorDTO = ConnectorConfigDTO & {
  baseURL: string;
  headers?: CustomHealthKeyAndValue[];
  params?: CustomHealthKeyAndValue[];
  method: "GET" | "POST";
  validationBody?: string;
  validationPath?: string;
  delegateSelectors?: string[];
};

export interface CustomHealthKeyAndValue {
  key: string;
  encryptedValueRef?: string;
  value?: string;
  valueEncrypted?: boolean;
}

export type CustomSecretManager = ConnectorConfigDTO & {
  delegateSelectors?: string[];
  onDelegate: boolean;
  connectorRef?: string;
  host?: string;
  workingDirectory?: string;
  template: TemplateLinkConfigForCustomSecretManager;
  default?: boolean;
};

export type DatadogConnectorDTO = ConnectorConfigDTO & {
  url: string;
  applicationKeyRef: string;
  apiKeyRef: string;
  delegateSelectors?: string[];
};

export interface DockerAuthCredentialsDTO {
  [key: string]: any;
}

export interface DockerAuthenticationDTO {
  type: "UsernamePassword" | "Anonymous";
  spec?: DockerAuthCredentialsDTO;
}

export type DockerConnectorDTO = ConnectorConfigDTO & {
  dockerRegistryUrl: string;
  providerType: "DockerHub" | "Harbor" | "Quay" | "Other";
  auth?: DockerAuthenticationDTO;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
};

export type DockerUserNamePasswordDTO = DockerAuthCredentialsDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type DynatraceConnectorDTO = ConnectorConfigDTO & {
  url: string;
  apiTokenRef: string;
  delegateSelectors?: string[];
};

export type ELKConnectorDTO = ConnectorConfigDTO & {
  url: string;
  delegateSelectors?: string[];
  username?: string;
  apiKeyId?: string;
  passwordRef?: string;
  apiKeyRef?: string;
  authType?: "UsernamePassword" | "ApiClientToken" | "None";
};

export type ErrorTrackingConnectorDTO = ConnectorConfigDTO & {
  url: string;
  apiKeyRef: string;
  delegateSelectors?: string[];
};

export interface GcpBillingExportSpec {
  datasetId: string;
  tableId: string;
}

export type GcpCloudCostConnector = ConnectorConfigDTO & {
  featuresEnabled?: ("BILLING" | "OPTIMIZATION" | "VISIBILITY")[];
  projectId: string;
  serviceAccountEmail: string;
  billingExportSpec?: GcpBillingExportSpec;
};

export type GcpConnector = ConnectorConfigDTO & {
  credential: GcpConnectorCredential;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
};

export interface GcpConnectorCredential {
  type: "InheritFromDelegate" | "ManualConfig";
  spec?: GcpCredentialSpec;
}

export interface GcpCredentialSpec {
  [key: string]: any;
}

export type GcpKmsConnectorDTO = ConnectorConfigDTO & {
  projectId: string;
  region: string;
  keyRing: string;
  keyName: string;
  credentials: string;
  delegateSelectors?: string[];
  default?: boolean;
};

export type GcpManualDetails = GcpCredentialSpec & {
  secretKeyRef: string;
};

export interface GitAuthenticationDTO {
  [key: string]: any;
}

export type GitConfigDTO = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  branchName?: string;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
  type: "Http" | "Ssh";
  connectionType: "Account" | "Repo" | "Project";
  spec: GitAuthenticationDTO;
};

export type GitHTTPAuthenticationDTO = GitAuthenticationDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type GitSSHAuthenticationDTO = GitAuthenticationDTO & {
  sshKeyRef: string;
};

export interface GithubApiAccess {
  type: "GithubApp" | "Token" | "OAuth";
  spec?: GithubApiAccessSpecDTO;
}

export interface GithubApiAccessSpecDTO {
  [key: string]: any;
}

export type GithubAppSpec = GithubApiAccessSpecDTO & {
  installationId: string;
  applicationId: string;
  privateKeyRef: string;
};

export interface GithubAuthentication {
  type: "Http" | "Ssh";
  spec: GithubCredentialsDTO;
}

export type GithubConnector = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  authentication: GithubAuthentication;
  apiAccess?: GithubApiAccess;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
  type: "Account" | "Repo" | "Project";
};

export interface GithubCredentialsDTO {
  [key: string]: any;
}

export type GithubHttpCredentials = GithubCredentialsDTO & {
  type: "UsernamePassword" | "UsernameToken" | "OAuth";
  spec: GithubHttpCredentialsSpecDTO;
};

export interface GithubHttpCredentialsSpecDTO {
  [key: string]: any;
}

export type GithubOauth = GithubHttpCredentialsSpecDTO & {
  tokenRef: string;
};

export type GithubSshCredentials = GithubCredentialsDTO & {
  sshKeyRef: string;
};

export type GithubTokenSpec = GithubApiAccessSpecDTO & {
  tokenRef: string;
};

export type GithubUsernamePassword = GithubHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type GithubUsernameToken = GithubHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  tokenRef: string;
};

export interface GitlabApiAccess {
  type: "Token" | "OAuth";
  spec?: GitlabApiAccessSpecDTO;
}

export interface GitlabApiAccessSpecDTO {
  [key: string]: any;
}

export interface GitlabAuthentication {
  type: "Http" | "Ssh";
  spec: GitlabCredentialsDTO;
}

export type GitlabConnector = ConnectorConfigDTO & {
  url: string;
  validationRepo?: string;
  authentication: GitlabAuthentication;
  apiAccess?: GitlabApiAccess;
  delegateSelectors?: string[];
  executeOnDelegate?: boolean;
  type: "Account" | "Repo" | "Project";
};

export interface GitlabCredentialsDTO {
  [key: string]: any;
}

export type GitlabHttpCredentials = GitlabCredentialsDTO & {
  type: "UsernamePassword" | "UsernameToken" | "Kerberos" | "OAuth";
  spec: GitlabHttpCredentialsSpecDTO;
};

export interface GitlabHttpCredentialsSpecDTO {
  [key: string]: any;
}

export type GitlabKerberos = GitlabHttpCredentialsSpecDTO & {
  kerberosKeyRef: string;
};

export type GitlabOauth = GitlabHttpCredentialsSpecDTO & {
  tokenRef: string;
  refreshTokenRef: string;
};

export type GitlabSshCredentials = GitlabCredentialsDTO & {
  sshKeyRef: string;
};

export type GitlabTokenSpec = GitlabApiAccessSpecDTO & {
  tokenRef: string;
};

export type GitlabUsernamePassword = GitlabHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type GitlabUsernameToken = GitlabHttpCredentialsSpecDTO & {
  username?: string;
  usernameRef?: string;
  tokenRef: string;
};

export interface GovernanceMetadata {
  [key: string]: any;
}

export interface HostDTO {
  hostname: string;
  hostAttributes?: {
    [key: string]: string;
  };
}

export interface HttpHelmAuthCredentialsDTO {
  [key: string]: any;
}

export interface HttpHelmAuthenticationDTO {
  type: "UsernamePassword" | "Anonymous";
  spec?: HttpHelmAuthCredentialsDTO;
}

export type HttpHelmConnectorDTO = ConnectorConfigDTO & {
  helmRepoUrl: string;
  auth?: HttpHelmAuthenticationDTO;
  delegateSelectors?: string[];
};

export type HttpHelmUsernamePasswordDTO = HttpHelmAuthCredentialsDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export interface JenkinsAuthCredentialsDTO {
  [key: string]: any;
}

export interface JenkinsAuthenticationDTO {
  type: "UsernamePassword" | "Anonymous" | "Bearer Token(HTTP Header)";
  spec?: JenkinsAuthCredentialsDTO;
}

export type JenkinsBearerTokenDTO = JenkinsAuthCredentialsDTO & {
  tokenRef: string;
};

export type JenkinsConnectorDTO = ConnectorConfigDTO & {
  jenkinsUrl: string;
  auth?: JenkinsAuthenticationDTO;
  delegateSelectors?: string[];
};

export type JenkinsUserNamePasswordDTO = JenkinsAuthCredentialsDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type JiraConnector = ConnectorConfigDTO & {
  jiraUrl: string;
  username?: string;
  usernameRef?: string;
  passwordRef: string;
  delegateSelectors?: string[];
};

export interface KubernetesAuthCredentialDTO {
  [key: string]: any;
}

export interface KubernetesAuthDTO {
  type:
    | "UsernamePassword"
    | "ClientKeyCert"
    | "ServiceAccount"
    | "OpenIdConnect";
  spec: KubernetesAuthCredentialDTO;
}

export type KubernetesClientKeyCertDTO = KubernetesAuthCredentialDTO & {
  caCertRef?: string;
  clientCertRef: string;
  clientKeyRef: string;
  clientKeyPassphraseRef?: string;
  clientKeyAlgo?: string;
};

export type KubernetesClusterConfigDTO = ConnectorConfigDTO & {
  credential: KubernetesCredentialDTO;
  delegateSelectors?: string[];
};

export type KubernetesClusterDetailsDTO = KubernetesCredentialSpecDTO & {
  masterUrl: string;
  auth: KubernetesAuthDTO;
};

export interface KubernetesCredentialDTO {
  type: "InheritFromDelegate" | "ManualConfig";
  spec?: KubernetesCredentialSpecDTO;
}

export interface KubernetesCredentialSpecDTO {
  [key: string]: any;
}

export type KubernetesOpenIdConnectDTO = KubernetesAuthCredentialDTO & {
  oidcIssuerUrl?: string;
  oidcUsername?: string;
  oidcUsernameRef?: string;
  oidcClientIdRef: string;
  oidcPasswordRef: string;
  oidcSecretRef?: string;
  oidcScopes?: string;
};

export type KubernetesServiceAccountDTO = KubernetesAuthCredentialDTO & {
  serviceAccountTokenRef: string;
  caCertRef?: string;
};

export type KubernetesUserNamePasswordDTO = KubernetesAuthCredentialDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export type LocalConnectorDTO = ConnectorConfigDTO & {
  default?: boolean;
};

export interface NameValuePairWithDefault {
  name?: string;
  value: string;
  type: string;
  useAsDefault?: boolean;
}

export type NewRelicConnectorDTO = ConnectorConfigDTO & {
  newRelicAccountId: string;
  url: string;
  apiKeyRef: string;
  delegateSelectors?: string[];
};

export interface NexusAuthCredentials {
  [key: string]: any;
}

export interface NexusAuthentication {
  type: "UsernamePassword" | "Anonymous";
  spec?: NexusAuthCredentials;
}

export type NexusConnector = ConnectorConfigDTO & {
  nexusServerUrl: string;
  version: string;
  auth?: NexusAuthentication;
  delegateSelectors?: string[];
};

export type NexusUsernamePasswordAuth = NexusAuthCredentials & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export interface OciHelmAuthCredentialsDTO {
  [key: string]: any;
}

export interface OciHelmAuthenticationDTO {
  type: "UsernamePassword" | "Anonymous";
  spec?: OciHelmAuthCredentialsDTO;
}

export type OciHelmConnectorDTO = ConnectorConfigDTO & {
  helmRepoUrl: string;
  auth?: OciHelmAuthenticationDTO;
  delegateSelectors?: string[];
};

export type OciHelmUsernamePasswordDTO = OciHelmAuthCredentialsDTO & {
  username?: string;
  usernameRef?: string;
  passwordRef: string;
};

export interface PageCcmK8sConnectorResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: CcmK8sConnectorResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export type PagerDutyConnectorDTO = ConnectorConfigDTO & {
  apiTokenRef: string;
  delegateSelectors?: string[];
};

export type PhysicalDataCenterConnectorDTO = ConnectorConfigDTO & {
  hosts?: HostDTO[];
  delegateSelectors?: string[];
};

export type PrometheusConnectorDTO = ConnectorConfigDTO & {
  url: string;
  username?: string;
  passwordRef?: string;
  headers?: CustomHealthKeyAndValue[];
  delegateSelectors?: string[];
};

export interface ResponsePageCcmK8sConnectorResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageCcmK8sConnectorResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type ServiceNowConnector = ConnectorConfigDTO & {
  serviceNowUrl: string;
  username?: string;
  usernameRef?: string;
  passwordRef: string;
  delegateSelectors?: string[];
};

export type SplunkConnectorDTO = ConnectorConfigDTO & {
  splunkUrl: string;
  username?: string;
  accountId: string;
  delegateSelectors?: string[];
  passwordRef: string;
};

export type SumoLogicConnectorDTO = ConnectorConfigDTO & {
  url: string;
  accessIdRef: string;
  accessKeyRef: string;
  delegateSelectors?: string[];
};

export interface TemplateLinkConfigForCustomSecretManager {
  templateRef: string;
  versionLabel: string;
  templateInputs?: {
    [key: string]: NameValuePairWithDefault[];
  };
}

export type VaultConnectorDTO = ConnectorConfigDTO & {
  authToken?: string;
  basePath?: string;
  vaultUrl: string;
  renewalIntervalMinutes: number;
  secretEngineManuallyConfigured?: boolean;
  secretEngineName?: string;
  appRoleId?: string;
  secretId?: string;
  secretEngineVersion?: number;
  delegateSelectors?: string[];
  namespace?: string;
  sinkPath?: string;
  useVaultAgent?: boolean;
  useAwsIam?: boolean;
  awsRegion?: string;
  vaultAwsIamRole?: string;
  xvaultAwsIamServerId?: string;
  useK8sAuth?: boolean;
  vaultK8sAuthRole?: string;
  serviceAccountTokenPath?: string;
  k8sAuthEndpoint?: string;
  renewAppRoleToken?: boolean;
  accessType?: "APP_ROLE" | "TOKEN" | "VAULT_AGENT" | "AWS_IAM" | "K8s_AUTH";
  default?: boolean;
  readOnly?: boolean;
};

export interface ConnectorCatalogueItem {
  category?:
    | "CLOUD_PROVIDER"
    | "SECRET_MANAGER"
    | "CLOUD_COST"
    | "ARTIFACTORY"
    | "CODE_REPO"
    | "MONITORING"
    | "TICKETING";
  connectors?: (
    | "K8sCluster"
    | "Git"
    | "Splunk"
    | "AppDynamics"
    | "Prometheus"
    | "Dynatrace"
    | "Vault"
    | "AzureKeyVault"
    | "DockerRegistry"
    | "Local"
    | "AwsKms"
    | "GcpKms"
    | "AwsSecretManager"
    | "Gcp"
    | "Aws"
    | "Azure"
    | "Artifactory"
    | "Jira"
    | "Nexus"
    | "Github"
    | "Gitlab"
    | "Bitbucket"
    | "Codecommit"
    | "CEAws"
    | "CEAzure"
    | "GcpCloudCost"
    | "CEK8sCluster"
    | "HttpHelmRepo"
    | "NewRelic"
    | "Datadog"
    | "SumoLogic"
    | "PagerDuty"
    | "CustomHealth"
    | "ServiceNow"
    | "ErrorTracking"
    | "Pdc"
    | "AzureRepo"
    | "Jenkins"
    | "OciHelmRepo"
    | "CustomSecretManager"
    | "ELK"
  )[];
}

export interface ConnectorCatalogueResponse {
  catalogue?: ConnectorCatalogueItem[];
}

export interface ResponseConnectorCatalogueResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ConnectorCatalogueResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ConnectorStatistics {
  typeStats?: ConnectorTypeStatistics[];
  statusStats?: ConnectorStatusStatistics[];
}

export interface ConnectorStatusStatistics {
  status?: "SUCCESS" | "FAILURE" | "PARTIAL" | "UNKNOWN";
  count?: number;
}

export interface ConnectorTypeStatistics {
  type?:
    | "K8sCluster"
    | "Git"
    | "Splunk"
    | "AppDynamics"
    | "Prometheus"
    | "Dynatrace"
    | "Vault"
    | "AzureKeyVault"
    | "DockerRegistry"
    | "Local"
    | "AwsKms"
    | "GcpKms"
    | "AwsSecretManager"
    | "Gcp"
    | "Aws"
    | "Azure"
    | "Artifactory"
    | "Jira"
    | "Nexus"
    | "Github"
    | "Gitlab"
    | "Bitbucket"
    | "Codecommit"
    | "CEAws"
    | "CEAzure"
    | "GcpCloudCost"
    | "CEK8sCluster"
    | "HttpHelmRepo"
    | "NewRelic"
    | "Datadog"
    | "SumoLogic"
    | "PagerDuty"
    | "CustomHealth"
    | "ServiceNow"
    | "ErrorTracking"
    | "Pdc"
    | "AzureRepo"
    | "Jenkins"
    | "OciHelmRepo"
    | "CustomSecretManager"
    | "ELK";
  count?: number;
}

export interface ResponseConnectorStatistics {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ConnectorStatistics;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListConnectorResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ConnectorResponse[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface FieldValues {
  fieldValues?: {
    [key: string]: string[];
  };
}

export interface ResponseFieldValues {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FieldValues;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseConnectorResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ConnectorResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface Connector {
  connector?: ConnectorInfoDTO;
}

export interface PageConnectorResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ConnectorResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageConnectorResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageConnectorResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AvailabilityRestrictionDTO = RestrictionDTO & {
  enabled?: boolean;
};

export type CustomRestrictionDTO = RestrictionDTO & { [key: string]: any };

export type DurationRestrictionDTO = RestrictionDTO & {
  timeUnit?: TimeUnit;
};

export interface FeatureRestrictionDetailsDTO {
  name?:
    | "TEST1"
    | "TEST2"
    | "TEST3"
    | "TEST4"
    | "TEST5"
    | "TEST6"
    | "TEST7"
    | "PERSPECTIVES"
    | "CCM_K8S_CLUSTERS"
    | "CCM_AUTOSTOPPING_RULES"
    | "MULTIPLE_ORGANIZATIONS"
    | "MULTIPLE_PROJECTS"
    | "INTEGRATED_APPROVALS_WITH_HARNESS_UI"
    | "INTEGRATED_APPROVALS_WITH_CUSTOM_SCRIPT"
    | "INTEGRATED_APPROVALS_WITH_JIRA"
    | "SECRET_MANAGERS"
    | "DEPLOYMENTS"
    | "INITIAL_DEPLOYMENTS"
    | "DEPLOYMENTS_PER_MONTH"
    | "SERVICES"
    | "BUILDS"
    | "SAML_SUPPORT"
    | "OAUTH_SUPPORT"
    | "LDAP_SUPPORT"
    | "TWO_FACTOR_AUTH_SUPPORT"
    | "CUSTOM_ROLES"
    | "CUSTOM_RESOURCE_GROUPS"
    | "MAX_TOTAL_BUILDS"
    | "MAX_BUILDS_PER_MONTH"
    | "ACTIVE_COMMITTERS"
    | "TEST_INTELLIGENCE"
    | "TEMPLATE_SERVICE"
    | "SRM_SERVICES"
    | "K8S_BG_SWAP_SERVICES"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_APPLY"
    | "K8S_DELETE"
    | "K8S_CANARY_DELETE"
    | "K8S_ROLLING_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_SCALE"
    | "K8S_ROLLING_ROLLBACK"
    | "TERRAFORM_APPLY"
    | "TERRAFORM_PLAN"
    | "TERRAFORM_DESTROY"
    | "TERRAFORM_ROLLBACK"
    | "INTEGRATED_APPROVALS_WITH_SERVICE_NOW"
    | "CREATE_STACK"
    | "DELETE_STACK"
    | "ROLLBACK_STACK"
    | "COMMAND"
    | "AZURE_SLOT_DEPLOYMENT"
    | "AZURE_TRAFFIC_SHIFT"
    | "AZURE_SWAP_SLOT"
    | "AZURE_WEBAPP_ROLLBACK"
    | "JENKINS_BUILD"
    | "AZURE_CREATE_ARM_RESOURCE"
    | "AZURE_CREATE_BP_RESOURCE"
    | "AZURE_ROLLBACK_ARM_RESOURCE"
    | "SECURITY"
    | "DEVELOPERS"
    | "MONTHLY_ACTIVE_USERS"
    | "JENKINS_ARTIFACT"
    | "STRATEGY_MAX_CONCURRENT"
    | "MAX_CHAOS_SCENARIO_RUNS_PER_MONTH"
    | "MAX_CHAOS_DELEGATES";
  description?: string;
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  allowed?: boolean;
  restrictionType?:
    | "AVAILABILITY"
    | "STATIC_LIMIT"
    | "RATE_LIMIT"
    | "CUSTOM"
    | "DURATION"
    | "LICENSE_RATE_LIMIT"
    | "LICENSE_STATIC_LIMIT";
  restriction?: RestrictionDTO;
}

export type LicenseRateLimitRestrictionDTO = RestrictionDTO & {
  limit?: number;
  fieldName?: string;
  count?: number;
  timeUnit?: TimeUnit;
};

export type LicenseStaticLimitRestrictionDTO = RestrictionDTO & {
  limit?: number;
  fieldName?: string;
  count?: number;
};

export type RateLimitRestrictionDTO = RestrictionDTO & {
  limit?: number;
  count?: number;
  timeUnit?: TimeUnit;
};

export interface ResponseFeatureRestrictionDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FeatureRestrictionDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RestrictionDTO {
  [key: string]: any;
}

export type StaticLimitRestrictionDTO = RestrictionDTO & {
  limit?: number;
  count?: number;
};

export interface TimeUnit {
  unit?:
    | "NANOS"
    | "MICROS"
    | "MILLIS"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "HALF_DAYS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "DECADES"
    | "CENTURIES"
    | "MILLENNIA"
    | "ERAS"
    | "FOREVER";
  numberOfUnits?: number;
}

export interface FeatureRestrictionDetailRequestDTO {
  name:
    | "TEST1"
    | "TEST2"
    | "TEST3"
    | "TEST4"
    | "TEST5"
    | "TEST6"
    | "TEST7"
    | "PERSPECTIVES"
    | "CCM_K8S_CLUSTERS"
    | "CCM_AUTOSTOPPING_RULES"
    | "MULTIPLE_ORGANIZATIONS"
    | "MULTIPLE_PROJECTS"
    | "INTEGRATED_APPROVALS_WITH_HARNESS_UI"
    | "INTEGRATED_APPROVALS_WITH_CUSTOM_SCRIPT"
    | "INTEGRATED_APPROVALS_WITH_JIRA"
    | "SECRET_MANAGERS"
    | "DEPLOYMENTS"
    | "INITIAL_DEPLOYMENTS"
    | "DEPLOYMENTS_PER_MONTH"
    | "SERVICES"
    | "BUILDS"
    | "SAML_SUPPORT"
    | "OAUTH_SUPPORT"
    | "LDAP_SUPPORT"
    | "TWO_FACTOR_AUTH_SUPPORT"
    | "CUSTOM_ROLES"
    | "CUSTOM_RESOURCE_GROUPS"
    | "MAX_TOTAL_BUILDS"
    | "MAX_BUILDS_PER_MONTH"
    | "ACTIVE_COMMITTERS"
    | "TEST_INTELLIGENCE"
    | "TEMPLATE_SERVICE"
    | "SRM_SERVICES"
    | "K8S_BG_SWAP_SERVICES"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_APPLY"
    | "K8S_DELETE"
    | "K8S_CANARY_DELETE"
    | "K8S_ROLLING_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_SCALE"
    | "K8S_ROLLING_ROLLBACK"
    | "TERRAFORM_APPLY"
    | "TERRAFORM_PLAN"
    | "TERRAFORM_DESTROY"
    | "TERRAFORM_ROLLBACK"
    | "INTEGRATED_APPROVALS_WITH_SERVICE_NOW"
    | "CREATE_STACK"
    | "DELETE_STACK"
    | "ROLLBACK_STACK"
    | "COMMAND"
    | "AZURE_SLOT_DEPLOYMENT"
    | "AZURE_TRAFFIC_SHIFT"
    | "AZURE_SWAP_SLOT"
    | "AZURE_WEBAPP_ROLLBACK"
    | "JENKINS_BUILD"
    | "AZURE_CREATE_ARM_RESOURCE"
    | "AZURE_CREATE_BP_RESOURCE"
    | "AZURE_ROLLBACK_ARM_RESOURCE"
    | "SECURITY"
    | "DEVELOPERS"
    | "MONTHLY_ACTIVE_USERS"
    | "JENKINS_ARTIFACT"
    | "STRATEGY_MAX_CONCURRENT"
    | "MAX_CHAOS_SCENARIO_RUNS_PER_MONTH"
    | "MAX_CHAOS_DELEGATES";
}

export interface ResponseListFeatureRestrictionDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FeatureRestrictionDetailsDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface FeatureRestrictionDetailListRequestDTO {
  names: (
    | "TEST1"
    | "TEST2"
    | "TEST3"
    | "TEST4"
    | "TEST5"
    | "TEST6"
    | "TEST7"
    | "PERSPECTIVES"
    | "CCM_K8S_CLUSTERS"
    | "CCM_AUTOSTOPPING_RULES"
    | "MULTIPLE_ORGANIZATIONS"
    | "MULTIPLE_PROJECTS"
    | "INTEGRATED_APPROVALS_WITH_HARNESS_UI"
    | "INTEGRATED_APPROVALS_WITH_CUSTOM_SCRIPT"
    | "INTEGRATED_APPROVALS_WITH_JIRA"
    | "SECRET_MANAGERS"
    | "DEPLOYMENTS"
    | "INITIAL_DEPLOYMENTS"
    | "DEPLOYMENTS_PER_MONTH"
    | "SERVICES"
    | "BUILDS"
    | "SAML_SUPPORT"
    | "OAUTH_SUPPORT"
    | "LDAP_SUPPORT"
    | "TWO_FACTOR_AUTH_SUPPORT"
    | "CUSTOM_ROLES"
    | "CUSTOM_RESOURCE_GROUPS"
    | "MAX_TOTAL_BUILDS"
    | "MAX_BUILDS_PER_MONTH"
    | "ACTIVE_COMMITTERS"
    | "TEST_INTELLIGENCE"
    | "TEMPLATE_SERVICE"
    | "SRM_SERVICES"
    | "K8S_BG_SWAP_SERVICES"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_APPLY"
    | "K8S_DELETE"
    | "K8S_CANARY_DELETE"
    | "K8S_ROLLING_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_SCALE"
    | "K8S_ROLLING_ROLLBACK"
    | "TERRAFORM_APPLY"
    | "TERRAFORM_PLAN"
    | "TERRAFORM_DESTROY"
    | "TERRAFORM_ROLLBACK"
    | "INTEGRATED_APPROVALS_WITH_SERVICE_NOW"
    | "CREATE_STACK"
    | "DELETE_STACK"
    | "ROLLBACK_STACK"
    | "COMMAND"
    | "AZURE_SLOT_DEPLOYMENT"
    | "AZURE_TRAFFIC_SHIFT"
    | "AZURE_SWAP_SLOT"
    | "AZURE_WEBAPP_ROLLBACK"
    | "JENKINS_BUILD"
    | "AZURE_CREATE_ARM_RESOURCE"
    | "AZURE_CREATE_BP_RESOURCE"
    | "AZURE_ROLLBACK_ARM_RESOURCE"
    | "SECURITY"
    | "DEVELOPERS"
    | "MONTHLY_ACTIVE_USERS"
    | "JENKINS_ARTIFACT"
    | "STRATEGY_MAX_CONCURRENT"
    | "MAX_CHAOS_SCENARIO_RUNS_PER_MONTH"
    | "MAX_CHAOS_DELEGATES"
  )[];
}

export type AvailabilityRestrictionMetadataDTO = RestrictionMetadataDTO & {
  enabled?: boolean;
};

export type CustomRestrictionMetadataDTO = RestrictionMetadataDTO & {};

export type DurationRestrictionMetadataDTO = RestrictionMetadataDTO & {
  timeUnit?: TimeUnit;
};

export interface FeatureRestrictionMetadataDTO {
  name?:
    | "TEST1"
    | "TEST2"
    | "TEST3"
    | "TEST4"
    | "TEST5"
    | "TEST6"
    | "TEST7"
    | "PERSPECTIVES"
    | "CCM_K8S_CLUSTERS"
    | "CCM_AUTOSTOPPING_RULES"
    | "MULTIPLE_ORGANIZATIONS"
    | "MULTIPLE_PROJECTS"
    | "INTEGRATED_APPROVALS_WITH_HARNESS_UI"
    | "INTEGRATED_APPROVALS_WITH_CUSTOM_SCRIPT"
    | "INTEGRATED_APPROVALS_WITH_JIRA"
    | "SECRET_MANAGERS"
    | "DEPLOYMENTS"
    | "INITIAL_DEPLOYMENTS"
    | "DEPLOYMENTS_PER_MONTH"
    | "SERVICES"
    | "BUILDS"
    | "SAML_SUPPORT"
    | "OAUTH_SUPPORT"
    | "LDAP_SUPPORT"
    | "TWO_FACTOR_AUTH_SUPPORT"
    | "CUSTOM_ROLES"
    | "CUSTOM_RESOURCE_GROUPS"
    | "MAX_TOTAL_BUILDS"
    | "MAX_BUILDS_PER_MONTH"
    | "ACTIVE_COMMITTERS"
    | "TEST_INTELLIGENCE"
    | "TEMPLATE_SERVICE"
    | "SRM_SERVICES"
    | "K8S_BG_SWAP_SERVICES"
    | "K8S_BLUE_GREEN_DEPLOY"
    | "K8S_APPLY"
    | "K8S_DELETE"
    | "K8S_CANARY_DELETE"
    | "K8S_ROLLING_DEPLOY"
    | "K8S_CANARY_DEPLOY"
    | "K8S_SCALE"
    | "K8S_ROLLING_ROLLBACK"
    | "TERRAFORM_APPLY"
    | "TERRAFORM_PLAN"
    | "TERRAFORM_DESTROY"
    | "TERRAFORM_ROLLBACK"
    | "INTEGRATED_APPROVALS_WITH_SERVICE_NOW"
    | "CREATE_STACK"
    | "DELETE_STACK"
    | "ROLLBACK_STACK"
    | "COMMAND"
    | "AZURE_SLOT_DEPLOYMENT"
    | "AZURE_TRAFFIC_SHIFT"
    | "AZURE_SWAP_SLOT"
    | "AZURE_WEBAPP_ROLLBACK"
    | "JENKINS_BUILD"
    | "AZURE_CREATE_ARM_RESOURCE"
    | "AZURE_CREATE_BP_RESOURCE"
    | "AZURE_ROLLBACK_ARM_RESOURCE"
    | "SECURITY"
    | "DEVELOPERS"
    | "MONTHLY_ACTIVE_USERS"
    | "JENKINS_ARTIFACT"
    | "STRATEGY_MAX_CONCURRENT"
    | "MAX_CHAOS_SCENARIO_RUNS_PER_MONTH"
    | "MAX_CHAOS_DELEGATES";
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  edition?: "COMMUNITY" | "FREE" | "TEAM" | "ENTERPRISE";
  restrictionMetadata?: {
    [key: string]: RestrictionMetadataDTO;
  };
}

export type LicenseRateLimitRestrictionMetadataDTO = RestrictionMetadataDTO & {
  limit?: number;
  fieldName?: string;
  timeUnit?: TimeUnit;
};

export type LicenseStaticLimitRestrictionMetadataDTO = RestrictionMetadataDTO & {
  limit?: number;
  fieldName?: string;
};

export type RateLimitRestrictionMetadataDTO = RestrictionMetadataDTO & {
  limit?: number;
  timeUnit?: TimeUnit;
  allowedIfEqual?: boolean;
};

export interface ResponseListFeatureRestrictionMetadataDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FeatureRestrictionMetadataDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RestrictionMetadataDTO {
  restrictionType?:
    | "AVAILABILITY"
    | "STATIC_LIMIT"
    | "RATE_LIMIT"
    | "CUSTOM"
    | "DURATION"
    | "LICENSE_RATE_LIMIT"
    | "LICENSE_STATIC_LIMIT";
}

export type StaticLimitRestrictionMetadataDTO = RestrictionMetadataDTO & {
  limit?: number;
  allowedIfEqual?: boolean;
};

export interface ResponseFeatureRestrictionMetadataDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FeatureRestrictionMetadataDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface FileDTO {
  accountIdentifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier: string;
  name: string;
  fileUsage?: "MANIFEST_FILE" | "CONFIG" | "SCRIPT";
  type: "FILE" | "FOLDER";
  parentIdentifier: string;
  description?: string;
  tags?: NGTag[];
  mimeType?: string;
  path?: string;
  draft?: boolean;
  createdBy?: EmbeddedUserDetailsDTO;
  lastModifiedBy?: EmbeddedUserDetailsDTO;
  lastModifiedAt?: number;
}

export interface ResponseFileDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FileDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface FileStoreRequest {
  file: FileDTO;
}

export interface EntityDetail {
  type?:
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait";
  entityRef?: EntityReference;
  name?: string;
}

export interface EntityReference {
  branch?: string;
  repoIdentifier?: string;
  default?: boolean;
  identifier?: string;
  accountIdentifier?: string;
  projectIdentifier?: string;
  orgIdentifier?: string;
}

export type EntityReferredByPipelineSetupUsageDetail = SetupUsageDetail & {
  identifier?: string;
  referenceType?: string;
};

export interface EntitySetupUsageDTO {
  accountIdentifier?: string;
  referredEntity?: EntityDetail;
  referredByEntity: EntityDetail;
  detail?: SetupUsageDetail;
  createdAt?: number;
}

export type IdentifierRef = EntityReference & {
  scope?: "account" | "org" | "project" | "unknown";
  metadata?: {
    [key: string]: string;
  };
  isDefault?: boolean;
  fullyQualifiedScopeIdentifier?: string;
};

export type InputSetReference = EntityReference & {
  pipelineIdentifier?: string;
  isDefault?: boolean;
};

export interface PageEntitySetupUsageDTO {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  sort?: Sort;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  content?: EntitySetupUsageDTO[];
  empty?: boolean;
}

export interface Pageable {
  sort?: Sort;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
  pageNumber?: number;
  offset?: number;
}

export interface ResponsePageEntitySetupUsageDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageEntitySetupUsageDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type SecretReferredByConnectorSetupUsageDetail = SetupUsageDetail & {
  fieldName?: string;
};

export interface SetupUsageDetail {
  [key: string]: any;
}

export interface Sort {
  sorted?: boolean;
  unsorted?: boolean;
  empty?: boolean;
}

export interface ResponseListEntityType {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: (
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait"
  )[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageFileDTO {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  sort?: Sort;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  content?: FileDTO[];
  empty?: boolean;
}

export interface ResponsePageFileDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageFileDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseSetEmbeddedUserDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EmbeddedUserDetailsDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type FileNodeDTO = FileStoreNodeDTO & {
  fileUsage: "MANIFEST_FILE" | "CONFIG" | "SCRIPT";
  description?: string;
  tags?: NGTag[];
  mimeType?: string;
  content?: string;
  size?: number;
};

export interface FileStoreNodeDTO {
  identifier: string;
  parentIdentifier?: string;
  name: string;
  type: "FILE" | "FOLDER";
  path?: string;
  lastModifiedAt?: number;
  lastModifiedBy?: EmbeddedUserDetailsDTO;
}

export interface FolderNodeDTO {
  identifier: string;
  parentIdentifier?: string;
  name: string;
  type: "FILE" | "FOLDER";
  path?: string;
  lastModifiedAt?: number;
  lastModifiedBy?: EmbeddedUserDetailsDTO;
  children?: FileStoreNodeDTO[];
}

export interface ResponseFolderNodeDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FolderNodeDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface FilterDTO {
  name: string;
  identifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  filterProperties: FilterProperties;
  filterVisibility?: "EveryOne" | "OnlyCreator";
}

export interface ResponseFilterDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FilterDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageFilterDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: FilterDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageFilterDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageFilterDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type ConnectedArgoGitOpsInfoDTO = GitOpsInfoDTO & {
  adapterUrl: string;
};

export interface GitOpsInfoDTO {
  type?: "CONNECTED_ARGO_PROVIDER" | "MANAGED_ARGO_PROVIDER";
}

export interface GitopsProviderResponse {
  name: string;
  identifier: string;
  description?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  tags?: {
    [key: string]: string;
  };
  spec: GitOpsInfoDTO;
}

export type ManagedArgoGitOpsInfoDTO = GitOpsInfoDTO & {
  namespace: string;
};

export interface ResponseGitopsProviderResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitopsProviderResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitOpsProvider {
  name: string;
  identifier: string;
  description?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  tags?: {
    [key: string]: string;
  };
  spec: GitOpsInfoDTO;
}

export interface PageGitopsProviderResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: GitopsProviderResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageGitopsProviderResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageGitopsProviderResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitBranchDTO {
  branchName?: string;
  branchSyncStatus?: "SYNCED" | "SYNCING" | "UNSYNCED";
}

export interface GitBranchListDTO {
  defaultBranch?: GitBranchDTO;
  branches?: PageGitBranchDTO;
}

export interface PageGitBranchDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: GitBranchDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponseGitBranchListDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitBranchListDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitSyncEntityDTO {
  entityName?: string;
  entityType?:
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait";
  entityIdentifier?: string;
  gitConnectorId?: string;
  repoUrl?: string;
  branch?: string;
  folderPath?: string;
  entityGitPath?: string;
  entityReference?: EntityReference;
  entityUrl?: string;
}

export interface GitSyncEntityListDTO {
  entityType?:
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait";
  count?: number;
  gitSyncEntities?: GitSyncEntityDTO[];
}

export interface PageGitSyncEntityListDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: GitSyncEntityListDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageGitSyncEntityListDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageGitSyncEntityListDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitSyncRepoFiles {
  gitSyncConfigIdentifier?: string;
  gitSyncEntityLists?: GitSyncEntityListDTO[];
}

export interface GitSyncRepoFilesList {
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  gitSyncRepoFilesList?: GitSyncRepoFiles[];
}

export interface ResponseGitSyncRepoFilesList {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitSyncRepoFilesList;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitEntityFilterProperties {
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  gitSyncConfigIdentifiers?: string[];
  entityTypes?: (
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait"
  )[];
  searchTerm?: string;
}

export interface ResponseListGitSyncEntityListDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitSyncEntityListDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitEntityBranchFilterSummaryProperties {
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  entityTypes?: (
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait"
  )[];
  searchTerm?: string;
}

export interface GitFullSyncEntityInfoDTO {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  filePath?: string;
  entityType?:
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait";
  syncStatus?: "QUEUED" | "SUCCESS" | "FAILED" | "OVERRIDDEN";
  name?: string;
  identifier?: string;
  branch?: string;
  repoName?: string;
  repoUrl?: string;
  rootFolder?: string;
  retryCount?: number;
  errorMessage?: string;
}

export interface PageGitFullSyncEntityInfoDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: GitFullSyncEntityInfoDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageGitFullSyncEntityInfoDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageGitFullSyncEntityInfoDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitFullSyncEntityInfoFilterKeys {
  entityTypes?: (
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait"
  )[];
  syncStatus?: "QUEUED" | "SUCCESS" | "FAILED" | "OVERRIDDEN";
}

export interface ResponseTriggerFullSyncResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: TriggerFullSyncResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface TriggerFullSyncResponseDTO {
  isFullSyncTriggered?: boolean;
}

export interface GitFullSyncConfigDTO {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  baseBranch?: string;
  branch?: string;
  prTitle?: string;
  createPullRequest?: boolean;
  repoIdentifier?: string;
  targetBranch?: string;
  rootFolder?: string;
  newBranch?: boolean;
}

export interface ResponseGitFullSyncConfigDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitFullSyncConfigDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitFullSyncConfigRequestDTO {
  branch: string;
  repoIdentifier: string;
  rootFolder: string;
  baseBranch?: string;
  createPullRequest?: boolean;
  targetBranch?: string;
  prTitle?: string;
  newBranch?: boolean;
}

export interface GitSyncSettingsDTO {
  accountIdentifier?: string;
  projectIdentifier: string;
  orgIdentifier: string;
  executeOnDelegate: boolean;
  gitSimplificationEnabled?: boolean;
  enabledOnlyForFF?: boolean;
}

export interface ResponseGitSyncSettingsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitSyncSettingsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitFileContent {
  content?: string;
  objectId?: string;
}

export interface ResponseGitFileContent {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitFileContent;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseSaasGitDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SaasGitDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SaasGitDTO {
  saasGit?: boolean;
}

export interface CreatePRDTO {
  prNumber?: number;
}

export interface ResponseCreatePRDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: CreatePRDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitPRCreateRequest {
  sourceBranch: string;
  targetBranch: string;
  title: string;
  yamlGitConfigRef?: string;
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  useUserFromToken?: boolean;
}

export interface CreatePRResponse {
  prNumber?: number;
}

export interface ResponseCreatePRResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: CreatePRResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface CreatePRRequest {
  orgIdentifier?: string;
  projectIdentifier?: string;
  repoName?: string;
  sourceBranchName?: string;
  targetBranchName?: string;
  connectorRef?: string;
  title?: string;
}

export interface GetFileResponseDTO {
  fileContent?: string;
  commitId?: string;
  blobId?: string;
}

export interface ResponseGetFileResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GetFileResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitRepositoryResponseDTO {
  name?: string;
}

export interface ResponseListGitRepositoryResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitRepositoryResponseDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListUserRepoResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: UserRepoResponse[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface UserRepoResponse {
  namespace?: string;
  name?: string;
}

export interface GitBranchDetailsDTO {
  name?: string;
}

export interface GitBranchesResponseDTO {
  branches?: GitBranchDetailsDTO[];
  defaultBranch?: GitBranchDetailsDTO;
}

export interface ResponseGitBranchesResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitBranchesResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListString {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: string[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitSyncConfig {
  identifier?: string;
  name?: string;
  projectIdentifier?: string;
  orgIdentifier?: string;
  gitConnectorRef?: string;
  repo?: string;
  branch?: string;
  gitConnectorType: "Github" | "Gitlab" | "Bitbucket";
  gitSyncFolderConfigDTOs?: GitSyncFolderConfigDTO[];
}

export interface GitSyncFolderConfigDTO {
  rootFolder?: string;
  isDefault?: boolean;
}

export interface GitEnabledDTO {
  connectivityMode?: "MANAGER" | "DELEGATE";
  gitSyncEnabledOnlyForFF?: boolean;
  gitSyncEnabled?: boolean;
  gitSimplificationEnabled?: boolean;
}

export interface GitSyncErrorCountDTO {
  gitToHarnessErrorCount?: number;
  connectivityErrorCount?: number;
}

export interface ResponseGitSyncErrorCountDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GitSyncErrorCountDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitSyncErrorDTO {
  accountIdentifier?: string;
  repoUrl?: string;
  repoId?: string;
  branchName?: string;
  scopes?: Scope[];
  changeType?:
    | "ADD"
    | "RENAME"
    | "MODIFY"
    | "DELETE"
    | "NONE"
    | "ADD_V2"
    | "UPDATE_V2";
  completeFilePath?: string;
  entityType?:
    | "CreatePR"
    | "GITOPS_MERGE_PR"
    | "Projects"
    | "Pipelines"
    | "PipelineSteps"
    | "Http"
    | "Email"
    | "JiraCreate"
    | "JiraUpdate"
    | "JiraApproval"
    | "HarnessApproval"
    | "CustomApproval"
    | "Barrier"
    | "Queue"
    | "FlagConfiguration"
    | "ShellScript"
    | "K8sCanaryDeploy"
    | "K8sApply"
    | "K8sBlueGreenDeploy"
    | "K8sRollingDeploy"
    | "K8sRollingRollback"
    | "K8sScale"
    | "K8sDelete"
    | "K8sBGSwapServices"
    | "K8sCanaryDelete"
    | "TerraformApply"
    | "TerraformPlan"
    | "TerraformDestroy"
    | "TerraformRollback"
    | "HelmDeploy"
    | "HelmRollback"
    | "Connectors"
    | "Secrets"
    | "Files"
    | "Service"
    | "Environment"
    | "EnvironmentGroup"
    | "InputSets"
    | "CvConfig"
    | "Verify"
    | "Delegates"
    | "DelegateConfigurations"
    | "CvVerificationJob"
    | "IntegrationStage"
    | "IntegrationSteps"
    | "SecurityStage"
    | "SecuritySteps"
    | "CvKubernetesActivitySource"
    | "DeploymentSteps"
    | "DeploymentStage"
    | "ApprovalStage"
    | "FeatureFlagStage"
    | "Template"
    | "TemplateStage"
    | "CustomDeployment"
    | "Triggers"
    | "MonitoredService"
    | "GitRepositories"
    | "FeatureFlags"
    | "ServiceNowApproval"
    | "ServiceNowCreate"
    | "ServiceNowUpdate"
    | "GovernancePolicies"
    | "POLICY_STEP"
    | "Run"
    | "RunTests"
    | "Plugin"
    | "RestoreCacheGCS"
    | "RestoreCacheS3"
    | "SaveCacheGCS"
    | "SaveCacheS3"
    | "Security"
    | "GitClone"
    | "ArtifactoryUpload"
    | "GCSUpload"
    | "S3Upload"
    | "BuildAndPushGCR"
    | "BuildAndPushECR"
    | "BuildAndPushDockerRegistry"
    | "CreateStack"
    | "DeleteStack"
    | "ServerlessAwsLambdaDeploy"
    | "ServerlessAwsLambdaRollback"
    | "CustomStage"
    | "RollbackStack"
    | "Infrastructure"
    | "Command"
    | "StrategyNode"
    | "AZURE_SLOT_DEPLOYMENT_STEP"
    | "AzureTrafficShift"
    | "AzureSwapSlot"
    | "AzureWebAppRollback"
    | "JenkinsBuild"
    | "EcsRollingDeploy"
    | "EcsRollingRollback"
    | "EcsCanaryDeploy"
    | "EcsCanaryDelete"
    | "AzureCreateARMResource"
    | "BuildAndPushACR"
    | "AzureCreateBPResource"
    | "AzureARMRollback"
    | "Background"
    | "Wait";
  failureReason?: string;
  status?: "ACTIVE" | "DISCARDED" | "EXPIRED" | "RESOLVED" | "OVERRIDDEN";
  errorType?: "GIT_TO_HARNESS" | "CONNECTIVITY_ISSUE" | "FULL_SYNC";
  additionalErrorDetails?: GitSyncErrorDetailsDTO;
  createdAt?: number;
}

export interface GitSyncErrorDetailsDTO {
  [key: string]: any;
}

export interface PageGitSyncErrorDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: GitSyncErrorDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageGitSyncErrorDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageGitSyncErrorDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface Scope {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
}

export interface GitSyncErrorAggregateByCommitDTO {
  gitCommitId?: string;
  failedCount?: number;
  repoId?: string;
  branchName?: string;
  commitMessage?: string;
  createdAt?: number;
  errorsForSummaryView?: GitSyncErrorDTO[];
}

export interface PageGitSyncErrorAggregateByCommitDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: GitSyncErrorAggregateByCommitDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageGitSyncErrorAggregateByCommitDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageGitSyncErrorAggregateByCommitDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface LdapTestResponse {
  status?: "SUCCESS" | "FAILURE";
  message?: string;
}

export interface RestResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: { [key: string]: any };
  responseMessages?: ResponseMessage[];
}

export interface RestResponseLdapTestResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LdapTestResponse;
  responseMessages?: ResponseMessage[];
}

export interface EmbeddedUser {
  uuid?: string;
  name?: string;
  email?: string;
  externalUserId?: string;
}

export interface LdapConnectionSettings {
  host: string;
  port?: number;
  sslEnabled?: boolean;
  referralsEnabled?: boolean;
  maxReferralHops?: number;
  bindDN?: string;
  bindPassword?: string;
  passwordType?: string;
  bindSecret?: string[];
  connectTimeout?: number;
  responseTimeout?: number;
  useRecursiveGroupMembershipSearch?: boolean;
  accountId?: string;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "RANCHER"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "SSO_SAML"
    | "LDAP"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER"
    | "OCI_HELM_REPO";
}

export interface LdapGroupSettings {
  baseDN?: string;
  searchFilter?: string;
  nameAttr?: string;
  descriptionAttr?: string;
  userMembershipAttr?: string;
  referencedUserAttr?: string;
}

export type LdapSettings = SSOSettings & {
  connectionSettings: LdapConnectionSettings;
  userSettingsList?: LdapUserSettings[];
  groupSettingsList?: LdapGroupSettings[];
  userSettings?: LdapUserSettings;
  groupSettings?: LdapGroupSettings;
  cronExpression?: string;
};

export interface LdapUserSettings {
  baseDN?: string;
  searchFilter?: string;
  uidAttr?: string;
  samAccountNameAttr?: string;
  emailAttr?: string;
  displayNameAttr?: string;
  groupMembershipAttr?: string;
}

export interface LdapGroupResponse {
  dn?: string;
  name?: string;
  description?: string;
  totalMembers?: number;
  selectable?: boolean;
  message?: string;
  users?: LdapUserResponse[];
}

export interface LdapUserResponse {
  dn?: string;
  email?: string;
  name?: string;
  userId?: string;
}

export interface RestResponseCollectionLdapGroupResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LdapGroupResponse[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseBoolean {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: boolean;
  responseMessages?: ResponseMessage[];
}

export interface LdapResponse {
  status?: "SUCCESS" | "FAILURE";
  message?: string;
}

export interface RestResponseLdapResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LdapResponse;
  responseMessages?: ResponseMessage[];
}

export type CDModuleLicenseDTO = ModuleLicenseDTO & {
  cdLicenseType?: "SERVICES" | "SERVICE_INSTANCES";
  workloads?: number;
  serviceInstances?: number;
};

export type CEModuleLicenseDTO = ModuleLicenseDTO & {
  spendLimit?: number;
};

export type CFModuleLicenseDTO = ModuleLicenseDTO & {
  numberOfUsers?: number;
  numberOfClientMAUs?: number;
};

export type CIModuleLicenseDTO = ModuleLicenseDTO & {
  numberOfCommitters?: number;
};

export type CVModuleLicenseDTO = ModuleLicenseDTO & {};

export type ChaosModuleLicenseDTO = ModuleLicenseDTO & {
  totalChaosScenarioRun?: number;
  totalChaosDelegates?: number;
};

export interface ModuleLicenseDTO {
  id?: string;
  accountIdentifier?: string;
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  edition?: "COMMUNITY" | "FREE" | "TEAM" | "ENTERPRISE";
  licenseType?: "TRIAL" | "PAID";
  status?: "ACTIVE" | "DELETED" | "EXPIRED";
  startTime?: number;
  expiryTime?: number;
  premiumSupport?: boolean;
  selfService?: boolean;
  createdAt?: number;
  lastModifiedAt?: number;
  trialExtended?: boolean;
}

export interface ResponseListModuleLicenseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ModuleLicenseDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type STOModuleLicenseDTO = ModuleLicenseDTO & {
  numberOfDevelopers?: number;
};

export type CDLicenseSummaryDTO = LicensesWithSummaryDTO & {
  totalWorkload?: number;
  totalServiceInstances?: number;
};

export type CELicenseSummaryDTO = LicensesWithSummaryDTO & {
  totalSpendLimit?: number;
};

export type CFLicenseSummaryDTO = LicensesWithSummaryDTO & {
  totalClientMAUs?: number;
  totalFeatureFlagUnits?: number;
};

export type CILicenseSummaryDTO = LicensesWithSummaryDTO & {
  totalDevelopers?: number;
};

export type CVLicenseSummaryDTO = LicensesWithSummaryDTO & {};

export interface LicensesWithSummaryDTO {
  edition?: "COMMUNITY" | "FREE" | "TEAM" | "ENTERPRISE";
  licenseType?: "TRIAL" | "PAID";
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  maxExpiryTime?: number;
}

export interface ResponseLicensesWithSummaryDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: LicensesWithSummaryDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type STOLicenseSummaryDTO = LicensesWithSummaryDTO & {
  totalDevelopers?: number;
};

export interface AccountLicenseDTO {
  accountId?: string;
  moduleLicenses?: {
    [key: string]: ModuleLicenseDTO;
  };
  allModuleLicenses?: {
    [key: string]: ModuleLicenseDTO[];
  };
  createdAt?: number;
  lastUpdatedAt?: number;
}

export interface ResponseAccountLicenseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AccountLicenseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseModuleLicenseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ModuleLicenseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface StartTrialDTO {
  moduleType:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  edition: "COMMUNITY" | "FREE" | "TEAM" | "ENTERPRISE";
}

export interface EditionActionDTO {
  action?:
    | "START_FREE"
    | "START_TRIAL"
    | "EXTEND_TRIAL"
    | "SUBSCRIBE"
    | "UPGRADE"
    | "CONTACT_SALES"
    | "CONTACT_SUPPORT"
    | "MANAGE"
    | "DISABLED_BY_TEAM"
    | "DISABLED_BY_ENTERPRISE";
  reason?: string;
}

export interface ResponseMapEditionSetEditionActionDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: {
    [key: string]: EditionActionDTO[];
  };
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseMapModuleTypeLong {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: {
    [key: string]: number;
  };
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ReferenceDTO {
  name?: string;
  identifier?: string;
  projectIdentifier?: string;
  orgIdentifier?: string;
  accountIdentifier?: string;
  count?: number;
}

export interface ResponseServiceUsageDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceUsageDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceUsageDTO {
  accountIdentifier?: string;
  module?: string;
  timestamp?: number;
  activeServices?: UsageDataDTO;
  activeServiceInstances?: UsageDataDTO;
  cdLicenseType?: "SERVICES" | "SERVICE_INSTANCES";
  serviceLicenses?: UsageDataDTO;
}

export interface UsageDataDTO {
  count?: number;
  displayName?: string;
  references?: ReferenceDTO[];
}

export interface ResponseServiceInstanceUsageDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceInstanceUsageDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceInstanceUsageDTO {
  accountIdentifier?: string;
  module?: string;
  timestamp?: number;
  activeServices?: UsageDataDTO;
  activeServiceInstances?: UsageDataDTO;
  cdLicenseType?: "SERVICES" | "SERVICE_INSTANCES";
}

export interface LicenseUsageDTO {
  accountIdentifier?: string;
  module?: string;
  timestamp?: number;
}

export interface ResponseLicenseUsageDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: LicenseUsageDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResourceGroup {
  identifier?: string;
  name?: string;
}

export interface ResponseRoleAssignmentAggregateResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: RoleAssignmentAggregateResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface Role {
  identifier: string;
  name: string;
  permissions?: string[];
  allowedScopeLevels?: string[];
  description?: string;
  tags?: {
    [key: string]: string;
  };
}

export interface RoleAssignment {
  identifier?: string;
  resourceGroupIdentifier: string;
  roleIdentifier: string;
  principal: Principal;
  disabled?: boolean;
  managed?: boolean;
}

export interface RoleAssignmentAggregateResponse {
  roleAssignments?: RoleAssignment[];
  scope?: Scope;
  roles?: RoleResponse[];
  resourceGroups?: ResourceGroup[];
}

export interface RoleResponse {
  role: Role;
  scope: Scope;
  harnessManaged?: boolean;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface RoleAssignmentFilter {
  resourceGroupFilter?: string[];
  roleFilter?: string[];
  principalTypeFilter?: (
    | "USER"
    | "USER_GROUP"
    | "SERVICE"
    | "API_KEY"
    | "SERVICE_ACCOUNT"
  )[];
  principalScopeLevelFilter?: string[];
  principalFilter?: Principal[];
  harnessManagedFilter?: boolean[];
  disabledFilter?: boolean[];
}

export interface PageRoleAssignmentResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: RoleAssignmentResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageRoleAssignmentResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageRoleAssignmentResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RoleAssignmentResponse {
  roleAssignment: RoleAssignment;
  scope: Scope;
  createdAt?: number;
  lastModifiedAt?: number;
  harnessManaged?: boolean;
}

export interface ResponseListRoleAssignmentResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: RoleAssignmentResponse[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RoleAssignmentCreateRequest {
  roleAssignments?: RoleAssignment[];
}

export interface ResponseRoleAssignmentResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: RoleAssignmentResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface LoginTypeResponse {
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
  showCaptcha?: boolean;
  defaultExperience?: "NG" | "CG";
  oauthEnabled?: boolean;
  ssorequest?: SSORequest;
}

export interface RestResponseLoginTypeResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LoginTypeResponse;
  responseMessages?: ResponseMessage[];
}

export interface SSORequest {
  oauthProviderType?:
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN";
  idpRedirectUrl?: string;
  oauthProviderTypes?: (
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN"
  )[];
}

export type LDAPSettings = NGAuthSettings & {
  connectionSettings: LdapConnectionSettings;
  identifier: string;
  userSettingsList?: LdapUserSettings[];
  groupSettingsList?: LdapGroupSettings[];
  displayName: string;
  cronExpression?: string;
  nextIterations?: number[];
};

export interface RestResponseLDAPSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LDAPSettings;
  responseMessages?: ResponseMessage[];
}

export interface TwoFactorAdminOverrideSettings {
  adminOverrideTwoFactorEnabled?: boolean;
}

export type OauthSettings = SSOSettings & {
  filter?: string;
  allowedProviders?: (
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN"
  )[];
};

export interface RestResponseSSOConfig {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: SSOConfig;
  responseMessages?: ResponseMessage[];
}

export interface SSOConfig {
  accountId?: string;
  ssoSettings?: SSOSettings[];
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
}

export interface SSOSettings {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  type: "SAML" | "LDAP" | "OAUTH";
  displayName?: string;
  url?: string;
  nextIteration?: number;
  nextIterations?: number[];
  accountId?: string;
}

export type SamlSettings = SSOSettings & {
  metaDataFile?: string;
  accountId: string;
  origin: string;
  groupMembershipAttr?: string;
  logoutUrl?: string;
  entityIdentifier?: string;
  clientId?: string;
  clientSecret?: string[];
  userIdAttr?: string;
  samlProviderType?: "AZURE" | "OKTA" | "ONELOGIN" | "OTHER";
  encryptedClientSecret?: string;
  authorizationEnabled?: boolean;
  settingType?:
    | "HOST_CONNECTION_ATTRIBUTES"
    | "BASTION_HOST_CONNECTION_ATTRIBUTES"
    | "SMTP"
    | "SFTP"
    | "JENKINS"
    | "BAMBOO"
    | "STRING"
    | "SPLUNK"
    | "ELK"
    | "LOGZ"
    | "SUMO"
    | "DATA_DOG"
    | "APM_VERIFICATION"
    | "BUG_SNAG"
    | "LOG_VERIFICATION"
    | "APP_DYNAMICS"
    | "NEW_RELIC"
    | "DYNA_TRACE"
    | "INSTANA"
    | "DATA_DOG_LOG"
    | "CLOUD_WATCH"
    | "SCALYR"
    | "ELB"
    | "SLACK"
    | "AWS"
    | "GCS"
    | "GCP"
    | "AZURE"
    | "PCF"
    | "RANCHER"
    | "DIRECT"
    | "KUBERNETES_CLUSTER"
    | "DOCKER"
    | "ECR"
    | "GCR"
    | "ACR"
    | "PHYSICAL_DATA_CENTER"
    | "KUBERNETES"
    | "NEXUS"
    | "ARTIFACTORY"
    | "SMB"
    | "AMAZON_S3"
    | "GIT"
    | "SSH_SESSION_CONFIG"
    | "SERVICE_VARIABLE"
    | "CONFIG_FILE"
    | "KMS"
    | "GCP_KMS"
    | "JIRA"
    | "SERVICENOW"
    | "SECRET_TEXT"
    | "YAML_GIT_SYNC"
    | "VAULT"
    | "VAULT_SSH"
    | "AWS_SECRETS_MANAGER"
    | "WINRM_CONNECTION_ATTRIBUTES"
    | "WINRM_SESSION_CONFIG"
    | "PROMETHEUS"
    | "INFRASTRUCTURE_MAPPING"
    | "HTTP_HELM_REPO"
    | "AMAZON_S3_HELM_REPO"
    | "GCS_HELM_REPO"
    | "SPOT_INST"
    | "AZURE_ARTIFACTS_PAT"
    | "CUSTOM"
    | "CE_AWS"
    | "CE_GCP"
    | "CE_AZURE"
    | "AZURE_VAULT"
    | "KUBERNETES_CLUSTER_NG"
    | "GIT_NG"
    | "SSO_SAML"
    | "LDAP"
    | "GCP_SECRETS_MANAGER"
    | "TRIGGER"
    | "OCI_HELM_REPO";
};

export interface Account {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  companyName: string;
  nextGenEnabled?: boolean;
  accountName: string;
  whitelistedDomains?: string[];
  licenseId?: string;
  dataRetentionDurationMs?: number;
  licenseInfo?: LicenseInfo;
  ceLicenseInfo?: CeLicenseInfo;
  accountEvents?: AccountEvent[];
  subdomainUrl?: string;
  twoFactorAdminEnforced?: boolean;
  forImport?: boolean;
  migratedToClusterUrl?: string;
  defaultExperience?: "NG" | "CG";
  createdFromNG?: boolean;
  accountActivelyUsed?: boolean;
  localEncryptionEnabled?: boolean;
  delegateConfiguration?: DelegateConfiguration;
  techStacks?: TechStack[];
  oauthEnabled?: boolean;
  ringName?: string;
  accountPreferences?: AccountPreferences;
  cloudCostEnabled?: boolean;
  ceAutoCollectK8sEvents?: boolean;
  trialSignupOptions?: TrialSignupOptions;
  serviceGuardLimit?: number;
  serviceAccountConfig?: ServiceAccountConfig;
  globalDelegateAccount?: boolean;
  defaults?: {
    [key: string]: string;
  };
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
  productLed?: boolean;
  harnessSupportAccessAllowed?: boolean;
  povAccount?: boolean;
}

export interface AccountEvent {
  accountEventType?:
    | "APP_CREATED"
    | "SERVICE_CREATED"
    | "ENV_CREATED"
    | "WORKFLOW_CREATED"
    | "WORKFLOW_DEPLOYED"
    | "PIPELINE_DEPLOYED"
    | "DELEGATE_INSTALLED"
    | "CLOUD_PROVIDER_CREATED"
    | "ARTIFACT_REPO_CREATED"
    | "PIPELINE_CREATED"
    | "ARTIFACT_STREAM_ADDED"
    | "INFRA_MAPPING_ADDED"
    | "INFRA_DEFINITION_ADDED"
    | "CUSTOM";
  customMsg?: string;
  category?: string;
  properties?: {
    [key: string]: string;
  };
}

export interface AccountPermissions {
  permissions?: (
    | "ACCOUNT"
    | "LOGGED_IN"
    | "DELEGATE"
    | "NONE"
    | "APP"
    | "ALL_APP_ENTITIES"
    | "ENV"
    | "SERVICE"
    | "WORKFLOW"
    | "PIPELINE"
    | "DEPLOYMENT"
    | "APPLICATION_CREATE_DELETE"
    | "USER_PERMISSION_MANAGEMENT"
    | "ACCOUNT_MANAGEMENT"
    | "PROVISIONER"
    | "TEMPLATE_MANAGEMENT"
    | "APP_TEMPLATE"
    | "USER_PERMISSION_READ"
    | "AUDIT_VIEWER"
    | "TAG_MANAGEMENT"
    | "CE_ADMIN"
    | "CE_VIEWER"
    | "MANAGE_CLOUD_PROVIDERS"
    | "MANAGE_CONNECTORS"
    | "MANAGE_APPLICATIONS"
    | "MANAGE_APPLICATION_STACKS"
    | "MANAGE_DELEGATES"
    | "MANAGE_ALERT_NOTIFICATION_RULES"
    | "MANAGE_DELEGATE_PROFILES"
    | "MANAGE_CONFIG_AS_CODE"
    | "MANAGE_SECRETS"
    | "MANAGE_SECRET_MANAGERS"
    | "MANAGE_SSH_AND_WINRM"
    | "MANAGE_AUTHENTICATION_SETTINGS"
    | "MANAGE_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "VIEW_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "MANAGE_IP_WHITELIST"
    | "MANAGE_IP_WHITELISTING"
    | "MANAGE_DEPLOYMENT_FREEZES"
    | "ALLOW_DEPLOYMENTS_DURING_FREEZE"
    | "MANAGE_PIPELINE_GOVERNANCE_STANDARDS"
    | "MANAGE_API_KEYS"
    | "MANAGE_TAGS"
    | "MANAGE_ACCOUNT_DEFAULTS"
    | "MANAGE_CUSTOM_DASHBOARDS"
    | "CREATE_CUSTOM_DASHBOARDS"
    | "MANAGE_RESTRICTED_ACCESS"
  )[];
}

export interface AccountPreferences {
  delegateSecretsCacheTTLInHours?: number;
}

export type AppFilter = Filter & {
  filterType?: string;
};

export interface AppPermission {
  permissionType?:
    | "ACCOUNT"
    | "LOGGED_IN"
    | "DELEGATE"
    | "NONE"
    | "APP"
    | "ALL_APP_ENTITIES"
    | "ENV"
    | "SERVICE"
    | "WORKFLOW"
    | "PIPELINE"
    | "DEPLOYMENT"
    | "APPLICATION_CREATE_DELETE"
    | "USER_PERMISSION_MANAGEMENT"
    | "ACCOUNT_MANAGEMENT"
    | "PROVISIONER"
    | "TEMPLATE_MANAGEMENT"
    | "APP_TEMPLATE"
    | "USER_PERMISSION_READ"
    | "AUDIT_VIEWER"
    | "TAG_MANAGEMENT"
    | "CE_ADMIN"
    | "CE_VIEWER"
    | "MANAGE_CLOUD_PROVIDERS"
    | "MANAGE_CONNECTORS"
    | "MANAGE_APPLICATIONS"
    | "MANAGE_APPLICATION_STACKS"
    | "MANAGE_DELEGATES"
    | "MANAGE_ALERT_NOTIFICATION_RULES"
    | "MANAGE_DELEGATE_PROFILES"
    | "MANAGE_CONFIG_AS_CODE"
    | "MANAGE_SECRETS"
    | "MANAGE_SECRET_MANAGERS"
    | "MANAGE_SSH_AND_WINRM"
    | "MANAGE_AUTHENTICATION_SETTINGS"
    | "MANAGE_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "VIEW_USER_AND_USER_GROUPS_AND_API_KEYS"
    | "MANAGE_IP_WHITELIST"
    | "MANAGE_IP_WHITELISTING"
    | "MANAGE_DEPLOYMENT_FREEZES"
    | "ALLOW_DEPLOYMENTS_DURING_FREEZE"
    | "MANAGE_PIPELINE_GOVERNANCE_STANDARDS"
    | "MANAGE_API_KEYS"
    | "MANAGE_TAGS"
    | "MANAGE_ACCOUNT_DEFAULTS"
    | "MANAGE_CUSTOM_DASHBOARDS"
    | "CREATE_CUSTOM_DASHBOARDS"
    | "MANAGE_RESTRICTED_ACCESS";
  appFilter?: AppFilter;
  entityFilter?: Filter;
  actions?: (
    | "ALL"
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "EXECUTE"
    | "EXECUTE_WORKFLOW"
    | "EXECUTE_PIPELINE"
    | "EXECUTE_WORKFLOW_ROLLBACK"
    | "DEFAULT"
    | "ABORT_WORKFLOW"
  )[];
}

export interface AuthenticationSettingsResponse {
  ngAuthSettings?: NGAuthSettings[];
  whitelistedDomains?: string[];
  authenticationMechanism?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
  twoFactorEnabled?: boolean;
}

export interface CeLicenseInfo {
  licenseType?: "FULL_TRIAL" | "LIMITED_TRIAL" | "PAID";
  expiryTime?: number;
}

export interface DelegateConfiguration {
  delegateVersions?: string[];
  action?: "SELF_DESTRUCT";
  validUntil?: number;
  validTillNextRelease?: boolean;
}

export type EnvFilter = Filter & {
  filterTypes?: string[];
};

export type ExecutableElementsFilter = Filter & {
  filterTypes?: string[];
  executableElementFilterType?: string;
  filter?: GenericEntityFilter;
};

export interface Filter {
  ids?: string[];
}

export type GenericEntityFilter = Filter & {
  filterType?: string;
};

export interface LicenseInfo {
  accountType?: string;
  accountStatus?: string;
  expiryTime?: number;
  licenseUnits?: number;
}

export interface LoginSettings {
  uuid: string;
  accountId: string;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt?: number;
  userLockoutPolicy: UserLockoutPolicy;
  passwordExpirationPolicy: PasswordExpirationPolicy;
  passwordStrengthPolicy: PasswordStrengthPolicy;
}

export interface NGAuthSettings {
  settingsType?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
}

export interface NotificationSettings {
  useIndividualEmails?: boolean;
  sendMailToNewMembers?: boolean;
  emailAddresses: string[];
  slackConfig: SlackNotificationSetting;
  pagerDutyIntegrationKey?: string;
  microsoftTeamsWebhookUrl?: string;
}

export interface OAuthSettings {
  filter?: string;
  allowedProviders?: (
    | "AZURE"
    | "BITBUCKET"
    | "GITHUB"
    | "GITLAB"
    | "GOOGLE"
    | "LINKEDIN"
  )[];
  settingsType?: "USER_PASSWORD" | "SAML" | "LDAP" | "OAUTH";
}

export interface PasswordExpirationPolicy {
  enabled?: boolean;
  daysBeforePasswordExpires?: number;
  daysBeforeUserNotifiedOfPasswordExpiration?: number;
}

export interface PasswordStrengthPolicy {
  enabled?: boolean;
  minNumberOfCharacters?: number;
  minNumberOfUppercaseCharacters?: number;
  minNumberOfLowercaseCharacters?: number;
  minNumberOfSpecialCharacters?: number;
  minNumberOfDigits?: number;
}

export interface RateLimitProtection {
  incorrectAttemptTimestamps?: number[];
  lastNotificationSentToUserAt?: number;
  lastNotificationSentToSecOpsAt?: number;
  totalIncorrectAttempts?: number;
}

export interface RestResponseAuthenticationSettingsResponse {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AuthenticationSettingsResponse;
  responseMessages?: ResponseMessage[];
}

export type SAMLSettings = NGAuthSettings & {
  origin: string;
  identifier: string;
  logoutUrl?: string;
  groupMembershipAttr?: string;
  displayName?: string;
  authorizationEnabled?: boolean;
  entityIdentifier?: string;
  samlProviderType?: string;
  clientId?: string;
  clientSecret?: string;
};

export interface SlackNotificationSetting {
  name?: string;
  outgoingWebhookUrl: string;
}

export interface TechStack {
  category?: string;
  technology?: string;
}

export interface TrialSignupOptions {
  productsSelected?: ("CD" | "CE" | "CI")[];
  assistedOption?: boolean;
}

export interface User {
  uuid: string;
  appId: string;
  createdBy?: EmbeddedUser;
  createdAt?: number;
  lastUpdatedBy?: EmbeddedUser;
  lastUpdatedAt: number;
  name?: string;
  externalUserId?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  companyName?: string;
  accountName?: string;
  userGroups?: UserGroup[];
  accounts?: Account[];
  pendingAccounts?: Account[];
  supportAccounts?: Account[];
  lastLogin?: number;
  firstLogin?: boolean;
  password?: string[];
  token?: string;
  twoFactorJwtToken?: string;
  emailVerified?: boolean;
  passwordExpired?: boolean;
  userLocked?: boolean;
  statsFetchedOn?: number;
  lastAccountId?: string;
  defaultAccountId?: string;
  lastAppId?: string;
  disabled?: boolean;
  imported?: boolean;
  userLockoutInfo?: UserLockoutInfo;
  rateLimitProtection?: RateLimitProtection;
  twoFactorAuthenticationEnabled?: boolean;
  twoFactorAuthenticationMechanism?: "TOTP";
  oauthProvider?: string;
  reportedSegmentTracks?: string[];
  utmInfo?: UtmInfo;
  accountIds?: string[];
}

export interface UserGroup {
  id?: string;
  accountIdentifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier?: string;
  isSsoLinked?: boolean;
  externallyManaged?: boolean;
  linkedSsoType?: "SAML" | "LDAP" | "OAUTH";
  linkedSsoId?: string;
  linkedSsoDisplayName?: string;
  ssoGroupId?: string;
  ssoGroupName?: string;
  name?: string;
  users: string[];
  notificationConfigs: NotificationSettingConfig[];
  harnessManaged?: boolean;
  description: string;
  tags: NGTag[];
  createdAt?: number;
  lastModifiedAt?: number;
  version?: number;
  deleted?: boolean;
}

export interface UserGroupEntityReference {
  id?: string;
  appId?: string;
  accountId?: string;
  entityType?: string;
}

export interface UserLockoutInfo {
  numberOfFailedLoginAttempts?: number;
  userLockedAt?: number;
}

export interface UserLockoutPolicy {
  enableLockoutPolicy?: boolean;
  numberOfFailedAttemptsBeforeLockout?: number;
  lockOutPeriod?: number;
  notifyUser?: boolean;
  userGroupsToNotify?: UserGroup[];
}

export type UsernamePasswordSettings = NGAuthSettings & {
  loginSettings: LoginSettings;
};

export interface UtmInfo {
  utmSource?: string;
  utmContent?: string;
  utmMedium?: string;
  utmTerm?: string;
  utmCampaign?: string;
}

export type WorkflowFilter = Filter & {
  filterTypes?: string[];
};

export interface RestResponsePasswordStrengthPolicy {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: PasswordStrengthPolicy;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseLoginSettings {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: LoginSettings;
  responseMessages?: ResponseMessage[];
}

export interface AccountSettingConfig {
  [key: string]: any;
}

export interface AccountSettingResponse {
  accountSettings?: AccountSettings;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface AccountSettings {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  config: AccountSettingConfig;
  type: "Connector";
}

export type ConnectorSettings = AccountSettingConfig & {
  builtInSMDisabled?: boolean;
};

export interface ResponseAccountSettingResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AccountSettingResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListAccountSettings {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AccountSettings[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ConnectivityCheckSummary {
  successCount?: number;
  failureCount?: number;
  startTime?: number;
  endTime?: number;
}

export interface ResponseConnectivityCheckSummary {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ConnectivityCheckSummary;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ActivitySummary {
  startTime?: number;
  endTime?: number;
  heartBeatFailuresCount?: number;
  successfulActivitiesCount?: number;
  failedActivitiesCount?: number;
}

export interface PageActivitySummary {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  sort?: Sort;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  content?: ActivitySummary[];
  empty?: boolean;
}

export interface ResponsePageActivitySummary {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageActivitySummary;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface Activity {
  accountIdentifier?: string;
  referredEntity?: EntityDetail;
  type:
    | "CONNECTIVITY_CHECK"
    | "ENTITY_USAGE"
    | "ENTITY_CREATION"
    | "ENTITY_UPDATE";
  activityStatus: "SUCCESS" | "FAILED";
  detail?: ActivityDetail;
  activityTime: number;
  description?: string;
}

export interface ActivityDetail {
  [key: string]: any;
}

export interface PageActivity {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  sort?: Sort;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  content?: Activity[];
  empty?: boolean;
}

export interface ResponsePageActivity {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageActivity;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AgentMtlsEndpointDetails {
  uuid?: string;
  accountId?: string;
  fqdn?: string;
  caCertificates?: string;
  mode?: "LOOSE" | "STRICT";
}

export interface RestResponseAgentMtlsEndpointDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: AgentMtlsEndpointDetails;
  responseMessages?: ResponseMessage[];
}

export interface AgentMtlsEndpointRequest {
  domainPrefix?: string;
  caCertificates?: string;
  mode?: "LOOSE" | "STRICT";
}

export interface AcrBuildDetailsDTO {
  tag?: string;
  buildUrl?: string;
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  repository?: string;
}

export interface AcrResponseDTO {
  buildDetailsList?: AcrBuildDetailsDTO[];
}

export interface ResponseAcrResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AcrResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureSubscriptionDTO {
  subscriptionName: string;
  subscriptionId: string;
}

export interface AzureSubscriptionsDTO {
  subscriptions?: AzureSubscriptionDTO[];
}

export interface ResponseAzureSubscriptionsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureSubscriptionsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AcrRegistriesDTO {
  registries?: AcrRegistryDTO[];
}

export interface AcrRegistryDTO {
  registry: string;
}

export interface ResponseAcrRegistriesDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AcrRegistriesDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AcrRepositoriesDTO {
  repositories?: AcrRepositoryDTO[];
}

export interface AcrRepositoryDTO {
  repository: string;
}

export interface ResponseAcrRepositoriesDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AcrRepositoriesDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ArtifactoryBuildDetailsDTO {
  [key: string]: any;
}

export interface ArtifactoryResponseDTO {
  buildDetailsList?: ArtifactoryBuildDetailsDTO[];
}

export interface ResponseArtifactoryResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ArtifactoryResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseArtifactoryBuildDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ArtifactoryBuildDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ArtifactoryRequestDTO {
  tag?: string;
  tagRegex?: string;
}

export interface ArtifactoryRepoDetailsDTO {
  repositories?: {
    [key: string]: string;
  };
}

export interface ResponseArtifactoryRepoDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ArtifactoryRepoDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ArtifactoryArtifactBuildDetailsDTO {
  artifactName?: string;
  artifactPath?: string;
}

export interface ResponseListArtifactoryArtifactBuildDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ArtifactoryArtifactBuildDetailsDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface DockerBuildDetailsDTO {
  tag?: string;
  buildUrl?: string;
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  imagePath?: string;
}

export interface DockerResponseDTO {
  buildDetailsList?: DockerBuildDetailsDTO[];
}

export interface ResponseDockerResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: DockerResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface DockerRequestDTO {
  tag?: string;
  tagRegex?: string;
  tagsList?: string[];
}

export interface ResponseDockerBuildDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: DockerBuildDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface EcrBuildDetailsDTO {
  tag?: string;
  buildUrl?: string;
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  imagePath?: string;
}

export interface EcrResponseDTO {
  buildDetailsList?: EcrBuildDetailsDTO[];
}

export interface ResponseEcrResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EcrResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseEcrBuildDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EcrBuildDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface EcrRequestDTO {
  tag?: string;
  tagRegex?: string;
  tagsList?: string[];
  region?: string;
}

export interface EcrListImagesDTO {
  images?: string[];
}

export interface ResponseEcrListImagesDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EcrListImagesDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GARBuildDetailsDTO {
  version?: string;
}

export interface GARResponseDTO {
  buildDetailsList?: GARBuildDetailsDTO[];
}

export interface ResponseGARResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GARResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RegionGar {
  name?: string;
  value?: string;
}

export interface ResponseListRegionGar {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: RegionGar[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GcrBuildDetailsDTO {
  tag?: string;
  buildUrl?: string;
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  imagePath?: string;
}

export interface GcrResponseDTO {
  buildDetailsList?: GcrBuildDetailsDTO[];
}

export interface ResponseGcrResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GcrResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseGcrBuildDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GcrBuildDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GcrRequestDTO {
  tag?: string;
  tagRegex?: string;
  tagsList?: string[];
  registryHostname?: string;
}

export interface ArtifactFileMetadata {
  fileName?: string;
  url?: string;
}

export interface BuildDetails {
  number?: string;
  revision?: string;
  description?: string;
  artifactPath?: string;
  buildUrl?: string;
  buildDisplayName?: string;
  buildFullDisplayName?: string;
  artifactFileSize?: string;
  uiDisplayName?: string;
  status?: "FAILURE" | "UNSTABLE" | "SUCCESS";
  buildParameters?: {
    [key: string]: string;
  };
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  artifactFileMetadataList?: ArtifactFileMetadata[];
}

export interface ResponseListBuildDetails {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: BuildDetails[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseBuildDetails {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: BuildDetails;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GithubPackageDTO {
  packageId?: string;
  packageName?: string;
  packageType?: string;
  visibility?: string;
  packageUrl?: string;
}

export interface GithubPackagesResponseDTO {
  githubPackageResponse?: GithubPackageDTO[];
}

export interface ResponseGithubPackagesResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GithubPackagesResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface JenkinsJobDetailsDTO {
  jobDetails?: JobDetails[];
}

export interface JobDetails {
  jobName?: string;
  url?: string;
  parameters?: JobParameter[];
  folder?: boolean;
}

export interface JobParameter {
  name?: string;
  options?: string[];
  defaultValue?: string;
  description?: string;
}

export interface ResponseJenkinsJobDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JenkinsJobDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListJobParameter {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JobParameter[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface NexusBuildDetailsDTO {
  tag?: string;
  buildUrl?: string;
  metadata?: {
    [key: string]: string;
  };
  labels?: {
    [key: string]: string;
  };
  imagePath?: string;
}

export interface NexusResponseDTO {
  buildDetailsList?: NexusBuildDetailsDTO[];
}

export interface ResponseNexusResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: NexusResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseNexusBuildDetailsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: NexusBuildDetailsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface NexusRequestDTO {
  tag?: string;
  tagRegex?: string;
}

export interface ResponseSetString {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: string[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseMapStringString {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: {
    [key: string]: string;
  };
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AwsListInstancesFilter {
  region: string;
  autoScalingGroupName?: string;
  vpcIds?: string[];
  tags?: {
    [key: string]: string;
  };
  winRm?: boolean;
}

export interface AwsVPC {
  id?: string;
  name?: string;
}

export interface ResponseListAwsVPC {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AwsVPC[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AwsCFTemplateParamsData {
  paramKey?: string;
  paramType?: string;
  defaultValue?: string;
}

export interface ResponseListAwsCFTemplateParamsData {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AwsCFTemplateParamsData[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface BucketResponse {
  bucketName?: string;
}

export interface ResponseListBucketResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: BucketResponse[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface FilePaths {
  buildDetails?: BuildDetails;
}

export interface ResponseListFilePaths {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: FilePaths[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface CustomDeploymentVariableProperties {
  fqn: string;
  variableName: string;
  localName?: string;
  aliasFqn?: string;
  visible?: boolean;
}

export interface CustomDeploymentVariableResponse {
  yaml: string;
  metadataMap: {
    [key: string]: CustomDeploymentVariableProperties;
  };
}

export interface ResponseCustomDeploymentVariableResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: CustomDeploymentVariableResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface CustomDeploymentYamlRequest {
  entityYaml: string;
}

export interface EntityDetailProtoDTO {
  [key: string]: any;
}

export interface ResponseListEntityDetailProtoDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EntityDetailProtoDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface DelegateProfileDetailsNg {
  uuid?: string;
  accountId?: string;
  name?: string;
  description?: string;
  primary?: boolean;
  approvalRequired?: boolean;
  startupScript?: string;
  scopingRules?: ScopingRuleDetailsNg[];
  selectors?: string[];
  createdBy?: EmbeddedUserDetails;
  lastUpdatedBy?: EmbeddedUserDetails;
  createdAt?: number;
  lastUpdatedAt?: number;
  identifier?: string;
  numberOfDelegates?: number;
  orgIdentifier?: string;
  projectIdentifier?: string;
}

export interface EmbeddedUserDetails {
  uuid?: string;
  name?: string;
  email?: string;
}

export interface RestResponseDelegateProfileDetailsNg {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateProfileDetailsNg;
  responseMessages?: ResponseMessage[];
}

export interface ScopingRuleDetailsNg {
  description?: string;
  environmentTypeId?: string;
  environmentIds?: string[];
}

export interface RestResponsePageResponseDelegateProfileDetailsNg {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateProfileDetailsNg[];
  responseMessages?: ResponseMessage[];
}

export interface DelegateEntityOwner {
  identifier?: string;
}

export interface DelegateGroup {
  uuid: string;
  name?: string;
  delegateType?: string;
  description?: string;
  accountId?: string;
  owner?: DelegateEntityOwner;
  ng?: boolean;
  delegateConfigurationId?: string;
  sizeDetails?: DelegateSizeDetails;
  k8sConfigDetails?: K8sConfigDetails;
  tags?: string[];
  status?: "ENABLED" | "DELETED";
  validUntil?: string;
  identifier?: string;
  upgraderLastUpdated?: number;
}

export interface DelegateSizeDetails {
  size?: "LAPTOP" | "SMALL" | "MEDIUM" | "LARGE";
  label?: string;
  replicas?: number;
  ram?: number;
  cpu?: number;
}

export interface K8sConfigDetails {
  k8sPermissionType?: "CLUSTER_ADMIN" | "CLUSTER_VIEWER" | "NAMESPACE_ADMIN";
  namespace?: string;
}

export interface RestResponseDelegateGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateGroup;
  responseMessages?: ResponseMessage[];
}

export interface DelegateGroupTags {
  tags?: string[];
}

export interface DelegateDownloadRequest {
  name: string;
  description?: string;
  size?: "LAPTOP" | "SMALL" | "MEDIUM" | "LARGE";
  tags?: string[];
  tokenName?: string;
  clusterPermissionType?:
    | "CLUSTER_ADMIN"
    | "CLUSTER_VIEWER"
    | "NAMESPACE_ADMIN";
  customClusterNamespace?: string;
}

export interface DelegateGroupDTO {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  name?: string;
  identifier?: string;
  tags?: string[];
}

export interface RestResponseDelegateGroupDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateGroupDTO;
  responseMessages?: ResponseMessage[];
}

export interface RestResponseListDelegateGroupDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateGroupDTO[];
  responseMessages?: ResponseMessage[];
}

export interface RestResponseVoid {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: Void;
  responseMessages?: ResponseMessage[];
}

export interface Void {
  [key: string]: any;
}

export interface DelegateSetupDetails {
  orgIdentifier?: string;
  projectIdentifier?: string;
  name: string;
  description?: string;
  size?: "LAPTOP" | "SMALL" | "MEDIUM" | "LARGE";
  hostName?: string;
  delegateConfigurationId?: string;
  identifier?: string;
  k8sConfigDetails?: K8sConfigDetails;
  tags?: string[];
  delegateType: string;
  tokenName?: string;
}

export type ApiKeyPrincipal = Principal & {};

export interface DelegateTokenDetails {
  uuid?: string;
  accountId?: string;
  name?: string;
  createdBy?: EmbeddedUser;
  createdByNgUser?: Principal;
  createdAt?: number;
  status?: "ACTIVE" | "REVOKED";
  value?: string;
  ownerIdentifier?: string;
}

export interface RestResponseListDelegateTokenDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateTokenDetails[];
  responseMessages?: ResponseMessage[];
}

export type ServiceAccountPrincipal = Principal & {
  email?: string;
  username?: string;
};

export type ServicePrincipal = Principal & {};

export type UserPrincipal = Principal & {
  email?: string;
  username?: string;
  accountId?: string;
};

export interface RestResponseDelegateTokenDetails {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateTokenDetails;
  responseMessages?: ResponseMessage[];
}

export interface DelegateConnectionDetails {
  uuid?: string;
  version?: string;
  lastHeartbeat?: number;
  lastGrpcHeartbeat?: number;
}

export interface DelegateGroupDetails {
  groupId?: string;
  delegateGroupIdentifier?: string;
  delegateType?: string;
  groupName?: string;
  delegateDescription?: string;
  delegateConfigurationId?: string;
  groupImplicitSelectors?: {
    [key: string]:
      | "PROFILE_NAME"
      | "DELEGATE_NAME"
      | "HOST_NAME"
      | "GROUP_NAME"
      | "GROUP_SELECTORS"
      | "PROFILE_SELECTORS";
  };
  groupCustomSelectors?: string[];
  lastHeartBeat?: number;
  connectivityStatus?: string;
  activelyConnected?: boolean;
  grpcActive?: boolean;
  delegateInstanceDetails?: DelegateInner[];
  tokenActive?: boolean;
  autoUpgrade?: boolean;
  delegateGroupExpirationTime?: number;
  delegateVersion?: string;
  upgraderLastUpdated?: number;
  immutable?: boolean;
  versions?: string[];
}

export interface DelegateGroupListing {
  delegateGroupDetails?: DelegateGroupDetails[];
}

export interface DelegateInner {
  uuid?: string;
  lastHeartbeat?: number;
  activelyConnected?: boolean;
  hostName?: string;
  tokenActive?: boolean;
  version?: string;
  delegateExpirationTime?: number;
  connections?: DelegateConnectionDetails[];
}

export interface RestResponseDelegateGroupListing {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: DelegateGroupListing;
  responseMessages?: ResponseMessage[];
}

export interface ResponseEnvironmentResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvironmentResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface EnvironmentRequestDTO {
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier?: string;
  tags?: {
    [key: string]: string;
  };
  name?: string;
  description?: string;
  color?: string;
  type: "PreProduction" | "Production";
  yaml?: string;
}

export interface PageEnvironmentResponseDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: EnvironmentResponseDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageEnvironmentResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageEnvironmentResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseEnvironmentResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvironmentResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageEnvironmentResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: EnvironmentResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageEnvironmentResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageEnvironmentResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListEnvironmentResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvironmentResponse[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ApplicationSettingsConfiguration {
  store: StoreConfigWrapper;
  metadata?: string;
}

export type ArtifactoryStoreConfig = StoreConfig & {
  connectorRef: string;
  repositoryName: string;
  artifactPaths?: string[];
  metadata?: string;
};

export type AzureRepoStore = StoreConfig & {
  connectorRef: string;
  gitFetchType: "Branch" | "Commit";
  branch?: string;
  commitId?: string;
  paths?: string[];
  folderPath?: string;
  repoName?: string;
};

export type BitbucketStore = StoreConfig & {
  connectorRef: string;
  gitFetchType: "Branch" | "Commit";
  branch?: string;
  commitId?: string;
  paths?: string[];
  folderPath?: string;
  repoName?: string;
};

export interface ConfigFile {
  identifier: string;
  spec: ConfigFileAttributes;
}

export interface ConfigFileAttributeStepParameters {
  store?: StoreConfigWrapperParameters;
}

export interface ConfigFileAttributes {
  store: StoreConfigWrapper;
  configFileAttributeStepParameters?: ConfigFileAttributeStepParameters;
}

export interface ConfigFileWrapper {
  configFile?: ConfigFile;
}

export interface ConnectionStringsConfiguration {
  store: StoreConfigWrapper;
  metadata?: string;
}

export type CustomRemoteStoreConfig = StoreConfig & {
  extractionScript: string;
  filePath: string;
  delegateSelectors?: string[];
};

export type EcsScalableTargetDefinitionManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export type EcsScalingPolicyDefinitionManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export type EcsServiceDefinitionManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export type EcsTaskDefinitionManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export type GcsStoreConfig = StoreConfig & {
  connectorRef?: string;
  bucketName?: string;
  folderPath?: string;
  metadata?: string;
};

export type GitLabStore = StoreConfig & {
  connectorRef: string;
  gitFetchType: "Branch" | "Commit";
  branch?: string;
  commitId?: string;
  paths?: string[];
  folderPath?: string;
  repoName?: string;
};

export type GitStore = StoreConfig & {
  connectorRef: string;
  gitFetchType: "Branch" | "Commit";
  branch?: string;
  commitId?: string;
  paths?: string[];
  folderPath?: string;
  repoName?: string;
};

export type GithubStore = StoreConfig & {
  connectorRef: string;
  gitFetchType: "Branch" | "Commit";
  branch?: string;
  commitId?: string;
  paths?: string[];
  folderPath?: string;
  repoName?: string;
};

export type HarnessStore = StoreConfig & {
  files?: string[];
  secretFiles?: string[];
};

export type HelmChartManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  chartName?: string;
  chartVersion?: string;
  helmVersion?: "V2" | "V3" | "V380";
  valuesPaths?: string[];
  skipResourceVersioning?: boolean;
  commandFlags?: HelmManifestCommandFlag[];
  metadata?: string;
};

export interface HelmManifestCommandFlag {
  commandType:
    | "Fetch"
    | "Template"
    | "Pull"
    | "Install"
    | "Upgrade"
    | "Rollback"
    | "History"
    | "Delete"
    | "Uninstall"
    | "List";
  flag?: string;
}

export type HttpStoreConfig = StoreConfig & {
  connectorRef?: string;
  metadata?: string;
};

export type InheritFromManifestStoreConfig = StoreConfig & {
  paths?: string[];
};

export type InlineStoreConfig = StoreConfig & {
  content: string;
};

export interface InputSetValidator {
  validatorType?: "ALLOWED_VALUES" | "REGEX";
  parameters?: string;
}

export type K8sManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  valuesPaths?: string[];
  skipResourceVersioning?: ParameterFieldBoolean;
  metadata?: string;
};

export type KustomizeManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  overlayConfiguration?: OverlayConfiguration;
  patchesPaths?: string[];
  skipResourceVersioning?: ParameterFieldBoolean;
  pluginPath?: string;
  metadata?: string;
};

export type KustomizePatchesManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export interface ManifestAttributes {
  [key: string]: any;
}

export interface ManifestConfig {
  identifier: string;
  type:
    | "HelmChart"
    | "K8sManifest"
    | "Kustomize"
    | "KustomizePatches"
    | "OpenshiftParam"
    | "OpenshiftTemplate"
    | "Values"
    | "ServerlessAwsLambda"
    | "ReleaseRepo"
    | "EcsTaskDefinition"
    | "EcsServiceDefinition"
    | "EcsScalableTargetDefinition"
    | "EcsScalingPolicyDefinition";
  spec: ManifestAttributes;
}

export interface ManifestConfigWrapper {
  manifest?: ManifestConfig;
}

export interface NGEnvironmentConfig {
  environment?: NGEnvironmentInfoConfig;
}

export interface NGEnvironmentGlobalOverride {
  manifests?: ManifestConfigWrapper[];
  configFiles?: ConfigFileWrapper[];
  applicationSettings?: ApplicationSettingsConfiguration;
  connectionStrings?: ConnectionStringsConfiguration;
  metadata?: string;
}

export interface NGEnvironmentInfoConfig {
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier: string;
  tags?: {
    [key: string]: string;
  };
  name: string;
  description?: string;
  type: "PreProduction" | "Production";
  variables?: NGVariable[];
  overrides?: NGEnvironmentGlobalOverride;
}

export interface NGVariable {
  description?: string;
  required?: boolean;
  name?: string;
  type?: "String" | "Number" | "Secret";
  metadata?: string;
}

export type NumberNGVariable = NGVariable & {
  name?: string;
  type?: "Number";
  value: number;
  default?: number;
};

export type OciHelmChartConfig = StoreConfig & {
  config?: OciHelmChartStoreConfigWrapper;
  basePath?: string;
  metadata?: string;
};

export interface OciHelmChartStoreConfig {
  [key: string]: any;
}

export interface OciHelmChartStoreConfigWrapper {
  type: "Generic";
  spec: OciHelmChartStoreConfig;
  metadata?: string;
}

export type OciHelmChartStoreGenericConfig = OciHelmChartStoreConfig & {
  connectorRef?: ParameterFieldString;
  metadata?: string;
};

export type OpenshiftManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  paramsPaths?: string[];
  skipResourceVersioning?: ParameterFieldBoolean;
  metadata?: string;
};

export type OpenshiftParamManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export interface OverlayConfiguration {
  kustomizeYamlFolderPath: string;
}

export interface ParameterField {
  expressionValue?: string;
  expression?: boolean;
  value?: { [key: string]: any };
  defaultValue?: { [key: string]: any };
  typeString?: boolean;
  inputSetValidator?: InputSetValidator;
  jsonResponseField?: boolean;
  responseField?: string;
  executionInput?: boolean;
}

export interface ParameterFieldBoolean {
  expressionValue?: string;
  expression?: boolean;
  value?: boolean;
  defaultValue?: boolean;
  typeString?: boolean;
  inputSetValidator?: InputSetValidator;
  jsonResponseField?: boolean;
  responseField?: string;
  executionInput?: boolean;
}

export interface ParameterFieldString {
  expressionValue?: string;
  expression?: boolean;
  value?: string;
  defaultValue?: string;
  typeString?: boolean;
  inputSetValidator?: InputSetValidator;
  jsonResponseField?: boolean;
  responseField?: string;
  executionInput?: boolean;
}

export type ReleaseRepoManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export interface ResponseNGEnvironmentConfig {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: NGEnvironmentConfig;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type S3StoreConfig = StoreConfig & {
  connectorRef?: string;
  bucketName?: string;
  region?: string;
  folderPath?: string;
  metadata?: string;
};

export type S3UrlStoreConfig = StoreConfig & {
  connectorRef: string;
  region: string;
  urls: string[];
  metadata?: string;
};

export type SecretNGVariable = NGVariable & {
  name?: string;
  type?: "Secret";
  value: string;
  default?: string;
};

export type ServerlessAwsLambdaManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  configOverridePath?: string;
  metadata?: string;
};

export interface StoreConfig {
  [key: string]: any;
}

export interface StoreConfigWrapper {
  spec: StoreConfig;
  metadata?: string;
  type:
    | "CustomRemote"
    | "Git"
    | "Github"
    | "Bitbucket"
    | "GitLab"
    | "Http"
    | "S3"
    | "Gcs"
    | "Inline"
    | "Artifactory"
    | "S3Url"
    | "InheritFromManifest"
    | "Harness"
    | "OciHelmChart"
    | "AzureRepo";
}

export interface StoreConfigWrapperParameters {
  type?: string;
  spec?: StoreConfig;
}

export type StringNGVariable = NGVariable & {
  name?: string;
  type?: "String";
  value: string;
  default?: string;
};

export type ValuesManifest = ManifestAttributes & {
  store?: StoreConfigWrapper;
  metadata?: string;
};

export interface ResponseServiceOverrideResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceOverrideResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceOverrideResponseDTO {
  accountId?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  environmentRef?: string;
  serviceRef?: string;
  yaml?: string;
}

export interface ServiceOverrideRequestDTO {
  orgIdentifier?: string;
  projectIdentifier?: string;
  environmentIdentifier?: string;
  serviceIdentifier?: string;
  yaml?: string;
}

export interface NGServiceOverrideConfig {
  serviceOverrides?: NGServiceOverrideInfoConfig;
}

export interface NGServiceOverrideInfoConfig {
  environmentRef: string;
  serviceRef: string;
  variables?: NGVariable[];
  manifests?: ManifestConfigWrapper[];
  configFiles?: ConfigFileWrapper[];
  applicationSettings?: ApplicationSettingsConfiguration;
  connectionStrings?: ConnectionStringsConfiguration;
}

export interface ResponseNGServiceOverrideConfig {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: NGServiceOverrideConfig;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageServiceOverrideResponseDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ServiceOverrideResponseDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageServiceOverrideResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageServiceOverrideResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface NGEntityTemplateResponseDTO {
  inputSetTemplateYaml?: string;
}

export interface ResponseNGEntityTemplateResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: NGEntityTemplateResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface InfrastructureResponse {
  infrastructure?: InfrastructureResponseDTO;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface InfrastructureResponseDTO {
  accountId?: string;
  identifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  environmentRef?: string;
  name?: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  type?:
    | "KubernetesDirect"
    | "KubernetesGcp"
    | "KubernetesAzure"
    | "Pdc"
    | "SshWinRmAzure"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "SshWinRmAws"
    | "CustomDeployment"
    | "ECS";
  deploymentType?:
    | "Kubernetes"
    | "NativeHelm"
    | "Ssh"
    | "WinRm"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "CustomDeployment"
    | "ECS";
  yaml?: string;
}

export interface ResponseInfrastructureResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: InfrastructureResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface InfrastructureRequestDTO {
  identifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  environmentRef?: string;
  name?: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  type?:
    | "KubernetesDirect"
    | "KubernetesGcp"
    | "KubernetesAzure"
    | "Pdc"
    | "SshWinRmAzure"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "SshWinRmAws"
    | "CustomDeployment"
    | "ECS";
  yaml?: string;
}

export interface PageInfrastructureResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: InfrastructureResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageInfrastructureResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageInfrastructureResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AllHostsFilter = HostFilterSpec & { [key: string]: any };

export interface AwsInstanceFilter {
  vpcs?: string[];
  tags?: {
    [key: string]: string;
  };
}

export type AzureWebAppInfrastructure = Infrastructure & {
  connectorRef: string;
  subscriptionId: string;
  resourceGroup: string;
};

export type CustomDeploymentInfrastructure = Infrastructure & {
  variables: NGVariable[];
  metadata?: string;
};

export type EcsInfrastructure = Infrastructure & {
  connectorRef: string;
  cluster: string;
  region: string;
  metadata?: string;
};

export type HostAttributesFilter = HostFilterSpec & {
  value?: {
    [key: string]: string;
  };
};

export interface HostFilter {
  type: "All" | "HostNames" | "HostAttributes";
  spec?: HostFilterSpec;
}

export interface HostFilterSpec {
  type?: "All" | "HostNames" | "HostAttributes";
}

export type HostNamesFilter = HostFilterSpec & {
  value?: string[];
};

export interface Infrastructure {
  [key: string]: any;
}

export interface InfrastructureConfig {
  infrastructureDefinition?: InfrastructureDefinitionConfig;
}

export interface InfrastructureDefinitionConfig {
  name: string;
  identifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  environmentRef?: string;
  deploymentType?:
    | "Kubernetes"
    | "NativeHelm"
    | "Ssh"
    | "WinRm"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "CustomDeployment"
    | "ECS";
  description?: string;
  tags?: {
    [key: string]: string;
  };
  allowSimultaneousDeployments?: boolean;
  type:
    | "KubernetesDirect"
    | "KubernetesGcp"
    | "KubernetesAzure"
    | "Pdc"
    | "SshWinRmAzure"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "SshWinRmAws"
    | "CustomDeployment"
    | "ECS";
  spec: Infrastructure;
}

export type K8SDirectInfrastructure = Infrastructure & {
  connectorRef: string;
  namespace: string;
  releaseName: string;
};

export type K8sAzureInfrastructure = Infrastructure & {
  connectorRef: string;
  namespace: string;
  releaseName: string;
  cluster: string;
  subscriptionId: string;
  resourceGroup: string;
  useClusterAdminCredentials?: boolean;
};

export type K8sGcpInfrastructure = Infrastructure & {
  connectorRef: string;
  namespace: string;
  releaseName: string;
  cluster: string;
  metadata?: string;
};

export type PdcInfrastructure = Infrastructure & {
  credentialsRef: string;
  hosts?: string[];
  connectorRef?: string;
  hostFilter: HostFilter;
  delegateSelectors?: string[];
};

export interface ResponseInfrastructureConfig {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: InfrastructureConfig;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type ServerlessAwsLambdaInfrastructure = Infrastructure & {
  connectorRef: string;
  region: string;
  stage: string;
  metadata?: string;
};

export type SshWinRmAwsInfrastructure = Infrastructure & {
  connectorRef: string;
  credentialsRef: string;
  region: string;
  awsInstanceFilter: AwsInstanceFilter;
};

export type SshWinRmAzureInfrastructure = Infrastructure & {
  connectorRef: string;
  subscriptionId: string;
  resourceGroup: string;
  credentialsRef: string;
  tags?: {
    [key: string]: string;
  };
  usePublicDns?: boolean;
};

export interface Invite {
  id: string;
  name: string;
  email: string;
  accountIdentifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  roleBindings?: RoleBinding[];
  userGroups?: string[];
  inviteType:
    | "USER_INITIATED_INVITE"
    | "ADMIN_INITIATED_INVITE"
    | "SCIM_INITIATED_INVITE";
  approved?: boolean;
}

export interface ResponseInvite {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: Invite;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RoleBinding {
  roleIdentifier: string;
  roleName: string;
  resourceGroupIdentifier?: string;
  resourceGroupName?: string;
  managedRole: boolean;
}

export interface PageInvite {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: Invite[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageInvite {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageInvite;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ACLAggregateFilter {
  resourceGroupIdentifiers?: string[];
  roleIdentifiers?: string[];
}

export interface ResponseOptionalInvite {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: Invite;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GcpResponseDTO {
  clusterNames?: string[];
}

export interface ResponseGcpResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: GcpResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ApiKeyDTO {
  identifier: string;
  name: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  apiKeyType: "USER" | "SERVICE_ACCOUNT";
  parentIdentifier: string;
  defaultTimeToExpireToken?: number;
  accountIdentifier: string;
  projectIdentifier?: string;
  orgIdentifier?: string;
}

export interface ResponseApiKeyDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ApiKeyDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListApiKeyDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ApiKeyDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ApiKeyAggregateDTO {
  apiKey: ApiKeyDTO;
  createdAt: number;
  lastModifiedAt: number;
  tokensCount?: number;
}

export interface PageApiKeyAggregateDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ApiKeyAggregateDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageApiKeyAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageApiKeyAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseApiKeyAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ApiKeyAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RestResponseString {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: string;
  responseMessages?: ResponseMessage[];
}

export interface HostValidationDTO {
  host?: string;
  status?: "SUCCESS" | "FAILED";
  error?: ErrorDetail;
}

export interface ResponseListHostValidationDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: HostValidationDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface HostValidationParams {
  hosts: string[];
  tags?: string[];
}

export interface AccountResourcesDTO {
  connectorsCount?: number;
  secretsCount?: number;
  delegatesCount?: number;
  templatesCount?: number;
}

export interface ResponseAccountResourcesDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AccountResourcesDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface Organization {
  identifier: string;
  name: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
}

export interface Project {
  orgIdentifier?: string;
  identifier: string;
  name: string;
  color?: string;
  modules?: (
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS"
  )[];
  description?: string;
  tags?: {
    [key: string]: string;
  };
}

export interface ProjectAggregateDTO {
  projectResponse: ProjectResponse;
  organization?: Organization;
  harnessManagedOrg?: boolean;
  admins?: UserMetadataDTO[];
  collaborators?: UserMetadataDTO[];
}

export interface ProjectResponse {
  project: Project;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface ResponseProjectAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ProjectAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface UserMetadataDTO {
  name?: string;
  email: string;
  uuid: string;
  locked?: boolean;
  disabled?: boolean;
  externallyManaged?: boolean;
}

export interface OrganizationAggregateDTO {
  organizationResponse: OrganizationResponse;
  projectsCount?: number;
  connectorsCount?: number;
  secretsCount?: number;
  delegatesCount?: number;
  templatesCount?: number;
  admins?: UserMetadataDTO[];
  collaborators?: UserMetadataDTO[];
}

export interface OrganizationResponse {
  organization: Organization;
  createdAt?: number;
  lastModifiedAt?: number;
  harnessManaged?: boolean;
}

export interface ResponseOrganizationAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: OrganizationAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type EmailConfigDTO = NotificationSettingConfigDTO & {
  groupEmail: string;
};

export type MicrosoftTeamsConfigDTO = NotificationSettingConfigDTO & {
  microsoftTeamsWebhookUrl: string;
};

export interface NotificationSettingConfigDTO {
  type?: "EMAIL" | "SLACK" | "PAGERDUTY" | "MSTEAMS";
}

export interface PageUserGroupAggregateDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: UserGroupAggregateDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export type PagerDutyConfigDTO = NotificationSettingConfigDTO & {
  pagerDutyKey: string;
};

export interface ResponsePageUserGroupAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageUserGroupAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface RoleAssignmentMetadataDTO {
  identifier: string;
  roleIdentifier: string;
  roleName: string;
  resourceGroupIdentifier: string;
  resourceGroupName: string;
  managedRole: boolean;
  managedRoleAssignment: boolean;
}

export type SlackConfigDTO = NotificationSettingConfigDTO & {
  slackWebhookUrl: string;
};

export interface UserGroupAggregateDTO {
  userGroupDTO: UserGroupDTO;
  users?: UserMetadataDTO[];
  roleAssignmentsMetadataDTO?: RoleAssignmentMetadataDTO[];
  lastModifiedAt?: number;
}

export interface UserGroupDTO {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier: string;
  name: string;
  users?: string[];
  notificationConfigs?: NotificationSettingConfigDTO[];
  linkedSsoId?: string;
  linkedSsoDisplayName?: string;
  ssoGroupId?: string;
  ssoGroupName?: string;
  linkedSsoType?: string;
  externallyManaged?: boolean;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  harnessManaged?: boolean;
  ssoLinked?: boolean;
}

export interface PageProjectAggregateDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ProjectAggregateDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageProjectAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageProjectAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ScopeSelector {
  filter: "EXCLUDING_CHILD_SCOPES" | "INCLUDING_CHILD_SCOPES";
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
}

export interface UserGroupAggregateFilter {
  scopeFilter?: ScopeSelector[];
}

export interface PageOrganizationAggregateDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: OrganizationAggregateDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageOrganizationAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageOrganizationAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseUserGroupAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: UserGroupAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageHostDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: HostDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageHostDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageHostDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface HostFilterDTO {
  type?: "All" | "HostNames" | "HostAttributes";
  filter?: string;
}

export type AzureKeyVaultMetadataSpecDTO = SecretManagerMetadataSpecDTO & {
  vaultNames?: string[];
};

export interface ResponseSecretManagerMetadataDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SecretManagerMetadataDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SecretManagerMetadataDTO {
  encryptionType?:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH"
    | "CUSTOM_NG";
  spec?: SecretManagerMetadataSpecDTO;
}

export interface SecretManagerMetadataSpecDTO {
  [key: string]: any;
}

export type VaultMetadataSpecDTO = SecretManagerMetadataSpecDTO & {
  secretEngines?: VaultSecretEngineDTO[];
};

export interface VaultSecretEngineDTO {
  name?: string;
  description?: string;
  type?: string;
  version?: number;
}

export type AzureKeyVaultMetadataRequestSpecDTO = SecretManagerMetadataRequestSpecDTO & {
  clientId: string;
  tenantId: string;
  secretKey: string;
  subscription: string;
  azureEnvironmentType?: "AZURE" | "AZURE_US_GOVERNMENT";
  delegateSelectors?: string[];
};

export interface SecretManagerMetadataRequestDTO {
  encryptionType:
    | "LOCAL"
    | "KMS"
    | "GCP_KMS"
    | "AWS_SECRETS_MANAGER"
    | "AZURE_VAULT"
    | "VAULT"
    | "GCP_SECRETS_MANAGER"
    | "CUSTOM"
    | "VAULT_SSH"
    | "CUSTOM_NG";
  orgIdentifier?: string;
  projectIdentifier?: string;
  identifier: string;
  spec: SecretManagerMetadataRequestSpecDTO;
}

export interface SecretManagerMetadataRequestSpecDTO {
  [key: string]: any;
}

export interface SecretRefData {
  identifier?: string;
  scope?: "account" | "org" | "project" | "unknown";
  decryptedValue?: string[];
  null?: boolean;
}

export type VaultAgentCredentialDTO = VaultCredentialDTO & {
  sinkPath?: string;
};

export type VaultAppRoleCredentialDTO = VaultCredentialDTO & {
  appRoleId?: string;
  secretId?: string;
};

export type VaultAuthTokenCredentialDTO = VaultCredentialDTO & {
  authToken?: string;
};

export type VaultAwsIamRoleCredentialDTO = VaultCredentialDTO & {
  awsRegion?: string;
  vaultAwsIamRole?: string;
  xvaultAwsIamServerId?: SecretRefData;
};

export interface VaultCredentialDTO {
  [key: string]: any;
}

export type VaultK8sCredentialDTO = VaultCredentialDTO & {
  vaultK8sAuthRole?: string;
  serviceAccountTokenPath?: string;
  k8sAuthEndpoint?: string;
};

export type VaultMetadataRequestSpecDTO = SecretManagerMetadataRequestSpecDTO & {
  url: string;
  accessType: "APP_ROLE" | "TOKEN" | "VAULT_AGENT" | "AWS_IAM" | "K8s_AUTH";
  spec?: VaultCredentialDTO;
  delegateSelectors?: string[];
  namespace?: string;
};

export interface BaseSSHSpecDTO {
  [key: string]: any;
}

export interface BaseWinRmSpecDTO {
  [key: string]: any;
}

export type KerberosConfigDTO = BaseSSHSpecDTO & {
  principal: string;
  realm: string;
  tgtGenerationMethod?: "KeyTabFilePath" | "Password";
  spec?: TGTGenerationSpecDTO;
};

export type KerberosWinRmConfigDTO = BaseWinRmSpecDTO & {
  principal: string;
  realm: string;
  tgtGenerationMethod?: "KeyTabFilePath" | "Password";
  spec?: TGTGenerationSpecDTO;
  useSSL?: boolean;
  skipCertChecks?: boolean;
  useNoProfile?: boolean;
};

export type NTLMConfigDTO = BaseWinRmSpecDTO & {
  domain: string;
  username: string;
  useSSL?: boolean;
  skipCertChecks?: boolean;
  useNoProfile?: boolean;
  password: string;
};

export interface PageSecretResponseWrapper {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: SecretResponseWrapper[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageSecretResponseWrapper {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageSecretResponseWrapper;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SSHAuthDTO {
  spec: BaseSSHSpecDTO;
  type: "SSH" | "Kerberos";
}

export type SSHConfigDTO = BaseSSHSpecDTO & {
  credentialType: "Password" | "KeyPath" | "KeyReference";
  spec: SSHCredentialSpecDTO;
};

export interface SSHCredentialSpecDTO {
  [key: string]: any;
}

export type SSHKeyPathCredentialDTO = SSHCredentialSpecDTO & {
  userName: string;
  keyPath: string;
  encryptedPassphrase?: string;
};

export type SSHKeyReferenceCredentialDTO = SSHCredentialSpecDTO & {
  userName: string;
  key?: string;
  encryptedPassphrase?: string;
};

export type SSHKeySpecDTO = SecretSpecDTO & {
  port?: number;
  auth: SSHAuthDTO;
};

export type SSHPasswordCredentialDTO = SSHCredentialSpecDTO & {
  userName: string;
  password: string;
};

export interface SecretDTOV2 {
  type: "SecretFile" | "SecretText" | "SSHKey" | "WinRmCredentials";
  name: string;
  identifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  tags?: {
    [key: string]: string;
  };
  description?: string;
  spec: SecretSpecDTO;
}

export type SecretFileSpecDTO = SecretSpecDTO & {
  secretManagerIdentifier: string;
};

export interface SecretResponseWrapper {
  secret: SecretDTOV2;
  createdAt?: number;
  updatedAt?: number;
  draft?: boolean;
  governanceMetadata?: GovernanceMetadata;
}

export interface SecretSpecDTO {
  errorMessageForInvalidYaml?: string;
}

export type SecretTextSpecDTO = SecretSpecDTO & {
  secretManagerIdentifier: string;
  valueType: "Inline" | "Reference" | "CustomSecretManagerValues";
  value?: string;
};

export interface TGTGenerationSpecDTO {
  [key: string]: any;
}

export type TGTKeyTabFilePathSpecDTO = TGTGenerationSpecDTO & {
  keyPath?: string;
};

export type TGTPasswordSpecDTO = TGTGenerationSpecDTO & {
  password?: string;
};

export interface WinRmAuthDTO {
  spec: BaseWinRmSpecDTO;
  type: "NTLM" | "Kerberos";
}

export type WinRmCredentialsSpecDTO = SecretSpecDTO & {
  port?: number;
  auth: WinRmAuthDTO;
};

export interface SecretResourceFilterDTO {
  identifiers?: string[];
  searchTerm?: string;
  secretTypes?: ("SecretFile" | "SecretText" | "SSHKey" | "WinRmCredentials")[];
  sourceCategory?:
    | "CLOUD_PROVIDER"
    | "SECRET_MANAGER"
    | "CLOUD_COST"
    | "ARTIFACTORY"
    | "CODE_REPO"
    | "MONITORING"
    | "TICKETING";
  includeSecretsFromEverySubScope?: boolean;
}

export interface ResponseSecretResponseWrapper {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SecretResponseWrapper;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SecretRequestWrapper {
  secret: SecretDTOV2;
}

export interface ResponseSecretValidationResultDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SecretValidationResultDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SecretValidationResultDTO {
  success?: boolean;
  message?: string;
}

export type SSHKeyValidationMetadata = SecretValidationMetaData & {
  host: string;
};

export interface SecretValidationMetaData {
  type: "SecretFile" | "SecretText" | "SSHKey" | "WinRmCredentials";
}

export type WinRmCredentialsValidationMetadata = SecretValidationMetaData & {
  host: string;
};

export interface ResponseOrganizationResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: OrganizationResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface OrganizationRequest {
  organization: Organization;
}

export interface PageOrganizationResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: OrganizationResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageOrganizationResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageOrganizationResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageProjectResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ProjectResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageProjectResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageProjectResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseProjectResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ProjectResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ProjectRequest {
  project: Project;
}

export interface TokenDTO {
  identifier: string;
  name: string;
  validFrom?: number;
  validTo?: number;
  scheduledExpireTime?: number;
  valid?: boolean;
  accountIdentifier: string;
  projectIdentifier?: string;
  orgIdentifier?: string;
  apiKeyIdentifier: string;
  parentIdentifier: string;
  apiKeyType: "USER" | "SERVICE_ACCOUNT";
  description?: string;
  tags?: {
    [key: string]: string;
  };
  email?: string;
  username?: string;
  encodedPassword?: string;
}

export interface ResponseTokenDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: TokenDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageTokenAggregateDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: TokenAggregateDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageTokenAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageTokenAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface TokenAggregateDTO {
  token: TokenDTO;
  expiryAt: number;
  createdAt: number;
  lastModifiedAt: number;
}

export interface ResponseListScopeName {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ScopeName[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ScopeName {
  accountIdentifier?: string;
  orgName?: string;
  orgIdentifier?: string;
  projectName?: string;
  projectIdentifier?: string;
}

export interface AccessControlCheckError {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  code?:
    | "DEFAULT_ERROR_CODE"
    | "INVALID_ARGUMENT"
    | "INVALID_EMAIL"
    | "DOMAIN_NOT_ALLOWED_TO_REGISTER"
    | "COMMNITY_EDITION_NOT_FOUND"
    | "DEPLOY_MODE_IS_NOT_ON_PREM"
    | "USER_ALREADY_REGISTERED"
    | "USER_INVITATION_DOES_NOT_EXIST"
    | "USER_DOES_NOT_EXIST"
    | "USER_INVITE_OPERATION_FAILED"
    | "USER_DISABLED"
    | "ACCOUNT_DOES_NOT_EXIST"
    | "INACTIVE_ACCOUNT"
    | "ACCOUNT_MIGRATED"
    | "USER_DOMAIN_NOT_ALLOWED"
    | "MAX_FAILED_ATTEMPT_COUNT_EXCEEDED"
    | "RESOURCE_NOT_FOUND"
    | "INVALID_FORMAT"
    | "ROLE_DOES_NOT_EXIST"
    | "EMAIL_NOT_VERIFIED"
    | "EMAIL_VERIFICATION_TOKEN_NOT_FOUND"
    | "INVALID_TOKEN"
    | "REVOKED_TOKEN"
    | "INVALID_CAPTCHA_TOKEN"
    | "NOT_ACCOUNT_MGR_NOR_HAS_ALL_APP_ACCESS"
    | "EXPIRED_TOKEN"
    | "INVALID_AGENT_MTLS_AUTHORITY"
    | "TOKEN_ALREADY_REFRESHED_ONCE"
    | "ACCESS_DENIED"
    | "NG_ACCESS_DENIED"
    | "INVALID_CREDENTIAL"
    | "INVALID_CREDENTIALS_THIRD_PARTY"
    | "INVALID_KEY"
    | "INVALID_CONNECTOR_TYPE"
    | "INVALID_KEYPATH"
    | "INVALID_VARIABLE"
    | "UNKNOWN_HOST"
    | "UNREACHABLE_HOST"
    | "INVALID_PORT"
    | "SSH_SESSION_TIMEOUT"
    | "SOCKET_CONNECTION_ERROR"
    | "CONNECTION_ERROR"
    | "SOCKET_CONNECTION_TIMEOUT"
    | "WINRM_COMMAND_EXECUTION_TIMEOUT"
    | "CONNECTION_TIMEOUT"
    | "SSH_CONNECTION_ERROR"
    | "USER_GROUP_ERROR"
    | "INVALID_EXECUTION_ID"
    | "ERROR_IN_GETTING_CHANNEL_STREAMS"
    | "UNEXPECTED"
    | "UNKNOWN_ERROR"
    | "UNKNOWN_EXECUTOR_TYPE_ERROR"
    | "DUPLICATE_STATE_NAMES"
    | "TRANSITION_NOT_LINKED"
    | "TRANSITION_TO_INCORRECT_STATE"
    | "TRANSITION_TYPE_NULL"
    | "STATES_WITH_DUP_TRANSITIONS"
    | "BARRIERS_NOT_RUNNING_CONCURRENTLY"
    | "NON_FORK_STATES"
    | "NON_REPEAT_STATES"
    | "INITIAL_STATE_NOT_DEFINED"
    | "FILE_INTEGRITY_CHECK_FAILED"
    | "INVALID_URL"
    | "FILE_DOWNLOAD_FAILED"
    | "PLATFORM_SOFTWARE_DELETE_ERROR"
    | "INVALID_CSV_FILE"
    | "INVALID_REQUEST"
    | "SCHEMA_VALIDATION_FAILED"
    | "FILTER_CREATION_ERROR"
    | "INVALID_YAML_ERROR"
    | "PLAN_CREATION_ERROR"
    | "INVALID_INFRA_STATE"
    | "PIPELINE_ALREADY_TRIGGERED"
    | "NON_EXISTING_PIPELINE"
    | "DUPLICATE_COMMAND_NAMES"
    | "INVALID_PIPELINE"
    | "COMMAND_DOES_NOT_EXIST"
    | "DUPLICATE_ARTIFACTSTREAM_NAMES"
    | "DUPLICATE_HOST_NAMES"
    | "STATE_NOT_FOR_TYPE"
    | "STATE_MACHINE_ISSUE"
    | "STATE_DISCONTINUE_FAILED"
    | "STATE_PAUSE_FAILED"
    | "PAUSE_ALL_ALREADY"
    | "RESUME_ALL_ALREADY"
    | "ROLLBACK_ALREADY"
    | "ABORT_ALL_ALREADY"
    | "EXPIRE_ALL_ALREADY"
    | "RETRY_FAILED"
    | "UNKNOWN_ARTIFACT_TYPE"
    | "UNKNOWN_STAGE_ELEMENT_WRAPPER_TYPE"
    | "INIT_TIMEOUT"
    | "LICENSE_EXPIRED"
    | "NOT_LICENSED"
    | "REQUEST_TIMEOUT"
    | "WORKFLOW_ALREADY_TRIGGERED"
    | "JENKINS_ERROR"
    | "INVALID_ARTIFACT_SOURCE"
    | "INVALID_ARTIFACT_SERVER"
    | "INVALID_CLOUD_PROVIDER"
    | "UPDATE_NOT_ALLOWED"
    | "DELETE_NOT_ALLOWED"
    | "APPDYNAMICS_CONFIGURATION_ERROR"
    | "APM_CONFIGURATION_ERROR"
    | "SPLUNK_CONFIGURATION_ERROR"
    | "ELK_CONFIGURATION_ERROR"
    | "LOGZ_CONFIGURATION_ERROR"
    | "SUMO_CONFIGURATION_ERROR"
    | "INSTANA_CONFIGURATION_ERROR"
    | "APPDYNAMICS_ERROR"
    | "STACKDRIVER_ERROR"
    | "STACKDRIVER_CONFIGURATION_ERROR"
    | "NEWRELIC_CONFIGURATION_ERROR"
    | "NEWRELIC_ERROR"
    | "DYNA_TRACE_CONFIGURATION_ERROR"
    | "DYNA_TRACE_ERROR"
    | "CLOUDWATCH_ERROR"
    | "CLOUDWATCH_CONFIGURATION_ERROR"
    | "PROMETHEUS_CONFIGURATION_ERROR"
    | "DATA_DOG_CONFIGURATION_ERROR"
    | "SERVICE_GUARD_CONFIGURATION_ERROR"
    | "ENCRYPTION_NOT_CONFIGURED"
    | "UNAVAILABLE_DELEGATES"
    | "WORKFLOW_EXECUTION_IN_PROGRESS"
    | "PIPELINE_EXECUTION_IN_PROGRESS"
    | "AWS_ACCESS_DENIED"
    | "AWS_CLUSTER_NOT_FOUND"
    | "AWS_SERVICE_NOT_FOUND"
    | "IMAGE_NOT_FOUND"
    | "ILLEGAL_ARGUMENT"
    | "IMAGE_TAG_NOT_FOUND"
    | "DELEGATE_NOT_AVAILABLE"
    | "INVALID_YAML_PAYLOAD"
    | "AUTHENTICATION_ERROR"
    | "AUTHORIZATION_ERROR"
    | "UNRECOGNIZED_YAML_FIELDS"
    | "COULD_NOT_MAP_BEFORE_YAML"
    | "MISSING_BEFORE_YAML"
    | "MISSING_YAML"
    | "NON_EMPTY_DELETIONS"
    | "GENERAL_YAML_ERROR"
    | "GENERAL_YAML_INFO"
    | "YAML_GIT_SYNC_ERROR"
    | "GIT_CONNECTION_ERROR"
    | "GIT_ERROR"
    | "ARTIFACT_SERVER_ERROR"
    | "ENCRYPT_DECRYPT_ERROR"
    | "SECRET_MANAGEMENT_ERROR"
    | "SECRET_NOT_FOUND"
    | "KMS_OPERATION_ERROR"
    | "GCP_KMS_OPERATION_ERROR"
    | "VAULT_OPERATION_ERROR"
    | "AWS_SECRETS_MANAGER_OPERATION_ERROR"
    | "AZURE_KEY_VAULT_OPERATION_ERROR"
    | "UNSUPPORTED_OPERATION_EXCEPTION"
    | "FEATURE_UNAVAILABLE"
    | "GENERAL_ERROR"
    | "BASELINE_CONFIGURATION_ERROR"
    | "SAML_IDP_CONFIGURATION_NOT_AVAILABLE"
    | "INVALID_AUTHENTICATION_MECHANISM"
    | "INVALID_SAML_CONFIGURATION"
    | "INVALID_OAUTH_CONFIGURATION"
    | "INVALID_LDAP_CONFIGURATION"
    | "USER_GROUP_SYNC_FAILURE"
    | "USER_GROUP_ALREADY_EXIST"
    | "INVALID_TWO_FACTOR_AUTHENTICATION_CONFIGURATION"
    | "EXPLANATION"
    | "HINT"
    | "NOT_WHITELISTED_IP"
    | "INVALID_TOTP_TOKEN"
    | "EMAIL_FAILED"
    | "SSL_HANDSHAKE_FAILED"
    | "NO_APPS_ASSIGNED"
    | "INVALID_INFRA_CONFIGURATION"
    | "TEMPLATES_LINKED"
    | "USER_HAS_NO_PERMISSIONS"
    | "USER_NOT_AUTHORIZED"
    | "USER_ALREADY_PRESENT"
    | "EMAIL_ERROR"
    | "INVALID_USAGE_RESTRICTION"
    | "USAGE_RESTRICTION_ERROR"
    | "STATE_EXECUTION_INSTANCE_NOT_FOUND"
    | "DELEGATE_TASK_RETRY"
    | "KUBERNETES_API_TASK_EXCEPTION"
    | "KUBERNETES_TASK_EXCEPTION"
    | "KUBERNETES_YAML_ERROR"
    | "SAVE_FILE_INTO_GCP_STORAGE_FAILED"
    | "READ_FILE_FROM_GCP_STORAGE_FAILED"
    | "FILE_NOT_FOUND_ERROR"
    | "USAGE_LIMITS_EXCEEDED"
    | "EVENT_PUBLISH_FAILED"
    | "CUSTOM_APPROVAL_ERROR"
    | "JIRA_ERROR"
    | "EXPRESSION_EVALUATION_FAILED"
    | "KUBERNETES_VALUES_ERROR"
    | "KUBERNETES_CLUSTER_ERROR"
    | "INCORRECT_SIGN_IN_MECHANISM"
    | "OAUTH_LOGIN_FAILED"
    | "INVALID_TERRAFORM_TARGETS_REQUEST"
    | "TERRAFORM_EXECUTION_ERROR"
    | "FILE_READ_FAILED"
    | "FILE_SIZE_EXCEEDS_LIMIT"
    | "CLUSTER_NOT_FOUND"
    | "MARKETPLACE_TOKEN_NOT_FOUND"
    | "INVALID_MARKETPLACE_TOKEN"
    | "INVALID_TICKETING_SERVER"
    | "SERVICENOW_ERROR"
    | "PASSWORD_EXPIRED"
    | "USER_LOCKED"
    | "PASSWORD_STRENGTH_CHECK_FAILED"
    | "ACCOUNT_DISABLED"
    | "INVALID_ACCOUNT_PERMISSION"
    | "PAGERDUTY_ERROR"
    | "HEALTH_ERROR"
    | "SAML_TEST_SUCCESS_MECHANISM_NOT_ENABLED"
    | "DOMAIN_WHITELIST_FILTER_CHECK_FAILED"
    | "INVALID_DASHBOARD_UPDATE_REQUEST"
    | "DUPLICATE_FIELD"
    | "INVALID_AZURE_VAULT_CONFIGURATION"
    | "USER_NOT_AUTHORIZED_DUE_TO_USAGE_RESTRICTIONS"
    | "INVALID_ROLLBACK"
    | "DATA_COLLECTION_ERROR"
    | "SUMO_DATA_COLLECTION_ERROR"
    | "DEPLOYMENT_GOVERNANCE_ERROR"
    | "BATCH_PROCESSING_ERROR"
    | "GRAPHQL_ERROR"
    | "FILE_CREATE_ERROR"
    | "ILLEGAL_STATE"
    | "GIT_DIFF_COMMIT_NOT_IN_ORDER"
    | "FAILED_TO_ACQUIRE_PERSISTENT_LOCK"
    | "FAILED_TO_ACQUIRE_NON_PERSISTENT_LOCK"
    | "POD_NOT_FOUND_ERROR"
    | "COMMAND_EXECUTION_ERROR"
    | "REGISTRY_EXCEPTION"
    | "ENGINE_INTERRUPT_PROCESSING_EXCEPTION"
    | "ENGINE_IO_EXCEPTION"
    | "ENGINE_OUTCOME_EXCEPTION"
    | "ENGINE_SWEEPING_OUTPUT_EXCEPTION"
    | "CACHE_NOT_FOUND_EXCEPTION"
    | "ENGINE_ENTITY_UPDATE_EXCEPTION"
    | "SHELL_EXECUTION_EXCEPTION"
    | "TEMPLATE_NOT_FOUND"
    | "AZURE_SERVICE_EXCEPTION"
    | "AZURE_CLIENT_EXCEPTION"
    | "GIT_UNSEEN_REMOTE_HEAD_COMMIT"
    | "TIMEOUT_ENGINE_EXCEPTION"
    | "NO_AVAILABLE_DELEGATES"
    | "NO_GLOBAL_DELEGATE_ACCOUNT"
    | "NO_INSTALLED_DELEGATES"
    | "DUPLICATE_DELEGATE_EXCEPTION"
    | "GCP_MARKETPLACE_EXCEPTION"
    | "MISSING_DEFAULT_GOOGLE_CREDENTIALS"
    | "INCORRECT_DEFAULT_GOOGLE_CREDENTIALS"
    | "OPTIMISTIC_LOCKING_EXCEPTION"
    | "NG_PIPELINE_EXECUTION_EXCEPTION"
    | "NG_PIPELINE_CREATE_EXCEPTION"
    | "RESOURCE_NOT_FOUND_EXCEPTION"
    | "PMS_INITIALIZE_SDK_EXCEPTION"
    | "UNEXPECTED_SNIPPET_EXCEPTION"
    | "UNEXPECTED_SCHEMA_EXCEPTION"
    | "CONNECTOR_VALIDATION_EXCEPTION"
    | "TIMESCALE_NOT_AVAILABLE"
    | "MIGRATION_EXCEPTION"
    | "REQUEST_PROCESSING_INTERRUPTED"
    | "SECRET_MANAGER_ID_NOT_FOUND"
    | "GCP_SECRET_MANAGER_OPERATION_ERROR"
    | "GCP_SECRET_OPERATION_ERROR"
    | "GIT_OPERATION_ERROR"
    | "TASK_FAILURE_ERROR"
    | "INSTANCE_STATS_PROCESS_ERROR"
    | "INSTANCE_STATS_MIGRATION_ERROR"
    | "DEPLOYMENT_MIGRATION_ERROR"
    | "CG_LICENSE_USAGE_ERROR"
    | "INSTANCE_STATS_AGGREGATION_ERROR"
    | "UNRESOLVED_EXPRESSIONS_ERROR"
    | "KRYO_HANDLER_NOT_FOUND_ERROR"
    | "DELEGATE_ERROR_HANDLER_EXCEPTION"
    | "UNEXPECTED_TYPE_ERROR"
    | "EXCEPTION_HANDLER_NOT_FOUND"
    | "CONNECTOR_NOT_FOUND_EXCEPTION"
    | "GCP_SERVER_ERROR"
    | "HTTP_RESPONSE_EXCEPTION"
    | "SCM_NOT_FOUND_ERROR"
    | "SCM_CONFLICT_ERROR"
    | "SCM_CONFLICT_ERROR_V2"
    | "SCM_UNPROCESSABLE_ENTITY"
    | "PROCESS_EXECUTION_EXCEPTION"
    | "SCM_UNAUTHORIZED"
    | "SCM_BAD_REQUEST"
    | "SCM_INTERNAL_SERVER_ERROR"
    | "DATA"
    | "CONTEXT"
    | "PR_CREATION_ERROR"
    | "URL_NOT_REACHABLE"
    | "URL_NOT_PROVIDED"
    | "ENGINE_EXPRESSION_EVALUATION_ERROR"
    | "ENGINE_FUNCTOR_ERROR"
    | "JIRA_CLIENT_ERROR"
    | "SCM_NOT_MODIFIED"
    | "APPROVAL_STEP_NG_ERROR"
    | "BUCKET_SERVER_ERROR"
    | "GIT_SYNC_ERROR"
    | "TEMPLATE_EXCEPTION"
    | "ENTITY_REFERENCE_EXCEPTION"
    | "INVALID_INPUT_SET"
    | "INVALID_OVERLAY_INPUT_SET"
    | "RESOURCE_ALREADY_EXISTS"
    | "INVALID_JSON_PAYLOAD"
    | "POLICY_EVALUATION_FAILURE"
    | "POLICY_SET_ERROR"
    | "INVALID_ARTIFACTORY_REGISTRY_REQUEST"
    | "INVALID_NEXUS_REGISTRY_REQUEST"
    | "ENTITY_NOT_FOUND"
    | "INVALID_AZURE_CONTAINER_REGISTRY_REQUEST"
    | "AZURE_AUTHENTICATION_ERROR"
    | "AZURE_CONFIG_ERROR"
    | "DATA_PROCESSING_ERROR"
    | "INVALID_AZURE_AKS_REQUEST"
    | "AWS_IAM_ERROR"
    | "AWS_CF_ERROR"
    | "AWS_INSTANCE_ERROR"
    | "AWS_VPC_ERROR"
    | "AWS_TAG_ERROR"
    | "AWS_ASG_ERROR"
    | "AWS_LOAD_BALANCER_ERROR"
    | "SCM_INTERNAL_SERVER_ERROR_V2"
    | "SCM_UNAUTHORIZED_ERROR_V2"
    | "TOO_MANY_REQUESTS"
    | "INVALID_IDENTIFIER_REF"
    | "SPOTINST_NULL_ERROR"
    | "SCM_UNEXPECTED_ERROR"
    | "DUPLICATE_FILE_IMPORT"
    | "AZURE_APP_SERVICES_TASK_EXCEPTION"
    | "MEDIA_NOT_SUPPORTED"
    | "AWS_ECS_ERROR"
    | "AWS_APPLICATION_AUTO_SCALING"
    | "AWS_ECS_SERVICE_NOT_ACTIVE"
    | "AWS_ECS_CLIENT_ERROR"
    | "AWS_STS_ERROR";
  message?: string;
  correlationId?: string;
  detailedMessage?: string;
  responseMessages?: ResponseMessage[];
  metadata?: ErrorMetadataDTO;
  failedPermissionChecks?: PermissionCheck[];
}

export interface PermissionCheck {
  resourceScope?: ResourceScope;
  resourceType?: string;
  resourceAttributes?: {
    [key: string]: string;
  };
  resourceIdentifier?: string;
  permission?: string;
}

export interface ResourceScope {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
}

export interface PageUserMetadataDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: UserMetadataDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageUserMetadataDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageUserMetadataDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface UserFilter {
  searchTerm?: string;
  identifiers?: string[];
  parentFilter?:
    | "NO_PARENT_SCOPES"
    | "INCLUDE_PARENT_SCOPES"
    | "STRICTLY_PARENT_SCOPES";
}

export interface ResponseUserGroupDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: UserGroupDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type EmailConfig = NotificationSettingConfig & {
  groupEmail?: string;
};

export type MicrosoftTeamsConfig = NotificationSettingConfig & {
  microsoftTeamsWebhookUrl?: string;
};

export interface NotificationSettingConfig {
  type: "EMAIL" | "SLACK" | "PAGERDUTY" | "MSTEAMS";
}

export type PagerDutyConfig = NotificationSettingConfig & {
  pagerDutyKey?: string;
};

export interface RestResponseUserGroup {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserGroup;
  responseMessages?: ResponseMessage[];
}

export type SlackConfig = NotificationSettingConfig & {
  slackWebhookUrl?: string;
};

export interface SamlLinkGroupRequest {
  samlGroupName?: string;
}

export interface LdapLinkGroupRequest {
  ldapGroupDN?: string;
  ldapGroupName?: string;
}

export interface ResponseListUserGroupDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: UserGroupDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface UserGroupFilterDTO {
  databaseIdFilter?: string[];
  identifierFilter?: string[];
  userIdentifierFilter?: string[];
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  searchTerm?: string;
  filterType?: "INCLUDE_INHERITED_GROUPS" | "EXCLUDE_INHERITED_GROUPS";
}

export interface PageUserGroupDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: UserGroupDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageUserGroupDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageUserGroupDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureWebAppNamesDTO {
  webAppNames?: string[];
}

export interface ResponseAzureWebAppNamesDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureWebAppNamesDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureDeploymentSlotDTO {
  type: string;
  name: string;
}

export interface AzureDeploymentSlotsDTO {
  deploymentSlots?: AzureDeploymentSlotDTO[];
}

export interface ResponseAzureDeploymentSlotsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureDeploymentSlotsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureResourceGroupDTO {
  resourceGroup: string;
}

export interface AzureResourceGroupsDTO {
  resourceGroups?: AzureResourceGroupDTO[];
}

export interface ResponseAzureResourceGroupsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureResourceGroupsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureClusterDTO {
  cluster: string;
}

export interface AzureClustersDTO {
  clusters?: AzureClusterDTO[];
}

export interface ResponseAzureClustersDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureClustersDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureTagDTO {
  tag: string;
}

export interface AzureTagsDTO {
  tags?: AzureTagDTO[];
}

export interface ResponseAzureTagsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureTagsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureManagementGroupsDTO {
  managementGroups?: ManagementGroupData[];
}

export interface ManagementGroupData {
  id?: string;
  name?: string;
  displayName?: string;
}

export interface ResponseAzureManagementGroupsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureManagementGroupsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AzureLocationsDTO {
  locations?: string[];
}

export interface ResponseAzureLocationsDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AzureLocationsDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseServiceResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceResponseDTO {
  accountId?: string;
  identifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  name?: string;
  description?: string;
  deleted?: boolean;
  tags?: {
    [key: string]: string;
  };
  yaml?: string;
}

export interface ServiceRequestDTO {
  identifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  name?: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  yaml?: string;
}

export interface ResponseSetHelmCommandFlagType {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: (
    | "Fetch"
    | "Template"
    | "Pull"
    | "Install"
    | "Upgrade"
    | "Rollback"
    | "History"
    | "Delete"
    | "Uninstall"
    | "List"
  )[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageServiceResponseDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ServiceResponseDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageServiceResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageServiceResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseServiceResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceResponse {
  service?: ServiceResponseDTO;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface PageServiceResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ServiceResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageServiceResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageServiceResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListServiceResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceResponse[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AcrArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  subscriptionId: string;
  registry: string;
  repository: string;
  tag?: string;
  tagRegex?: string;
};

export type AmazonS3ArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  region?: string;
  bucketName: string;
  filePathRegex?: string;
  filePath?: string;
};

export interface ArtifactConfig {
  [key: string]: any;
}

export interface ArtifactListConfig {
  primary?: PrimaryArtifact;
  sidecars?: SidecarArtifactWrapper[];
}

export interface ArtifactOverrideSetWrapper {
  overrideSet?: ArtifactOverrideSets;
}

export interface ArtifactOverrideSets {
  identifier?: string;
  artifacts?: ArtifactListConfig;
}

export interface ArtifactSource {
  identifier: string;
  type:
    | "DockerRegistry"
    | "Gcr"
    | "Ecr"
    | "Nexus3Registry"
    | "ArtifactoryRegistry"
    | "CustomArtifact"
    | "Acr"
    | "Jenkins"
    | "AmazonS3"
    | "GoogleArtifactRegistry"
    | "GithubPackageRegistry"
    | "AzureArtifactsRegistry";
  spec: ArtifactConfig;
  metadata?: string;
}

export type ArtifactoryRegistryArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  repository: string;
  artifactPath?: string;
  artifactPathFilter?: string;
  artifactDirectory?: string;
  repositoryFormat: "docker" | "generic";
  repositoryUrl?: string;
  tag?: string;
  tagRegex?: string;
};

export type AzureArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  packageType: "maven" | "nuget";
  scope: "project" | "org";
  project: string;
  feed: string;
  packageName: string;
  version?: string;
  versionRegex?: string;
};

export type AzureWebAppServiceSpec = ServiceSpec & {
  startupCommand?: StartupCommandConfiguration;
  applicationSettings?: ApplicationSettingsConfiguration;
  connectionStrings?: ConnectionStringsConfiguration;
};

export interface ConfigFileOverrideSetWrapper {
  overrideSet?: ConfigFileOverrideSets;
}

export interface ConfigFileOverrideSets {
  identifier?: string;
  configFiles?: ConfigFileWrapper[];
}

export type CustomArtifactConfig = ArtifactConfig & {
  version: string;
  versionRegex?: string;
  timeout?: string;
  scripts?: CustomArtifactScripts;
  inputs?: NGVariable[];
  delegateSelectors?: string[];
  metadata?: string;
};

export interface CustomArtifactScriptInfo {
  shell: "Bash" | "PowerShell";
  source: CustomArtifactScriptSourceWrapper;
}

export interface CustomArtifactScriptSourceWrapper {
  type: string;
  spec: CustomScriptBaseSource;
}

export interface CustomArtifactScripts {
  fetchAllArtifacts: FetchAllArtifacts;
}

export type CustomDeploymentServiceSpec = ServiceSpec & {};

export interface CustomScriptBaseSource {
  type?: string;
}

export type CustomScriptInlineSource = CustomScriptBaseSource & {
  script?: string;
};

export type DockerHubArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  imagePath: string;
  tag?: string;
  tagRegex?: string;
};

export type EcrArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  region: string;
  imagePath: string;
  tag?: string;
  tagRegex?: string;
  metadata?: string;
};

export type EcsServiceSpec = ServiceSpec & {};

export interface FetchAllArtifacts {
  artifactsArrayPath?: ParameterFieldString;
  attributes?: NGVariable[];
  versionPath?: ParameterFieldString;
  spec: CustomArtifactScriptInfo;
}

export type GcrArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  registryHostname: string;
  imagePath: string;
  tag?: string;
  tagRegex?: string;
};

export type GithubPackagesArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  packageType: "npm" | "maven" | "rubygems" | "nuget" | "container";
  packageName: string;
  org?: string;
  version?: string;
  versionRegex?: string;
};

export type GoogleArtifactRegistryConfig = ArtifactConfig & {
  connectorRef: string;
  project: string;
  region: string;
  repositoryName: string;
  package: string;
  version?: string;
  versionRegex?: string;
  repositoryType: "docker";
  metadata?: string;
};

export type JenkinsArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  jobName: string;
  build?: string;
  artifactPath?: string;
};

export type KubernetesServiceSpec = ServiceSpec & {};

export interface ManifestOverrideSetWrapper {
  overrideSet?: ManifestOverrideSets;
}

export interface ManifestOverrideSets {
  identifier?: string;
  manifests?: ManifestConfigWrapper[];
}

export interface NGServiceConfig {
  service?: NGServiceV2InfoConfig;
  metadata?: string;
}

export interface NGServiceV2InfoConfig {
  name: string;
  identifier: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  gitOpsEnabled?: boolean;
  useFromStage?: ServiceUseFromStageV2;
  serviceDefinition?: ServiceDefinition;
  metadata?: string;
}

export interface NGVariableOverrideSetWrapper {
  overrideSet?: NGVariableOverrideSets;
}

export interface NGVariableOverrideSets {
  identifier?: string;
  variables?: NGVariable[];
}

export type NativeHelmServiceSpec = ServiceSpec & {};

export type NexusRegistryArtifactConfig = ArtifactConfig & {
  connectorRef: string;
  repository: string;
  artifactPath: string;
  repositoryFormat: "docker";
  repositoryPort?: string;
  repositoryUrl?: string;
  tag?: string;
  tagRegex?: string;
  metadata?: string;
};

export interface PrimaryArtifact {
  type?:
    | "DockerRegistry"
    | "Gcr"
    | "Ecr"
    | "Nexus3Registry"
    | "ArtifactoryRegistry"
    | "CustomArtifact"
    | "Acr"
    | "Jenkins"
    | "AmazonS3"
    | "GoogleArtifactRegistry"
    | "GithubPackageRegistry"
    | "AzureArtifactsRegistry";
  spec?: ArtifactConfig;
  primaryArtifactRef?: string;
  sources?: ArtifactSource[];
}

export interface ResponseNGServiceConfig {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: NGServiceConfig;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type ServerlessAwsLambdaServiceSpec = ServiceSpec & {};

export interface ServiceDefinition {
  type:
    | "Kubernetes"
    | "NativeHelm"
    | "Ssh"
    | "WinRm"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "CustomDeployment"
    | "ECS";
  spec: ServiceSpec;
}

export interface ServiceSpec {
  variables?: NGVariable[];
  manifests?: ManifestConfigWrapper[];
  configFiles?: ConfigFileWrapper[];
  artifacts?: ArtifactListConfig;
}

export interface ServiceUseFromStageV2 {
  stage: string;
  metadata?: string;
}

export interface SidecarArtifact {
  identifier: string;
  type:
    | "DockerRegistry"
    | "Gcr"
    | "Ecr"
    | "Nexus3Registry"
    | "ArtifactoryRegistry"
    | "CustomArtifact"
    | "Acr"
    | "Jenkins"
    | "AmazonS3"
    | "GoogleArtifactRegistry"
    | "GithubPackageRegistry"
    | "AzureArtifactsRegistry";
  spec: ArtifactConfig;
}

export interface SidecarArtifactWrapper {
  sidecar?: SidecarArtifact;
}

export type SshServiceSpec = ServiceSpec & {};

export interface StartupCommandConfiguration {
  store: StoreConfigWrapper;
  metadata?: string;
}

export type WinRmServiceSpec = ServiceSpec & {
  variableOverrideSets?: NGVariableOverrideSetWrapper[];
  artifactOverrideSets?: ArtifactOverrideSetWrapper[];
  manifestOverrideSets?: ManifestOverrideSetWrapper[];
  configFileOverrideSets?: ConfigFileOverrideSetWrapper[];
};

export type AcrArtifactSummary = ArtifactSummary & {
  imagePath?: string;
  tag?: string;
};

export interface ArtifactSummary {
  displayName?: string;
  type?: string;
}

export type ArtifactoryArtifactSummary = ArtifactSummary & {
  artifactPath?: string;
  tag?: string;
};

export type ArtifactoryGenericArtifactSummary = ArtifactSummary & {
  artifactPath?: string;
  artifactDirectory?: string;
};

export type CustomArtifactSummary = ArtifactSummary & {
  version?: string;
};

export type DockerArtifactSummary = ArtifactSummary & {
  imagePath?: string;
  tag?: string;
};

export type EcrArtifactSummary = ArtifactSummary & {
  imagePath?: string;
  tag?: string;
};

export type GcrArtifactSummary = ArtifactSummary & {
  imagePath?: string;
  tag?: string;
};

export type GithubPackagesArtifactSummary = ArtifactSummary & {
  packageName?: string;
  version?: string;
};

export type JenkinsArtifactSummary = ArtifactSummary & {
  jobName?: string;
  build?: string;
};

export type NexusArtifactSummary = ArtifactSummary & {
  artifactPath?: string;
  tag?: string;
};

export interface ResponseArtifactSummary {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ArtifactSummary;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type S3ArtifactSummary = ArtifactSummary & {
  bucketName?: string;
  filePath?: string;
};

export interface ResponseServicesV2YamlMetadataDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServicesV2YamlMetadataDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceV2YamlMetadata {
  serviceIdentifier: string;
  serviceYaml?: string;
  inputSetTemplateYaml?: string;
}

export interface ServicesV2YamlMetadataDTO {
  serviceV2YamlMetadataList?: ServiceV2YamlMetadata[];
}

export interface ServicesYamlMetadataApiInput {
  serviceIdentifiers: string[];
}

export interface ArtifactSourcesResponseDTO {
  sourceIdentifiers?: string[];
  sourceIdentifierToSourceInputMap?: {
    [key: string]: string;
  };
}

export interface ResponseArtifactSourcesResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ArtifactSourcesResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseValidationResultDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ValidationResultDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ValidationResultDTO {
  valid?: boolean;
  errorMessage?: string;
}

export interface NgSmtpDTO {
  uuid?: string;
  accountId?: string;
  name: string;
  value: SmtpConfigDTO;
}

export interface ResponseNgSmtpDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: NgSmtpDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SmtpConfigDTO {
  host: string;
  port: number;
  fromAddress?: string;
  useSSL?: boolean;
  startTLS?: boolean;
  username?: string;
  password?: string[];
}

export interface ResponsePasswordChangeResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?:
    | "PASSWORD_CHANGED"
    | "INCORRECT_CURRENT_PASSWORD"
    | "PASSWORD_STRENGTH_VIOLATED";
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PasswordChangeDTO {
  currentPassword?: string;
  newPassword?: string;
}

export interface GatewayAccountRequestDTO {
  uuid?: string;
  accountName?: string;
  companyName?: string;
  defaultExperience?: "NG" | "CG";
  createdFromNG?: boolean;
  nextGenEnabled?: boolean;
}

export interface ResponseUserInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: UserInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface UserInfo {
  uuid: string;
  name?: string;
  email?: string;
  token?: string;
  defaultAccountId?: string;
  intent?: string;
  accounts?: GatewayAccountRequestDTO[];
  admin?: boolean;
  twoFactorAuthenticationEnabled?: boolean;
  emailVerified?: boolean;
  locked?: boolean;
  disabled?: boolean;
  signupAction?: string;
  edition?: string;
  billingFrequency?: string;
  utmInfo?: UtmInfo;
  externallyManaged?: boolean;
}

export interface ResponseTwoFactorAuthSettingsInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: TwoFactorAuthSettingsInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface TwoFactorAuthSettingsInfo {
  userId?: string;
  email?: string;
  twoFactorAuthenticationEnabled?: boolean;
  mechanism?: "TOTP";
  totpSecretKey?: string;
  totpqrurl?: string;
}

export interface PageProject {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: Project[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageProject {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageProject;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListProject {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: Project[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ActiveProjectsCountDTO {
  count?: number;
}

export interface ResponseActiveProjectsCountDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ActiveProjectsCountDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseUserAggregate {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: UserAggregate;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface UserAggregate {
  user: UserMetadataDTO;
  roleAssignmentMetadata: RoleAssignmentMetadataDTO[];
}

export interface PageUserAggregate {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: UserAggregate[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageUserAggregate {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageUserAggregate;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AddUsersResponse {
  addUserResponseMap?: {
    [key: string]:
      | "USER_INVITED_SUCCESSFULLY"
      | "USER_ADDED_SUCCESSFULLY"
      | "USER_ALREADY_ADDED"
      | "USER_ALREADY_INVITED"
      | "FAIL";
  };
}

export interface ResponseAddUsersResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: AddUsersResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AddUsers {
  emails: string[];
  roleBindings?: RoleBinding[];
  userGroups?: string[];
}

export interface ResponseVariableResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: VariableResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type StringVariableConfigDTO = VariableConfigDTO & {
  fixedValue?: string;
  defaultValue?: string;
  allowedValues?: string[];
  regex?: string;
};

export interface VariableConfigDTO {
  valueType: "FIXED" | "FIXED_SET" | "REGEX";
  value?: { [key: string]: any };
}

export interface VariableDTO {
  identifier: string;
  name: string;
  description?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  type: "String";
  spec: VariableConfigDTO;
}

export interface VariableResponseDTO {
  variable: VariableDTO;
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface VariableRequestDTO {
  variable?: VariableDTO;
}

export interface PageVariableResponseDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: VariableResponseDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageVariableResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageVariableResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListExecutionStatus {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: (
    | "Running"
    | "AsyncWaiting"
    | "TaskWaiting"
    | "TimedWaiting"
    | "Failed"
    | "Errored"
    | "IgnoreFailed"
    | "NotStarted"
    | "Expired"
    | "Aborted"
    | "Discontinuing"
    | "Queued"
    | "Paused"
    | "ResourceWaiting"
    | "InterventionWaiting"
    | "ApprovalWaiting"
    | "Success"
    | "Suspended"
    | "Skipped"
    | "Pausing"
    | "ApprovalRejected"
    | "InputWaiting"
    | "NOT_STARTED"
    | "INTERVENTION_WAITING"
    | "APPROVAL_WAITING"
    | "APPROVAL_REJECTED"
    | "WAITING"
  )[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ArtifactsSummary {
  primary?: ArtifactSummary;
  sidecars?: ArtifactSummary[];
}

export interface CDStageModuleInfo {
  serviceInfo?: ServiceExecutionSummary;
  infraExecutionSummary?: InfraExecutionSummary;
  nodeExecutionId?: string;
}

export interface InfraExecutionSummary {
  identifier?: string;
  name?: string;
  type?: string;
  infrastructureIdentifier?: string;
  infrastructureName?: string;
}

export interface ResponseCDStageModuleInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: CDStageModuleInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceExecutionSummary {
  identifier?: string;
  displayName?: string;
  deploymentType?: string;
  gitOpsEnabled?: boolean;
  artifacts?: ArtifactsSummary;
}

export interface CDPipelineModuleInfo {
  serviceIdentifiers?: string[];
  envIdentifiers?: string[];
  envGroupIdentifiers?: string[];
  serviceDefinitionTypes?: string[];
  environmentTypes?: ("PreProduction" | "Production")[];
  infrastructureTypes?: string[];
  infrastructureIdentifiers?: string[];
  infrastructureNames?: string[];
}

export interface ResponseCDPipelineModuleInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: CDPipelineModuleInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AbortFailureActionConfig = FailureStrategyActionConfig & {
  type: "Abort";
};

export type AzureARMRollbackStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  provisionerIdentifier: string;
};

export interface AzureCreateARMResourceParameterFile {
  store: StoreConfigWrapper;
}

export interface AzureCreateARMResourceStepConfiguration {
  connectorRef: string;
  template: AzureTemplateFile;
  parameters?: AzureCreateARMResourceParameterFile;
  scope: AzureCreateARMResourceStepScope;
}

export type AzureCreateARMResourceStepInfo = StepSpecType & {
  provisionerIdentifier: string;
  delegateSelectors?: string[];
  metadata?: string;
  configuration: AzureCreateARMResourceStepConfiguration;
};

export interface AzureCreateARMResourceStepScope {
  type: string;
  spec: AzureScopeType;
}

export interface AzureCreateBPStepConfiguration {
  assignmentName?: string;
  scope: "Subscription" | "ManagementGroup";
  template: AzureTemplateFile;
  connectorRef: string;
}

export type AzureCreateBPStepInfo = StepSpecType & {
  provisionerIdentifier: string;
  delegateSelectors?: string[];
  metadata?: string;
  configuration: AzureCreateBPStepConfiguration;
};

export type AzureManagementSpec = AzureScopeType & {
  managementGroupId: string;
  location: string;
};

export type AzureResourceGroupSpec = AzureScopeType & {
  subscription: string;
  resourceGroup: string;
  mode?: "Incremental" | "Complete";
};

export interface AzureScopeType {
  [key: string]: any;
}

export type AzureSubscriptionSpec = AzureScopeType & {
  subscription: string;
  location: string;
};

export interface AzureTemplateFile {
  store: StoreConfigWrapper;
}

export type AzureTenantSpec = AzureScopeType & {
  location: string;
};

export type AzureWebAppRollbackStepInfo = StepSpecType & {
  delegateSelectors?: string[];
};

export type AzureWebAppSlotDeploymentStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  webApp: string;
  deploymentSlot: string;
};

export type AzureWebAppSwapSlotStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  targetSlot: string;
};

export type AzureWebAppTrafficShiftStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  traffic: ParameterFieldString;
};

export interface CloudformationCreateStackStepConfiguration {
  stackName: string;
  templateFile: CloudformationTemplateFile;
  parameters?: CloudformationParametersFileSpec[];
  connectorRef: string;
  region: string;
  roleArn?: string;
  capabilities?: string[];
  tags?: CloudformationTags;
  skipOnStackStatuses?: string[];
  parameterOverrides?: NGVariable[];
}

export type CloudformationCreateStackStepInfo = StepSpecType & {
  provisionerIdentifier: string;
  delegateSelectors?: string[];
  metadata?: string;
  configuration: CloudformationCreateStackStepConfiguration;
};

export interface CloudformationDeleteStackStepConfiguration {
  type: string;
  spec: CloudformationDeleteStackStepConfigurationSpec;
}

export interface CloudformationDeleteStackStepConfigurationSpec {
  type?: string;
}

export type CloudformationDeleteStackStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  metadata?: string;
  configuration: CloudformationDeleteStackStepConfiguration;
};

export interface CloudformationParametersFileSpec {
  identifier: string;
  store: StoreConfigWrapper;
}

export interface CloudformationRollbackStepConfiguration {
  provisionerIdentifier: string;
}

export type CloudformationRollbackStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  configuration: CloudformationRollbackStepConfiguration;
  metadata?: string;
};

export interface CloudformationTags {
  type: string;
  spec: CloudformationTagsFileSpec;
}

export interface CloudformationTagsFileSpec {
  type?: string;
}

export interface CloudformationTemplateFile {
  type: string;
  spec: CloudformationTemplateFileSpec;
}

export interface CloudformationTemplateFileSpec {
  type?: string;
}

export interface ClusterYaml {
  identifier: string;
  metadata?: string;
}

export type CommandStepInfo = StepSpecType & {
  onDelegate: boolean;
  delegateSelectors?: string[];
  commandUnits?: CommandUnitWrapper[];
  host?: string;
  environmentVariables?: NGVariable[];
  outputVariables?: NGVariable[];
  metadata?: string;
};

export interface CommandUnitBaseSpec {
  type?: string;
}

export interface CommandUnitWrapper {
  identifier: string;
  type: string;
  name: string;
  spec?: CommandUnitBaseSpec;
}

export type CopyCommandUnitSpec = CommandUnitBaseSpec & {
  destinationPath: string;
  sourceType: "Artifact" | "Config";
};

export type CountInstanceSelection = InstanceSelectionBase & {
  count?: ParameterFieldString;
};

export type CreatePRStepInfo = StepSpecType & {
  shell: "Bash";
  overrideConfig: boolean;
  stringMap?: ParameterFieldMapStringString;
  source?: CreatePRStepUpdateConfigScriptWrapper;
  delegateSelectors?: string[];
};

export interface CreatePRStepUpdateConfigScriptBaseSource {
  type?: string;
}

export type CreatePRStepUpdateConfigScriptInlineSource = CreatePRStepUpdateConfigScriptBaseSource & {
  updateConfigScript?: string;
};

export interface CreatePRStepUpdateConfigScriptWrapper {
  type: string;
  spec: CreatePRStepUpdateConfigScriptBaseSource;
}

export type DeleteManifestPathSpec = DeleteResourcesBaseSpec & {
  manifestPaths?: string[];
  allManifestPaths?: boolean;
};

export type DeleteReleaseNameSpec = DeleteResourcesBaseSpec & {
  deleteNamespace?: boolean;
};

export type DeleteResourceNameSpec = DeleteResourcesBaseSpec & {
  resourceNames: string[];
};

export interface DeleteResourcesBaseSpec {
  [key: string]: any;
}

export interface DeleteResourcesWrapper {
  type?: "ResourceName" | "ReleaseName" | "ManifestPath";
  spec?: DeleteResourcesBaseSpec;
}

export type DeploymentStageConfig = StageInfoConfig & {
  serviceConfig?: ServiceConfig;
  service?: ServiceYamlV2;
  services?: ServicesYaml;
  deploymentType?:
    | "Kubernetes"
    | "NativeHelm"
    | "Ssh"
    | "WinRm"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "CustomDeployment"
    | "ECS";
  gitOpsEnabled?: boolean;
  environment?: EnvironmentYamlV2;
  environments?: EnvironmentsYaml;
  environmentGroup?: EnvironmentGroupYaml;
  infrastructure?: PipelineInfrastructure;
  execution: ExecutionElementConfig;
};

export type EcsCanaryDeleteStepInfo = StepSpecType & {
  delegateSelectors?: string[];
};

export type EcsCanaryDeployStepInfo = StepSpecType & {
  delegateSelectors?: string[];
};

export type EcsRollingDeployStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  sameAsAlreadyRunningInstances?: boolean;
  forceNewDeployment?: boolean;
};

export type EcsRollingRollbackStepInfo = StepSpecType & {
  delegateSelectors?: string[];
};

export interface EnvironmentGroupYaml {
  __uuid?: string;
  envGroupRef: string;
  environments?: EnvironmentYamlV2[];
  deployToAll?: boolean;
  metadata?: string;
}

export interface EnvironmentYaml {
  name: string;
  identifier: string;
  description?: string;
  type: "PreProduction" | "Production";
  tags?: {
    [key: string]: string;
  };
}

export interface EnvironmentYamlV2 {
  environmentRef: string;
  deployToAll?: boolean;
  infrastructureDefinitions?: InfraStructureDefinitionYaml[];
  infrastructureDefinition?: InfraStructureDefinitionYaml;
  environmentInputs?: JsonNode;
  serviceOverrideInputs?: JsonNode;
  gitOpsClusters?: ClusterYaml[];
}

export interface EnvironmentsMetadata {
  parallel?: boolean;
}

export interface EnvironmentsYaml {
  values?: EnvironmentYamlV2[];
  metadata?: EnvironmentsMetadata;
}

export interface ExcludeConfig {
  exclude?: {
    [key: string]: string;
  };
}

export interface ExecutionElementConfig {
  steps: ExecutionWrapperConfig[];
  rollbackSteps?: ExecutionWrapperConfig[];
}

export interface ExecutionWrapperConfig {
  step?: StepElementConfig;
  parallel?: ParallelStepElementConfig;
  stepGroup?: StepGroupElementConfig;
}

export interface FailureStrategyActionConfig {
  type:
    | "Ignore"
    | "Retry"
    | "MarkAsSuccess"
    | "Abort"
    | "StageRollback"
    | "StepGroupRollback"
    | "ManualIntervention"
    | "ProceedWithDefaultValue";
}

export interface FailureStrategyConfig {
  onFailure: OnFailureConfig;
}

export type HarnessFileStoreSource = ShellScriptBaseSource & {
  file?: string;
};

export interface HarnessForConfig {
  times?: number;
  maxConcurrency?: number;
  items?: string[];
  partitionSize?: number;
  start?: number;
  end?: number;
  unit?: "Percentage" | "Count";
}

export type HelmDeployStepInfo = StepSpecType & {
  delegateSelectors?: string[];
};

export type HelmRollbackStepInfo = StepSpecType & {
  delegateSelectors?: string[];
};

export type IgnoreFailureActionConfig = FailureStrategyActionConfig & {
  type: "Ignore";
};

export interface InfraOverrides {
  environment?: EnvironmentYaml;
  infrastructureDefinition?: InfrastructureDef;
}

export interface InfraStructureDefinitionYaml {
  identifier: string;
  inputs?: JsonNode;
  metadata?: string;
}

export interface InfraUseFromStage {
  stage: string;
  overrides?: InfraOverrides;
}

export interface InfrastructureDef {
  type:
    | "KubernetesDirect"
    | "KubernetesGcp"
    | "KubernetesAzure"
    | "Pdc"
    | "SshWinRmAzure"
    | "ServerlessAwsLambda"
    | "AzureWebApp"
    | "SshWinRmAws"
    | "CustomDeployment"
    | "ECS";
  spec: Infrastructure;
  provisioner?: ExecutionElementConfig;
}

export type InheritedCloudformationDeleteStackStepConfiguration = CloudformationDeleteStackStepConfigurationSpec & {
  provisionerIdentifier: string;
};

export type InlineCloudformationDeleteStackStepConfiguration = CloudformationDeleteStackStepConfigurationSpec & {
  stackName: string;
  connectorRef: string;
  region: string;
  roleArn?: string;
};

export type InlineCloudformationTagsFileSpec = CloudformationTagsFileSpec & {
  content: string;
};

export type InlineCloudformationTemplateFileSpec = CloudformationTemplateFileSpec & {
  templateBody: string;
};

export type InlineTerraformBackendConfigSpec = TerraformBackendConfigSpec & {
  content?: string;
};

export type InlineTerraformVarFileSpec = TerraformVarFileSpec & {
  content?: string;
};

export interface InstanceSelectionBase {
  [key: string]: any;
}

export interface InstanceSelectionWrapper {
  type?: "Count" | "Percentage";
  spec?: InstanceSelectionBase;
}

export type JenkinsBuildStepInfo = StepSpecType & {
  connectorRef: string;
  jobName: string;
  jobParameter?: JenkinsParameterField[];
  unstableStatusAsSuccess?: boolean;
  useConnectorUrlForJobExecution?: boolean;
  delegateSelectors?: string[];
  metadata?: string;
};

export interface JenkinsParameterField {
  name?: string;
  value: string;
  metadata?: string;
}

export interface JsonNode {
  [key: string]: any;
}

export type K8sApplyStepInfo = StepSpecType & {
  skipDryRun?: boolean;
  skipSteadyStateCheck?: boolean;
  filePaths?: string[];
  delegateSelectors?: string[];
  skipRendering?: boolean;
  overrides?: ManifestConfigWrapper[];
};

export type K8sBGSwapServicesStepInfo = StepSpecType & {
  skipDryRun?: boolean;
  delegateSelectors?: string[];
};

export type K8sBlueGreenStepInfo = StepSpecType & {
  skipDryRun?: boolean;
  delegateSelectors?: string[];
};

export type K8sCanaryDeleteStepInfo = StepSpecType & {
  skipDryRun?: boolean;
  delegateSelectors?: string[];
};

export type K8sCanaryStepInfo = StepSpecType & {
  instanceSelection: InstanceSelectionWrapper;
  skipDryRun?: boolean;
  delegateSelectors?: string[];
};

export type K8sDeleteStepInfo = StepSpecType & {
  deleteResources: DeleteResourcesWrapper;
  skipDryRun?: boolean;
  delegateSelectors?: string[];
};

export type K8sRollingRollbackStepInfo = StepSpecType & {
  skipDryRun?: boolean;
  delegateSelectors?: string[];
};

export type K8sRollingStepInfo = StepSpecType & {
  skipDryRun?: boolean;
  delegateSelectors?: string[];
};

export type K8sScaleStepInfo = StepSpecType & {
  instanceSelection: InstanceSelectionWrapper;
  workload: string;
  skipDryRun?: boolean;
  skipSteadyStateCheck?: boolean;
  delegateSelectors?: string[];
};

export interface ManualFailureSpecConfig {
  timeout: string;
  onTimeout: OnTimeoutConfig;
}

export type ManualInterventionFailureActionConfig = FailureStrategyActionConfig & {
  spec: ManualFailureSpecConfig;
  type: "ManualIntervention";
};

export type MarkAsSuccessFailureActionConfig = FailureStrategyActionConfig & {
  type: "MarkAsSuccess";
};

export type MatrixConfig = MatrixConfigInterface & {
  exclude?: ParameterFieldListExcludeConfig;
  maxConcurrency?: number;
};

export interface MatrixConfigInterface {
  [key: string]: any;
}

export type MergePRStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  deleteSourceBranch: boolean;
};

export interface ModuleSource {
  useConnectorCredentials: ParameterFieldBoolean;
}

export interface OnFailureConfig {
  errors: (
    | "Unknown"
    | "AllErrors"
    | "Authentication"
    | "Connectivity"
    | "Timeout"
    | "Authorization"
    | "Verification"
    | "DelegateProvisioning"
    | "PolicyEvaluationFailure"
    | "ExecutionInputTimeoutError"
  )[];
  action: FailureStrategyActionConfig;
}

export interface OnRetryFailureConfig {
  action?: FailureStrategyActionConfig;
}

export interface OnTimeoutConfig {
  action?: FailureStrategyActionConfig;
}

export interface ParallelStepElementConfig {
  sections: ExecutionWrapperConfig[];
}

export interface ParameterFieldListExcludeConfig {
  expressionValue?: string;
  expression?: boolean;
  value?: ExcludeConfig[];
  defaultValue?: ExcludeConfig[];
  typeString?: boolean;
  inputSetValidator?: InputSetValidator;
  jsonResponseField?: boolean;
  responseField?: string;
  executionInput?: boolean;
}

export interface ParameterFieldMapStringString {
  expressionValue?: string;
  expression?: boolean;
  value?: {
    [key: string]: string;
  };
  defaultValue?: {
    [key: string]: string;
  };
  typeString?: boolean;
  inputSetValidator?: InputSetValidator;
  jsonResponseField?: boolean;
  responseField?: string;
  executionInput?: boolean;
}

export type PercentageInstanceSelection = InstanceSelectionBase & {
  percentage?: ParameterFieldString;
};

export interface PipelineInfrastructure {
  infrastructureDefinition?: InfrastructureDef;
  useFromStage?: InfraUseFromStage;
  environment?: EnvironmentYaml;
  allowSimultaneousDeployments?: boolean;
  infrastructureKey?: string;
  environmentRef?: string;
}

export type ProceedWithDefaultValuesFailureActionConfig = FailureStrategyActionConfig & {
  type: "ProceedWithDefaultValue";
};

export type RemoteCloudformationTagsFileSpec = CloudformationTagsFileSpec & {
  store: StoreConfigWrapper;
};

export type RemoteCloudformationTemplateFileSpec = CloudformationTemplateFileSpec & {
  store: StoreConfigWrapper;
};

export type RemoteTerraformVarFileSpec = TerraformVarFileSpec & {
  store: StoreConfigWrapper;
};

export interface ResponseStageElementConfig {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: StageElementConfig;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type RetryFailureActionConfig = FailureStrategyActionConfig & {
  spec: RetryFailureSpecConfig;
  type: "Retry";
};

export interface RetryFailureSpecConfig {
  retryCount: number;
  retryIntervals: string[];
  onRetryFailure: OnRetryFailureConfig;
}

export type S3UrlCloudformationTemplateFileSpec = CloudformationTemplateFileSpec & {
  templateUrl: string;
};

export type ScriptCommandUnitSpec = CommandUnitBaseSpec & {
  shell: "Bash" | "PowerShell";
  source: ShellScriptSourceWrapper;
  tailFiles?: TailFilePattern[];
  workingDirectory?: string;
};

export type ServerlessAwsLambdaDeployStepInfo = StepSpecType & {
  delegateSelectors?: string[];
  commandOptions?: string;
};

export type ServerlessAwsLambdaRollbackStepInfo = StepSpecType & {
  delegateSelectors?: string[];
};

export interface ServiceConfig {
  useFromStage?: ServiceUseFromStage;
  service?: ServiceYaml;
  serviceRef?: string;
  serviceDefinition?: ServiceDefinition;
  stageOverrides?: StageOverridesConfig;
}

export interface ServiceOverrides {
  name?: string;
  description?: string;
}

export interface ServiceUseFromStage {
  stage: string;
  overrides?: ServiceOverrides;
  metadata?: string;
}

export interface ServiceYaml {
  identifier: string;
  name: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
}

export interface ServiceYamlV2 {
  serviceRef?: string;
  useFromStage?: ServiceUseFromStageV2;
  serviceInputs?: JsonNode;
}

export interface ServicesMetadata {
  parallel?: boolean;
}

export interface ServicesYaml {
  values?: ServiceYamlV2[];
  metadata?: ServicesMetadata;
}

export interface ShellScriptBaseSource {
  type?: string;
}

export type ShellScriptInlineSource = ShellScriptBaseSource & {
  script?: string;
};

export interface ShellScriptSourceWrapper {
  type: string;
  spec: ShellScriptBaseSource;
}

export interface StageElementConfig {
  identifier: string;
  name: string;
  description?: string;
  when?: StageWhenCondition;
  failureStrategies?: FailureStrategyConfig[];
  delegateSelectors?: string[];
  variables?: NGVariable[];
  tags?: {
    [key: string]: string;
  };
  type?: string;
  template?: TemplateLinkConfig;
  skipInstances?: boolean;
  strategy?: StrategyConfig;
  spec?: StageInfoConfig;
}

export interface StageInfoConfig {
  execution?: ExecutionElementConfig;
}

export interface StageOverridesConfig {
  variables?: NGVariable[];
  artifacts?: ArtifactListConfig;
  manifests?: ManifestConfigWrapper[];
  configFiles?: ConfigFileWrapper[];
}

export type StageRollbackFailureActionConfig = FailureStrategyActionConfig & {
  type: "StageRollback";
};

export interface StageWhenCondition {
  pipelineStatus: "Success" | "Failure" | "All";
  condition?: string;
}

export interface StepElementConfig {
  identifier: string;
  name: string;
  description?: string;
  timeout?: string;
  failureStrategies?: FailureStrategyConfig[];
  when?: StepWhenCondition;
  type: string;
  strategy?: StrategyConfig;
  spec?: StepSpecType;
}

export interface StepGroupElementConfig {
  identifier: string;
  name: string;
  when?: StepWhenCondition;
  failureStrategies?: FailureStrategyConfig[];
  steps: ExecutionWrapperConfig[];
  delegateSelectors?: string[];
  strategy?: StrategyConfig;
}

export type StepGroupFailureActionConfig = FailureStrategyActionConfig & {
  type: "StepGroupRollback";
};

export interface StepSpecType {
  [key: string]: any;
}

export interface StepWhenCondition {
  stageStatus: "Success" | "Failure" | "All";
  condition?: string;
}

export interface StrategyConfig {
  matrix?: MatrixConfigInterface;
  repeat?: HarnessForConfig;
  parallelism?: number;
}

export interface TailFilePattern {
  tailFile?: ParameterFieldString;
  tailPattern?: ParameterFieldString;
}

export interface TemplateLinkConfig {
  templateRef: string;
  versionLabel?: string;
  templateInputs?: JsonNode;
  templateVariables?: JsonNode;
}

export type TerraformApplyStepInfo = StepSpecType & {
  provisionerIdentifier: string;
  delegateSelectors?: string[];
  metadata?: string;
  configuration: TerraformStepConfiguration;
};

export interface TerraformBackendConfig {
  type?: string;
  spec?: TerraformBackendConfigSpec;
}

export interface TerraformBackendConfigSpec {
  [key: string]: any;
}

export interface TerraformConfigFilesWrapper {
  store: StoreConfigWrapper;
  moduleSource?: ModuleSource;
}

export type TerraformDestroyStepInfo = StepSpecType & {
  provisionerIdentifier: string;
  delegateSelectors?: string[];
  metadata?: string;
  configuration: TerraformStepConfiguration;
};

export interface TerraformExecutionData {
  workspace?: string;
  targets?: string[];
  environmentVariables?: NGVariable[];
  configFiles: TerraformConfigFilesWrapper;
  varFiles?: TerraformVarFileWrapper[];
  backendConfig?: TerraformBackendConfig;
}

export interface TerraformPlanExecutionData {
  workspace?: string;
  configFiles: TerraformConfigFilesWrapper;
  varFiles?: TerraformVarFileWrapper[];
  backendConfig?: TerraformBackendConfig;
  targets?: string[];
  environmentVariables?: NGVariable[];
  command: "Apply" | "Destroy";
  secretManagerRef: string;
  exportTerraformPlanJson?: boolean;
}

export type TerraformPlanStepInfo = StepSpecType & {
  provisionerIdentifier: string;
  delegateSelectors?: string[];
  configuration: TerraformPlanExecutionData;
};

export type TerraformRollbackStepInfo = StepSpecType & {
  provisionerIdentifier: string;
  delegateSelectors?: string[];
};

export interface TerraformStepConfiguration {
  type: "Inline" | "InheritFromPlan" | "InheritFromApply";
  spec?: TerraformExecutionData;
}

export interface TerraformVarFile {
  type: string;
  identifier: string;
  spec: TerraformVarFileSpec;
}

export interface TerraformVarFileSpec {
  type?: string;
}

export interface TerraformVarFileWrapper {
  varFile: TerraformVarFile;
}

export interface ResponseStepElementConfig {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: StepElementConfig;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface FeedbackFormDTO {
  accountId?: string;
  email?: string;
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  score?: number;
  suggestion?: string;
}

export interface ClusterResponse {
  clusterRef?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  accountIdentifier?: string;
  envRef?: string;
  linkedAt?: number;
  scope?: "ACCOUNT" | "ORGANIZATION" | "PROJECT";
  name?: string;
}

export interface ResponseClusterResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ClusterResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ClusterRequest {
  identifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  envRef?: string;
  scope?: "ACCOUNT" | "ORGANIZATION" | "PROJECT";
}

export interface ClusterBatchResponse {
  linked?: number;
}

export interface ResponseClusterBatchResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ClusterBatchResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ClusterBasicDTO {
  identifier?: string;
  name?: string;
  scope?: "ACCOUNT" | "ORGANIZATION" | "PROJECT";
}

export interface ClusterBatchRequest {
  orgIdentifier?: string;
  projectIdentifier?: string;
  envRef?: string;
  linkAllClusters?: boolean;
  searchTerm?: string;
  clusters?: ClusterBasicDTO[];
}

export interface ClusterFromGitops {
  identifier?: string;
  name?: string;
  scopeLevel?: "ACCOUNT" | "ORGANIZATION" | "PROJECT";
}

export interface PageClusterFromGitops {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ClusterFromGitops[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageClusterFromGitops {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageClusterFromGitops;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageClusterResponse {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ClusterResponse[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageClusterResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageClusterResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface HarnessServiceInfoNG {
  serviceId?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  envId?: string;
  infraMappingId?: string;
}

export interface ResponseOptionalHarnessServiceInfoNG {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: HarnessServiceInfoNG;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface GitOpsInstanceRequest {
  accountIdentifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  applicationIdentifier?: string;
  agentIdentifier?: string;
  envIdentifier?: string;
  serviceIdentifier?: string;
  buildId?: string;
  clusterIdentifier?: string;
  creationTimestamp: number;
  lastDeployedAt: number;
  instanceInfo: K8sBasicInfo;
  pipelineName?: string;
  lastExecutedAt?: number;
  pipelineExecutionId?: string;
  lastDeployedByName?: string;
  lastDeployedById?: string;
}

export interface K8sBasicInfo {
  namespace: string;
  podName: string;
  podId: string;
  containerList?: K8sContainer[];
}

export interface K8sContainer {
  containerId?: string;
  name?: string;
  image?: string;
}

export interface DeleteInstancesRequest {
  status?: boolean;
  deletedCount?: number;
}

export interface ResponseDeleteInstancesRequest {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: DeleteInstancesRequest;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ContextElement {
  elementType?:
    | "SERVICE"
    | "INFRAMAPPING"
    | "SERVICE_TEMPLATE"
    | "TAG"
    | "SHELL"
    | "HOST"
    | "INSTANCE"
    | "STANDARD"
    | "PARAM"
    | "PARTITION"
    | "OTHER"
    | "FORK"
    | "CONTAINER_SERVICE"
    | "CLUSTER"
    | "AWS_LAMBDA_FUNCTION"
    | "AMI_SERVICE_SETUP"
    | "AMI_SERVICE_DEPLOY"
    | "ECS_SERVICE_SETUP"
    | "AMI_SWITCH_ROUTES"
    | "PCF_SERVICE_SETUP"
    | "PCF_SERVICE_DEPLOY"
    | "PCF_ROUTE_SWAP_ROLLBACK"
    | "PCF_INSTANCE"
    | "SPOTINST_SERVICE_SETUP"
    | "SPOTINST_SERVICE_DEPLOY"
    | "ARTIFACT"
    | "ARTIFACT_VARIABLE"
    | "HELM_DEPLOY"
    | "CLOUD_FORMATION_PROVISION"
    | "CLOUD_FORMATION_ROLLBACK"
    | "CLOUD_FORMATION_DEPROVISION"
    | "TERRAFORM_PROVISION"
    | "SHELL_SCRIPT_PROVISION"
    | "K8S"
    | "TERRAFORM_INHERIT_PLAN"
    | "TERRAGRUNT_INHERIT_PLAN"
    | "AZURE_VMSS_SETUP"
    | "AZURE_WEBAPP_SETUP"
    | "HELM_CHART"
    | "MANIFEST_VARIABLE"
    | "RANCHER_K8S_CLUSTER_CRITERIA";
  uuid?: string;
  name?: string;
}

export interface DOMConfiguration {
  parameterNames?: DOMStringList;
}

export interface DOMImplementation {
  [key: string]: any;
}

export interface DOMStringList {
  length?: number;
}

export interface DelegateMetaInfo {
  id?: string;
  host_name?: string;
}

export interface DelegateResponseData {
  [key: string]: any;
}

export interface Document {
  documentElement?: Element;
  inputEncoding?: string;
  xmlEncoding?: string;
  xmlStandalone?: boolean;
  xmlVersion?: string;
  strictErrorChecking?: boolean;
  documentURI?: string;
  domConfig?: DOMConfiguration;
  doctype?: DocumentType;
  implementation?: DOMImplementation;
  prefix?: string;
  nodeName?: string;
  nodeValue?: string;
  nodeType?: number;
  parentNode?: Node;
  childNodes?: NodeList;
  firstChild?: Node;
  lastChild?: Node;
  previousSibling?: Node;
  nextSibling?: Node;
  ownerDocument?: Document;
  namespaceURI?: string;
  localName?: string;
  baseURI?: string;
  textContent?: string;
  attributes?: NamedNodeMap;
}

export interface DocumentType {
  entities?: NamedNodeMap;
  publicId?: string;
  notations?: NamedNodeMap;
  systemId?: string;
  internalSubset?: string;
  name?: string;
  prefix?: string;
  nodeName?: string;
  nodeValue?: string;
  nodeType?: number;
  parentNode?: Node;
  childNodes?: NodeList;
  firstChild?: Node;
  lastChild?: Node;
  previousSibling?: Node;
  nextSibling?: Node;
  ownerDocument?: Document;
  namespaceURI?: string;
  localName?: string;
  baseURI?: string;
  textContent?: string;
  attributes?: NamedNodeMap;
}

export interface Element {
  tagName?: string;
  schemaTypeInfo?: TypeInfo;
  prefix?: string;
  nodeName?: string;
  nodeValue?: string;
  nodeType?: number;
  parentNode?: Node;
  childNodes?: NodeList;
  firstChild?: Node;
  lastChild?: Node;
  previousSibling?: Node;
  nextSibling?: Node;
  ownerDocument?: Document;
  namespaceURI?: string;
  localName?: string;
  baseURI?: string;
  textContent?: string;
  attributes?: NamedNodeMap;
}

export interface ExecutionDataValue {
  displayName?: string;
  value?: { [key: string]: any };
}

export type HttpStateExecutionData = DelegateResponseData & {
  httpUrl?: string;
  httpMethod?: string;
  httpResponseCode?: number;
  httpResponseBody?: string;
  assertionStatement?: string;
  assertionStatus?: string;
  header?: string;
  headers?: KeyValuePair[];
  useProxy?: boolean;
  warningMessage?: string;
  document?: Document;
  stateName?: string;
  stateType?: string;
  startTs?: number;
  endTs?: number;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  errorMsg?: string;
  waitInterval?: number;
  element?: ContextElement;
  stateParams?: {
    [key: string]: { [key: string]: any };
  };
  delegateMetaInfo?: DelegateMetaInfo;
  templateVariable?: {
    [key: string]: { [key: string]: any };
  };
  executionDetails?: {
    [key: string]: ExecutionDataValue;
  };
  executionSummary?: {
    [key: string]: ExecutionDataValue;
  };
};

export interface KeyValuePair {
  key?: string;
  value?: string;
}

export interface NamedNodeMap {
  length?: number;
}

export interface Node {
  prefix?: string;
  nodeName?: string;
  nodeValue?: string;
  nodeType?: number;
  parentNode?: Node;
  childNodes?: NodeList;
  firstChild?: Node;
  lastChild?: Node;
  previousSibling?: Node;
  nextSibling?: Node;
  ownerDocument?: Document;
  namespaceURI?: string;
  localName?: string;
  baseURI?: string;
  textContent?: string;
  attributes?: NamedNodeMap;
}

export interface NodeList {
  length?: number;
}

export type ScriptStateExecutionData = DelegateResponseData & {
  name?: string;
  activityId?: string;
  sweepingOutputEnvVariables?: {
    [key: string]: string;
  };
  secretOutputVars?: string[];
  stateName?: string;
  stateType?: string;
  startTs?: number;
  endTs?: number;
  status?:
    | "ABORTED"
    | "DISCONTINUING"
    | "ERROR"
    | "FAILED"
    | "NEW"
    | "PAUSED"
    | "PAUSING"
    | "QUEUED"
    | "RESUMED"
    | "RUNNING"
    | "SCHEDULED"
    | "STARTING"
    | "SUCCESS"
    | "WAITING"
    | "SKIPPED"
    | "ABORTING"
    | "REJECTED"
    | "EXPIRED"
    | "PREPARING";
  errorMsg?: string;
  waitInterval?: number;
  element?: ContextElement;
  stateParams?: {
    [key: string]: { [key: string]: any };
  };
  delegateMetaInfo?: DelegateMetaInfo;
  templateVariable?: {
    [key: string]: { [key: string]: any };
  };
  executionDetails?: {
    [key: string]: ExecutionDataValue;
  };
  executionSummary?: {
    [key: string]: ExecutionDataValue;
  };
};

export interface TypeInfo {
  typeNamespace?: string;
  typeName?: string;
}

export interface JiraProjectBasicNG {
  id: string;
  key: string;
  name: string;
}

export interface ResponseListJiraProjectBasicNG {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JiraProjectBasicNG[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface JiraUserData {
  accountId?: string;
  name?: string;
  displayName?: string;
  emailAddress?: string;
  active?: boolean;
}

export interface ResponseListJiraUserData {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JiraUserData[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface JiraStatusCategoryNG {
  id: number;
  key: string;
  name: string;
}

export interface JiraStatusNG {
  id: string;
  name: string;
  statusCategory?: JiraStatusCategoryNG;
}

export interface ResponseListJiraStatusNG {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JiraStatusNG[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface JiraFieldAllowedValueNG {
  id?: string;
  name?: string;
  value?: string;
}

export interface JiraFieldNG {
  key: string;
  name: string;
  required?: boolean;
  schema: JiraFieldSchemaNG;
  allowedValues: JiraFieldAllowedValueNG[];
  custom?: boolean;
}

export interface JiraFieldSchemaNG {
  array?: boolean;
  typeStr: string;
  type:
    | "string"
    | "number"
    | "date"
    | "datetime"
    | "timetracking"
    | "option"
    | "user";
  customType?: string;
}

export interface JiraIssueCreateMetadataNG {
  projects?: {
    [key: string]: JiraProjectNG;
  };
}

export interface JiraIssueTypeNG {
  id: string;
  name: string;
  description?: string;
  statuses: JiraStatusNG[];
  fields: {
    [key: string]: JiraFieldNG;
  };
  subTask?: boolean;
}

export interface JiraProjectNG {
  id: string;
  key: string;
  name: string;
  issuetypes: {
    [key: string]: JiraIssueTypeNG;
  };
}

export interface ResponseJiraIssueCreateMetadataNG {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JiraIssueCreateMetadataNG;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface JiraIssueUpdateMetadataNG {
  fields: {
    [key: string]: JiraFieldNG;
  };
}

export interface ResponseJiraIssueUpdateMetadataNG {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JiraIssueUpdateMetadataNG;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface OauthAccessTokenResponseDTO {
  accessTokenRef?: string;
  refreshTokenRef?: string;
}

export interface RestResponseOauthAccessTokenResponseDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: OauthAccessTokenResponseDTO;
  responseMessages?: ResponseMessage[];
}

export interface OauthAccessTokenDTO {
  accessToken?: string;
  refreshToken?: string;
}

export interface AuthorInfo {
  name?: string;
  url?: string;
}

export interface DashboardWorkloadDeployment {
  workloadDeploymentInfoList?: WorkloadDeploymentInfo[];
}

export interface LastWorkloadInfo {
  startTime?: number;
  endTime?: number;
  deploymentType?: string;
  status?: string;
  authorInfo?: AuthorInfo;
  triggerType?: string;
}

export interface ResponseDashboardWorkloadDeployment {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: DashboardWorkloadDeployment;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface WorkloadCountInfo {
  count?: number;
}

export interface WorkloadDateCountInfo {
  date?: number;
  execution?: WorkloadCountInfo;
}

export interface WorkloadDeploymentInfo {
  serviceName?: string;
  serviceId?: string;
  lastExecuted?: LastWorkloadInfo;
  deploymentTypeList?: string[];
  totalDeployments?: number;
  totalDeploymentChangeRate?: number;
  percentSuccess?: number;
  rateSuccess?: number;
  failureRate?: number;
  failureRateChangeRate?: number;
  frequency?: number;
  frequencyChangeRate?: number;
  lastPipelineExecutionId?: string;
  workload?: WorkloadDateCountInfo[];
}

export interface InstanceGroupedByArtifact {
  artifactVersion?: string;
  artifactPath?: string;
  instanceGroupedByEnvironmentList?: InstanceGroupedByEnvironment[];
}

export interface InstanceGroupedByArtifactList {
  instanceGroupedByArtifactList?: InstanceGroupedByArtifact[];
}

export interface InstanceGroupedByEnvironment {
  envId?: string;
  envName?: string;
  instanceGroupedByInfraList?: InstanceGroupedByInfrastructure[];
}

export interface InstanceGroupedByInfrastructure {
  infraIdentifier?: string;
  infraName?: string;
  count?: number;
  lastPipelineExecutionId?: string;
  lastPipelineExecutionName?: string;
  lastDeployedAt?: string;
}

export interface ResponseInstanceGroupedByArtifactList {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: InstanceGroupedByArtifactList;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface Deployment {
  count?: number;
}

export interface DeploymentDateAndCount {
  time?: number;
  deployments?: Deployment;
}

export interface DeploymentInfo {
  count?: number;
  rate?: number;
  countList?: DeploymentDateAndCount[];
}

export interface HealthDeploymentDashboard {
  healthDeploymentInfo?: HealthDeploymentInfo;
}

export interface HealthDeploymentInfo {
  total?: TotalDeploymentInfo;
  success?: DeploymentInfo;
  failure?: DeploymentInfo;
  active?: DeploymentInfo;
}

export interface ResponseHealthDeploymentDashboard {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: HealthDeploymentDashboard;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface TotalDeploymentInfo {
  count?: number;
  production?: number;
  nonProduction?: number;
  rate?: number;
  countList?: DeploymentDateAndCount[];
}

export interface DeploymentChangeRates {
  failureRate?: number;
  failureRateChangeRate?: number;
  frequency?: number;
  frequencyChangeRate?: number;
}

export interface DeploymentCount {
  total?: number;
  success?: number;
  failure?: number;
}

export interface ResponseServiceDeploymentInfoDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceDeploymentInfoDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceDeployment {
  time?: number;
  deployments?: DeploymentCount;
  rate?: DeploymentChangeRates;
}

export interface ServiceDeploymentInfoDTO {
  serviceDeploymentList?: ServiceDeployment[];
}

export interface InstanceCountDetailsByEnvTypeBase {
  totalInstances?: number;
  prodInstances?: number;
  nonProdInstances?: number;
}

export interface ResponseServiceDetailsInfoDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceDetailsInfoDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceDetailsDTO {
  serviceName?: string;
  serviceIdentifier?: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  deploymentTypeList?: string[];
  totalDeployments?: number;
  totalDeploymentChangeRate?: number;
  successRate?: number;
  successRateChangeRate?: number;
  failureRate?: number;
  failureRateChangeRate?: number;
  frequency?: number;
  frequencyChangeRate?: number;
  instanceCountDetails?: InstanceCountDetailsByEnvTypeBase;
  lastPipelineExecuted?: ServicePipelineInfo;
}

export interface ServiceDetailsInfoDTO {
  serviceDeploymentDetailsList?: ServiceDetailsDTO[];
}

export interface ServicePipelineInfo {
  pipelineExecutionId?: string;
  planExecutionId?: string;
  identifier?: string;
  name?: string;
  status?: string;
  deployedById?: string;
  deployedByName?: string;
  lastExecutedAt?: number;
}

export interface ResponseServiceDeploymentListInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceDeploymentListInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceDeploymentListInfo {
  startTime?: number;
  endTime?: number;
  totalDeployments?: number;
  failureRate?: number;
  frequency?: number;
  failureRateChangeRate?: number;
  totalDeploymentsChangeRate?: number;
  frequencyChangeRate?: number;
  serviceDeploymentList?: ServiceDeployment[];
}

export interface ExecutionDeployment {
  time?: number;
  deployments?: DeploymentCount;
}

export interface ExecutionDeploymentInfo {
  executionDeploymentList?: ExecutionDeployment[];
}

export interface ResponseExecutionDeploymentInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ExecutionDeploymentInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface DashboardExecutionStatusInfo {
  failure?: ExecutionStatusInfo[];
  pending?: ExecutionStatusInfo[];
  active?: ExecutionStatusInfo[];
}

export interface EnvironmentDeploymentsInfo {
  envId?: string;
  envName?: string;
  envType?: string;
  infrastructureDetails?: InfrastructureInfo[];
}

export interface ExecutionStatusInfo {
  pipelineName?: string;
  pipelineIdentifier?: string;
  startTs?: number;
  endTs?: number;
  status?: string;
  planExecutionId?: string;
  gitInfo?: GitInfo;
  triggerType?: string;
  author?: AuthorInfo;
  serviceInfoList?: ServiceDeploymentInfo[];
  environmentInfoList?: EnvironmentDeploymentsInfo[];
}

export interface GitInfo {
  sourceBranch?: string;
  targetBranch?: string;
  commit?: string;
  commitID?: string;
  eventType?: string;
  repoName?: string;
}

export interface InfrastructureInfo {
  infrastructureIdentifier?: string;
  infrastructureName?: string;
}

export interface ResponseDashboardExecutionStatusInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: DashboardExecutionStatusInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceDeploymentInfo {
  serviceName?: string;
  serviceTag?: string;
  image?: string;
  serviceId?: string;
}

export interface ResponseTimeValuePairListDTOInteger {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: TimeValuePairListDTOInteger;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface TimeValuePair {
  timestamp?: number;
  value?: { [key: string]: any };
}

export interface TimeValuePairInteger {
  timestamp?: number;
  value?: number;
}

export interface TimeValuePairListDTO {
  timeValuePairList?: TimeValuePairObject[];
}

export interface TimeValuePairListDTOInteger {
  timeValuePairList?: TimeValuePairInteger[];
}

export interface TimeValuePairObject {
  timestamp?: number;
  value?: { [key: string]: any };
}

export interface InstanceCountDetailsByEnvTypeAndServiceId {
  instanceCountDetailsByEnvTypeBaseMap?: {
    [key: string]: InstanceCountDetailsByEnvTypeBase;
  };
}

export interface ResponseInstanceCountDetailsByEnvTypeAndServiceId {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: InstanceCountDetailsByEnvTypeAndServiceId;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ActiveServiceInstanceSummary {
  changeRate?: number;
  countDetails?: InstanceCountDetailsByEnvTypeBase;
}

export interface ResponseActiveServiceInstanceSummary {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ActiveServiceInstanceSummary;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface BuildIdAndInstanceCount {
  buildId?: string;
  count?: number;
}

export interface EnvBuildIdAndInstanceCountInfo {
  envId?: string;
  envName?: string;
  buildIdAndInstanceCountList?: BuildIdAndInstanceCount[];
}

export interface EnvBuildIdAndInstanceCountInfoList {
  envBuildIdAndInstanceCountInfoList?: EnvBuildIdAndInstanceCountInfo[];
}

export interface ResponseEnvBuildIdAndInstanceCountInfoList {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvBuildIdAndInstanceCountInfoList;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AwsSshWinrmInstanceInfoDTO = InstanceInfoDTO & {
  serviceType: string;
  infrastructureKey: string;
  host: string;
};

export type AzureSshWinrmInstanceInfoDTO = InstanceInfoDTO & {
  serviceType: string;
  infrastructureKey: string;
  host: string;
};

export type AzureWebAppInfrastructureDetails = InfrastructureDetails & {
  subscriptionId?: string;
  resourceGroup?: string;
};

export type AzureWebAppInstanceInfoDTO = InstanceInfoDTO & {
  subscriptionId: string;
  resourceGroup: string;
  appName: string;
  deploySlot: string;
  appServicePlanId?: string;
  instanceType?: string;
  instanceName?: string;
  deploySlotId?: string;
  hostName?: string;
  instanceIp?: string;
  instanceState?: string;
  instanceId?: string;
};

export type CustomDeploymentInstanceInfoDTO = InstanceInfoDTO & {
  hostname: string;
  instanceFetchScript: string;
  properties?: {
    [key: string]: { [key: string]: any };
  };
};

export interface EcsContainer {
  containerArn?: string;
  name?: string;
  image?: string;
  runtimeId?: string;
}

export type EcsInfrastructureDetails = InfrastructureDetails & {
  region?: string;
  cluster?: string;
};

export type EcsInstanceInfoDTO = InstanceInfoDTO & {
  region: string;
  clusterArn: string;
  taskArn: string;
  taskDefinitionArn: string;
  launchType?: string;
  serviceName: string;
  containers: EcsContainer[];
  startedAt?: number;
  startedBy?: string;
  version?: number;
  infraStructureKey?: string;
};

export type GitOpsInstanceInfoDTO = InstanceInfoDTO & {
  namespace: string;
  podName: string;
  appIdentifier?: string;
  clusterIdentifier?: string;
  agentIdentifier?: string;
  podId?: string;
  containerList: K8sContainer[];
};

export interface HelmChartInfo {
  name?: string;
  version?: string;
  repoUrl?: string;
}

export interface InfrastructureDetails {
  [key: string]: any;
}

export interface InstanceDetailsByBuildId {
  buildId?: string;
  instances?: InstanceDetailsDTO[];
}

export interface InstanceDetailsDTO {
  podName?: string;
  artifactName?: string;
  connectorRef?: string;
  infrastructureDetails?: InfrastructureDetails;
  terraformInstance?: string;
  deployedAt?: number;
  deployedById?: string;
  deployedByName?: string;
  pipelineExecutionName?: string;
  instanceInfoDTO?: InstanceInfoDTO;
}

export interface InstanceInfoDTO {
  podName?: string;
  type?: string;
}

export interface InstancesByBuildIdList {
  instancesByBuildIdList?: InstanceDetailsByBuildId[];
}

export type K8sInfrastructureDetails = InfrastructureDetails & {
  namespace?: string;
  releaseName?: string;
};

export type K8sInstanceInfoDTO = InstanceInfoDTO & {
  namespace: string;
  releaseName: string;
  podName: string;
  podIP?: string;
  blueGreenColor?: string;
  containerList: K8sContainer[];
};

export type NativeHelmInstanceInfoDTO = InstanceInfoDTO & {
  podName: string;
  ip?: string;
  namespace?: string;
  releaseName?: string;
  helmChartInfo?: HelmChartInfo;
  helmVersion?: "V2" | "V3" | "V380";
};

export type PdcInstanceInfoDTO = InstanceInfoDTO & {
  serviceType: string;
  infrastructureKey: string;
  host: string;
};

export type ReferenceInstanceInfoDTO = InstanceInfoDTO & {
  namespace?: string;
  releaseName?: string;
};

export interface ResponseInstancesByBuildIdList {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: InstancesByBuildIdList;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type ServerlessAwsLambdaInfrastructureDetails = InfrastructureDetails & {
  region?: string;
  stage?: string;
};

export type ServerlessAwsLambdaInstanceInfoDTO = InstanceInfoDTO & {
  serviceName: string;
  stage: string;
  functionName: string;
  region: string;
  handler?: string;
  memorySize?: string;
  runTime?: string;
  timeout?: number;
  infraStructureKey?: string;
};

export type SshWinrmInfrastructureDetails = InfrastructureDetails & {
  host?: string;
};

export interface EnvIdCountPair {
  envId?: string;
  count?: number;
}

export interface ResponseTimeValuePairListDTOEnvIdCountPair {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: TimeValuePairListDTOEnvIdCountPair;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface TimeValuePairEnvIdCountPair {
  timestamp?: number;
  value?: EnvIdCountPair;
}

export interface TimeValuePairListDTOEnvIdCountPair {
  timeValuePairList?: TimeValuePairEnvIdCountPair[];
}

export interface DeploymentsInfo {
  deployments?: ExecutionStatusInfo[];
}

export interface ResponseDeploymentsInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: DeploymentsInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseServiceHeaderInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceHeaderInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceHeaderInfo {
  name?: string;
  identifier?: string;
  description?: string;
  deploymentTypes?: string[];
  createdAt?: number;
  lastModifiedAt?: number;
}

export interface EnvironmentDeploymentInfo {
  environmentInfoByServiceId?: EnvironmentInfoByServiceId[];
}

export interface EnvironmentInfoByServiceId {
  environmentName?: string;
  environmentId?: string;
  artifactImage?: string;
  tag?: string;
  serviceName?: string;
  serviceId?: string;
  service_startTs?: number;
  service_endTs?: number;
}

export interface ResponseEnvironmentDeploymentInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvironmentDeploymentInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseServicesDashboardInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServicesDashboardInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceDashboardInfo {
  name?: string;
  identifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  accountIdentifier?: string;
  totalDeploymentsCount?: number;
  successDeploymentsCount?: number;
  failureDeploymentsCount?: number;
  totalDeploymentsChangeRate?: number;
  instancesCount?: number;
  instancesCountChangeRate?: number;
}

export interface ServicesDashboardInfo {
  serviceDashboardInfoList?: ServiceDashboardInfo[];
}

export interface LandingDashboardRequestCD {
  orgProjectIdentifiers: OrgProjectIdentifier[];
}

export interface OrgProjectIdentifier {
  orgIdentifier?: string;
  projectIdentifier?: string;
}

export interface ProjectDashBoardInfo {
  projectIdentifier?: string;
  orgIdentifier?: string;
  accountId?: string;
  deploymentsCount?: number;
  deploymentsCountChangeRate?: number;
  successDeploymentsCount?: number;
  failedDeploymentsCount?: number;
}

export interface ProjectsDashboardInfo {
  projectDashBoardInfoList?: ProjectDashBoardInfo[];
}

export interface ResponseProjectsDashboardInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ProjectsDashboardInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface DeploymentStatsSummary {
  totalCount?: number;
  totalCountChangeRate?: number;
  failureRate?: number;
  failureRateChangeRate?: number;
  deploymentRate?: number;
  deploymentRateChangeRate?: number;
  timeBasedDeploymentInfoList?: TimeBasedDeploymentInfo[];
}

export interface ResponseDeploymentStatsSummary {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: DeploymentStatsSummary;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface TimeBasedDeploymentInfo {
  epochTime?: number;
  totalCount?: number;
  successCount?: number;
  failedCount?: number;
  failureRate?: number;
}

export interface PipelineExecutionDashboardInfo {
  name?: string;
  identifier?: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  accountIdentifier?: string;
  planExecutionId?: string;
  startTs?: number;
}

export interface PipelinesExecutionDashboardInfo {
  runningExecutions?: PipelineExecutionDashboardInfo[];
  pendingApprovalExecutions?: PipelineExecutionDashboardInfo[];
  pendingManualInterventionExecutions?: PipelineExecutionDashboardInfo[];
  failed24HrsExecutions?: PipelineExecutionDashboardInfo[];
}

export interface ResponsePipelinesExecutionDashboardInfo {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PipelinesExecutionDashboardInfo;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseServicesCount {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServicesCount;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServicesCount {
  totalCount?: number;
  newCount?: number;
}

export interface EnvCount {
  totalCount?: number;
  newCount?: number;
}

export interface ResponseEnvCount {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: EnvCount;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface Member {
  value?: string;
  ref?: string;
  display?: string;
}

export interface ScimGroup {
  id?: string;
  externalId?: string;
  meta?: JsonNode;
  schemas?: string[];
  displayName?: string;
  members?: Member[];
  harnessScopes?: string;
}

export type AddOperation = PatchOperation & {
  value?: JsonNode;
};

export type OktaAddOperation = PatchOperation & {
  value?: JsonNode;
};

export type OktaRemoveOperation = PatchOperation & {
  value?: JsonNode;
};

export type OktaReplaceOperation = PatchOperation & {
  value?: JsonNode;
};

export interface PatchOperation {
  path?: string;
}

export interface PatchRequest {
  id?: string;
  externalId?: string;
  meta?: JsonNode;
  Operations: PatchOperation[];
  schemas: string[];
}

export type RemoveOperation = PatchOperation & {
  value?: JsonNode;
};

export type ReplaceOperation = PatchOperation & {
  value?: JsonNode;
};

export interface ScimUser {
  schemas?: string[];
  userName?: string;
  displayName?: string;
  active?: boolean;
  emails?: JsonNode;
  roles?: JsonNode;
  name?: JsonNode;
  groups?: JsonNode;
  password?: JsonNode;
  id?: string;
  externalId?: string;
  meta?: JsonNode;
}

export interface ResponseServiceAccountDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceAccountDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceAccountDTO {
  identifier: string;
  name: string;
  email: string;
  description?: string;
  tags?: {
    [key: string]: string;
  };
  accountIdentifier: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
}

export interface ResponseListServiceAccountDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceAccountDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PageServiceAccountAggregateDTO {
  totalPages?: number;
  totalItems?: number;
  pageItemCount?: number;
  pageSize?: number;
  content?: ServiceAccountAggregateDTO[];
  pageIndex?: number;
  empty?: boolean;
}

export interface ResponsePageServiceAccountAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PageServiceAccountAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceAccountAggregateDTO {
  serviceAccount: ServiceAccountDTO;
  createdAt: number;
  lastModifiedAt: number;
  tokensCount?: number;
  roleAssignmentsMetadataDTO?: RoleAssignmentMetadataDTO[];
}

export interface ResponseServiceAccountAggregateDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceAccountAggregateDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseListServiceNowFieldNG {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceNowFieldNG[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceNowFieldAllowedValueNG {
  id?: string;
  name?: string;
  value?: string;
}

export interface ServiceNowFieldNG {
  key: string;
  name: string;
  required?: boolean;
  schema: ServiceNowFieldSchemaNG;
  internalType?: string;
  allowedValues: ServiceNowFieldAllowedValueNG[];
  custom?: boolean;
}

export interface ServiceNowFieldSchemaNG {
  array?: boolean;
  typeStr: string;
  type:
    | "glide_date_time"
    | "integer"
    | "boolean"
    | "string"
    | "option"
    | "unknown";
  customType?: string;
}

export interface ResponseListServiceNowTicketTypeDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceNowTicketTypeDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceNowTicketTypeDTO {
  key: string;
  name: string;
}

export interface ResponseListServiceNowTemplate {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ServiceNowTemplate[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ServiceNowFieldValueNG {
  value?: string;
  displayValue?: string;
}

export interface ServiceNowTemplate {
  sys_id: string;
  name: string;
  fields: {
    [key: string]: ServiceNowFieldValueNG;
  };
}

export interface ResponseDelegateStatus {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: "SUCCESS" | "IN_PROGRESS" | "FAILURE";
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseScmConnectorResponse {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: ScmConnectorResponse;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ScmConnectorResponse {
  connectorResponseDTO?: ConnectorResponse;
  secretResponseWrapper?: SecretResponseWrapper;
  connectorValidationResult?: ConnectorValidationResult;
}

export interface ScmConnectorDTO {
  secret?: SecretDTOV2;
  connector?: ConnectorInfoDTO;
}

export interface ResponseSetupStatus {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?:
    | "SUCCESS"
    | "DELEGATE_PROVISION_FAILURE"
    | "K8S_CONNECTOR_PROVISION_FAILURE"
    | "DOCKER_CONNECTOR_PROVISION_FAILURE"
    | "PROVISIONING_DISABLED";
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export type AwsCodeCommitSCMDTO = SourceCodeManagerDTO & {
  authentication: AwsCodeCommitAuthenticationDTO;
};

export type AzureRepoSCMDTO = SourceCodeManagerDTO & {
  authentication?: AzureRepoAuthentication;
};

export type BitbucketSCMDTO = SourceCodeManagerDTO & {
  authentication?: BitbucketAuthentication;
};

export type GithubSCMDTO = SourceCodeManagerDTO & {
  authentication?: GithubAuthentication;
};

export type GitlabSCMDTO = SourceCodeManagerDTO & {
  authentication: GitlabAuthentication;
};

export interface ResponseListSourceCodeManagerDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SourceCodeManagerDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SourceCodeManagerAuthentication {
  [key: string]: any;
}

export interface SourceCodeManagerDTO {
  id?: string;
  userIdentifier?: string;
  accountIdentifier?: string;
  name: string;
  createdAt?: number;
  lastModifiedAt?: number;
  authentication?: SourceCodeManagerAuthentication;
  type?: "BITBUCKET" | "GITHUB" | "GITLAB" | "AWS_CODE_COMMIT" | "AZURE_REPO";
}

export interface ResponseSourceCodeManagerDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SourceCodeManagerDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseSettingValueResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SettingValueResponseDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SettingValueResponseDTO {
  valueType: "String" | "Boolean" | "Number";
  value: string;
}

export interface ResponseListSettingUpdateResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SettingUpdateResponseDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SettingDTO {
  identifier: string;
  name: string;
  orgIdentifier?: string;
  projectIdentifier?: string;
  category:
    | "CD"
    | "CI"
    | "CE"
    | "CV"
    | "CF"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  groupIdentifier: string;
  valueType: "String" | "Boolean" | "Number";
  allowedValues?: string[];
  allowOverrides: boolean;
  value?: string;
  defaultValue?: string;
  settingSource?: "ACCOUNT" | "ORG" | "PROJECT" | "DEFAULT";
  isSettingEditable: boolean;
}

export interface SettingUpdateResponseDTO {
  identifier?: string;
  setting: SettingDTO;
  lastModifiedAt?: number;
  updateStatus?: boolean;
  errorMessage?: string;
}

export interface SettingRequestDTO {
  identifier?: string;
  value?: string;
  allowOverrides: boolean;
  updateType: "UPDATE" | "RESTORE";
}

export interface ResponseListSettingResponseDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SettingResponseDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SettingResponseDTO {
  setting: SettingDTO;
  lastModifiedAt?: number;
}

export interface SignupDTO {
  email?: string;
  password?: string;
  utmInfo?: UtmInfo;
  intent?: string;
  signupAction?: "REGULAR" | "TRIAL" | "SUBSCRIBE";
  edition?: "COMMUNITY" | "FREE" | "TEAM" | "ENTERPRISE";
  billingFrequency?: "MONTHLY" | "YEARLY";
}

export interface RestResponseUserInfo {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: UserInfo;
  responseMessages?: ResponseMessage[];
}

export interface OAuthSignupDTO {
  email?: string;
  name?: string;
  utmInfo?: UtmInfo;
  intent?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  signupAction?: "REGULAR" | "TRIAL" | "SUBSCRIBE";
  edition?: "COMMUNITY" | "FREE" | "TEAM" | "ENTERPRISE";
}

export interface RestResponseVerifyTokenResponseDTO {
  metaData?: {
    [key: string]: { [key: string]: any };
  };
  resource?: VerifyTokenResponseDTO;
  responseMessages?: ResponseMessage[];
}

export interface VerifyTokenResponseDTO {
  accountIdentifier?: string;
}

export interface InvoiceDetailDTO {
  items?: ItemDTO[];
  subscriptionId?: string;
  invoiceId?: string;
  totalAmount?: number;
  periodStart?: number;
  periodEnd?: number;
  nextPaymentAttempt?: number;
  amountDue?: number;
  clientSecret?: string;
  paymentIntent?: PaymentIntentDetailDTO;
}

export interface ItemDTO {
  price?: PriceDTO;
  description?: string;
  quantity?: number;
  amount?: number;
  proration?: boolean;
}

export interface NextActionDetailDTO {
  type?: string;
  useStripeSdk?: {
    [key: string]: { [key: string]: any };
  };
}

export interface PaymentIntentDetailDTO {
  id?: string;
  clientSecret?: string;
  status?: string;
  nextAction?: NextActionDetailDTO;
}

export interface PendingUpdateDetailDTO {
  expiresAt?: number;
}

export interface PriceDTO {
  priceId?: string;
  currency?: string;
  tiersDTO?: TiersDTO[];
  tierMode?: "volume" | "graduated";
  unitAmount?: number;
  lookupKey?: string;
  productId?: string;
  metaData?: {
    [key: string]: string;
  };
  active?: boolean;
}

export interface ResponseListSubscriptionDetailDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SubscriptionDetailDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface SubscriptionDetailDTO {
  subscriptionId?: string;
  accountIdentifier?: string;
  customerId?: string;
  moduletype?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  status?: string;
  cancelAt?: number;
  canceledAt?: number;
  clientSecret?: string;
  pendingUpdate?: PendingUpdateDetailDTO;
  latestInvoice?: string;
  latestInvoiceDetail?: InvoiceDetailDTO;
}

export interface TiersDTO {
  upTo?: number;
  unitAmount?: number;
}

export interface ResponseEnumMapUsageKeyLong {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: {
    [key: string]: number;
  };
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PriceCollectionDTO {
  prices?: PriceDTO[];
}

export interface ResponsePriceCollectionDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PriceCollectionDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseSubscriptionDetailDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: SubscriptionDetailDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface AddressDto {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
}

export interface CustomerDTO {
  billingEmail?: string;
  companyName?: string;
  address?: AddressDto;
}

export interface FfSubscriptionDTO {
  accountId?: string;
  numberOfMau?: number;
  numberOfDevelopers?: number;
  paymentFreq?: string;
  edition?: string;
  premiumSupport?: boolean;
  customer?: CustomerDTO;
}

export interface ItemParams {
  priceId?: string;
  quantity?: number;
  lookupKey?: string;
}

export interface SubscriptionDTO {
  customerId?: string;
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  paymentMethodId?: string;
  items?: ItemParams[];
}

export interface ResponseVoid {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: Void;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseInvoiceDetailDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: InvoiceDetailDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface CustomerDetailDTO {
  customerId?: string;
  billingEmail?: string;
  companyName?: string;
  defaultSource?: string;
  address?: AddressDto;
}

export interface ResponseCustomerDetailDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: CustomerDetailDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface StripeBillingDTO {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  creditCardId?: string;
}

export interface CardDTO {
  id?: string;
  name?: string;
  last4?: string;
  funding?: string;
  expireMonth?: number;
  expireYear?: number;
  cvcCheck?: string;
  brand?: string;
  addressCity?: string;
  addressCountry?: string;
  addressState?: string;
  addressZip?: string;
  addressLine1?: string;
  addressLine2?: string;
}

export interface PaymentMethodCollectionDTO {
  paymentMethods?: CardDTO[];
}

export interface ResponsePaymentMethodCollectionDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PaymentMethodCollectionDTO;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface PartialSchemaDTO {
  schema?: JsonNode;
  nodeType?: string;
  nodeName?: string;
  namespace?: string;
  skipStageSchema?: boolean;
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
}

export interface ResponseListPartialSchemaDTO {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: PartialSchemaDTO[];
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseYamlSchemaDetailsWrapper {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: YamlSchemaDetailsWrapper;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface YamlGroup {
  group?: string;
}

export interface YamlSchemaDetailsWrapper {
  yamlSchemaWithDetailsList?: YamlSchemaWithDetails[];
}

export interface YamlSchemaMetadata {
  namespace?: string;
  modulesSupported?: (
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS"
  )[];
  featureFlags?: string[];
  featureRestrictions?: string[];
  yamlGroup: YamlGroup;
}

export interface YamlSchemaWithDetails {
  schema?: JsonNode;
  schemaClassName?: string;
  moduleType?:
    | "CD"
    | "CI"
    | "CV"
    | "CF"
    | "CE"
    | "STO"
    | "CORE"
    | "PMS"
    | "TEMPLATESERVICE"
    | "GOVERNANCE"
    | "CHAOS";
  yamlSchemaMetadata?: YamlSchemaMetadata;
  availableAtOrgLevel?: boolean;
  availableAtAccountLevel?: boolean;
  availableAtProjectLevel?: boolean;
}

export interface ResponseJsonNode {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: JsonNode;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface ResponseYamlSnippets {
  status?: "SUCCESS" | "FAILURE" | "ERROR";
  data?: YamlSnippets;
  metaData?: { [key: string]: any };
  correlationId?: string;
}

export interface YamlSnippetMetaData {
  name?: string;
  description?: string;
  version?: string;
  identifier?: string;
  tags?: string[];
  iconTag?: string;
}

export interface YamlSnippets {
  yamlSnippets?: YamlSnippetMetaData[];
}

export type GitOpsInstanceRequestArrayRequestBody = GitOpsInstanceRequest[];

export type ServiceRequestDTORequestBody = ServiceRequestDTO;

export type SignupDTORequestBody = SignupDTO;

export type ApiKeyDTORequestBody = ApiKeyDTO;

export type PatchRequestRequestBody = PatchRequest;

export type TokenDTORequestBody = TokenDTO;

export type PostLdapAuthenticationTestRequestBody = void;

export type OrganizationRequestRequestBody = OrganizationRequest;

export type LandingDashboardRequestCDRequestBody = LandingDashboardRequestCD;

export type CustomDeploymentYamlRequestRequestBody = CustomDeploymentYamlRequest;

export type GcrRequestDTORequestBody = GcrRequestDTO;

export type GetBuildDetailsForArtifactoryArtifactWithYamlBodyRequestBody = string;

export type GitOpsProviderRequestBody = GitOpsProvider;

export type UploadSamlMetaDataRequestBody = void;

export type HostValidationParamsRequestBody = HostValidationParams;

export type ProjectRequestRequestBody = ProjectRequest;

export type GitSyncConfigRequestBody = GitSyncConfig;

export type DelegateProfileDetailsNgRequestBody = DelegateProfileDetailsNg;

export type GetBuildDetailsForAcrArtifactWithYamlBodyRequestBody = string;

export type RoleAssignmentFilterRequestBody = RoleAssignmentFilter;

export type GitFullSyncConfigRequestDTORequestBody = GitFullSyncConfigRequestDTO;

export type CFParametersForAwsBodyRequestBody = string;

export type ServiceAccountDTORequestBody = ServiceAccountDTO;

export type FileStoreRequestRequestBody = void;

export type ConnectorFilterPropertiesRequestBody = ConnectorFilterProperties;

export type LdapSettingsRequestBody = LdapSettings;

export type UserGroupDTORequestBody = UserGroupDTO;

export type FilterDTORequestBody = FilterDTO;

export type SubscriptionDTORequestBody = SubscriptionDTO;

export type AccountDTORequestBody = AccountDTO;

export type EnvironmentGroupRequestDTORequestBody = EnvironmentGroupRequestDTO;

export type ConnectorRequestBody = Connector;

export type GitSyncSettingsDTORequestBody = GitSyncSettingsDTO;

export type StartTrialDTORequestBody = StartTrialDTO;

export type LDAPSettingsRequestBody = LDAPSettings;

export type UpdateWhitelistedDomainsBodyRequestBody = string[];

export type AccountSettingsRequestBody = AccountSettings;

export type DockerRequestDTORequestBody = DockerRequestDTO;

export type EcrRequestDTORequestBody = EcrRequestDTO;

export type ScopingRuleDetailsNgArrayRequestBody = ScopingRuleDetailsNg[];

export type DelegateGroupTagsRequestBody = DelegateGroupTags;

export type DelegateDownloadRequestRequestBody = DelegateDownloadRequest;

export type EnvironmentRequestDTORequestBody = EnvironmentRequestDTO;

export type InfrastructureRequestDTORequestBody = InfrastructureRequestDTO;

export type SecretRequestWrapperRequestBody = SecretRequestWrapper;

export type SecretRequestWrapper2RequestBody = void;

export type UserFilterRequestBody = UserFilter;

export type ServiceRequestDTOArrayRequestBody = ServiceRequestDTO[];

export type NgSmtpDTORequestBody = NgSmtpDTO;

export type VariableRequestDTORequestBody = VariableRequestDTO;

export type ScimGroupRequestBody = ScimGroup;

export type ScimUserRequestBody = ScimUser;

export type SourceCodeManagerDTORequestBody = SourceCodeManagerDTO;

export type CustomerDTORequestBody = CustomerDTO;

export type YamlSchemaDetailsWrapperRequestBody = YamlSchemaDetailsWrapper;

export interface SignupQueryParams {
  captchaToken?: string;
}

export type SignupProps = Omit<
  MutateProps<
    RestResponseVoid,
    Failure | Error,
    SignupQueryParams,
    SignupDTORequestBody,
    void
  >,
  "path" | "verb"
>;

export const Signup = (props: SignupProps) => (
  <Mutate<
    RestResponseVoid,
    Failure | Error,
    SignupQueryParams,
    SignupDTORequestBody,
    void
  >
    verb="POST"
    path={`/signup`}
    base={getConfig("ng/api")}
    {...props}
  />
);

export type UseSignupProps = Omit<
  UseMutateProps<
    RestResponseVoid,
    Failure | Error,
    SignupQueryParams,
    SignupDTORequestBody,
    void
  >,
  "path" | "verb"
>;

export const useSignup = (props: UseSignupProps) =>
  useMutate<
    RestResponseVoid,
    Failure | Error,
    SignupQueryParams,
    SignupDTORequestBody,
    void
  >("POST", `/signup`, { base: getConfig("ng/api"), ...props });

export interface CompleteSignupInviteQueryParams {
  referer?: string;
}

export interface CompleteSignupInvitePathParams {
  token: string;
}

export type CompleteSignupInviteProps = Omit<
  MutateProps<
    RestResponseUserInfo,
    Failure | Error,
    CompleteSignupInviteQueryParams,
    void,
    CompleteSignupInvitePathParams
  >,
  "path" | "verb"
> &
  CompleteSignupInvitePathParams;

export const CompleteSignupInvite = ({
  token,
  ...props
}: CompleteSignupInviteProps) => (
  <Mutate<
    RestResponseUserInfo,
    Failure | Error,
    CompleteSignupInviteQueryParams,
    void,
    CompleteSignupInvitePathParams
  >
    verb="PUT"
    path={`/signup/complete/${token}`}
    base={getConfig("ng/api")}
    {...props}
  />
);

export type UseCompleteSignupInviteProps = Omit<
  UseMutateProps<
    RestResponseUserInfo,
    Failure | Error,
    CompleteSignupInviteQueryParams,
    void,
    CompleteSignupInvitePathParams
  >,
  "path" | "verb"
> &
  CompleteSignupInvitePathParams;

export const useCompleteSignupInvite = ({
  token,
  ...props
}: UseCompleteSignupInviteProps) =>
  useMutate<
    RestResponseUserInfo,
    Failure | Error,
    CompleteSignupInviteQueryParams,
    void,
    CompleteSignupInvitePathParams
  >(
    "PUT",
    (paramsInPath: CompleteSignupInvitePathParams) =>
      `/signup/complete/${paramsInPath.token}`,
    { base: getConfig("ng/api"), pathParams: { token }, ...props }
  );

export interface ResendVerifyEmailQueryParams {
  email: string;
}

export type ResendVerifyEmailProps = Omit<
  MutateProps<
    ResponseBoolean,
    Failure | Error,
    ResendVerifyEmailQueryParams,
    void,
    void
  >,
  "path" | "verb"
>;

/**
 * Resend user verification email
 */
export const ResendVerifyEmail = (props: ResendVerifyEmailProps) => (
  <Mutate<
    ResponseBoolean,
    Failure | Error,
    ResendVerifyEmailQueryParams,
    void,
    void
  >
    verb="POST"
    path={`/signup/verify-notification`}
    base={getConfig("ng/api")}
    {...props}
  />
);

export type UseResendVerifyEmailProps = Omit<
  UseMutateProps<
    ResponseBoolean,
    Failure | Error,
    ResendVerifyEmailQueryParams,
    void,
    void
  >,
  "path" | "verb"
>;

/**
 * Resend user verification email
 */
export const useResendVerifyEmail = (props: UseResendVerifyEmailProps) =>
  useMutate<
    ResponseBoolean,
    Failure | Error,
    ResendVerifyEmailQueryParams,
    void,
    void
  >("POST", `/signup/verify-notification`, {
    base: getConfig("ng/api"),
    ...props
  });

export type CommunitySignupProps = Omit<
  MutateProps<
    RestResponseUserInfo,
    Failure | Error,
    void,
    SignupDTORequestBody,
    void
  >,
  "path" | "verb"
>;

export const CommunitySignup = (props: CommunitySignupProps) => (
  <Mutate<
    RestResponseUserInfo,
    Failure | Error,
    void,
    SignupDTORequestBody,
    void
  >
    verb="POST"
    path={`/signup/community`}
    base={getConfig("ng/api")}
    {...props}
  />
);

export type UseCommunitySignupProps = Omit<
  UseMutateProps<
    RestResponseUserInfo,
    Failure | Error,
    void,
    SignupDTORequestBody,
    void
  >,
  "path" | "verb"
>;

export const useCommunitySignup = (props: UseCommunitySignupProps) =>
  useMutate<
    RestResponseUserInfo,
    Failure | Error,
    void,
    SignupDTORequestBody,
    void
  >("POST", `/signup/community`, { base: getConfig("ng/api"), ...props });
